const headers 	 = ['THEFF', 'ELEFF', 'LHV', 'CO2', 'CAPEX', 'OPEXFIX', 'OPEXVAR', 'FUELCOST', 'LIFETIME']
const units = ['%', '%', 'MWh/t', 't CO2 equiv./MWh', '(1000 EUR)/MW', '(1000 EUR)/(MW year)', '(1000 EUR)/(MWh year)', '(1000 EUR)/MWh', 'years']

const schema 	 = [
["PV", 	            "ROOFTOP", 	"PD_TechPvRoofHshare", 	"PD_TechPvRoofEshare", 	"PD_TechPvRoofLhv", 	"PD_TechPvRoofCoo", 	"PD_TechPvRoofCapex", 	"PD_TechPvRoofOpexfix", 	"PD_TechPvRoofOpexvar", 	"PD_TechPvRoofFuel", 	"PD_TechPvRoofLifetime"],
["PV", 	            "GROUNDMOUNT", 	"PD_TechPvGroundHshare", 	"PD_TechPvGroundEshare", 	"PD_TechPvGroundLhv", 	"PD_TechPvGroundCoo", 	"PD_TechPvGroundCapex", 	"PD_TechPvGroundOpexfix", 	"PD_TechPvGroundOpexvar", 	"PD_TechPvGroundFuel", 	"PD_TechPvGroundLifetime"],
["WIND", 	        "ONSHORE", 	"PD_TechWindOnshoreHshare", 	"PD_TechWindOnshoreEshare", 	"PD_TechWindOnshoreLhv", 	"PD_TechWindOnshoreCoo", 	"PD_TechWindOnshoreCapex", 	"PD_TechWindOnshoreOpexfix", 	"PD_TechWindOnshoreOpexvar", 	"PD_TechWindOnshoreFuel", 	"PD_TechWindOnshoreLifetime"],
["HYDROELECTRIC", 	"LARGE", 	"PD_TechHydroLargeHshare", 	"PD_TechHydroLargeEshare", 	"PD_TechHydroLargeLhv", 	"PD_TechHydroLargeCoo", 	"PD_TechHydroLargeCapex", 	"PD_TechHydroLargeOpexfix", 	"PD_TechHydroLargeOpexvar", 	"PD_TechHydroLargeFuel", 	"PD_TechHydroLargeLifetime"],
["HYDROELECTRIC", 	"SMALLANDVERYSMALL", 	"PD_TechHydroSmallHshare", 	"PD_TechHydroSmallEshare", 	"PD_TechHydroSmallLhv", 	"PD_TechHydroSmallCoo", 	"PD_TechHydroSmallCapex", 	"PD_TechHydroSmallOpexfix", 	"PD_TechHydroSmallOpexvar", 	"PD_TechHydroSmallFuel", 	"PD_TechHydroSmallLifetime"],
["BIOGAS", 	        "TPP", 	"PD_TechTppBiogHshare", 	"PD_TechTppBiogEshare", 	"PD_TechTppBiogLhv", 	"PD_TechTppBiogCoo", 	"PD_TechTppBiogCapex", 	"PD_TechTppBiogOpexfix", 	"PD_TechTppBiogOpexvar", 	"PD_TechTppBiogFuel", 	"PD_TechTppBiogLifetime"],
["BIOGAS", 	        "CHP", 	"PD_TechChpBiogAllHshare", 	"PD_TechChpBiogAllEshare", 	"PD_TechChpBiogAllLhv", 	"PD_TechChpBiogAllCoo", 	"PD_TechChpBiogAllCapex", 	"PD_TechChpBiogAllOpexfix", 	"PD_TechChpBiogAllOpexvar", 	"PD_TechChpBiogAllFuel", 	"PD_TechChpBiogAllLifetime"],
["BIOMASS", 	    "TPP", 	"PD_TechTppBiomHshare", 	"PD_TechTppBiomEshare", 	"PD_TechTppBiomLhv", 	"PD_TechTppBiomCoo", 	"PD_TechTppBiomCapex", 	"PD_TechTppBiomOpexfix", 	"PD_TechTppBiomOpexvar", 	"PD_TechTppBiomFuel", 	"PD_TechTppBiomLifetime"],
["BIOMASS", 	    "CHP", 	"PD_TechChpBiomAllHshare", 	"PD_TechChpBiomAllEshare", 	"PD_TechChpBiomAllLhv", 	"PD_TechChpBiomAllCoo", 	"PD_TechChpBiomAllCapex", 	"PD_TechChpBiomAllOpexfix", 	"PD_TechChpBiomAllOpexvar", 	"PD_TechChpBiomAllFuel", 	"PD_TechChpBiomAllLifetime"],
["MSW", 	        "TPP", 	"PD_TechTppMswHshare", 	"PD_TechTppMswEshare", 	"PD_TechTppMswLhv", 	"PD_TechTppMswCoo", 	"PD_TechTppMswCapex", 	"PD_TechTppMswOpexfix", 	"PD_TechTppMswOpexvar", 	"PD_TechTppMswFuel", 	"PD_TechTppMswLifetime"],
["MSW", 	        "CHP", 	"PD_TechChpMswAllHshare", 	"PD_TechChpMswAllEshare", 	"PD_TechChpMswAllLhv", 	"PD_TechChpMswAllCoo", 	"PD_TechChpMswAllCapex", 	"PD_TechChpMswAllOpexfix", 	"PD_TechChpMswAllOpexvar", 	"PD_TechChpMswAllFuel", 	"PD_TechChpMswAllLifetime"],
["NATURAL", 	    "TPP", 	"PD_TechTppNgasHshare", 	"PD_TechTppNgasEshare", 	"PD_TechTppNgasLhv", 	"PD_TechTppNgasCoo", 	"PD_TechTppNgasCapex", 	"PD_TechTppNgasOpexfix", 	"PD_TechTppNgasOpexvar", 	"PD_TechTppNgasFuel", 	"PD_TechTppNgasLifetime"],
["NATURAL", 	    "CHP", 	"PD_TechChpNgasAllHshare", 	"PD_TechChpNgasAllEshare", 	"PD_TechChpNgasAllLhv", 	"PD_TechChpNgasAllCoo", 	"PD_TechChpNgasAllCapex", 	"PD_TechChpNgasAllOpexfix", 	"PD_TechChpNgasAllOpexvar", 	"PD_TechChpNgasAllFuel", 	"PD_TechChpNgasAllLifetime"],
["COAL", 	        "TPP", 	"PD_TechTppCoalHshare", 	"PD_TechTppCoalEshare", 	"PD_TechTppCoalLhv", 	"PD_TechTppCoalCoo", 	"PD_TechTppCoalCapex", 	"PD_TechTppCoalOpexfix", 	"PD_TechTppCoalOpexvar", 	"PD_TechTppCoalFuel", 	"PD_TechTppCoalLifetime"],
["COAL", 	        "CHP", 	"PD_TechChpCoalAllHshare", 	"PD_TechChpCoalAllEshare", 	"PD_TechChpCoalAllLhv", 	"PD_TechChpCoalAllCoo", 	"PD_TechChpCoalAllCapex", 	"PD_TechChpCoalAllOpexfix", 	"PD_TechChpCoalAllOpexvar", 	"PD_TechChpCoalAllFuel", 	"PD_TechChpCoalAllLifetime"],
["NGBOILER", 	    "", 	"PD_TechBoilNgasAllHshare", 	"PD_TechBoilNgasAllEshare", 	"PD_TechBoilNgasAllLhv", 	"PD_TechBoilNgasAllCoo", 	"PD_TechBoilNgasAllCapex", 	"PD_TechBoilNgasAllOpexfix", 	"PD_TechBoilNgasAllOpexvar", 	"PD_TechBoilNgasAllFuel", 	"PD_TechBoilNgasAllLifetime"],
["FUEL", 	        "", 	"PD_TechBoilOilAllHshare", 	"PD_TechBoilOilAllEshare", 	"PD_TechBoilOilAllLhv", 	"PD_TechBoilOilAllCoo", 	"PD_TechBoilOilAllCapex", 	"PD_TechBoilOilAllOpexfix", 	"PD_TechBoilOilAllOpexvar", 	"PD_TechBoilOilAllFuel", 	"PD_TechBoilOilAllLifetime"],
["BIOMASSBOILER", 	"", 	"PD_TechBoilBiomAllHshare", 	"PD_TechBoilBiomAllEshare", 	"PD_TechBoilBiomAllLhv", 	"PD_TechBoilBiomAllCoo", 	"PD_TechBoilBiomAllCapex", 	"PD_TechBoilBiomAllOpexfix", 	"PD_TechBoilBiomAllOpexvar", 	"PD_TechBoilBiomAllFuel", 	"PD_TechBoilBiomAllLifetime"],
["LPG", 	        "", 	"PD_TechBoilLpgAllHshare", 	"PD_TechBoilLpgAllEshare", 	"PD_TechBoilLpgAllLhv", 	"PD_TechBoilLpgAllCoo", 	"PD_TechBoilLpgAllCapex", 	"PD_TechBoilLpgAllOpexfix", 	"PD_TechBoilLpgAllOpexvar", 	"PD_TechBoilLpgAllFuel", 	"PD_TechBoilLpgAllLifetime"],
["HEAT", 	        "AIRSOURCE", 	"PD_TechHpAirwaterAllHshare", 	"PD_TechHpAirwaterAllEshare", 	"PD_TechHpAirwaterAllLhv", 	"PD_TechHpAirwaterAllCoo", 	"PD_TechHpAirwaterAllCapex", 	"PD_TechHpAirwaterAllOpexfix", 	"PD_TechHpAirwaterAllOpexvar", 	"PD_TechHpAirwaterAllFuel", 	"PD_TechHpAirwaterAllLifetime"],
["HEAT", 	        "GROUNDSOURCE", 	"PD_TechHpBheAllHshare", 	"PD_TechHpBheAllEshare", 	"PD_TechHpBheAllLhv", 	"PD_TechHpBheAllCoo", 	"PD_TechHpBheAllCapex", 	"PD_TechHpBheAllOpexfix", 	"PD_TechHpBheAllOpexvar", 	"PD_TechHpBheAllFuel", 	"PD_TechHpBheAllLifetime"],
["ELECTRIC", 	    "BATTERY", 	"PD_TechBatHshare", 	"PD_TechBatEshare", 	"PD_TechBatLhv", 	"PD_TechBatCoo", 	"PD_TechBatCapex", 	"PD_TechBatOpexfix", 	"PD_TechBatOpexvar", 	"PD_TechBatFuel", 	"PD_TechBatLifetime"],
["THERMAL", 	    "", 	"PD_TechTesAllHshare", 	"PD_TechTesAllEshare", 	"PD_TechTesAllLhv", 	"PD_TechTesAllCoo", 	"PD_TechTesAllCapex", 	"PD_TechTesAllOpexfix", 	"PD_TechTesAllOpexvar", 	"PD_TechTesAllFuel", 	"PD_TechTesAllLifetime"],
["ELECTRICITY", 	"", 	"", 	"", 	"", 	"PD_TechEImpCoo", 	"", 	"", 	"", 	"PD_TechEImpFuel", 	""],
]


export const tech_schema = { headers, units, schema }
