import React, { Component, Fragment } 	from "react";
import classnames       				from 'classnames';
import ProvinceTreeViewer 				from './ProvinceTreeViewer'
import { FrontPageChart } 				from '../chart/FrontPageChart'

import MasterFrame						from '../app/MasterFrame'
import {ThailandMap} 					from './ThaiMapReact'
import {AppController} 					from '../app/AppController'

import {Help}							from '../help/Help'

import { Navigation } from '../navigation/navigation'


const API_KEY = 'ExgWFH9XHHyXIHyKEHIH'



class ProvinceSelector extends Component
{
	static contextType = AppController;

	constructor( props )
	{
		super( props )
		this.state = {}
	}


	render_error() {
		return <div className='sankey-menu'>
			<div className='warning section'>Data for scenario is not available</div>
			<Help id='province-selector' />
		</div>
	}



	display_province( pdata )
	{
		return (
			<div className="province-details">
				<div className="name">{pdata.name}</div>
				{pdata.uploads && pdata.uploads.length > 0 && (
					<div className="uploads">
						<div className="title">Uploads</div>
						<div className="content">{pdata.uploads.map((u, idx) => this.upload(pdata, u, idx))}</div>
					</div>
				)}
				</div>)
	}


	setSelectedProvince( province )
	{
		this.props.history.push( `/province/${province.key}`.trim().toLowerCase())
	}


	render()
	{
		if (!this.context || !this.context.ctx.i18nstr)
			return <div/>
		const i18 = this.context.ctx.i18nstrp("TITLE")


		const {history,match, location}	= this.props
		const province  				= this.get_province()
		let province_data
		if (this.context.provinces && province)
			province_data = this.context.provinces[province.toUpperCase()]

		return 	<MasterFrame history={history} match={match} province={province}>
					<div className="sankey-main">
						<div className="sankey-menu">
							{this.unit_selector({area: false, currency: false })}

							<div className='section'>&nbsp;</div>
							<Help id="province-selector"/>
						</div>
						<div className="sankey-container shadowfill">
							<div className={classnames("bottom")} style={{backgroundColor:"white"}}>
								<div className='frontpage-rows'>
									<div className='top-section'>
										<div className='helpblock'>
											<div className='title'>
												<span>{i18("HEADLINE1")}</span>
											</div>
										</div>
										<div className='content'>
											<div className='provinces'>									
												<ProvinceTreeViewer parent={this}
																	selected={province}/>
											</div>
											<div className='chart thailand-map'>
												<style>
												{
													`.provincemap[title='${province}']
													{
														fill:  #168;
														stroke-width : 2px;
														stroke: black;
													}`
												}
												</style>
												<ThailandMap/>
											</div>
										</div>
									</div>
									<div className='chart-section'>
										<div className='helpblock'>
											<div className='title'>{i18("TITLE:HEADLINE2")}</div>
											<div className="text">{i18("TITLE:TEXT2")}</div>
											<div className="text">{i18("TXT:INPUTDATA")}</div>
										</div>
										<FrontPageChart i18={i18} history={history} location={location} match={match} />
									</div>


								</div>
							</div>
						</div>
					</div>
				</MasterFrame>
	}
}

Object.assign(ProvinceSelector.prototype, Navigation)

export default ProvinceSelector

//											
