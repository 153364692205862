import React, {Component, Fragment} from 'react';
import { AppController } from '../app/AppController'


export class ScenarioSelector extends Component 
{
	static contextType = AppController

	constructor(props) {
		super(props);
		this.force_reload = false;
		this.state = {};
	}

	get_group_name = () => this.props.parent.get_group_name ? this.props.parent.get_group_name() : null
	get_scenario = () => this.props.parent.get_scenario()

	get_selected_group = () => {
		let selected_group = this.get_group_name();
		if (!selected_group) {
			selected_group = this.groups.index[this.get_scenario()];
		}

		if (!selected_group) {
			selected_group = this.groups && this.groups.names.length > 0 ? this.groups.names[0] : null;
		}

		return selected_group;
	}

	handle_group = e => 
	{
		const {value} = e.target;
		if (value && value === this.get_group_name()) {
			return;
		}


		let s = null;
		if (this.props.display_scenario === false && this.props.callback) {
			this.props.callback({group_name: value});
		} 
		else 
		{
			const c_scen = this.get_scenario();
			const re = this.groups.scenarios[c_scen].re_share_fmt;

			const n_scen = this.groups.groups[value].find(s => s.re_share_fmt == re);
			if (n_scen) {
				s = n_scen.id;
			} else {
				s = this.groups.groups[value][0].id;
			}

			this.props.callback && this.props.callback({scenario: s});
		}

		this.setState({group_name: value, scenario: s});
	}

	handle_select = e => {
		const {value} = e.target;
		if (this.props.callback) {
			this.props.callback({scenario: value});
		}

		this.setState({scenario: value});
	}

	get_groups(scenarios) {
		if (scenarios == null) {
			return null;
		}

		const names = [];
		const groups = {};
		const index = {};
		const titles = {};
		const scenaria = {};

		scenarios.forEach(s => {
			if (s.calculated) {
				const id       = s.group_id;
				let group_name = s.group_name || 'Custom';
				if (group_name.startsWith('Basis')) {
					group_name = `${group_name} ${s.efficiency}`;
				}

				titles[id] = group_name.replace("EEPlus", "EE+");

				if (names.indexOf(id) < 0) {
					names.push(id);
				}

				if (!groups[id]) {
					groups[id] = [];
				}

				groups[id].push(s);

				s.i18re = s.re_share == 0 ? "MCOST" : `${s.re_share*100}`

				index[s.id] = id;
				scenaria[s.id] = s;
			}
		});

		const snames = names.sort((x, y) => {
			if (!x.startsWith('C') && !y.startsWith('C')) {
				return x - y;
			}

			if (!x.startsWith('C')) {
				return -1;
			}

			if (!y.startsWith('C')) {
				return 1;
			}

			return x - y;
		});

		const rv = {
			names: snames,
			titles,
			groups,
			index,
			scenarios: scenaria,
		};

		return rv;
	}

	componentDidUpdate() {
		if (this.force_reload && this.target_scenario) {
			setTimeout(() => {
				if (this.target_scenario) {
					this.props.callback({scenario: this.target_scenario});
				}

				this.target_scenario = null;
				this.force_reload = false;
			}, 100);
		}
	}

	render() {

		if (!this.context || !this.context.ctx.i18nstr)
			return <div />

		const i18 = this.context.ctx.i18nstrp('SCN')


		const {province_data} = this.props
		if (!province_data) {
			return null;
		}

		const display_scenario = this.props.display_scenario !== false

		const ee_set_id = this.props.parent.get_ee_set_id()
		const chp       = this.props.parent.get_chp()
		const scen_key  = `${chp == 'on' ? 'ON' : 'OFF'}-${ee_set_id}`

		const scenariosHere = province_data.all_scenarios[scen_key] || []
		const scenarios     = scenariosHere.filter(x => x.calculated)

		if (!scenarios || scenarios.length == 0) {
			return null;
		}

		this.groups           = this.get_groups(scenarios);
		const selected_group  = this.get_selected_group();
		let selected_scenario = this.get_scenario();

		if (!selected_scenario && selected_group) {
			selected_scenario = this.groups && this.groups.groups[selected_group]
				? this.groups.groups[selected_group][0].id : null;
		}

		this.force_reload = display_scenario && (!this.groups.titles[selected_group] || !this.groups.index[selected_scenario]);

		if (this.force_reload) {
			const target_group   = this.groups.groups[selected_group];
			this.target_scenario = null;
			if (target_group && target_group.length > 0) {
				this.target_scenario = target_group[0].id;
			} else if (this.groups.names && this.groups.names[0].length > 0) {
				this.target_scenario = this.groups.groups[this.groups.names[0]][0].id;
			}
		}

		return <div className="unit-selector">
			<select onChange={this.handle_group} value={selected_group} className="unit-select">
				{
					this.groups.names.map((s, i) =>
						<option key={`group-select-${i}`} value={s}>{this.groups.titles[s]}</option>)
				}
			</select>
			<span>&nbsp; </span>
			{
				display_scenario && selected_scenario && this.groups.groups[selected_group]
				&& <select onChange={this.handle_select} value={selected_scenario} className="unit-select">
					{
						this.groups.groups[selected_group].map((s, i) =>
							<option key={`group-select-${i}`} value={s.id}>
								{i18(s.i18re)}
							</option>,
						)
					}
				</select>
			}
		</div>;
	}
}

