


import React, { Component, Fragment } 	from "react";
import classnames       				from 'classnames';
import ProvinceTreeViewer 				from './province/ProvinceTreeViewer'
import Dropzone 						from 'react-dropzone'

import backend_config					from './config/runconfig.js'

import ReactTooltip 					from 'react-tooltip'
import SetupFrame						from './setup/SetupFrame'
import {AppController} 					from './app/AppController'
import axios 							from 'axios'




const  upload_icon  	= require('../public/icons/file-upload.svg')

class XLSSystemUpload extends Component
{
	constructor( props )
	{
		super( props )
		this.state = {
			status: 	"waiting",
			message: 	null,
			uploads:  	{},
			filedata: 	null
		}
	}


	get_filedata()
	{
		const url = `${backend_config.server}/system/file-data`
		axios.get( url )
		.then( result => {
			console.log( result.data )
			const filedata = result.data && result.data.result ? result.data.result : null
			this.setState( {filedata} )
			console.log( filedata )
		})
	}


	componentDidMount()
	{
		this.get_filedata()
	}


	update_progress( filename, progress, message )
	{
		const uploads = this.state.uploads


		if (progress == null)
			delete uploads[filename]
		else
		{
			if (!uploads[filename])
				uploads[filename] = {}

			uploads[filename].progress 	= progress
			uploads[filename].message 	= message
		}
		let status = Object.keys( this.state.uploads ).length == 0 ? 'waiting' : 'uploading'
		if (progress == -1)
			status = 'error'
		this.setState( {status: status, uploads: this.state.uploads, message: message} )
	}


	upload_file( target, files )
	{
		files.forEach(file =>
		{
			this.update_progress( file.name, 0 )

			const form_data 	= new FormData();

			const url 			= `${backend_config.server}/system/upload-file`
			console.log( url )

			form_data.append("file", file)
			const req = axios.post(url, form_data,
			{
    			headers: {'Content-Type': 'multipart/form-data'},
    			onUploadProgress: (progress_event) =>
    			{
    				let percent = 0
    				if (progress_event.total > 0)
    					percent = Math.floor( progress_event.loaded * 100 / progress_event.total )
    				this.update_progress( file.name, percent )
    			}
    		})

      		req.catch( err => {
				this.setState(  {
									status: "error",
									message: "Connection Failure"
								})
      		})

	    	req.then( response =>
	    	{
				const {data}   = response
    			if (data)
    			{
    				console.log( data )
    				if (data.status === 'success')
    				{
							this.update_progress( file.name, 1000 )
							setTimeout( () => this.update_progress( file.name, null), 5000 )
							this.get_filedata()
					}
    				else
    				{
    					const error = data.error
    					this.update_progress( file.name, -1, error )
    				}
    			}
	    	});
    	});
	}


	render_upload( filename, status )
	{
		let msg 				= ''
  	  	let progress 			= 0
  	  	let progress_percent 	= 0

  	  	if (status.progress === 0)
  	  	{
  	  		msg = 'Connecting...'
  	  	}
   	  	else if (status.progress > 100)
  	  	{
  	  		msg = 'Success'
  	  	}
  	  	else if (status.progress)
  	  	{
  	  		progress_percent = Math.round( status.progress )
			if (progress_percent > 99)
				msg = 'Analyzing File'
			else
				msg = `Uploading ${progress_percent} %`
		}

		return 	<div key={`UP-${filename}`} className='single_upload'>
							<div className='filename'>{filename}</div>
							<div className='progress'>{msg}</div>
				</div>
	}

	render_uploads()
	{
		const uploads = []
		for( let filename in this.state.uploads )
			uploads.push( this.render_upload( filename, this.state.uploads[filename] ) )
		return uploads
	}

	render_message( message )
	{
		if (Array.isArray( message ))
		{
			return <table>
				   {
				   		message.map( (m,i) => {

				   			return 	<Fragment>
				   						<tr key={`k-${i}`}>
				   							<td><i>Line: {m.line}</i></td>
				   							<td>{m.expression}</td>
				   				   		</tr>
				   				   		<tr key={`l-${i}`}>
				   				   			<td colspan='2'>
				   				   				<b>{m.error.message}</b>
				   				   			</td>
				   				   		</tr>
				   				   </Fragment>
				   		})
				   }
				   </table>
		}
		else return <div>{message}</div>
	}


	render_filedata()
	{
		if (!this.state.filedata)
			return null
		const content = Object.keys( this.state.filedata ).map((k,i) => {
			const entry = this.state.filedata[k]
			return 	<tr key={`fd-${i}`}>
						<td>{k}</td>
						<td><b>{entry.size} bytes</b></td>
						<td><i>{entry.time}</i></td>
					</tr>
		})

		return <table>{content}</table>
	}


	render()
	{
		return 	<div>

					<Dropzone onDrop={files => this.upload_file( "", files )}>
					  {({getRootProps, getInputProps}) =>
					  	{
					      return (<div {...getRootProps()}>
					        <input {...getInputProps()} />
					        <div className='inputform'>
				        		{
				        			<div className='head'>
				        				<div className='title'>Excel File Upload</div>
				        				<br/>
    									<div className='filedata'>
					    				{
					    					this.state.filedata && this.render_filedata()
					    				}
					    				</div>
				        				<div className='message'>
				        				{
				        					this.render_message( this.state.message )
					        			}
					        			</div>
					        		</div>
					        	}
				        		<div className='dropzone'>
					        		{
					        			this.state.status === 'uploading' &&
							        	<div className='description'>
						        			<div className='message'>
						        			{
						        				this.render_uploads()
						        			}
						        			</div>
							       	 	</div>
							        }
						        	{
					        			this.state.status !== 'uploading' &&
					        			<div className='description'>
					        				<div className='message'>
						       	 				Drag and drop XLSX spreadsheet files here
												<br/>
												You can upload SD.xlsx, PD.xlsx or QD.xlsx
												<br/>
												The file can also contain a date like
												<br/>
												<br/>
												<i>QD-1203129123.xlsx</i>
												<br/>or
												<br/>
												<i>1207092007-QD-for-Mondas.xlsx</i>
						       	 				<br/>
												<br/>
							       	 		</div>
								       	 	<div className='drop-icon'>
								       	 		<img src={upload_icon}/>
								       	 	</div>
							       	 	</div>
							       	}
							       	</div>
					       		</div>
					      	</div>
					      	)
					 }}
					</Dropzone>
				</div>
	}
}


class SystemUpload extends Component
{
	static contextType = AppController;

	constructor( props )
	{
		super( props )
		this.state = {
			dropzone 			: false,
			selected_scenario	: null,
			selected_file		: null
		}
	}


	get_province()
	{
		return 	this.props.match.params.province
	}


	render()
	{
		const {history,match}		= this.props
		const province  			= this.get_province()


		const user 		= this.context.ctx.user
		const role 		= user && user.role && user.role.kind
		const is_admin	= role == 'admin'
		const is_nat	= role == 'national'
		const is_po		= role == 'provincial'

		const can_upload = is_admin || (is_po && `${user.role.province}`.toLowerCase() == province) || is_nat


		if (this.props.no_frame)
			return this.render_chart()
		else
			return <SetupFrame history={history} match={match} province={province}>
						<div className={classnames("bottom", "grid-on", "sidebar-off", "chart-on")}>
							<div className='shadowfill flex'>
							{ can_upload && <XLSSystemUpload/>}
							{!can_upload && <div>You cannot upload data for this province</div>}
							</div>
						</div>
					</SetupFrame>
	}
}



export default SystemUpload
