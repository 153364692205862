import React, { Component, Fragment } 	from "react";
import classnames       				from 'classnames';
import backend_config					from "../config/runconfig.js"
import axios 							from 'axios'

import {sk_definition_current, sk_definition_future}
										from './sankey-definition'
import Sankey 							from './Sankey-NG'

import MasterFrame 						from '../app/MasterFrame'

import {AppController} 					from '../app/AppController'
import { Navigation } 					from '../navigation/navigation'
import {Help}							from '../help/Help'

const API_KEY 		= 'ExgWFH9XHHyXIHyKEHIH'


export class SankeyFrame extends Component
{
	static contextType = AppController;

	constructor( props )
	{
		super( props )
		this.state = {
						timestamp 	: "0000000",
						losses 		: true,
				 		labels 		: true,
				 		legend 		: true,
				 		values 		: true,
				 		links 		: false,

						scale 		: 9
					}

		this.energy_displayed 	= null
		this.area_displayed 	= null
		this.currency_displayed = null
		this.inflight 			= false
		this.timestamp 			= "0000000"

		this.chartRef   	= React.createRef()
        this.containerRef	= React.createRef()
	}




	fetch_expression()
	{
		const component 	= this


		const province = this.get_province()

		const url_params = this.get_backend_query()
		const uri 		= this.props.kind == 'qd' ? `${backend_config.server}/qdata/query?api_key=${API_KEY}`
												  : `${backend_config.server}/sdata/query?api_key=${API_KEY}`

		const year_key          = this.context ? this.context.year_key : 'NONE'
		const key = this.props.kind == 'qd' ? 'QD_SANKEY*' : 'SD_SANKEY*'
		const url 		= `${uri}&${url_params}&key=${key}&province=${province}&year_key=${year_key}`

		if (url === this.displayed_url || this.inflight)
			return
		console.log(url)

		this.inflight 	= true
		axios.get(url)
		 .then( (incoming) =>
		{
			this.inflight 			= false

			const {data}		= incoming

			if (data.status === 'error' )
			{
				console.log( "Error In Query")
				console.log( data.error )
				this.displayed_url  = url
				this.setState( {error: true, expressions: null})
			}
			else
			{
				this.displayed_url 	= url
				this.timestamp 		= data.timestamp
				component.setState( {
										error: 			false,
										timestamp: 		data.timestamp,
									 	expressions: 	data.result.value,
									 	units: 			data.result.units,
									 	is_head_data: 	data.result.is_head_data
									 } )
			}
		})
	}


	componentDidMount()
	{
		this.fetch_expression()
	}

	componentDidUpdate()
	{
		this.fetch_expression()
	 	if (this.chartRef && this.chartRef.current)
	 	{
 			this.containerRect()
      		this.chartRef.current.scrollLeft = 0
      		this.chartRef.current.scrollTop  = 0
    	}
	}



	get_detail()
	{
		const detail  = `${this.query().detail}`
		const kind  = `${this.props.kind}`

		if (kind == 'qd')
		{
			const definition = sk_definition_current[detail]
			if (!definition)
				return 'simple'
			else
				return detail
		}
		else
		{
			const definition = sk_definition_future[detail]
			if (!definition)
				return 'simple'
			else
				return detail
		}
	}

	handle_detail = (e) => {
		const newdetail = e.target.value.toLowerCase()
		this.goto({ detail: newdetail })
	}



	handle_losses 	= (event) 	=> this.setState( {losses: !this.state.losses} )
	handle_labels 	= (event) 	=> this.setState( {labels: !this.state.labels} )
	handle_legend 	= (event) 	=> this.setState( {legend: !this.state.legend} )
	handle_values 	= (event) 	=> this.setState( {values: !this.state.values} )
	handle_links 	= (event) 	=> this.setState( {links:  !this.state.links} )


	render_chart(i18)
	{
		const {kind}		= this.props
		const province  	= this.get_province()
		const type      	= this.get_detail()

		const expressions	= this.state.expressions
		const prefix 		= `${kind.toUpperCase()}_SANKEY`
		const scenario		= this.get_scenario()
		const timestamp		= this.state.timestamp

		const key 	= `SANKEY-${type}-${kind}-${province}-${scenario}-${timestamp}`

  		const style = {
  					transformOrigin  : "0px 0px",
  					transition: '300ms',
	  	  			transform: `scale(${this.state.scale/10})`
	  	  		}

		if (this.rendered_key !== key && !expressions)
		{
			this.fetch_expression()
			return <div className='sankey-container'></div>
		}
		else if (!expressions)
		{
			return <div className='sankey-container'></div>
		}
		else if (expressions)
		{
			this.rendered_key = key
			return <div className='sankey-container' ref={this.containerRef}>
								<Sankey chartref={this.chartRef}
										i18={i18}
										style={style}
										key={key}
										province={province}
										kind={kind}
										prefix={prefix}
										unit={this.get_energy()}
										type={type}
										all_links={this.state.links}
										draw_losses={this.state.losses}
										draw_legend={this.state.legend}
										draw_labels={this.state.labels}
										draw_values={this.state.values}
										draw_forces={false}
										visible_move={false}
										values={expressions} />
				</div>

		}

	}


	render_detail_select(i18)
	{
		return <div className='unit-selector'>
			<div className='title'>{i18('Detail')}</div>
			<select value={this.get_detail()} onChange={this.handle_detail} className='unit-select'>
				<option key={`detail-select-simple`} value="simple">{i18('Overview')}</option>
				<option key={`detail-select-sd`} 	 value="structure_demand">{i18('Demand Structure')}</option>
				<option key={`detail-select-pp`} value="powerplant">{i18('Supply Structure')}</option>
				<option key={`detail-select-ssdc`} value="structure_demand_powerplant">{i18('Demand and Supply Structure')}</option>
			</select>
		</div>
	}

	/*
  					<span  className='menu-icon fa fa-search-minus'	/>
  					<span  className='menu-icon fa fa-search-plus' 	/>
  					<span  className='menu-icon fa fa-arrows-alt-h' />
  					<span  className='menu-icon fa fa-arrows-alt-v' />
  					<span  className='menu-separator'/>

	 */


	containerRect()
	{
	    if (this.containerRef.current)
	    {
	        const chartRect  = this.containerRef.current.getBoundingClientRect()
	        return {
	                    height: Math.floor( chartRect.height ),
	                    width:  Math.floor( chartRect.width )
	                }
	    }
	    else
	        return {
	                    height: 100,
	                    width:  100
	                }
	}

	zoomOut = () => {
  						if (this.state.scale > 1)
  							this.setState({scale: this.state.scale - 1})
  					}

  	zoomIn  = () => {
  						if (this.state.scale < 20)
  							this.setState({scale: this.state.scale + 1})
  					}



	render_error()
	{
		const rp = this.get_province_data()
		if (!rp)
			return
		const scenarios = rp.scenarios

		if (!scenarios || scenarios.length == 0)
			return <div className='sankey-menu'>
					<div className='warning section'>No Scenarios have been calculated</div>
				</div>

		return 	<div className='sankey-menu'>
					<div className='warning section'>Data for scenario {this.get_scenario()} not available</div>
				</div>
	}


	render_help( i18 )
	{

	}


	render_menu( lang, i18 )
	{
		const province  	= this.get_province()
		const {kind} 		= this.props
		const is_present 	= kind == 'qd'
		const download_svg 	= kind == 'sd' ? `${backend_config.server}/sankey/future?province=${province}&type=${this.get_detail()}&scenario=${this.get_backend_query()}&lang=${lang}`
			: `${backend_config.server}/sankey/current?province=${province}&type=${this.get_detail()}&energy=${this.get_energy()}&lang=${lang}`


//		console.log(kind == 'sd', scenario)

		const download_ts = kind == 'sd' ?  `${backend_config.server}/system/timeseries/${province}?${this.get_backend_query(false)}`
										   : null

//		console.log( download_ts )
//
//		console.log(kind, download_ts)


		const p_key 		= province.toUpperCase()
		const province_data = this.context.provinces ? this.context.provinces[p_key] : null

		if (!province_data)
			return <div/>

		return <div className='sankey-menu'>
					{
						!is_present &&
						this.variant_selector()
					}
					{
						!is_present &&
						this.scenario_selector()
					}
					<div className='section'>
						{this.render_detail_select(i18)}
					</div>
					{
						this.unit_selector( {area: false, currency: false})
					}


					<div className='section'>
	  					<span onClick={this.zoomOut}  className='menu-icon fa fa-search-minus'/>
	  					<span onClick={this.zoomIn}   className='menu-icon fa fa-search-plus' />
	  				</div>

					<div className='section'>
						<div className={classnames('itemlight', this.state.losses ? 'selected' : false )} onClick={this.handle_losses}/>
						<div className={classnames('item', this.state.losses ? 'selected' : false )} onClick={this.handle_losses}>
							{i18('MENU:LOSSES')}
						</div>

						<div className={classnames('itemlight', this.state.labels ? 'selected' : false )} onClick={this.handle_labels}/>
						<div className={classnames('item', this.state.labels ? 'selected' : false )} onClick={this.handle_labels}>
							{i18('MENU:LABELS')}
						</div>

						<div className={classnames('itemlight', this.state.values ? 'selected' : false )} onClick={this.handle_values}/>
						<div className={classnames('item', this.state.values ? 'selected' : false )} onClick={this.handle_values}>
							{i18('MENU:VALUES')}
						</div>

						<div className={classnames('itemlight', this.state.legend ? 'selected' : false )} onClick={this.handle_legend}/>
						<div className={classnames('item', this.state.legend ? 'selected' : false )} onClick={this.handle_legend}>
							{i18('MENU:LEGENDS')}
						</div>


						<div className={classnames('itemlight', this.state.links ? 'selected' : false )} onClick={this.handle_links}/>
						<div className={classnames('item', this.state.links ? 'selected' : false )} onClick={this.handle_links}>
							{i18('MENU:LINKS')}
						</div>

						<a className='download' href={download_svg} target='_chart'>
							<i className='fa fa-download' />&nbsp; {i18('DOWNLOAD:CHART')}
						</a>

						{
							 kind == 'sd' &&
							<a className='download' href={download_ts} target='_chart'>
						<i className='fa fa-download' />&nbsp; {i18('DOWNLOAD:TIMESERIES')}
							</a>
						}
					</div>

					{
						this.state && this.state.is_head_data == false &&
						<div className='warning section'>
							<i className='fa fa-exclamation-circle'/>
							Diagram is not based on latest data
						</div>
					}

					<Help id={`sankey-${kind == 'qd' ? 'current' : 'future'}`}/>
				</div>
	}

	render_help_qd = (i18) =>  
	{
		const ref_year = this.context && this.context.year_refs ? `${this.context.year_refs.reference_year}` : ''
		return <div className='helpblock'>
					<div className='title'>
						<span>{i18("SANKEY:QD:HEADLINE:LINE1")}</span>
						&nbsp;
						<span>{i18("PROVINCE", this.get_province())}</span>
						&nbsp;
						<span>{i18("SANKEY:QD:HEADLINE:LINE2")}</span>
						&nbsp;
						<span>{ref_year}</span>
					</div>
					<div className='text'>{i18("SANKEY:QD:HELPTEXT:LINE1")}</div>
					<div className='text'>{i18("SANKEY:QD:HELPTEXT:LINE2")}</div>
					<div className='text'>{i18("SANKEY:QD:HELPTEXT:LINE3")}</div>
					<div className='text'>{i18("TXT:INPUTDATA")}</div>
				</div>
	}

	render_help_sd = (i18) => {
		const tgt_year = this.context && this.context.year_refs ? `${this.context.year_refs.target_year}` : ''
		return <div className='helpblock'>
			<div className='title'>
				<span>{i18("SANKEY:SD:HEADLINE:LINE1")}</span>
				&nbsp;
				<span>{i18("PROVINCE", this.get_province())}</span>
				&nbsp;
				<span>{i18("SANKEY:SD:HEADLINE:LINE2")}</span>
				&nbsp;
				<span>{tgt_year}</span>
			</div>
			<div className='text'>{i18("SANKEY:SD:HELPTEXT:LINE1")}</div>
			<div className='text'>{i18("SANKEY:SD:HELPTEXT:LINE2")}</div>
			<div className='text'>{i18("SANKEY:SD:HELPTEXT:LINE3")}</div>
			<div className='text'>{i18("SANKEY:SD:HELPTEXT:LINE4")}</div>
			<div className='text'>{i18("TXT:INPUTDATA")}</div>
		</div>
	}



	render()
	{
		const {history,match}		= this.props
		const province  			= this.get_province()

		if (!this.context || !this.context.ctx.i18nstrp)
			return <div />

		const i18 = this.context.ctx.i18nstrp("SANKEY")

		const { kind } = this.props
		const is_present = kind == 'qd'
		if (this.props.no_frame)
			return this.render_chart(i18)
		else
			return <MasterFrame parent={this}  history={history} match={match} province={province}>
						<div className={classnames("bottom")}>
						{
							<div className='sankey-main'>
								{ this.render_menu(this.context.lang, i18)}
								{ is_present && this.render_help_qd( i18 )}
								{ !is_present && this.render_help_sd(i18)}
								{ this.state.error && this.render_error()}
								{!this.state.error && this.render_chart(i18)}
							</div>
						}
						</div>
					</MasterFrame>
	}
}
Object.assign(SankeyFrame.prototype, Navigation)
export default SankeyFrame

