import React, { Component, Fragment } 	from "react";
import classnames       				from 'classnames';
import JSONPretty 						from 'react-json-pretty'
import JSONTree 						from 'react-json-tree'
import {CopyToClipboard} 				from 'react-copy-to-clipboard'
import backend_config					from "../config/runconfig"
import {visitNodes}         			from '../tree/tree'
import axios 							from 'axios'
import {PEMTDataModel}   				from "../datamodel/pemt_data_model"
import {TreeEditor}						from "../TreeEditor"
import {QDataModel}   					from "./qdata-viewmodel"
import {DependencyGraph}				from "./DependencyGraph"
import {AppController} 					from '../app/AppController'
import {Help}							from '../help/Help'
import { Navigation } 					from '../navigation/navigation'

const API_KEY 		= 'ExgWFH9XHHyXIHyKEHIH'


export class SDataExplorer extends Component
{
	static contextType = AppController;
	constructor( props )
	{
		super( props )
		this.state 	= {
		}

        this.all_years 		 = []
        for( let year = 2551; year < 2561; year++ )
        	this.all_years.push( `${year}` )
	}



	fetch_expression()
	{
		if (this.inflight)
			return

		const component 				= this
		const province  				= this.props.province
		const {energy,area,currency}	= this.state
		const scenario					= this.get_scenario() || '1'


				const keys  	= [ 'SD_SANKEY*',
							'SD_BAR*',

							'SD_ProjGGpp',
							'SD_ProjGPop',


							'SD_ProjFPop',
							'SD_ProjFGpp',
							'SD_ProjFEDres',
							'SD_ProjFEDagr',
							'SD_ProjFEDind',
							'SD_ProjFEDcom',
							'SD_ProjFEDoth',
							'SD_ProjFHDres',
							'SD_ProjFHDagr',
							'SD_ProjFHDind',
							'SD_ProjFHDcom',
							'SD_ProjFHDoth',
							'SD_ProjFMob',
							'SD_ProjFN',
							'SD_PopNo',
							'SD_Gpp',

							'SD_KpiguiScen*',
							'SD_Scenario*',

							'SD_FinalEDres',
							'SD_FinalEDagr',
							'SD_FinalEDind',
							'SD_FinalEDcom',
							'SD_FinalEDtra',
							'SD_FinalEDoth',
							'SD_FinalHDres',
							'SD_FinalHDagr',
							'SD_FinalHDind',
							'SD_FinalHDcom',
							'SD_FinalHDtra',
							'SD_FinalHDoth',

							'QD_DEMDEVEPLOTSUPP1',
							'QD_FINALHTOTHO',
							'QD_FINALMTOTXI',
							'QD_FINALNTOTXI',
							'SD_FINALE',
							'SD_FINALH',
							'SD_FINALM',
							'SD_FINALN',

						].map( x => x.toUpperCase() )


		const key 		= keys.join(',')
		const input_keys = this.props.keys ? this.props.keys.split(",")
															.map( x => `${x}`.toUpperCase().trim())
															.filter( x => x.startsWith("SD_")).join(",") : null
		const used_keys 	= input_keys || key

		const url_params = this.get_backend_query()

		const year_key	= this.context ? this.context.year_key : 'NONE'
		const url = `${backend_config.server}/sdata/query?${url_params}&key=${used_keys}&province=${province}&api_key=${API_KEY}&year-key=${year_key}`


		console.log( "GET URL ")
		console.log( url )
		console.log( this.url_displayed )
		if (url === this.url_displayed)
			return
		this.inflight = true
		console.log(url)
		axios.get(url)
			.then((incoming) => {
				this.url_displayed = url
				this.inflight = false

				const { data } = incoming
				if (data.status === 'error') {
					console.log("Error Loading expression")
					console.log(data.error)
					component.setState({ error: true, expressions: null })
				}
				else
				{
					console.log( data.result )
					const new_state = {}
					new_state.error 		= 	false
					new_state.errorMsg 		= 	null
					new_state.expressions 	=  	data.result.value
					new_state.units 		= 	data.result.units
					new_state.is_head_data 	= 	data.result.is_head_data
					this.qdata_model		= null  // To force a recalculation
					component.setState( new_state )
				}
		})
	}

	setUnits 		= (units) 	=> 		this.setState( units )

	close_graph 	= () 		 => 	{this.focus_variable( null, null )}


	focus_variable(year, varname)
	{
		const currentpath = this.props.history.location.pathname


      	const {keys}  = this.props
      	const keydisp = keys ? `&keys=${keys}` : ''

		const params = []
		if (year == null || varname == null)
			this.props.history.push(`${currentpath}?${keydisp}`)
		else
			this.props.history.push(`${currentpath}?year=${year}&varname=${varname}${keydisp}`)
	}


	componentDidMount() {
		this.fetch_expression()
	}

	componentDidUpdate() {
		this.fetch_expression()
	}


	setup_qdata_model()
	{
		if (!this.context || !this.context.year_refs)
			return null;

		const tgt_year =  `${this.context.year_refs.target_year}`

		if (!this.state.expressions)
			return null

		console.log( this.all_years )

		const parameters = {	tree: 		this.state.expressions,
								years: 		[tgt_year],
								province: 	this.props.province
							}
		const qdata_model 	= new QDataModel(parameters)
		qdata_model.view_model.setSelectedNodeCallback( (node, column, year) =>
		{
			this.focus_variable( year.key, node.key )
		})
		return qdata_model
	}




	render()
	{
		if (!this.context || !this.context.ctx.i18nstr)
			return <div />
		const i18 = this.context.ctx.i18nstrp("SANKEY:VALUES:SD")

		if (!this.qdata_model)
			this.qdata_model = this.setup_qdata_model()

		if (this.qdata_model)
			this.qdata_model.focusYear( this.state.focused_year )


		const focused_expression = this.props.varname && this.props.year && this.state.expressions
		const chart_on = focused_expression ? 'chart-on' : 'chart-off'


		const p_key 		= this.props.province.toUpperCase()
		const province_data = this.context.provinces ? this.context.provinces[p_key] : null

		if (!province_data)
			return <div/>

		return <Fragment>
					<div className='sankey-main'>
						<div className='sankey-menu'>
							{
								this.variant_selector()
							}
							{
								this.scenario_selector()
							}
							{
								this.unit_selector()
							}
							{
								this.qdata_model && this.state && this.state.is_head_data == false &&
								<div className='warning section'>
									<i className='fa fa-exclamation-circle'/>
									Diagram is not based on latest data
								</div>
							}
							{
								this.state.error &&
								<div className='warning section'>Data for scenario not available</div>
							}
							<Help id='sdata'/>
						</div>
						<div className='sankey-container with-expression'>
							<div className={classnames("bottom", "grid-on",
														"sidebar-on", chart_on)}>
								<div className='fill-with-title-spreadsheet'>
									<div className='helpblock'>
										<div className='title'>
											<span>{i18("HEADLINE")}</span>
											&nbsp;
											<span>{i18("PROVINCE", this.get_province())}</span>
										</div>
										<div className='text'>{i18("HELPTEXT:LINE1")}</div>
										<div className='text'>{i18("HELPTEXT:LINE2")}</div>
										<div className='text'>{i18("HELPTEXT:LINE3")}</div>
										<div className='text'>{i18("TXT:INPUTDATA")}</div>
									</div>
									<div className='sheetblock'>
									{
										this.qdata_model &&
										<TreeEditor narrow_header={true}
													view_model={this.qdata_model.view_model}
													commit={this.commit} />
									}
									</div>
								</div>
							</div>
						</div>
					</div>
					{
						focused_expression &&
						<div className='depgraph'>
							<DependencyGraph expressions={this.state.expressions}
								kind='sd'
								history={this.props.history}
								year={this.props.year}
								varname={this.props.varname}
								scenario={this.get_scenario()}
								selected_node={this.props.selected_node}
								province={this.props.province}
								keys={this.props.keys}
								close_graph={this.close_graph} />
						</div>
					}

				</Fragment>
	}


}
Object.assign(SDataExplorer.prototype, Navigation)


export default SDataExplorer;