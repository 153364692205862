import React, { Component, Fragment } 	from "react";
import Select 							from 'react-select';
import JSONPretty 						from 'react-json-pretty'
import JSONTree 						from 'react-json-tree'
import {CopyToClipboard} 				from 'react-copy-to-clipboard';
import backend_config					from "../config/runconfig.js"
import {visitNodes}         			from '../tree/tree.js';
import axios 							from 'axios';

const API_KEY 	= 'ExgWFH9XHHyXIHyKEHIH'


const otherfields =  [
{value: 'RDN_HHShareWoEl', 		label: 'RDN_HHShareWoEl	สัดส่วนครัวเรือนที่ไม่มีไฟฟ้าใช้'},
{value: 'RDN_EnergyConsCom', 	label: 'RDN_EnergyConsCom	การใช้พลังงาน'},
{value: 'RDN_EnergyConsComRel', label: 'RDN_EnergyConsComRel	การใช้พลังงานต่อหน่วยของ GPP'},
{value: 'RDN_GHGEmissionCap', 	label: 'RDN_GHGEmissionCap	ปริมาณการปล่อยก๊าซเรือนกระจกจากการผลิตพลังงานต่อหัว'},
{value: 'RDN_GHGEmissionGPP', 	label: 'RDN_GHGEmissionGPP	ปริมาณการปล่อยก๊าซเรือนกระจกจากการผลิตพลังงานต่อ GPP'},

{value:'RDPP_0001', label: 'RDPP_0001 (บริษัท กัลฟ์ เจพี เอ็นแอลแอล จำกัด)'},
{value:'RDPP_0002', label: 'RDPP_0002 (บริษัท กัลฟ์ ทีเอส1 จำกัด)'},
{value:'RDPP_0003', label: 'RDPP_0003 (บริษัท กัลฟ์ ทีเอส2 จำกัด)'},
{value:'RDPP_0004', label: 'RDPP_0004 (บริษัท กัลฟ์ ทีเอส3 จำกัด)'},
{value:'RDPP_0005', label: 'RDPP_0005 (บริษัท กัลฟ์ ทีเอส4 จำกัด)'},
{value:'RDPP_0006', label: 'RDPP_0006 (บริษัท กัลฟ์ วีทีพี จำกัด)'},
{value:'RDPP_0007', label: 'RDPP_0007 (บริษัท เก็คโค่-วัน จำกัด)'},
{value:'RDOR_0001', label: 'RDOR_0001 (บริษัท ไออาร์พีซี จำกัด)'},
{value:'RDOR_0002', label: 'RDOR_0002 (บริษัท พีทีที โกลบอล เคมิคอล จำกัด)'},
{value:'RDOR_0003', label: 'RDOR_0003 (บริษัท สตาร์ปิโตรเลียม รีไฟน์นิ่ง จำกัด)'},

{value: 'RDGSP_0001', label: 'RDGSP_0001 (โรงแยกก๊าซธรรมชาติ หน่วยที่..)'},
{value: 'RDGSP_0002', label: 'RDGSP_0002 (โรงแยกก๊าซธรรมชาติ หน่วยที่..)'},
{value: 'RDGSP_0003', label: 'RDGSP_0003 (โรงแยกก๊าซธรรมชาติ หน่วยที่..)'},
{value: 'RDGSP_0004', label: 'RDGSP_0004 (โรงแยกก๊าซธรรมชาติ หน่วยที่..)'},
{value: 'RDGSP_0005', label: 'RDGSP_0005 (โรงแยกก๊าซธรรมชาติ หน่วยที่..)'},

{value: 'RDREEEP_PVroofPotExTot', label: 'RDREEEP_PVroofPotExTot'},
{value: 'RDREEEP_PVroofPotExRem', label: 'RDREEEP_PVroofPotExRem'},
{value: 'RDREEEP_BiogasPotExTot', label: 'RDREEEP_BiogasPotExTot'},
{value: 'RDREEEP_BiogasPotExRem', label: 'RDREEEP_BiogasPotExRem'},
{value: 'RDREEEP_WastePotExTot', label: 'RDREEEP_WastePotExTot'},
{value: 'RDREEEP_WastePotExRem', label: 'RDREEEP_WastePotExRem'},
{value: 'RDREEEP_BiomassPotExTot', label: 'RDREEEP_BiomassPotExTot'},
{value: 'RDREEEP_BiomassPotExRem', label: 'RDREEEP_BiomassPotExRem'},
{value: 'RDREEEP_EthPotExTot', label: 'RDREEEP_EthPotExTot'},
{value: 'RDREEEP_EthPotExRem', label: 'RDREEEP_EthPotExRem'},
{value: 'RDREEEP_BiodieselPotExTot', label: 'RDREEEP_BiodieselPotExTot'},
{value: 'RDREEEP_BiodieselPotExRem', label: 'RDREEEP_BiodieselPotExRem'},
{value: 'RDREEEP_WindPotAlt', label: 'RDREEEP_WindPotAlt'},
{value: 'RDREEEP_PvgroundPotAlt', label: 'RDREEEP_PvgroundPotAlt'},
{value: 'RDREEEP_NapierPotAlt', label: 'RDREEEP_NapierPotAlt'},
{value: 'RDREEEP_EucalPotAlt', label: 'RDREEEP_EucalPotAlt'},
{value: 'RDREEEP_EffRes', label: 'RDREEEP_EffRes'},
{value: 'RDREEEP_EffInd', label: 'RDREEEP_EffInd'},
{value: 'RDREEEP_EffCom', label: 'RDREEEP_EffCom'},
{value: 'RDI_HHShareWoEl', label: 'RDI_HHShareWoEl'},
{value: 'RDI_EnergyConsCom', label: 'RDI_EnergyConsCom'},
{value: 'RDI_EnergyConsComRel', label: 'RDI_EnergyConsComRel'},
{value: 'RDI_GHGEmissionCap', label: 'RDI_GHGEmissionCap'},
{value: 'RDI_GHGEmissionGPP', label: 'RDI_GHGEmissionGPP'},
]

const formats = [
				  	{ label: "List",  value: "list" 	},
  					{ label: "Table", value: "hash" 	}
]


const groups  = [
					{ label : "--None--", 	value: null			},
					{ label : "Province", 	value: "province"	},
					{ label : "Year", 		value: "year"		},
					{ label : "Key", 		value: "key"		}
]

const FormLine = ({title, children}) =>
{
	return 	<div className='line'>
				<div className='title'>{title}</div>
				<div className='control'>{children}</div>
			</div>
}


export class ApiExplorer extends Component
{
	constructor( props )
	{
		super( props )
		this.state 	= {
			provinces 			: [],		// Selected provinces
			uploaded_provinces	: [],		// The provinces on the server
			uploaded_keys 		: null,
			query 				: null,
			result 				: null,
			group1 				: null,
			group2 				: null,
			group3 				: null,
			years				: null,
			format 				: null,
			energy_unit			: 'original'
		}

		this.oldquery 		= null
	}


	fetch_schema()
	{
		const component = this
		axios.get(`${backend_config.server}/system/schema?api_key=${API_KEY}`)
		 .then( (incoming) =>
		{
			const {data}		= incoming
			if (data.status === 'error' )
			{
				console.log( "Error Loading schema")
				console.log( data.error )
				component.setState( {error: data.error} )
			}
			else
			{
				const keys = otherfields


				visitNodes( data.result, false, (node) => {
					if (!node.nodes && node.key)
						keys.push( {label: `${node.key} (${node.name})`, value: `${node.key}`.toUpperCase()} )
				})
				component.setState( {uploaded_keys: keys} )
			}
		})
	}

	fetch_tree( continuous )
	{
		const component = this
		axios.get(`${backend_config.server}/system/provinces?api_key=${API_KEY}`)
		 .then( (incoming) =>
		{
			const {data}		= incoming
			if (data.status === 'error' )
			{
				console.log( "Error")
				console.log( data.error )
				component.setState( {error: data.error} )
			}
			else
			{
				console.log( data.result )
				component.setState( {uploaded_provinces: data.result.provinces} )
			}

			if (continuous)
				setTimeout( () => this.fetch_tree( true ), 20000 )
		})
	}


	lvalue( x )
	{
		if (Array.isArray(x))
			return x.join(",")
		else
			return `${x}`.trim()
	}

	fetch_api()
	{
		const component = this

		const query = {}

		if (this.state.format)
			query.format = this.state.format

		const groups = []

		if (this.state.group1 != null)
		{
			groups.push( this.state.group1 )
			if (this.state.group2 != null && !groups.includes( this.state.group2 ))
			{
				groups.push( this.state.group2 )

				if (this.state.group3 != null && !groups.includes( this.state.group3 ))
					groups.push( this.state.group3 )
			}
		}

		if (groups.length > 0)
			query.group = groups

		if (this.state.keys && this.state.keys.length > 0)
			query.key = this.state.keys

		if (this.state.years && this.state.years.length > 0)
			query.year = this.state.years

		if (this.state.provinces && this.state.provinces.length > 0)
			query.province = this.state.provinces

		if (this.state.energy_unit && this.state.energy_unit !== '-')
			query.energyunit = this.state.energy_unit

		if (this.state.work_unit && this.state.work_unit !== '-')
			query.workunit = this.state.work_unit

		query.api_key = API_KEY
		const params 	= []
		for( const q in query)
			params.push(`${q}=${this.lvalue( query[q] )}`)

		const question 	= params.length > 0 ? "?" : ""

		const get_query = `https://pemt-api.mondas.io/system/query${question}${params.join('&')}`

		const string_query = JSON.stringify( query )
		console.log( get_query )

		if (this.oldquery != string_query)
		{
			this.oldquery = string_query
			this.setState( {post_query: query, get_query: get_query} )
			axios.post(`${backend_config.server}/system/query`, query)
			 .then( (incoming) =>
			{
				const {data}		= incoming
				if (data.status === 'error' )
				{
					console.log( "Error")
					console.log( data.error )
					component.setState( {error: data.error} )
				}
				else
				{
					component.setState( {result: data} )
				}
			})
		}
	}

	handle_format 	= (selected)  =>	{this.setState( {format : selected.value}) }
	handle_group1 	= (selected)  =>  	{this.setState( {group1 : selected.value, group2: null, group3: null}) }
	handle_group2 	= (selected)  =>	{this.setState( {group2 : selected.value, group3: null}) }
	handle_group3 	= (selected)  =>	{this.setState( {group3 : selected.value}) }

	handle_year   	= (selected)  => 	{this.setState( {years  	: selected.map( s => `${s.value}` )}) }
	handle_province = (selected)  => 	{this.setState( {provinces  : selected.map( s => `${s.value}` )}) }
	handle_key 		= (selected)  =>    {this.setState( {keys  		: selected.map( s => `${s.value}` )}) }

	handle_energy 	= (selected) => { this.setState({ energy_unit: 	selected.value == 'original' ? null : selected.value }) }
	handle_work = (selected) => { this.setState({ work_unit: selected.value }) }

	render_provinces( provinces )
	{
		if (!provinces)
			return null

		const options = [{label: "-- National --", value: "_THAILAND_"}]
		for( const province in provinces )
		{
			const name 		= provinces[province].name
			options.push( {label: name, value: name} )
		}
		return <Select options={options} isMulti={true} onChange={this.handle_province} />
	}

	render_keys( keys )
	{
		if (!keys)
			return null
		return <Select options={keys} isMulti={true} onChange={this.handle_key} />
	}


	render_years()
	{
		const years = []
		for( let year = 2550; year < 2561; year++ )
			years.push( { label: year,  value: year })
		return <Select options={years} onChange={this.handle_year} isMulti={true} />
	}




	render_work() {
		const wunits = [
			{ value: '-', 	label: '--Leave as is--' },
			{ value: 'gw', 	label: 'GW' },
			{ value: 'mw', 	label: 'MW' },
			{ value: 'kw', 	label: 'kW' },
			{ value: 'w', 	label: 'W' },
		]
		return <Select options={wunits} onChange={this.handle_work} isMulti={false} />

	}


	render_energy() {
		const eunits 	= [
			{value: "original", 			label: "-- Do Not Change --" },
			{value: "j", 					label: "J"},
			{value: "gj", 					label: "GJ"},
			{value: "cal", 					label: "cal"},
			{value: "kcal", 				label: "kcal"},
			{value: "gcal", 				label: "Gcal"},
			{value: "toe", 					label: "toe"},
			{value: "ktoe", 				label: "ktoe"},
			{value: "btu", 					label: "BTU"},
			{value: "wh", 					label: "Wh"},
			{value: "kwh", 					label: "kWh"},
			{value: "mwh", 					label: "MWh"},
			{value: "kglpg", 				label: "kg LPG"},
			{value: "litrelowspeedsdiesel", label: "Litre Low Speed Diesel"},
			{value: "litrehighspeeddiesel",	label: "Litre High Speed Diesel"},
			{value: "litrefueloil", 		label: "Litre Fuel oil"},
			{value: "litregasoline91", 		label: "Litre Gasoline 91"},
			{value: "litregasoline95", 		label: "Litre Gasoline 95"},
			{value: "litregasohol91", 		label: "Litre Gasohol 91"},
			{value: "litregasohol95", 		label: "Litre Gasohol 95"},
			{value: "scfnaturalgas", 		label: "scf Natural gas"}
		]
		const current_value = eunits.find( unit => unit.value == this.state.energy)
		return <Select options={eunits} onChange={this.handle_energy} isMulti={false} value={current_value} />

	}


	componentDidMount()
	{
		this.fetch_tree( false )
		this.fetch_api()
		this.fetch_schema()
	}

	componentDidUpdate()
	{
		this.fetch_api()
	}

	render()
	{
		const curl_command = `curl -d '${JSON.stringify(this.state.post_query)}' -H "Content-Type: application/json" -X POST https://pemt-api.mondas.io/system/query`

		return  <form className='inputform wide'>
			<div className='formdata'>
				<FormLine title="Provinces">
					{ this.render_provinces( this.state.uploaded_provinces ) }
				</FormLine>

				<FormLine title="Year">
					{ this.render_years() }
				</FormLine>

				<FormLine title="Key">
					{ this.render_keys( this.state.uploaded_keys ) }
				</FormLine>

				<FormLine title="Energy Units">
					{this.render_energy()}
				</FormLine>

				<FormLine title="Group 1">
					<Select vaalue={this.state.group1} options={groups} onChange={this.handle_group1}/>
				</FormLine>
				{
					this.state.group1 &&
					<Fragment>
						<FormLine title="Group 2">
							<Select vaalue={this.state.group2} options={groups} onChange={this.handle_group2}/>
						</FormLine>
						{
							this.state.group2 &&
							<FormLine title="Group 3">
								<Select vaalue={this.state.group3} options={groups} onChange={this.handle_group3}/>
							</FormLine>
						}
					</Fragment>
				}
				{
					this.state.group1 !== null &&
					<FormLine title="Format">
						<Select options={formats} onChange={this.handle_format}/>
					</FormLine>
				}

				<FormLine title="Query">
					<div className='query-display'>
						<div className='query'>
								{
									this.state.get_query &&
									<div className='section'>
										<div className='explain getquery'>GET Query</div>
										<pre>{this.state.get_query}</pre>
								        <CopyToClipboard text={this.state.get_query}
								        	onCopy={() => this.setState({copied: true})}>
											<button className='copy2clip'>Copy to clipboard</button>
								        </CopyToClipboard>
									</div>
								}
								{
									this.state.post_query &&
									<div className='section'>
										<div className='explain'>POST Query with JSON body, sent via Javascript</div>
										<JSONPretty data={this.state.post_query}/>
								        <CopyToClipboard text={this.state.post_query}
								        	onCopy={() => this.setState({copied: true})}>
								          <button className='copy2clip'>Copy to clipboard</button>
								        </CopyToClipboard>
									</div>
								}
								{
									this.state.post_query &&
									<div className='section'>
										<div className='explain'><i>curl</i> command on a command prompt:</div>
										<pre>{curl_command}</pre>

								        <CopyToClipboard text={curl_command}
								        	onCopy={() => this.setState({copied: true})}>
								          <button className='copy2clip'>Copy to clipboard</button>
								        </CopyToClipboard>
									</div>
								}

						</div>
						<div className='result'>
							<div className='explain'>Result</div>
							{
							  this.state.result &&
							  this.state.result.status == 'success'  &&
							  <div className='json'>
							  	<JSONTree data={this.state.result.result}
							  				invertTheme={true}
							  				shouldExpandNode={(k,d,l) => l < 4}/>
							  </div>
							}
							{
							  this.state.result &&
							  this.state.result.status == 'error'  &&
							  <JSONTree data={this.state.result} />
							}
						</div>
					</div>
				</FormLine>
			</div>
		</form>
	}
}


export default ApiExplorer;