import React, { Component, Fragment } 			from 'react';
import * as utils                       		from '../utils/utils';


import {sk_definition_current, sk_definition_future, sk_labels, sk_colors} 	from './sankey-definition'


import {SankeyLayout} 							from './sankey-layout'

const NODE_WIDTH = 100
const MIN_HEIGHT = 30
const Y_SPACING  = 7

export class Sankey extends Component
{
	constructor(props)
	{
		super(props)

		this.state 	= {counter : 0, setup : false, focused: null}

		this.colorsused = {}
	}

	create_node( name, label, x,y, fn )
	{
		const node 			= {name,label,x,y}
		node.width 			= NODE_WIDTH
		node.orig_y 		= y
		node.offset_up 		= 0

		if (fn)
			fn(node)
		this.node_index[name] = node
		this.nodes.push( node )
		return node
	}


	fill_values()
	{
		const 	visited 	= {}
		const 	always_1 	= this.props.all_links
		const 	values 		= this.props.values
		let 	max 		= 0

		utils.visit( values, (i,val) =>
		{
			const v = val.value
			max = Math.max(max, v/10.0)
		})

		if (always_1)
			max = 1

		for( const link of this.links )
		{
			let varname = `${this.props.prefix}${link.from}${link.to}`
			visited[varname] 	= true
			const sk	  		=  values[varname]

			if (sk === undefined)
			{
				link.value 		= 0
				link.real_value	= 0
				link.color 		= 'error'
			}
			else
			{
				const v 		= always_1 ? 0.1 : (sk.value !== undefined ? sk.value : 0)
				const value 	= Math.max( v/max, 0)
				link.value 		= value

				link.varname 	= `${link.from}-${link.to}`
			//	console.log( `${link.from} -> ${link.to} :: ${value}`)
				link.real_value	= v
			}
		}

//		for( let vn in values )
//		{
//			if (vn.startsWith( 'SD_SANKEY'))
//			{
//				if (!visited[vn])
//					console.log( "Sankey not used : " + vn)
//			}
//		}
//		console.log( visited )
	}


	link_statistics()
	{
		if (this.links.length == 0)
		{
			this.link_avg 		= 0
			this.links_stddev 	= 0
			return
		}

		let sum 			= 0
		this.links.map( link => sum += link.value )
		this.links_avg 	= sum / this.links.length

		let variance_sum 	= 0
		this.links.map( link => {
			const delta   = link.value - this.links_avg
			variance_sum += delta*delta
		})

		this.links_stddev 	= Math.sqrt( variance_sum / this.links.length )

		if (this.links_stddev > 0.0001)
		{
			this.links.map( link => link.normed_value = (link.value - this.links_avg)/this.links_stddev )
		}
		else
			this.links.map( (link) => link.normed_value = 0 )

	}



	setup_layers()
	{
		const layers = {}
		for( const node of this.nodes )
		{
			const index 	= node.x

			if (!layers[index])
				layers[index] = {index: index, x: index, nodes: []}
//			if (!node.invisible)
			layers[index].nodes.push( node )
			node.layer 			 = index
			node.single_in_layer = false
		}

		const keys 		= Object.keys( layers ).sort()
		const l 		= keys.length
		this.lastlayer 	= layers[keys[l-1]]
		this.depth 		= this.lastlayer.x

		for( const li in layers )
		{
			const layer = layers[li]
			layer.nodes.forEach( (node,index) => node.y = index )
			layer.nodes = layers[li].nodes.sort( (a,b) => a.y - b.y )

			if (layer.nodes.length == 1)
				layer.nodes[0].single_in_layer = !layer.nodes[0].input

			layer.weight_dn = layer.index / this.depth
			layer.weight_up = 1.0 - layer.weight_dn
		}

		this.layers = layers
	}








	
	get_x_coordinate( x )
	{
		if (x < 40)
			return 	x * (NODE_WIDTH * 2) + 50
		else
			return 	x * (NODE_WIDTH * 2) + 10
	}


	normalize_coordinates( comment )
	{
		const [miny, maxy] = this.minmax_nodeset( this.nodes )

		utils.visit( this.nodes, (name, node) =>
		{
			node.xc 	= this.get_x_coordinate( node.x )
			node.yc 	= (node.y - miny) * 10 + 50

			node.xm  	= node.xc + NODE_WIDTH/2
			node.ym 	= node.yc + node.size /2

			//console.log( "   ", node.name, ":", node.y )
		})
	}



	print_coordinates( comment, node )
	{
		return
		if (node)
		{
			console.log( `Normalize ${comment} : ${node.name}:${node.y} ${node.yc}  ` )
		}
		else
		{
			const list = []
			utils.visit( this.nodes, (name, node) =>
			{
				list.push( `${node.name}:${node.y} ${node.yc}  `)
			})
			console.log( `Normalize ${comment} : ${list.join(',')}` )
		}
	}





	setup_nodes()
	{
		for( const node of this.nodes )
		{
			// size_in and out are scaled to a total of 10 overall
			node.size_in 				= 0
			node.size_out 				= 0

			// real_size_x are the actual values
			node.real_size_out			= 0
			node.real_size_in			= 0

			node.size_link				= {}
			node.out 					= []
			node.in 					= []
			node.downstream				= []
			node.downstream_internal	= []
			node.downstream_significant	= []

			node.upstream				= []
			node.upstream_internal		= []
			node.upstream_significant	= []

			node.all_internal 			= []
			node.all_neighbors_internal = []
			node.all_significant 		= []

			node.in_prop				= {}
			node.out_prop 				= {}

			node.reachable_nodes 		= []
			node.downstream_reachable 	= []
			node.upstream_reachable 	= []
			node.contribution_from 		= {}
			node.linked_to_links		= []
		}
	}


	setup_links()
	{
		for( const link of this.links )
		{
			link.name 	    = `${link.from}->${link.to}`
			const to_node 	= this.node_index[link.to]
			const from_node = this.node_index[link.from]

			if (!to_node)
			{
				console.log( "NODE " + link.to + " not found")
				continue
			}

			if (!from_node)
			{
				console.log( "NODE " + link.from + " not found")
				continue
			}

			to_node.size_in 		+= link.value
			to_node.real_size_in	+= link.real_value
			to_node.in.push( link )

			if (!from_node.input)
			{
				to_node.upstream.push( from_node )
				to_node.upstream_internal.push( from_node )
				to_node.all_internal.push( from_node )
				if (link.normed_value > -0.2)
				{
					to_node.upstream_significant.push( from_node )
					to_node.all_significant.push( from_node )
				}
			}
			else
				to_node.from_top 	= from_node
			link.to_node = to_node

			from_node.size_out 		+= link.value
			from_node.real_size_out += link.real_value
			from_node.out.push( link )

			if (!to_node.input)
			{
				from_node.downstream.push( to_node )
				from_node.downstream_internal.push( to_node )
				from_node.all_internal.push( to_node )
				if (link.normed_value > -0.2)
				{
					from_node.downstream_significant.push( to_node )
					from_node.all_significant.push( to_node )
				}
			}
			else
				from_node.to_bottom = to_node
			link.from_node = from_node
		}

		for( const node of this.nodes )
		{
			if (node.from_top)
				node.upstream.push( node.from_top )
			if (node.to_bottom)
				node.downstream.push( node.to_bottom )

			node.all_neighbors_internal 	= [...node.upstream_internal, ...node.downstream_internal]
			node.upstream_internal_names 	= node.upstream_internal.map(	n=>n.name)
			node.downstream_internal_names 	= node.downstream_internal.map(	n=>n.name)
		}
	}



	setup_reachability_for_node( root, node, direction )
	{
		if (root.reachable_nodes.includes( node.name ) && node.name !== root.name)
			return
		if (node.size == 0)
			return
		root.reachable_nodes.push( node.name )

		if (direction == 'down' && root.name != node.name && !node.input)
			root.downstream_reachable.push( node.name )
		else if (direction == 'up' && root.name != node.name && !node.input)
			root.upstream_reachable.push( node.name )

		const links = direction == 'down' ? node.out : node.in
		for( const link of links )
		{
			if (link.value > 0)
			{
				const next_id = direction == 'down' ? link.to : link.from
				const next 	  = this.node_index[next_id]
				if (next)
				{
					root.linked_to_links.push( link.name )
					this.setup_reachability_for_node( root, next, direction  )
				}
			}
		}
	}


	scan_partition_from( node, partition )
	{
		if (node.partition >= 0)
			return false
		if (node.input)
			return false
		node.partition = partition
		if (!this.partitions[partition])
			this.partitions[partition] = {index: partition, maxy: 0, miny: 0, nodes: []}
		this.partitions[partition].nodes.push( node )

//		for( let out_node of node.all_internal )
//		{
//			this.scan_partition_from( out_node, partition )
//		}

		for( let lo of node.out)
		{
			const out_node = lo.to_node
			if (lo.normed_value > -0.5 || out_node.upstream_internal.length == 1)
				this.scan_partition_from(out_node, partition)
		}

		for (let li of node.in) 
		{
			const in_node = li.from_node
			if (li.normed_value > -0.5  || in_node.downstream_internal.length == 1)
				this.scan_partition_from(in_node, partition)
		}


		return true
	}


	partition_nodes()
	{
		this.partitions = []
		let found = false

		for( let node of this.nodes )
			this.scan_partition_from( node, this.partitions.length )
		if (!found)
			return
	}


	setup_reachability()
	{
		for( const node of this.nodes )
		{
			this.setup_reachability_for_node( node, node, "down" )
			this.setup_reachability_for_node( node, node, "up" )

			node.reachable_internal = node.reachable_nodes.filter(n => !this.node_index[n].input )
		}

		this.partition_nodes()
	}

	nodeClick = (node_id) => {
    	return (event) => 	{
    							if (this.state.focused == node_id)
    								this.setState( {focused: null})
    					   		else
    								this.setState( {focused: node_id})
    						}
  	}



	// Calculate links sizes as percentages. For instance, if node X has a link X->Y
	// and that link carries 5% of the output value of X the relevant values
	// will be set to 0.05 on both the link and the target node
	prop_links()
	{
		for( const node of this.nodes )
		{
			if (node.size_out > 0)
			{
				for( const out_link of node.out )
				{
					const node_out							= out_link.to_node
					out_link.propsize_out 					= out_link.value*1.0/node.size_out*1.0
					out_link.to_node.in_prop[node.name] 	= out_link.value*1.0/node_out.size_in*1.0
				}
			}

			if (node.size_in > 0)
			{
				for( const in_link of node.in )
				{
					const node_in							= in_link.from_node
					in_link.propsize_in 					= in_link.value*1.0/node.size_in*1.0
					in_link.from_node.out_prop[node.name]	= in_link.value*1.0/node_in.size_out*1.0
				}
			}
		}
	}


	resize_nodes()
	{
		this.nodes.forEach(node => {
								node.size = Math.min( MIN_HEIGHT, Math.max( node.size_in, node.size_out ))
								if (node.size == 0)
									node.invisible = true
							} )
	}


	remove_dead_links()
	{
		const oldlinks 		= this.links
		this.links 	   		= oldlinks.filter((link) => link.value > 0)
	}


	remove_dead_nodes()
	{
		const oldnodes 		= this.nodes
		this.nodes 	   		= oldnodes.filter((node) => node.size > 0)
		this.node_index  	= {}
		this.nodes.map((node) => this.node_index[node.name] = node)

		const ns = this.node_index

		const oldlinks 	= this.links
		this.links 		= oldlinks.filter((l) => ns[l.from] && ns[l.to])
	}


	setup_diagram()
	{
		this.setup_nodes()
		this.setup_links()
		this.resize_nodes()

		// Now we have full nodes, we remove the empty ones and ...
		this.remove_dead_links()
		this.remove_dead_nodes()


		this.setup_nodes()
		this.link_statistics()
		this.setup_links()
		this.resize_nodes()
		this.prop_links()
		this.setup_reachability()
		this.link_statistics()
	}


	draw_links( svg )
	{
		this.calculate_link_values()
		this.draw_links_layer( 1, svg )
		this.draw_links_layer( 2, svg )
		this.draw_links_layer( 3, svg )
	}


	out_angle( node, link )
	{
		const to_node 		= link.to_node
		const layerdiff 	= to_node.xm - node.xm

		const xd 		= layerdiff
		const yd 		= to_node.ym - node.ym
		const angle 	= Math.atan2( yd,xd )

		return angle
	}


	in_angle( node, link )
	{
		const xd = node.xm - link.from_node.xm
		const yd = link.from_node.ym - node.ym
		if (node.input == 'bottom')
			return Math.atan2( -xd, yd )
		else if (node.input == 'top')
			return Math.atan2( xd, yd )
		else
			return Math.atan2( yd,xd )
	}


   calculate_link_values()
    {
        const y_from_offset = {}
        const y_to_offset   = {}

        for( const node of this.nodes )
        {
        	const angles = {}
        	node.out.forEach( link => angles[link.to] = this.out_angle( node, link ) )
            node.out.sort( (a,b) => angles[a.to] - angles[b.to] )
            node.out.forEach( (l,i) => l.from_index = i )
        }

        this.links.sort( (a,b) => a.from_index - b.from_index )

        for( const link of this.links )
        {
            const   from_node   = link.from_node
            const   to_node     = link.to_node



            if (!y_from_offset[from_node.name])
                y_from_offset[from_node.name] = 0

            if (!y_to_offset[to_node.name])
                y_to_offset[to_node.name]     = 0

            link.o1             = y_from_offset[from_node.name]
            link.o2             = y_to_offset[to_node.name]

            let w               = link.value * 10
            y_from_offset[from_node.name]   += w
            y_to_offset[    to_node.name]   += w

            if (w == 0)
            {
                // We leave it be 0 is zero
            }
            else if (w < 2 && from_node.size > 0 && to_node.size > 0)
            {
                const min_w     = 2
                link.o1         = link.o1 - (min_w - w)
                link.o2         = link.o2 - (min_w - w)

                if (link.o1 < 0)
                    link.o1 = 0
                if (link.o2 < 0)
                    link.o2 = 0

                w               = min_w
            }

            link.w          = w
        }

        for( const node of this.nodes )
        {
        	const angles = {}
        	node.in.forEach( link => angles[link.from] = this.in_angle( node, link ) )
        	node.in.sort( (a,b)   => angles[a.from] - angles[b.from] )
            node.in.forEach( (l,i) => l.to_index = i )
            node.from_offset = 0
            node.in.forEach( l =>
            {
                l.o2 = node.from_offset
                node.from_offset += l.w
            })
        }
    }

	draw_links_layer( layer, svg )
	{
		for( const link of this.links )
		{
			if (link.layer === layer)
			{
				const 	from_node 				= link.from_node
				const 	to_node  				= link.to_node

				const   color 					= sk_colors[link.col].rgb
				this.colorsused[link.col] 		= true

				const   {w,o1,o2} 				= link

				const opacity 	=  !this.state.focused || this.focused_links.includes( link.name )   ? 1 : 0.1

				const label 	=  link.real_value ? link.real_value.toFixed(2) : null

				let path

					//console.log( from_node.name + " ->" + to_node.name + " : " + w)

				if (!to_node.input && !from_node.input)
				{
					const x1 	=      from_node.xc + from_node.width
					const y1 	= o1 + from_node.yc

					const x2 	=      to_node.xc
					const y2 	= o2 + to_node.yc

					const cd 	= (x2 - x1) / 3

					path = <path d={`M ${x1},${y1} C ${x1+cd},${y1} ${x2-cd},${y2}, ${x2},${y2}
				 				     L ${x2} ${y2+w}
				 				     C ${x2-cd},${y2+w} ${x1+cd},${y1+w}, ${x1},${y1+w}
				 				     L ${x1}, ${y1}`}
	  							  	stroke="transparent"
	  							  	fill={color}  opacity={opacity}
	  							  	key={`LINE-${to_node.name}-${from_node.name}`}>
	  							  	{label && <title>{label}</title>}
	  						</path>
		  		}
				else if (to_node.input == 'top' && this.props.draw_losses)
				{
					const x1 	=      from_node.xc + from_node.width
					const y1 	= o1 + from_node.yc

					const x2 	= o2 + to_node.xc
					const y2 	=	   to_node.yc

					const cdx 	= (x2 - x1)
					const cdy 	= (y2 - y1) /5

					path = <path d={`M ${x1},${y1}
									 C ${x1+cdx},${y1} ${x2+w},${y2-cdy} ${x2+w},${y2}
									 L ${x2},${y2}
									 C ${x2},${y2-cdy} ${x1+cdx},${y1+w} ${x1},${y1+w}
									 L ${x1}, ${y1}`}
									stroke="transparent" fill={color} opacity={opacity}
									key={`LINE-${to_node.name}-${from_node.name}`}>
									<title>{label}</title>
							</path>
		  		}
				else if (from_node.input == 'top')
				{
					const x1 	= o1 + from_node.xc
					const y1 	= 	   from_node.yc

					const x2 	=  	   to_node.xc
					const y2 	= o2 + to_node.yc

					const cdx 	= (x2 - x1)
					const cdy 	= (y2 - y1) /5

					path = 	<path d={`M ${x1},${y1}
									 C ${x1},${y1+cdy} ${x2-cdx},${y2} ${x2},${y2}
									 L ${x2},${y2+w}
									 C ${x2-cdx},${y2+w} ${x1+w},${y1+cdy} ${x1+w},${y1}
									 L ${x1}, ${y1}`}
									stroke="transparent" fill={color} opacity={opacity}
									key={`LINE-${to_node.name}-${from_node.name}`}>
								<title>{label}</title>
							</path>

		  		}
		  		else if (to_node.input == 'bottom')
				{
					const x1 	=      from_node.xc + from_node.width
					const y1 	= o1 + from_node.yc

					const x2 	= o2 + to_node.xc
					const y2 	=	   to_node.yc   + w

					const cdx 	= (x2 - x1) / 3
					const cdy 	= (y1 - y2) / 3

					path = 	<path d={`M ${x1},${y1}
									 C ${x1+cdx},${y1} ${x2},${y2+cdy} ${x2},${y2}
									 L ${x2+w},${y2}
									 C ${x2+w},${y2+cdy} ${x1+cdx},${y1+w} ${x1},${y1+w}
									 L ${x1}, ${y1}`}
									stroke="transparent" fill={color}  opacity={opacity}
									key={`LINE-${to_node.name}-${from_node.name}`}>
								<title>{label}</title>
							</path>
		  		}
		  		else if (from_node.input == 'bottom')
				{
					const x1 	= o1 + from_node.xc
					const y1 	=	   from_node.yc   + w

					const x2 	=      to_node.xc
					const y2 	= o2 + to_node.yc

					const cdx 	= (x2 - x1) / 3
					const cdy 	= (y1 - y2)

					path = 	<path d={`M ${x1+w},${y1}
									 C ${x1+w},${y1-cdy} ${x2-cdx},${y2} ${x2},${y2}
									 L ${x2},${y2+w}
									 C ${x2-cdx},${y2+w} ${x1},${y1-cdy} ${x1},${y1}
									 L ${x1+w}, ${y1}`}
									stroke="transparent" fill={color}  opacity={opacity}
									key={`LINE-${to_node.name}-${from_node.name}`}>
									<title>{label}</title>
							</path>

		  		}

				svg.push( path )
			}
		}
	}



	draw_titles( svg )
	{
		const i18 = this.props.i18
		this.pushtext(i18("SANKEY:HEADER1:SUPPLY"), 				"A*", 			-30, svg )
		this.pushtext(i18("SANKEY:HEADER1:GENERATION"), 			"B*", 			-30, svg )
		this.pushtext(i18("SANKEY:HEADER1:TOTELGEN"), 		"C*", 			-30, svg )
		this.pushtext(i18("SANKEY:HEADER1:EXPORT"),				"D*", 			-30, svg )
		this.pushtext(i18("SANKEY:HEADER1:TOTELDEM"),		"G*", 			-30, svg )
		this.pushtext(i18("SANKEY:HEADER1:TRANSFORMATION"),		"H*", 			-30, svg )
		this.pushtext(i18("SANKEY:HEADER1:TOTHEATDEM"),		"J*", 			-30, svg )
		this.pushtext(i18("SANKEY:HEADER1:LOSSES"), 				"L*",			-30, svg )
		this.pushtext(i18("SANKEY:HEADER1:FINENDEM"), 	"E1,E2",		-30, svg )
		this.pushtext(i18("SANKEY:HEADER1:FINENDEMSECTOR"), 	"F*",			-30, svg )
		this.pushtext(i18("SANKEY:HEADER2:FINENDEMSECTOR"), 			"F*",			-10, svg )
	}

	pushtext( s, nodeset, y, svg )
	{
		const [miny, maxy, minx,maxx] = this.minmax_screen_nodeset( nodeset )
		const x = (minx)
		const style = {fontSize : "16px", fontWeight: "bold", textAnchor : "right"}
		svg.push( <text style={style} key={`TEXT${x}-${y}-${nodeset}`} x={x} y={y}>{s}</text>)
	}


	draw_legend( svg )
	{
		const i18 = this.props.i18
		if (!this.props.draw_legend)
			return
		const [miny, maxy, minx,maxx] = this.minmax_screen_nodeset("*")
		let x = minx + 10
		let y = maxy + 30
		for( let c in sk_colors)
		{
			const color = sk_colors[c]
			if (this.colorsused[c])
			{
				svg.push(<rect  key={`LEGR-${c}`} x={x} y={y} width={50} height={20} fill={color.rgb}/>)
				svg.push( <text fontSize='14px' key={`LEGT-${c}`} x={x+60} y={y+15}>{i18(`COLOR:${c}`)}</text>)
				x += 400
				if (x > 550)
				{
					x = minx + 10
					y += 30
				}
			}
		}
	}



	draw_nodes( svg )
	{
		for( const node of this.nodes )
		{
			if (!this.props.draw_losses && node.input == 'top')
				continue
			if (node.size > 0 && node.width > 0)
			{
				const x     	= node.xc
				const y 		= node.yc
				let height	= node.size * 10
				if (height < 2)
					height  = 2
				const id		= node.name
				const color 	= '#667'
				const sk_label  = sk_labels[id]

				const { i18 } = this.props


				let   abbr 	= sk_label ? `${id} ${i18("NODE:ABBR:" + node.name)}` : ''
				if (utils.blank(abbr))
					abbr  		= null

				let label   = sk_label ? `${id} ${sk_label.label}` : ''
				if (utils.blank(label))
					label = null

				let percentage = sk_label.percent

				let value
				if (node.size_out > 0)
					value 		= node.real_size_out
				else if (node.size_in > 0)
					value 		= node.real_size_in
				else
					value 		= null


				const val_str 	= `${utils.fmt(value)} ${i18(this.props.unit)}` //  ${node.partition}

				const opacity 	=  !this.state.focused || this.focused_nodes.includes( node.name ) ? 1 : 0.2

				const click 	=  this.nodeClick( node.name )

				let percent_str =  ''
				if (percentage)
				{
					const percent = node.upstream[0].out_prop[node.name]
						  percent_str 	= ` (${(percent*100).toFixed(1)}%)`
				}

				let rect
				if (node.input)
				{
					rect = <rect key={`NODE-${id}`} x={x} y={y} width={height} height={height}
									fill={color} stroke='transparent' onClick={click} opacity={opacity}>
								<title>{label}</title>
							</rect>
				}
				else
				{
					rect = <rect key={`NODE-${id}`} x={x} y={y} width={node.width} height={height}
									fill={color} stroke='transparent' onClick={click} opacity={opacity}>
									<title>{label}</title>
							</rect>
				}
				svg.push( rect )
//				svg.push(  <rect data-tip='Hello' key={`NODE-${id}`} x={node.xm-5} y={node.ym-5} width={10} height={/2
//									fill='black' stroke='transparent'>
//							</rect>)



				const loffset = this.props.draw_values ? 25 : 8
				if (label && this.props.draw_labels)
		  			svg.push( <text fontSize='16px' className="sk_label" onClick={click} opacity={opacity}
					                key={`${id}-t2`} x={x} y={y - loffset} className="varname">{abbr}</text>)
		  		if (val_str && this.props.draw_values)
		  			svg.push( <text fontSize='14px' className="sk_label" onClick={click} opacity={opacity}
									key={`${id}-t1`} x={x} y={y - 8} className="varname">{val_str}{percent_str}</text>)
			}
		}
	}


	draw_clusters( svg )
	{
		if (!this.clusters)
			return
		for(const c of this.clusters)
		{
			const [miny, maxy, minx,maxx] = c
			svg.push( <rect id={`CLUSTER-${c}`} x={minx-5} y={miny-5}
							width={maxx-minx + 10} height={maxy-miny+10} 
							stroke='indianred' strokeWidth="1"
							fill='none'/> )
		}
	}

	setup_focused_nodes()
	{
		const focused_node = this.node_index[this.state.focused]
		if (this.state.focused && focused_node)
		{
			this.focused_links = focused_node.linked_to_links
			this.focused_nodes = focused_node.reachable_nodes
		}
		else
		{
			this.focused_links = []
			this.focused_nodes = []
		}
	}

	draw_svg_diagram()
	{
		this.setup_focused_nodes()
		const svg = []
		this.draw_links( svg )
		this.draw_nodes( svg )
		this.draw_titles( svg )
		this.draw_legend( svg )
		this.draw_clusters( svg )
		return svg
	}


	move( node, y, weight = 1 )
	{
		if (!this.old_y)
		{
			this.old_y = {}
			this.nodes.forEach( n => this.old_y[n.name] = n.y )
		}

		if (this.old_y[node.name] != node.y && node.name == 'A1' && false)
			console.log( "*** HAS MOVED ", node.name, node.y, this.old_y[node.name])

		this.old_y[node.name] = y

		if (node.force_y)
		{
			//console.log( "Moving ", node.name, node.force_y.name)
			//node.force_y.y = y
		}
		node.y = y
		if (node.target_y == null)
			node.target_y = []
	}


	sort_layer( layer )
	{
		layer.nodes.sort( (a,b) =>
		{
			if (   a.partition == b.partition
				&& layer.index < this.depth - 1)
			{
				return a.y - b.y
			}
			else
				return a.partition - b.partition
		})
	}


	sort_layer_ds(layer) {
		layer.nodes.sort((a, b) => {
			if (a.partition == b.partition
				&& layer.index < this.depth - 1) {
				return a.ds_position - b.ds_position
			}
			else
				return a.partition - b.partition
		})
	}

	swap_nodes( nt, nb )
	{
		const oy = nt.y
		this.move( nb, oy, 				 "Swap1" )
		this.move( nt, nb.y + Y_SPACING, "Swap2" )
	}



	calculate_link_stress(node, offset, debug) 
	{
		let stress = 0
		if (!node.force_cluster) {
			for (let l of node.out) {
				const n = l.to_node
				if (n.input)
					continue

				const target_y = n.y + n.size / 2
				const source_y = (node.y + offset + node.size / 2)

				const yd = target_y - source_y
				const sv = Math.sqrt(yd * yd) * l.value

				if (debug)
					console.log(" OT ", node.name, source_y, " -> ", n.name, target_y.toFixed(1), " : ", sv.toFixed(1), stress.toFixed(1))
				stress = stress + sv
			}
		}

		for (let l of node.in) {
			const n = l.from_node
			if (n.input)
				continue

			const target_y = n.y + n.size / 2
			const source_y = (node.y + offset + node.size / 2)

			const yd = target_y - source_y
			const sv = Math.sqrt(yd * yd) * l.value

			if (debug)
				console.log( " IN ", node.name, source_y, " -> ", n.name, target_y.toFixed(1), " : ", sv.toFixed(1), stress.toFixed(1))
			stress = stress + sv
		}
		return stress
	}


	destress_links( node, move = true)
	{
		if (!node.destress && !node.force_cluster && !node.single_in_layer)
			return
		this.destress_links_force( node, move )
	}

	
	destress_links_force(node, move = true, debug = false) 
	{
		const current_stress = this.calculate_link_stress(node, 0, false)
		let min_stress = current_stress

		if( debug )
			console.log( "------- ", node.name, current_stress)

		let yp = 0
		let optimal_y = yp

		const [miny, maxy] = this.minmax_nodeset( node.all_internal )
		let yd = (maxy - miny) / 20
		if (debug)
			console.log( "MMMMNS   ", node.name, miny, maxy, yd )

		for (let i = 0; i < 20; i++) 
		{
			yp = yp + yd
			const stressup = this.calculate_link_stress(node, yp, false)

			if (false)
				console.log( "    ST ", node.name, optimal_y, yp, stressup)

			if (stressup < min_stress) {
				optimal_y = yp
				min_stress = stressup
			}
		}
		
		yp = 0
		for (let i = 0; i < 20; i++) {
			yp = yp + yd
			const stressdown = this.calculate_link_stress(node, -yp, debug)

			if (debug)
				console.log("    ST ", node.name, optimal_y, -yp, stressdown)

			if (stressdown < min_stress) {
				optimal_y = yp
				min_stress = stressdown
			}
		}

		const offset = optimal_y - (node.force_cluster || node.destress ? 0 : 0)

		const new_pos = node.y + offset

		let has_to_move = move
		//		if (node.min_y !== undefined && node.min_y !== null && new_pos < node.min_y)
		//			has_to_move = false

		if (has_to_move)
			node.y = new_pos

		return new_pos
	}



	force_spacing(r)
	{
		this.force_cluster_nodes_with_violence()
		utils.visit( this.layers, (n, layer) => this.force_spacing_layer( layer ))
	}

    // -------------

	force_spacing_layer( layer )
	{
		const l = layer.nodes.length
		for( let li = 0; li < l; li++)
		{
			const node = layer.nodes[li]
			if (node.force_y)
			{
//				console.log( "Nodes linked ", node.name, node.force_y.name )
				const thisy 	= node.y
				const thaty 	= node.force_y.y
				const y 		= Math.max( thisy, thaty )
				this.move( node, y )
				this.move( node.force_y, y )
			}
		}

		for (let li = 0; li < l; li++)
			layer.nodes[li].ds_position = this.destress_links(layer.nodes[li], false)

		this.sort_layer_ds( layer )

		for( let nn = 0; nn <= l*2; nn++)
		{
			// Swap nodes if links cross
			if (l > 1)
			{
				for( let li = 0; li < l-1; li++)
				{
					const nt = layer.nodes[li]
					const nb = layer.nodes[li+1]

					if (layer.index == 0 && nb.out.length > 1)
						nb.destress = true
					if (nb.destress) {
						nb.min_y = -10000000
						this.destress_links(nb)
					}

					if (nt.partition != nb.partition)
						continue

					if (nb.destress)
						this.destress_links(nb)

					if (nt.upstream_internal.length == 1 && nb.upstream_internal.length == 1)
					{
						const ut = nt.upstream_internal[0]
						const ub = nb.upstream_internal[0]

						if (ut.y > ub.y)
						{
							this.swap_nodes( nt, nb )
							this.sort_layer( layer )
						}
					}

					if (nt.downstream_internal.length == 1 && nb.downstream_internal.length == 1)
					{
						const ut = nt.downstream_internal[0]
						const ub = nb.downstream_internal[0]

						// We are swapping a difference in X coordinates here
						if (ut.x > ub.x)
						{
							this.swap_nodes( nt, nb )
							this.sort_layer( layer )
						}
					}
				}
			}
		}




		layer.nodes.forEach( n =>
		{
			n.avg_up   	= layer.index == 0 				? 0 : this.center_nodeset( n.upstream_internal_names,   n )
			n.avg_dn   	= layer.index == this.depth - 1 ? 0 : this.center_nodeset( n.downstream_internal_names, n )

//			n.avg_up 	= layer.index == 0 				? 0 : this.weighted_position( n, false )
//			n.avg_dn 	= layer.index == this.depth - 1 ? 0 : this.weighted_position( n, true )
			n.avg_wg	= layer.weight_up * n.avg_up + layer.weight_dn * n.avg_dn
		})

		// Center nodes where the is only one node in a layer
		if (l == 1)
		{
			const node = layer.nodes[0]

			if (!node.input)
			{
//				this.spread( node.upstream_internal_names )
				this.spread( node.downstream_internal_names )

				if (node.upstream_reachable.length * l < node.downstream_reachable.length * (this.depth - l))
					this.center_cluster( [node], node.upstream_reachable, 0 )
				else
					this.center_cluster( [node], node.downstream_reachable, 0 )

//				this.center_cluster( node.upstream_internal_names, [node], 0 )
//				this.center_cluster( [node], node.downstream_internal_names, 0 )
				this.center_cluster( node.all_internal, [node], 0, "Single Layer" )
			}
		}

		let part = -1
		for( let li = 0; li < l-1; li++)
		{
			const nt 	= layer.nodes[li]
			const nb 	= layer.nodes[li+1]

			const min_y = nt.y + nt.size + Y_SPACING

			if (part != nt.partition)
			{
				if (nt.y < nt.avg_wg)
					this.move( nt, nt.avg_wg )
				if (nt.force_y && nt.y < nt.force_y.y)
					this.move( nt, nt.force_y.y)
//				if (nt.offset_root && nt.y < nt.offset_root.y + nt.offset_up)
//					this.move( nt, nt.offset_root.y + nt.offset_up)
			}
			else if (nb.partition != nt.partition)
				continue

			part = nt.partition

			let partition_top = Y_SPACING
			if (nt.partition > 0)
				partition_top = this.partitions[nt.partition - 1].maxy + Y_SPACING


			if (nt.y < partition_top)
				this.move( nt, partition_top )

			if (nb.y > nb.avg_up)
				this.move( nb, nb.avg_up )

			if (nb.y < nt.y + nt.size + Y_SPACING)
				this.move(nb, nb.avg_up)

			if (nb.force_y && nb.y < nb.force_y.y)
				this.move( nb, nb.force_y.y )

			if (nt.y + nt.size + Y_SPACING > nb.y)
				this.move( nb, nt.y + nt.size + Y_SPACING )
		}
	}

	split_partitions()
	{
		for( let i = 0; i < this.partitions.length; i++ )
		{
			this.get_partition_bounds()

			const 	part 	= this.partitions[i]
			let 	shift 	= part.miny

			if (i > 0)
				shift = shift - this.partitions[i-1].maxy

			for( let n of part.nodes )
				this.move( n, n.y - shift + Y_SPACING )
		}
	}


	get_partition_bounds()
	{
		this.normalize_coordinates()
		for( let partition of this.partitions )
		{
			const [miny,maxy] 	= this.minmax_nodeset( partition.nodes )
			partition.miny  	= miny
			partition.maxy 	= maxy
		}
	}

	make_partition_clusters()
	{
		this.normalize_coordinates()
		for( let partition of this.partitions )
			this.clusters.push( this.minmax_screen_nodeset( partition.nodes ) )
	}


	clear_gaps()
	{
		return
		const sorted = [...this.nodes].filter(n=>!n.input).sort( (a,b) => (a.y + a.size) - (b.y + b.size) )

		let skip_offset = 0
		for( let i = 1; i < sorted.length; i++ )
		{
			let gap = sorted[i].y - (sorted[i-1].y + sorted[i-1].size)
			if (gap < 0)
				gap = 0
			let leave_gap = false
			if (sorted[i].single_in_layer)
				leave_gap = true
			if (sorted[i].y - sorted[i-1].y == 0)
				leave_gap = true

			if (gap > Y_SPACING*1.5 && !leave_gap)
				skip_offset += gap
			sorted[i].skip_offset = skip_offset
	//		console.log( "GAP", sorted[i-1].name, "->", sorted[i].name, sorted[i].y, sorted[i-1].y, gap, skip_offset )
		}

		for (let i = 1; i < sorted.length; i++)
			sorted[i].y = sorted[i].y - skip_offset
	}




    // ----------------

	is_valid( node )
	{
		if (!node)
			return false
		if (!this.props.draw_losses && node.name == 'L1')
			return false
		return true
	}

	// Calls a function on a set of nodes which is specified as a list
	// of ids (["A", "B"]), a string ("A") or a string with a negation
	// ("^A") in which case all nodes that do not have the name will be used
	walk_nodes( set, fn, mark )
	{
		if (mark )
		{
			console.log( "We are walking through ", set )
			console.log( this.node_set )
		}
		const call_fn = (index,node) => {
 			if (this.is_valid(node))
 			{
				if (this.verbose_walk)
					console.log( index )
				if (mark)
					console.log( `${mark} :: ${index}`)
				fn( index, node )

 			}
 			else if (this.verbose_walk)
 				console.log( `INVALID ${index}`)

		}

		if (typeof set == 'string')
		{
			const list = set.split(",").map( (x) => `${x}`.trim().toUpperCase())
			if (list.length > 1)
			{
				list.map( (x) => this.walk_nodes(x, fn, mark))
			}
			else if (set === "*")
			{
				this.walk_nodes( "!", fn, mark )
			}
			else if (set === "![bottom]")		// Cheat, we should fix this
			{
				for( const n in this.node_index )
				{
					if (this.node_index[n].input != 'top')
						call_fn( n,this.node_index[n] )
				}
			}
			else if (set === "![top]")
			{
				for( const n in this.node_index )
				{
					if (this.node_index[n].name != 'A0' && this.node_index[n].input != 'bottom' )
						call_fn( n,this.node_index[n] )
				}
			}			else if (set === "[bottom]")
			{
				for( const n in this.node_index )
				{
					if (this.node_index[n].input == 'top')
						call_fn( n,this.node_index[n] )
				}
			}
			else if (set === "[top]")
			{
				for( const n in this.node_index )
				{
					if (this.node_index[n].name == 'A0' || this.node_index[n].input == 'bottom')
					{
						call_fn( n,this.node_index[n] )
					}
				}
			}
			else if (set.endsWith(">>"))
			{
				const nid  = set.substring( 0,set.length - 2)
				const node = this.node_index[nid]
				if (node)
					this.walk_nodes( node.downstream_reachable, fn, mark )
			}
			else if (set.endsWith(">"))
			{
				const nid  = set.substring( 0,set.length - 1)
				const node = this.node_index[nid]
				if (node)
					this.walk_nodes( node.downstream_internal, fn, mark )
			}
			else if (set.endsWith(">S"))
			{
				const nid  = set.substring( 0,set.length - 2)
				const node = this.node_index[nid]
				if (node)
					this.walk_nodes( node.downstream_significant, fn, mark )
			}
			else if (set.endsWith("<<"))
			{
				const nid  = set.substring( 0,set.length - 2)
				const node = this.node_index[nid]

				if (node)
					this.walk_nodes( node.upstream_reachable, fn, mark )
			}
			else if (set.endsWith("<"))
			{
				const nid  = set.substring( 0,set.length - 1)
				const node = this.node_index[nid]
				if (node)
					this.walk_nodes( node.upstream_internal, fn, mark )
			}
			else if (set.endsWith("<S"))
			{
				const nid  = set.substring( 0,set.length - 2)
				const node = this.node_index[nid]
				if (node)
					this.walk_nodes( node.upstream_significant, fn, mark )
			}
			else if (set.endsWith("*"))
			{
				const nid = set.substring( 0,set.length - 1)
				for( const n in this.node_index )
				{
					if (n.startsWith(nid))
						call_fn( n,this.node_index[n] )
				}
			}
			else if (set.startsWith("!"))
			{
				const nid = set.substring( 1 )
				for( const n in this.node_index )
				{
					if (n !== nid)
						call_fn( n,this.node_index[n] )
				}
			}
			else if (this.node_index[set])
				call_fn( set, this.node_index[set] )
		}
		else
		{
			for( const id_or_node of set )
			{
				if (typeof id_or_node == 'string')
					this.walk_nodes( id_or_node, fn, mark )
				else if (this.is_valid( id_or_node ))
					call_fn( id_or_node.name, id_or_node )
			}
		}
	}


	weighted_position( node, downstream = true )
	{

		let 	sum 		= 0
		const 	our_center 	= node.y + node.size/2
		let 	miny 		=  1000000
		let 	maxy 		= -1000000

		const 	list 		= downstream ? node.downstream_internal : node.upstream_internal
		list.forEach( n =>
		{
			const linked_center = n.y + n.size/2
			const weight 		= downstream ? node.out_prop[n.name] : node.in_prop[n.name]
			miny 				= Math.min( miny, linked_center )
			maxy 				= Math.max( maxy, linked_center )
			sum += 1-weight * linked_center
		})
		return sum
	}




	center_nodeset( node_id_set, node = null )
	{
		const [miny,maxy] = this.minmax_nodeset( node_id_set )
		if (node)
			return (miny+maxy - node.size) / 2
		else
			return (miny+maxy / 2)
	}


	minmax_nodeset( node_id_set, mark )
	{
		let minx =  10000000
		let maxx = -10000000
		let miny =  10000000
		let maxy = -10000000


		this.walk_nodes( node_id_set, (node_id, node, mark) =>
		{
			minx = Math.min( minx, node.x )
			maxx = Math.max( maxx, node.x + 1 )

			miny = Math.min( miny, node.y )
			maxy = Math.max( maxy, node.y + node.size )
		})


		return [miny,maxy, minx,maxx]
	}


	minmax_screen_nodeset( node_id_set )
	{
		let minx =  10000000
		let maxx = -10000000
		let miny =  10000000
		let maxy = -10000000
		this.walk_nodes( node_id_set, (node_id, node) =>
		{
			minx = Math.min( minx, node.xc )
			maxx = Math.max( maxx, node.xc + node.width )

			miny = Math.min( miny, node.yc )
			maxy = Math.max( maxy, node.yc + node.size * 10 )
		})


		return [miny,maxy, minx,maxx]
	}

	spread( node_set, dist = Y_SPACING )
	{
		let y = -1
		this.walk_nodes( node_set, (node_id, node ) =>
		{
			if (y > -1)
				this.move( node, y )
			const min_y = node.y + node.size + dist
			if (node.size > 0)
				y = min_y
		});
	}


	shift_cluster( node_id_set, offset, reason )
	{
		this.walk_nodes( node_id_set, (node_id, node) =>
		{
			if (reason)
				console.log( "SHIFTING ", reason, node.name, node.y, "=>", (node.y + offset))
			this.move( node, node.y + offset )
		})
	}



	get_center_shift( fix_set, move_set, offset = 0, reason )
	{
		const [fmin,fmax] = this.minmax_nodeset( fix_set, reason )
		const [mmin,mmax] = this.minmax_nodeset( move_set, reason )

		const fcenter 	  = (fmax + fmin) / 2
		const mcenter  	  = (mmax + mmin) / 2

		const shift 	  = (fcenter - mcenter) + offset

		if (false && reason)
		{
			console.log( fix_set.length, move_set.length, shift )
			console.log( "FIX", [fmin,fmax], fcenter)
			console.log( "MOV", [mmin,mmax], mcenter)
		}

		return shift
	}

	center_cluster( fix_set, move_set, offset = 0 )
	{
		const shift = this.get_center_shift( fix_set, move_set, offset )
		if (false && reason)
			console.log( "CENTER CLUSTER ", reason, shift)
		this.shift_cluster( move_set, shift )
	}



	force_below( fix_set, move_set, offset = 0 )
	{
		const [fmin,fmax] = this.minmax_nodeset( fix_set )
		const [mmin,mmax] = this.minmax_nodeset( move_set )


		const shift 	  = Math.max( 0, (fmax - mmin) + offset )
		this.shift_cluster( move_set, shift )
	}



	align_top( move_set )
	{
		const [min] = this.minmax_nodeset( move_set )
		this.walk_nodes( move_set, (_, node) => this.move( node, min ))
	}





	move_up_below( fix_set, move_set, offset = 0 )
	{
		const [fmin,fmax] = this.minmax_nodeset( fix_set )
		const [mmin,mmax] = this.minmax_nodeset( move_set )

		const shift 	  = fmax - mmin + offset

//		if (fix_set === 'D1')
//		{
//			console.log( move_set )
//			console.log( [fmin,fmax])
//			console.log( [mmin, mmax])
//			console.log( shift )
//		}


		this.shift_cluster( move_set, shift )
	}


	force_top_bottom()
	{
		this.force_below(  	"[top]", "![top]",  100 )
		this.move_up_below( "[top]", "![top]",  5 )

		this.force_below(  	"![bottom]", "[bottom]",  8 )
		this.move_up_below( "![bottom]", "[bottom]",  10 )
		this.align_top( "[bottom]" )
	}


	reset_graph()
	{
		const type 	= this.props.type

		this.node_index 	= {}
		this.nodes 			= []
		this.render_counter = 0

		if (this.props.kind == 'qd')
			this.definition 	= new sk_definition_current[type]( type, this )
		else
			this.definition 	= new sk_definition_future[type]( type, this )

		this.definition.setup_graph()

		this.fill_values()
		this.setup_diagram()

		this.setup_layers()

		this.layersize 	= Object.keys( this.layers ).length
//		this.set_node_positions( this.layers )
		this.normalize_coordinates()


		const {nodes,links} = this.build_external_rep( this.nodes, this.links )


		const sk_layout = new SankeyLayout( nodes, links )		
		for( let i = 0; i < 50; i++)
			sk_layout.layout_step()

		Object.keys( sk_layout.nodes ).forEach( k => {
			const lnode = sk_layout.nodes[k]
			if (!lnode.dummy)
			{
				const rnode = this.nodes.find( n => n.name == k)
				rnode.y = lnode.sy/4
			}
		})


		this.clear_gaps()
		this.split_partitions()

		this.force_top_bottom()
		this.normalize_coordinates()

		this.setup = true
	}


	//testrec
			//const [bminy,bmaxy, bminx,bmaxx] = this.minmax_screen_nodeset( "B4>" )
		// <rect x={bminx} y={bminy} width={bmaxx-bminx} height={bmaxy-bminy} opacity='1'/>


	componentDidUpdate(prevProps)
	{
	  if(prevProps.all_links !== this.props.all_links )
	  {
	  	this.setup = false
	    this.setState({someState: prevProps.all_links  });
	  }
	}

	build_external_rep()
	{
		const nn = []

		this.nodes.forEach( (node,idx) => {
			if (!node.input)
				nn.push( {l: node.x, y: idx, size: node.size, name: node.name} )
		})

		const ll = []
		for (const link of this.links)
		{
			if (!link.from_node.input && !link.to_node.input)
				ll.push( {from: link.from, to: link.to, w: link.value })
		}
//		console.log( JSON.stringify({ nodes: nn, links: ll } ))
		return {nodes: nn, links: ll }
	}

	render(props)
	{
		if (!this.setup)
			this.reset_graph()
		this.normalize_coordinates()


		this.width 	= 2800
		this.height = 2800
		this.normalize_coordinates()
		const [miny,maxy, minx,maxx] = this.minmax_screen_nodeset( this.nodes )

		const wh = Math.max( maxx-minx+100, maxy-miny+800)

		return 	<div className='rescale-container' 	style={this.props.style}>
					<svg 	ref={this.props.chartref}
  	  						version={1}
		  					xmlns='http://www.w3.org/2000/svg'

		  					viewBox={`${minx -50} ${miny-120} ${wh} ${wh}`}>
		  					{
		  						this.draw_svg_diagram()
		  					}
					</svg>
				</div>
    }
}

export default Sankey

//http://localhost:1234/qdata/rayong?year=2560&varname=QD_SANKEYB1L1&selected_node=QD_PRITPPFNGASFIDIND
//RDS_NGasConsInd