import React, { Component, Fragment } 	from "react";
import classnames 						from 'classnames';
import backend_config 					from "../config/runconfig"
import axios 							from 'axios'
import UnitSelector 					from '../units/UnitSelector'
import { AppController } 				from '../app/AppController'
import MasterFrame 						from '../app/MasterFrame'
import BarChartSet 						from './BarChartSet'

import { Navigation } from '../navigation/navigation'
import {Help}							from '../help/Help'

const API_KEY = 'ExgWFH9XHHyXIHyKEHIH'



const SDVar = (props) =>
{
	const {data}	= props
	const id 		= `${props.id}`.toUpperCase()
	if (!data || !data[id] || data[id].value === undefined)
		return <span/>
	if (data[id].kind == 'scalar')
		return <span>{data[id].value.toFixed(3)}</span>
	else
	{
		const v = data[id].value
		if (props.i18)
			return <span>{props.i18( `${props.prefix || ''}${v}`)}</span>
		else
			return <span>{v}</span>
	}
}



const SubChart = ({children}) =>
{
	if (!children)
		return null
	else
		return (
			<div className='subchart'>
				<div className='chart-set'>
					{children}
				</div>
			</div>)
}

export class SupplyDemandChart extends Component
{
	static contextType = AppController;
	constructor(props)
	{
		super(props)
		this.state = {}
	}

	set_group_name 	= ({group_name})	=>
	{
		this.goto({ group_name })
	}


	get_group_name = () => `${this.query().group_name || 'BAU'}`

	fetch_expression() {
		const component 			= this
		const province 				= this.props.match.params.province
		const group_name 			= this.get_group_name() || 'BAU'
		const url_params 			= this.get_backend_query()
		
		this.province_displayed = province

		this.data_model = null
		const root = `${backend_config.server}/sdata/supplydemandchartdata`
		const year_key          = this.context ? this.context.year_key : 'NONE'
		const url = `${root}?${url_params}&group=${encodeURIComponent(group_name)}&province=${province}&api_key=${API_KEY}&year_key=${year_key}`

		if (url === this.displayed_url)
			return

		console.log(url)

		this.inflight = true
		axios.get(url)
			.then((incoming) =>
			{
				this.displayed_url = url

				const { data } = incoming

				if (data.status === 'error') {
					console.log("Error Loading expression")
					console.log(data.error)
					component.setState({ error: true, errorMsg: data.error, chart_data: null })
				}
				else {
					const new_state = {}
					this.data_model_right 	= null
					this.data_model_left  	= null
					new_state.error 		= false
					new_state.errorMsg 		= null
					new_state.chart_data 	= data.result.chart_data
					new_state.chart_info 	= data.result.info
					new_state.units 		= data.result.units
					new_state.is_head_data 	= data.result.is_head_data

					component.setState(new_state)
				}
			})
	}




	make_chart_labels = (i18) => {
		const labels =  {
		SD_BARS1: { 	
				
				color: 'rgba(204,201,207,0.8)' },
		SD_BARS2: { 	
				
				color: 'rgba(97,92,106,0.8)' },
		SD_BARS3: { 	
				
				color: 'rgba(58,57,60,1)' },
		SD_BARS4: { 	
				
				color: 'rgba(255,115,0,0.8)' },
		SD_BARS5: { 	
				
				color: 'rgba(219,69,0,0.8)' },
		SD_BARS6: { 	
				
				color: 'rgba(117,0,117,0.8)' },
		SD_BARS7: { 	
				
				color: 'rgba(203,198,154,0.8)' },
		SD_BARS8: { 	
				
				color: 'rgba(243,223,43,0.8)' },
		SD_BARS9: { 	
				
				color: 'rgba(14,27,223,0.8)' },
		SD_BARS10: {
				
				color: 'rgba(11,17,142,0.8)' },
		SD_BARS11: {
				
				color: 'rgba(187,49,7,0.8)' },
		SD_BARS12: {
				
				color: 'rgba(110,189,46,0.8)' },
		SD_BARS13: {
				
				color: 'rgba(109,45,8,0.8)' },
		SD_BARS14: {
				color: 'rgba(93,166,244,0.8)' },

		SD_BARD1: { 	
				
				color: 'rgba(36,62,110,0.8)' },
		SD_BARD2: { 	
				
				color: 'rgba(179,20,36,0.8)' },
		SD_BARD3: { 	
				
				color: 'rgba(255,115,0,0.8)' },
		SD_BARD4: { 	
				
				color: 'rgba(203,198,154,0.8)' },
		SD_BARD5: { 	
				
				color: 'rgba(93,166,244,0.8)' },
		SD_BARD6: { 	
				
				color: 'rgba(134,132,139,0.8)' },
		SD_BARX1:  {
				
				color: 'rgb(93,166,244)' },
		SD_BARX2: {	
				color: 'rgb(241,75,105)' },

		SD_BAR2S4: {
			color: 'rgba(68,114,196,0.8)'
		},
		SD_BAR2S5: {
				color: 'rgba(255,115,0,0.8)'
		}}

		Object.keys( labels ).forEach( k  => {labels[k].label = i18(`LABEL:${k}`)})

		return labels
	}


	setup_dataset_names( data, info )
	{
		const predefined_keys 	= []
		const custom_keys 		= []
		for( let key in data )
		{
			const set_info = info[key]
			if (set_info && set_info.custom)
				custom_keys.push( key )
			if (set_info && !set_info.custom)
				predefined_keys.push( key )
		}

		return predefined_keys.sort().concat( custom_keys.sort() )
	}




	add_value_to_chart(set, id, value)
	{
		if (!value || !value.kind || value.kind != 'scalar')
			return
		let v = value.value
		set.push({ id: id, value: v })
	}


	setup_subresult_left( data, data_set )
	{
		if (!data)
			return

		const key_list = Object.keys(data).map((k) =>
		{
			const sort_key = k.replace('QD_BAR', 'SD_BAR')
			return {
				key: 		k,
				sort_key: 	sort_key
			}
		})


		key_list.sort((x, y) => { x.sort_key > y.sort_key ? 1 : -1 })


		const valid_keys = ['SD_BARD1', 'SD_BARD2']
		for (let key_entry of key_list)
		{
			const {key,sort_key} 	= key_entry
			if (valid_keys.indexOf( sort_key ) > -1)
			{
				this.add_value_to_chart(data_set, sort_key, data[key])
			}
		}
	}



	setup_chart_data_left(i18, data, info)
	{
		if (!data)
			return null

		data.ref_year 	= this.context && this.context.year_refs ? `${this.context.year_refs.reference_year }` 	: ''
		data.tgt_year 	= this.context && this.context.year_refs ? `${this.context.year_refs.target_year}` 		: ''

		const dataset_future 	= []
		const dataset_current 	= []


		const result 			= {
				set_keys: ['current', 'future'],
				data: {
					current: 	{
									label: 		i18( 'START:GRAPH1:HEADER:TODAY'),
									sub_label: 	data.ref_year,
									data: 		[{data: dataset_current}]
								},
					future: 	{
									label: 		i18('START:GRAPH1:HEADER:FUTURE'),
									sub_label: 	data.tgt_year,
									data: 		[{data: dataset_future}]
								}
						}
		}


		const opt_key  	= Object.keys( info ).find( x => info[x].re_share_fmt === 'Min. Cost')
		this.setup_subresult_left( data['CURRENT'], dataset_current,	false )
		if (opt_key)
			this.setup_subresult_left( data[opt_key], 	dataset_future,		true )

 		return result
	}


	setup_subresult_right( i18, data, metadata )
	{
		const supply 	= []
		const setec		= []
		const setghg	= []
		const setstor	= []

		const keys = ['SD_BARS14', 'SD_BARS1', 'SD_BARS3', 'SD_BARS5', 'SD_BARS13', 'SD_BARS12', 'SD_BARS8', 'SD_BARS9', 'SD_BARS10',
					  'SD_BAR2S4', 'SD_BAR2S5', 'SD_BARX1', 'SD_BARX2']

		const key_list 	= []
		Object.keys(data).forEach((k) =>
		{
			const idx = keys.indexOf( k )
			if (idx > -1)
			{

				let chart 		= null
				if 		(k.startsWith('SD_BARS'))
					chart = supply
				else if (k.startsWith('SD_BAR2S4'))
					chart = setec
				else if (k.startsWith('SD_BAR2S5'))
					chart = setghg
				else
					chart = setstor


				const s = k.replace(/S\d$/, (match, aa, bb) => `${match.substring(0, 1)}0${match.substring(1)}`)
				const d = s.replace(/D\d$/, (match, aa, bb) => `${match.substring(0, 1)}0${match.substring(1)}`)


				const sort_key1   = d.replace('SD_BAR2', '')
									.replace('SD_BAR', '')
									.replace('QD_BAR', '')
									.replace('SD_BARX', 'X')
				const sort_key = k


				key_list.push({
					idx: 		idx,
					key: 		k,
					chart: 		chart,
					sort_key: 	sort_key
				})
			}
		})

		key_list.sort( (a,b) => a.idx - b.idx )

		for (let key_entry of key_list) {
			const {key,sort_key,chart} 	= key_entry

			if (chart)
				this.add_value_to_chart(chart, sort_key, data[key])
		}

		const target = metadata.id
	
		const reshare = data.SD_SCENRESHARE?.value.toFixed(0) || ''
		const label = metadata.re_share_fmt == 'Min. Cost' ? `${i18("CHART:SUPDEM:SCNLABEL:MINCOST")} ${reshare}% RE` : i18(`CHART:SUPDEM:SCNLABEL:${reshare}RE`)

		return {
					label:     	label,
					sub_label: 	this.context && this.context.year_refs ? `${this.context.year_refs.target_year}` : '',
					target: 	target,
					data: 	[{data: supply},
					   		 {data: setec},
					   		 {data: setghg},
					   		 {data: setstor}]
				}
	}



	setup_chart_data_right(i18, data, info)
	{
		if (!data)
			return null

		const result 		= {}

		for (let set in data)
		{
			if (set !== 'CURRENT')
				result[set] = this.setup_subresult_right( i18, data[set], info[set] )
		}

		const key_list = this.setup_dataset_names( data, info )

		const rv = {
			data: 			result,
			set_keys: 		key_list,
			draw_legend: 	false
		}

		return rv
	}




	gotoTarget = (target) => 
	{
		return (event) => {
			const province = this.get_province()
			this.new_page(`/sankey-future/${province}`, { scenario: target })
		}
	}



	render_text_box( i18, data, info )
	{

		const opt_key  		= Object.keys( info ).find( x => info[x].re_share_fmt === 'Min. Cost')
		const text_data 	= data[opt_key]
		const scen 			= info[opt_key]

		if (!scen)
			return <div></div>

	
		return 	<div className='descriptions'>
					<b>{i18( "DESC:LINE1")} <SDVar data={text_data} i18={i18} prefix="SCN:" id='SD_ScenarioEff'/>:</b>

					<ul>
				<li>{i18("DESC:LINE3")}: &nbsp;
					<SDVar data={text_data} id='SD_DemDevGuiE' />&nbsp;%, <SDVar data={text_data} id='SD_DemDevGuiH' />&nbsp;% {i18("DESC:LINE4")}
					&nbsp;(<SDVar data={text_data} id='SD_DemDevGuiEAbs' />&nbsp;%, <SDVar data={text_data} id='SD_DemDevGuiHAbs' />&nbsp;% {i18("DESC:LINE5")})</li>
						<li>{i18("DESC:LINE6")} <SDVar data={text_data} id='SD_KpiguiScen3'/>&nbsp;% 
								(<SDVar data={text_data} id='SD_KpiguiScen4' />&nbsp;%  {i18("DESC:LINE7")})</li>
						<li>{i18("DESC:LINE8")} <SDVar data={text_data} id='SD_KpiguiScen5'/>&nbsp;% 
							(<SDVar data={text_data} id='SD_KpiguiScen6' />&nbsp;% {i18("DESC:LINE9")})</li>
						<li><SDVar data={text_data} id='SD_KpiguiScen7' />&nbsp;% {i18("DESC:LINE10")}</li>
					</ul>
					<b className='headline'>{i18("DESC:LINE12")}</b>
					<p>{i18("DESC:LINE11")} {scen.custom ? 'user input' : 'Thai Studies (predefined)'}</p>
					<ul>
				<li><SDVar data={text_data} id='SD_GuiPvElPmaxRoof' /> {i18('DESC:VAR:SD_GuiPvElPmaxRoof')}</li>
				<li><SDVar data={text_data} id='SD_GuiPvElPmaxGround' /> {i18('DESC:VAR:SD_GuiPvElPmaxGround')}</li>
				<li><SDVar data={text_data} id='SD_GuiWindElPmax' /> {i18('DESC:VAR:SD_GuiWindElPmax')}</li>
				<li><SDVar data={text_data} id='SD_GuiHydroElPmaxLarge' /> {i18('DESC:VAR:SD_GuiHydroElPmaxLarge')}</li>
				<li><SDVar data={text_data} id='SD_GuiHydroElPmaxSmallAndVSmall' /> {i18('DESC:VAR:SD_GuiHydroElPmaxSmallAndVSmall')}</li>
				<li><SDVar data={text_data} id='SD_GuiBiogElPmax' /> {i18('DESC:VAR:SD_GuiBiogElPmax')}</li>
				<li><SDVar data={text_data} id='SD_GuiBiogChpPmax' /> {i18('DESC:VAR:SD_GuiBiogChpPmax')}</li>
				<li><SDVar data={text_data} id='SD_GuiBiomElPmax' /> {i18('DESC:VAR:SD_GuiBiomElPmax')}</li>
				<li><SDVar data={text_data} id='SD_GuiBiomChpPmax' /> {i18('DESC:VAR:SD_GuiBiomChpPmax')}</li>
				<li><SDVar data={text_data} id='SD_GuiBiomThPmax' /> {i18('DESC:VAR:SD_GuiBiomThPmax')}</li>
				<li><SDVar data={text_data} id='SD_GuiNapElPmax' /> {i18('DESC:VAR:SD_GuiNapElPmax')}</li>
				<li><SDVar data={text_data} id='SD_GuiNapChpPmax' /> {i18('DESC:VAR:SD_GuiNapChpPmax')}</li>
				<li><SDVar data={text_data} id='SD_GuiNapThPmax' /> {i18('DESC:VAR:SD_GuiNapThPmax')}</li>
				<li><SDVar data={text_data} id='SD_GuiEucElPmax' /> {i18('DESC:VAR:SD_GuiEucElPmax')}</li>
				<li><SDVar data={text_data} id='SD_GuiEucChpPmax' /> {i18('DESC:VAR:SD_GuiEucChpPmax')}</li>
				<li><SDVar data={text_data} id='SD_GuiEucThPmax' /> {i18('DESC:VAR:SD_GuiEucThPmax')}</li>
				<li><SDVar data={text_data} id='SD_GuiMswElPmax' /> {i18('DESC:VAR:SD_GuiMswElPmax')}</li>
				<li><SDVar data={text_data} id='SD_GuiMswChpPmax' /> {i18('DESC:VAR:SD_GuiMswChpPmax')}</li>
 					</ul>
				</div>
	}




	render_chart( i18, data, info, data_model_left, data_model_right)
	{
		if (!info)
			return <div></div>
		const province 	= this.get_province()
		const group 	= encodeURIComponent(this.get_group_name())

		const scenarios_available = Object.keys(data_model_right.data).length > 0
		const tgt_year = this.context && this.context.year_refs ? `${this.context.year_refs.target_year}` : ''


		
		const chart_2 = <BarChartSet data={data_model_right}
				verbose={true}
			y_axis_label={i18("SUP:Y_LABEL_2")}
			y_axis_unit={`${i18(this.get_energy())}`}
			labels={this.chart_labels}
			groups={[3]}
			download={`${backend_config.server}/chart/supply4?lang=${this.get_lang()}&province=${province}&group=${group}&energy=${this.get_energy()}&currency=${this.get_currency()}`}

			min_groups={6} />


		return  <div className='chart-container'>
					<div className='chart-1'>
						<div className='title'>{i18("TITLE:DEM")}</div>
						<div className='fixed-height'>
							<div className='chart-set'>
								<BarChartSet 	data={data_model_left}
										y_axis_label={`${i18("DEM:Y_LABEL")}`}
										bar_width_factor={0.5}
										y_axis_unit={`${i18(this.get_energy())}`}
										labels={this.chart_labels}
										detail_target='dummy'
										detail_label={i18("DETAIL")}

										download={`${backend_config.server}/chart/demand?lang=${this.get_lang()}&province=${province}&group=${group}&energy=${this.get_energy()}&currency=${this.get_currency()}`}

										groups={[0]}/>
							</div>
						</div>
						{
							this.render_text_box( i18, data, info )
						}
					</div>
					<div className='chart-3'>
						{scenarios_available && 
							<div className='title'>{i18("TITLE:SUP")}</div>
						}
						{!scenarios_available &&
							<div className='title' style={ {opacity: 0.8, font_style: "italic"}}>{i18( "SCN:NOTCALCULATED")}</div>
						}
						<div className='subchart'>
							<div className='chart-set'>
								<BarChartSet 	data={data_model_right}
										y_axis_label={i18("SUP:Y_LABEL_1")}
										y_axis_unit={`${i18(this.get_energy())}`}
										labels={this.chart_labels}
										groups={[0]}
										min_groups={6}
										download={`${backend_config.server}/chart/supply1?lang=${this.get_lang()}&province=${province}&group=${group}&energy=${this.get_energy()}&currency=${this.get_currency()}`}
										detail_target={this.gotoTarget} 
										detail_label={i18("DETAIL")}	/>
							</div>
						</div>


						<SubChart>{chart_2}</SubChart>

						<div className='subchart'>
							<div className='chart-set'>
								<BarChartSet 	data={data_model_right}
										y_axis_label={i18("SUP:Y_LABEL_3")}
										y_axis_unit={`${i18(this.get_currency())}/${i18(this.get_energy())}`}
										labels={this.chart_labels}
										groups={[1]}

										download={`${backend_config.server}/chart/supply2?lang=${this.get_lang()}&province=${province}&group=${group}&energy=${this.get_energy()}&currency=${this.get_currency()}`}
										min_groups={6} />
							</div>
						</div>

						<div className='subchart'>
							<div className='chart-set'>
								<BarChartSet 	data={data_model_right}
										y_axis_label={i18("SUP:Y_LABEL_4")}
										y_axis_unit={i18("t CO2 equiv.")}
										labels={this.chart_labels}

										download={`${backend_config.server}/chart/supply3?lang=${this.get_lang()}&province=${province}&group=${group}&energy=${this.get_energy()}&currency=${this.get_currency()}`}

										groups={[2]} />
							</div>
						</div>
					</div>
				</div>
	}


	render_error()
	{
		return 	<div className='sankey-menu'>
					<div className='warning section'>Data for scenario is not available</div>
					<Help id='supplydemand'/>
				</div>
	}


	componentDidUpdate()
	{
		this.fetch_expression()
	}


	componentDidMount()
	{
		this.fetch_expression()
	}

	render()
	{
		if (!this.context || !this.context.ctx.i18nstrp)
			return <div />

		const i18 = this.context.ctx.i18nstrp("CHART:SUPDEM")

		this.chart_labels = this.make_chart_labels( i18 )

		if (!this.data_model_right)
			this.data_model_right = this.setup_chart_data_right(i18,this.state.chart_data, this.state.chart_info )

		if (!this.data_model_left)
			this.data_model_left = this.setup_chart_data_left(i18, this.state.chart_data, this.state.chart_info )

		const { match, history } 	= this.props
		const p_key 				= this.get_province().toUpperCase()
		const province_data 		= this.context.provinces ? this.context.provinces[p_key] : null

		if (!province_data)
			return <div/>


		const tgt_year = this.context && this.context.year_refs ? `${this.context.year_refs.target_year}` : ''
		const ref_year = this.context && this.context.year_refs ? `${this.context.year_refs.reference_year}` : ''


		return <MasterFrame parent={this}  province={this.props.match.params.province} history={history} match={match}>
			<div className='sankey-main'>
				<div className='sankey-menu'>
					{
						this.variant_selector()
					}
					{   
						this.scenario_selector( null, true )
					}
					{
						this.unit_selector({ area: false })
					}
					{
						this.data_model_right && this.state && this.state.is_head_data == false &&
						<div className='warning section'>
							<i className='fa fa-exclamation-circle' />
							Diagram is not based on latest data
							</div>
					}
					{
						this.state.error &&
						<div className='section warning'>Data for scenario not available</div>
					}
					<Help id='supplydemand'/>
				</div>
				<div className='sankey-container'>
					<div className={classnames("bottom", "grid-on",
						"sidebar-on")}>
						<div className='fill-with-title'>
							<div className='helpblock'>
								<div className='title'>
									<span>{i18("HEADLINE:LINE1")}</span>
									&nbsp;
									<span>{i18("PROVINCE", this.get_province())}</span>
									&nbsp;
									<span>{i18("HEADLINE:LINE2")}</span>
									&nbsp;
									<span>{tgt_year}</span>
									<span>{i18("HEADLINE:LINE3")}</span>
								</div>
								<div className='text'>{i18("HELPTEXT:LINE1")}
									&nbsp;
									<span>{ref_year}</span>
									&nbsp;
									{i18("HELPTEXT:LINE2")}
									&nbsp;
									<span>{tgt_year}</span>
									&nbsp;
									{i18("HELPTEXT:LINE3")}
								</div>

								<div className='text'>{i18("HELPTEXT:LINE4")}</div>
								<div className='text'>{i18("HELPTEXT:LINE5")}</div>
								<div className='text'>{i18("HELPTEXT:LINE6")}</div>
								<div className='text'>{i18("HELPTEXT:LINE7")}</div>
								<div className='text'>{i18("HELPTEXT:LINE8")}</div>
								<div className='text'>{i18("HELPTEXT:LINE9")}</div>
								<div className='text'>{i18("TXT:INPUTDATA")}</div>
							</div>
							<div className='chartblock'>
								{!this.state.error && this.data_model_right && this.render_chart( i18, this.state.chart_data,
														this.state.chart_info,
														this.data_model_left, this.data_model_right )}
							</div>
						</div>
					</div>
				</div>
			</div>
		</MasterFrame>
	}
}


Object.assign(SupplyDemandChart.prototype, Navigation)

export default SupplyDemandChart;