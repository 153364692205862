import React, { Component, Fragment } 	from "react";
import classnames       				from 'classnames';
import backend_config					from "../config/runconfig.js"
import {visitNodes}         			from '../tree/tree.js'
import axios 							from 'axios'

import MasterFrame 						from '../app/MasterFrame'
import UnitSelector						from '../units/UnitSelector'

import {AppController} 					from '../app/AppController'

import {Help}							from '../help/Help'

import { Navigation } from '../navigation/navigation'


const API_KEY 		= 'ExgWFH9XHHyXIHyKEHIH'


export class Potential extends Component
{
	static contextType = AppController

	constructor( props )
	{
		super( props )
		this.state = {}

		this.energy_displayed 	= null
		this.area_displayed 	= null
		this.currency_displayed = null
		this.inflight 			= false

		//UnitSelector.setDefaultUnits( this.state )
	}

	fetch_expression()
	{
		if (this.inflight)
			return
		const component = this
		const province 	= this.get_province()
		const keys 		= "SD_KMD*,SD_PMAX*"


		const uri 		= `${backend_config.server}/sdata/query?api_key=${API_KEY}`
		const year_key  = this.context ? this.context.year_key : 'NONE'
		const url 		= `${uri}&convert_units=true&key=${keys}&province=${province}&scenario=0&year_key=${year_key}`

		if (this.displayed_url === url)
			return
		console.log(  url )

		this.inflight 	= true
		axios.get(url)
		 .then( (incoming) =>
		{
			this.inflight 			= false
			this.displayed_url		= url

			const {data}			= incoming
			if (data.status === 'error' )
			{
				console.log( "Error Downloading")
				console.log( data )
			}
			else
			{
				component.setState( {expressions: data.result.value, units: data.result.units } )
			}
		})
	}


	componentDidMount()
	{
		this.fetch_expression()
	}


	componentDidUpdate()
	{
		this.fetch_expression()
	}


	handle_year   	= (e)  => 	{this.setState( {year : e.target.value} )}
	render_years()
	{
		const years = []
		for( let year = 2544; year < 2593; year++ )
			years.push( year )

		return <select value={this.state.year} onChange={this.handle_year} >
			   {
			   		years.map( y => <option key={`year-${y}`} value={y}>{y} ({y - 543})</option>)
			   }
			   </select>
	}


	get_province()
	{
		return 	this.props.match.params.province
	}



	line( i18n, id,  v1, v2, v3, unit )
	{
		const unit1 = v1 != null ? i18n(unit) : ''
		const unit2 = v2 != null ? i18n(unit) : ''
		const unit3 = v3 != null ? i18n(unit) : ''

		return (
				<tr key={id}>
					<td className='kpi-title'>{i18n('POTENTIAL','TITLE', id)}</td>
				<td className='kpi-title'><i>{i18n('POTENTIAL', 'SUB', id)}</i></td>
					<td className='kpi-value'>{v2} {v2 =='Unlimited' ? '' : unit2}</td>
				</tr>
			)
	}

	val(entry)
	{
		if (!entry)
			return null
		if (entry.kind === 'scalar')
		{
			let v = entry.value
			if (v === 1234567890)
				return 'Unlimited'
			if (v === null || v === undefined)
				return null
			return v.toFixed(2)
		}
		return null
	}


	render_entry( i18n, data )
	{
		const {key,  type} = data
		const id 		= key.trim().toUpperCase()
		const entry 	= this.state.expressions[id]

		if (type == 'power')
			return this.line(i18n, key,  null, this.val(entry), null, 'MW' )
		else if (type == 'area')
			return this.line(i18n, key,  this.val(entry), null, null,  this.state.area )
		else if (type == 'primary')
			return this.line(i18n, key,  null, null, this.val(entry),  this.state.energy )
		else
			return null
	}

	render_kpi(i18n)
	{


		const rendered = [
				{key: "SD_KmdCompPvPmaxRoof1",  	name: "PV",							sub: "Rooftop"				, type: "power"},
				{key: "SD_KmdCompPvPmaxGround1",  	name: "PV",							sub: "Ground-mount"			, type: "power"},
				{key: "SD_KmdCompWindPmax",  		name: "Wind turbine",				sub: "Onshore"				, type: "power"},
				{key: "SD_KmdCompHydroPmaxLarge",  	name: "Hydroelectric power plant",	sub: "Large"				, type: "power"},
				{key: "SD_KmdCompHydroPmaxSmall",  	name: "Hydroelectric power plant",	sub: "Small and Very Small"	, type: "power"},
//				{key: "SD_KmdCompHydroPmaxVSmall",  name: "Hydroelectric power plant",	sub: "Very Small"			, type: "power"},
				{key: "SD_PmaxElBiog",  			name: "Biogas",						sub: "TPP/CHP"				, type: "power"},
				{key: "SD_PmaxElBiom",  			name: "Biomass",					sub: "TPP/CHP/Boiler"		, type: "power"},
				{key: "SD_PmaxElNap",  				name: "Napier",						sub: "TPP/CHP/Boiler"		, type: "power"},
				{key: "SD_PmaxElEuc",  				name: "Eucalyptus",					sub: "TPP/CHP/Boiler"		, type: "power"},
				{key: "SD_PmaxElMsw",  				name: "MSW",						sub: "TPP/CHP"				, type: "power"},
				{key: "SD_KmdFuelPotCoal",  		name: "Coal",						sub: "TPP/CHP"				, type: "power"},
				{key: "SD_KmdFuelPotOil",  			name: "Fuel oil",					sub: "Boiler"				, type: "power"},
				{key: "SD_KmdFuelPotNgas",  		name: "Natural gas",				sub: "TPP/CHP/Boiler"		, type: "power"},
				{key: "SD_KmdFuelPotLpg",  			name: "LPG",						sub: "Boiler"				, type: "power"},
				]

		return (<table className='kpi-table'>
					<thead>
						<tr key='headrow'>
							<th className='kpi-head-title'>{i18n("POTENTIAL:TECHNOLOGY")}</th>
							<th className='kpi-head-title'></th>
					<th className='kpi-head-value'>{i18n("POTENTIAL:POWERPOTENTIAL")}</th>
						</tr>
					</thead>
					<tbody>
					{
						rendered.map((x) => this.render_entry(i18n, x))
					}
					</tbody>
				</table>
			   )
	}


	handle_scenario = (e) => { console.log( e.target.value ); this.setState({scenario : e.target.value}); this.fetch_expression() }

	render_scenario_select()
	{
		if (!this.context.provinces)
			return null
				const p_key 	= this.get_province().toUpperCase()
		const scenarios = this.context.provinces[p_key].scenarios
									  .filter( x => x.calculated )


		if (!scenarios || scenarios.length == 0)
			return null

		return 	<div className='unit-selector'>
					<div className='title'>Scenario</div>

					<select onChange={this.handle_scenario} className='unit-select'>
					{
						scenarios.filter( x => x.calculated).map( (s,i) =>
							<option key  ={`scenario-select-${i}`} value={`${s.id}`}>Scenario {`${s.id}`}</option>
						)
					}
					</select>
				</div>
	}


	render_error()
	{
		if (!this.context || !this.context.provinces)
			return null
		const scenarios = this.context.provinces[this.get_province().toUpperCase()].scenarios

		if (!scenarios || scenarios.length == 0)
			return 	<div className='warning section'>No Scenarios have been calculated</div>
		else
			return 	<div className='warning section'>Data for scenario {this.state.scenario} not available</div>
	}

	render()
	{
		const {history,match}	= this.props
		const province  		= this.get_province()
		const expressions		= this.state.expressions

		if (!this.context || !this.context.ctx.i18nstr)
			return <div />

		const i18n = this.context.ctx.i18nstr


		return 	<MasterFrame history={history} match={match} province={province}>
					<div className='sankey-main'>
						<div className='sankey-menu'>
							<div className='section'>&nbsp;</div>
							<Help id='potentials'/>
						</div>
						<div className='sankey-container'>
							<div className={classnames("bottom")} style={{backgroundColor:"white"}}>
								<div className='fill-with-title'>

									<div className='helpblock'>
										<div className='title'>
											<span>{i18n("POTENTIAL:HEADLINE")}</span>
											&nbsp;
											<span>{i18n("PROVINCE", this.get_province())}</span>
										</div>
										<div className='text'>{i18n("POTENTIAL:HELPTEXT:LINE1")}</div>
										<div className='text'>{i18n("POTENTIAL:HELPTEXT:LINE2")}</div>
										<div className='text'>{i18n("TXT:INPUTDATA")}</div>
									</div>
									<div className='chartblock'>

										{
											expressions &&
											<div className='kpi-container'>
											{
												this.render_kpi(i18n)
											}
											<div className='comment'>
											{i18n('POTENTIAL:TEXT1')}
											</div>
											<div className='comment'>
												{i18n('POTENTIAL:TEXT2')}
											</div>
											</div>
										}
									</div>
								</div>
							</div>
						</div>
					</div>
				</MasterFrame>
	}
}

Object.assign(Potential.prototype, Navigation)


export default Potential
