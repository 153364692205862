
import React, { Component, Fragment } 	from "react";
import classnames       				from 'classnames';
import SetupFrame						from '../setup/SetupFrame'
import {AppController} 					from '../app/AppController'
import backend_config					from "../config/runconfig.js"
import {SubmitButton,ActionButton}		from "../form/FormComponents"
import axios 							from 'axios'

import {Help} 							from '../help/Help'

const API_KEY = 'ExgWFH9XHHyXIHyKEHIH'


const ShowYearRefs = ({refs, selected, i18}) =>
{
	if (!refs)
		return null

	const keys = Object.keys( refs ).sort()

	const table = []
	keys.forEach( k =>
	{
		const r 			= refs[k]
		const is_selected	= `${r.reference_year}-${r.target_year}-${r.pd_file}`.replace( 'undefined', '') == selected
		const classname 	= is_selected ? 'select' : null

		table.push( <tr key={k}>
						<td className={classname}>{is_selected ? '*' : null}</td>
						<td className={classname}><i>{r.reference_year}</i></td>
						<td className={classname}><i>{r.target_year}</i></td>
						<td className={classname}><b>{r.pd_file || i18('DEFAULT')}</b></td>
						<td>&nbsp;</td>
						<td className='created'>{r.created}</td>
				 	</tr>)
	})

	return 		<table className='year-ref-table'>
					<thead>
						<th></th>
						<th>{i18('TAB:COL1')}</th>
						<th>{i18('TAB:COL2')}</th>
						<th>&nbsp;</th>
						<th>{i18('TAB:COL3')}</th>
						<th>{i18('TAB:COL4')}</th>
					</thead>
					<tbody>
					{table}
					</tbody>
				</table>
}


class TargetYear extends Component
{
	static contextType = AppController;

	constructor( props )
	{
		super(props)

		this.state = {
			reference_year 		: null,
			target_year			: null,
			pd_file 			: null,

			confirmed 			: false,

			field_errors	: {}
		}
	}

	componentWillUpdate()
	{
		const refs = this.context ? this.context.year_refs : null

//		if (refs && refs.data_sets)
//		{
//			const keys = Object.keys( refs.data_sets ).sort()
//			keys.forEach( k =>
//				{
//					const set = refs.data_sets[k]
//					if (set.target_year != refs.target_year || set.reference_year != refs.reference_year)
//						console.log( k, refs.data_sets[k])
//				})
//		}
	}


	handleSetChange  = (event) =>
	{
		const value 	= `${event.target.value}`.trim()
		this.setState( {pd_file: value} )
	}

	handleChange = (field) =>
	{
		return (event) =>
		{
			const value 	= `${event.target.value}`.trim()

			const state 	= {...this.state}
			state[field] 	= value

			let err 		= null

			if (value.length === 0)
				err 		= 'Field must be filled in'
			else
			{
				const numvalue = parseFloat( value )
				if (isNaN( numvalue ))
					err = 'Only numeric values allowed'
				else if (field == 'target_year' 	&& numvalue < 2568)
					err = 'Value must be >= 2568'
				else if (field == 'target_year' 	&& numvalue > 2593)
					err = 'Value must be <= 2593'
				else if (field == 'reference_year' 	&& numvalue < 2544)
					err = 'Value must be >= 2544'
				else if (field == 'reference_year' 	&& numvalue > 2567)
					err = 'Value must be <= 2567'
				else
					err = null
			}


			state.field_errors[field]	= err

    		this.setState( state );
  		}
  	}


	handleConfirmation = (event) =>
	{
		const value = event.target.value
		this.setState( {confirmed: !this.state.confirmed})
	}



	handleSubmit = (event) =>
	{
		event.preventDefault();
		const url 							= 	`${backend_config.server}/system/set-year-refs`
		const {reference_year,target_year}	= 	this.state
		const {pd_file}						= 	this.state
		const data 							= 	{
													reference: reference_year,
											   		target: 	  target_year,
													pd_file
												}


		console.log( data )

	    axios.post(url,data)
	    .then((incoming) =>
	    {
	    	if (incoming.data.status == 'success')
	    	{
				this.setState( {error: null} )
			}
			else
			{
				console.log( incoming.data.error  )
				this.setState( {error: incoming.data.error} )
			}
	    })
	}




	check_if_submit_disabled()
	{
		if (!this.state.confirmed)
			return true
		if (!this.state.reference_year || `${this.state.reference_year}`.trim().length == 0)
			return true
		if (!this.state.target_year || `${this.state.target_year}`.trim().length == 0)
			return true

		return false
	}



	update_state = () =>
	{
		if (!this.context)
			return
		const refs = this.context.year_refs
		this.setState( {reference_year: refs.reference_year, target_year: refs.target_year, pd_file: refs.pd_file})
	}


	render()
	{
		if (!this.context || !this.context)
			return <div />

		const lang = this.context.lang || 'en'

		const {match,history,location} 	= this.props


		if (!this.context || !this.context.ctx.i18nstr)
			return <div />

		const i18 = this.context.ctx.i18nstrp("SETUP", "CONFIG")

		if (!this.context || !this.context.year_refs)
		{
			return 	<SetupFrame history={history} match={match}>
						<div className='sankey-main'>
							<div className='sankey-menu'>&nbsp;</div>
							<div className='sankey-container'/>
						</div>
					</SetupFrame>
		}
		else if ( (this.context.year_refs.reference_year && !this.state.reference_year )||
				  (this.context.year_refs.target_year    && !this.state.target_year    ))
		{
			setTimeout( this.update_state, 100 )
			return 	<SetupFrame history={history} match={match}>
						<div className='sankey-main'>
							<div className='sankey-menu'>&nbsp;</div>
							<div className='sankey-container'/>
						</div>
					</SetupFrame>
		}

		const year_refs = this.context && this.context.year_refs ? this.context.year_refs : null

		return  <SetupFrame lang={lang} history={history} match={match}>
					<div className="sankey-main">
						<div className="sankey-menu">
							<div className='section'>&nbsp;</div>
							<Help id='targetyear'/>
						</div>
						<div className="sankey-container">
							<div style={ {backgroundColor: 'white'} } className={classnames("bottom")}>
						      <form className='inputform' onSubmit={this.handleSubmit}>
								<div className='head'>
									<div className='title'>{i18('TITLE')}</div>
								</div>


								{lang == 'en' && 
									<div>
										<div className='help-text'>
											<p><b>Please note the following points in order to ensure the correct functionalities in PEMT and IPEPP.</b></p>
											<p>The reference year and the target year form the base for all calculations and analyses conducted in and by PEMT.</p>
											<p>The data of the reference year is the base for all provincial calculations conducted in PEMT and also the depiction of this data in IPEPP. In case the data of the reference year is not sufficiently provided, several functionalities in PEMT will fail. This will result in major errors in PEMT as well as IPEPP.</p>
											<p>The target year shapes the creation of the future provincial energy scenarios. This also requires the provision of data for the future through the uploaded input data (e.g. the projected GPP value for the reference year, the projected population for the reference year). </p>
											<p>Please also note that all provincial energy scenarios need to be recalculated once you have changed the target and/or the reference year.</p>
											<p>Please be aware of these points before changing the reference year and/or the target year and make sure that the necessary data for the reference year and target year is available (you can check that by going to Provincial Energy Data -> Input Data).</p>
										</div>
										<div className='help-text'>
											<p>The Technology Parameters describe the characteristics of all technologies implemented and modelled in <b>PEMT</b>.</p>

											<p>These parameters comprise of data about costs, GHG emissions etc. of all the different technologies for all the years
												considered. The value inserted for the Technology Parameters strongly influence the results of the Provincial Energy Scenarios of the Future.
												Consequently, the adjustment of these parameters should <b>only be conducted by experts</b>.</p>

											<p><b>Wrongly inserted parameters will force the calculation of the Provincial Energy Scenarios to crash!</b></p>
											<p>You can choose whether you would like to use the <i>predefined</i> Technology Parameters provided by the developers of <b>PEMT</b>  
											&nbsp;or whether you would like to upload your own parameters <b>at your own risk</b>. Please use the provided Excel template to be filled 
											out from the download area below.
											</p>
											<p><b>Please be careful about the values for the Technology Parameters.</b>
											</p>
											<p>The developers of PEMT are not responsible for any user upload nor the associated occurrence of bugs.
											</p>
											<p><b>Please note that all calculations need to be refreshed when providing new Technology Parameters for PEMT.</b>
											</p>
										</div>
									</div>
								}

							{lang == 'th' &&
								<div>
									<div className='help-text'>
									<p><b>กรุณาอ่านรายละเอียดด้านล่างเพื่อให้สามารถเข้าใจฟังก์ชั่นการทำงานของระบบ PEMT และ IPEPP ได้อย่างถูกต้อง</b></p>
									<p>ปีฐานและปีเป้าหมายเป็นตัวแปรสำคัญสำหรับการประมวลผลของระบบ PEMT</p>
									<p>ข้อมูลด้านพลังงานในปีฐานของแต่ละจังหวัดที่เชื่อมต่อมากจากฐานข้อมูลของกระทรวงพลังงาน จะถูกนำมาใช้เป็นข้อมูลอ้างอิงสำหรับการคำนวณภาพฉายอนาคตด้านพลังงานของ PEMT จากนั้นผลลัพธ์ที่ได้ยังถูกส่งไปแสดงผลบน IPEPP  ในกรณีที่มีข้อมูลไม่เพียงพอต่อการสร้างภาพฉายอนาคตฟังก์ชั่นการประมวลผลส่วนใหญ่ของระบบ PEMT ก็จะไม่สามารถแสงผลการคำนวณได้อย่างครบถ้วนทั้งในระบบ PEMT และ IPEPP.</p>
									<p>ปีเป้าหมายจะเป็นตัวแปรกำหนดระยะเวลาการจัดทำภาพฉายอนาคตด้านพลังงานของจังหวัด นอกจากนี้ระบบยังต้องการข้อมูลผลการคาดการณ์อนาคตในมุมมองอื่น ๆ ที่ส่งผลต่อรูปแบบการใช้พลังงานในอนาคตในปีเป้าหมายด้วย เช่น ผลการคาดการณ์ค่าผลิตภัณฑ์มวลรวมจังหวัด และผลการคาดการณ์จำนวนประชากร ในปีเป้าหมาย</p>
									<p>โปรดทราบว่าภาพฉายอนาคตด้านพลังงานของจังหวัดจะถูกคำนวณใหม่ทั้งหมดทุกครั้ง เมื่อมีการเปลี่ยนปีเป้าหมายและ/หรือปีฐานในการคำนวณ</p>
									<p>กรุณาทำความเข้าใจรายละเอียดเหล่านี้ก่อนทำการเปลี่ยนปีฐานและ/หรือปีเป้าหมาย เพื่อให้มั่นใจได้ว่าระบบมีข้อมูลที่เพียงพอต่อการคำนวณ (ผู้ใช้งานสามารถตรวจสอบว่าข้อมูลเหล่านี้มีอยู่ในระบบแล้วหรือไม่โดยดูจากส่วนข้อมูลพลังงานจังหวัด -> ข้อมูลขาเข้า)</p>
									</div>
									<div className='help-text'>
									<p>ตัวแปรเทคโนโลยีเป็นตัวแปรที่อธิบายข้อมูลเฉพาะของเทคโนโลยีพลังงานทั้งหมดที่มีในระบบ <b>PEMT</b></p>

									<p>ตัวแปรเหล่านี้ประกอบด้วยข้อมูลประสิทธิภาพของโรงไฟฟ้า มูลค่าการดำเนินงาน ปริมาณการปล่อยก๊าซเรือนกระจก และตัวแปรอื่น ๆ ที่เป็นข้อมูลเฉพาะของเทคโนโลยีแต่ละประเภทที่พิจารณาในช่วงปีนั้น ค่าเฉพาะของเทคโนโลยีพลังงานที่กรอกเข้าไปในระบบเป็นตัวแปรสำคัญที่ส่งผลต่อผลการคำนวณภาพฉายอนาคตพลังงานจังหวัด 
										ดังนั้นการกำหนดค่าดังกล่าวควรเป็นค่าที่ผ่านการพิจารณาจาก <b>ผู้เชี่ยวชาญเท่านั้น </b>.</p>

									<p><b>หากกรอกตัวแปรไม่ตรงตามเงื่อนไขความต้องการของระบบจะส่งผลให้ผลการคำนวณภาพฉายอนาคตพลังงานของจังหวัดผิดพลาดได้!</b></p>
									<p>ผู้ใช้งานสามารถเลือกได้ว่าจะใช้ตัวแปรด้านเทคโนโลยีที่ PEMT จัดหามาให้ หรืออัปโหลดค่าเฉพาะของประเทศเข้าไปใน PEMT สำหรับผู้ใช้งานที่ต้องการอัปโหลดค่าเฉพาะของประเทศเข้าไปในระบบ ให้ดาวน์โหลดแบบฟอร์ม Excel ด้านล่างเพื่อนำไปกรอกข้อมูลดังกล่าวแล้วอัปโหลดแบบฟอร์มที่กรอกข้อมูลแล้วกลับเข้าไปในระบบอีกครั้ง
										</p>
									<p><b>กรุณาตรวจสอบตัวแปรด้านเทคโนโลยีให้ถูกต้องก่อนอัปโหลดข้อมูลกลับเข้าไปใน PEMT</b>
										</p>
									<p>ทีมผู้พัฒนา PEMT จะไม่รับผิดชอบความผิดพลาดที่เกิดจากการอัปโหลดข้อมูลโดยผู้ใช้งาน รวมถึงข้อบกพร่องที่เกิดกับผลการคำนวณภาพฉายอนาคตที่เกิดขึ้นจากการอัปโหลดข้อมูลโดยผู้ใช้งาน
										</p>
									<p><b>โปรดทราบว่าภาพฉายอนาคตด้านพลังงานของจังหวัดจะถูกคำนวณใหม่ทั้งหมดทุกครั้ง เมื่อมีการอัปเดทตัวแปรด้านเทคโนโลยีที่ PEMT</b>
										</p>
									</div>
								</div>
							}


								<div className='head'>
									<div className='subtitle'>{i18('TITEL2')}</div>
								</div>

								{year_refs && <ShowYearRefs i18={i18} refs={year_refs.data_sets} selected={year_refs.key} />}

								<div className='head'>
									<div className='subtitle'>{i18('TITEL3')}</div>
								</div>
								<div className='formdata'>
									<div className='line'>
										<div key={`er-title`}  	className='title'>
										</div>

										<div key={`er-control`} 	className='control' >
										{this.state.error}
										</div>
										<div></div>
									</div>
									<div className='line'>
										<div key={`tgt-title`}  	className='title'>
											{i18('RY')}
										</div>

										<div key={`tgt-control`} 	className='control' >
	    									<input name='reference' type='text'
	    															defaultValue={this.state.reference_year}
	    															onChange={this.handleChange('reference_year')} />
										</div>
										<div key={'tgt-error'}		className='error'>
										{this.state.field_errors.reference_year}
										</div>
									</div>
									<div className='line'>
										<div key={`ref-title`}  	className='title'>
											{i18('TY')}
										</div>

										<div key={`ref-control`} 	className='control' >
											<input name='target' 	defaultValue={this.state.target_year}
																	onChange={this.handleChange('target_year')}
																	type='text'/>
										</div>
										<div key={'re-error'}		className='error'>
										{this.state.field_errors.target_year}
										</div>
									</div>
								</div>


								<div className='head'>
									<div className='subtitle'>{i18('PARAMSET')}</div>
								</div>

								<div className='help-text'>
								{lang == 'en' && <span>Please select the uploaded parameter set to use. You may upload new parameter sets
									&nbsp;<a href='/upload-params'>here</a>
								</span>
								}
								{lang == 'th' && <span>กรุณาเลือกชุดข้อมูลที่ต้องการอัปโหลด แล้วทำการอัปโหลดชุดข้อมูลได้
									&nbsp;<a href='/upload-params'>ที่นี่</a>
								</span>
								}
								</div>

								<div className='formdata'>
									<div className='line'>
										<div key={`ref-title`}  	className='title'>
											{i18('PARAMSET')}
										</div>

										<div key={`ref-control`} 	className='control' >
											<select onChange={this.handleSetChange} defaultValue={this.state.pd_file}>
												<option value=''>{i18('DEFAULT')}</option>
												{year_refs.pd_files.map( (y,i) => <option key={`${i}`} value={y.id}>{y.id}</option>)}
											</select >
										</div>
										<div key={'re-error'}		className='error'>
										</div>
									</div>
								</div>

								<div className='confirmation'>
									<br/>
									<br/>
									<input id='confirm' type='checkbox' onChange={this.handleConfirmation} value={this.state.confirmed}/>&nbsp;
										<label htmlFor='confirm' className='warning' style={{color: 'indianred', fontWeight: 'bold'}}>
												{i18('CHECKBOX')}
										</label>
								</div>

						    	<div className='form-controls'>
								<input className='action' type='submit' value={i18("SETUP:CONFIG:SUBMIT")}
											disabled={this.check_if_submit_disabled()}/>
								</div>
							</form>
						</div>
					</div>
				</div>
			</SetupFrame>
	}
}

export default TargetYear


