
import React, { Component, Fragment } 	from "react";
import classnames       				from 'classnames';

import {AppController} 					from '../app/AppController'
import backend_config					from "../config/runconfig.js"
import {SubmitButton,ActionButton}		from "../form/FormComponents"
import axios 							from 'axios'
import queryString 						from 'query-string'
import {trimmed_or_blank, blank} 		from  '../utils/utils'

const API_KEY = 'ExgWFH9XHHyXIHyKEHIH'

class UserEdit extends Component
{
	static contextType = AppController;

	constructor( props )
	{
		super(props)

		const {user} = props || {}
		if (!user.role)
			user.role = {kind: 'national'}

		this.state = {
			field_errors	: {},
			save_error 		: null,
			user 			: this.props.user
		}

		this.province_names = ['Amnat Charoen', 'Angthong', 'Bangkok', 'Bungkan', 'Burirum', 'Chachoengsao', 'Chainat', 'Chaiyaphum', 'Chanthaburi', 'Chiamg Rai', 'Chiang Mai',
								'Chonburi','Chumphon','Kalasin','Kamphaeng Phet','Kanchanaburi','Khon Kaen','Krabi','Lampang','Lamphun','Loei','Lopburi', 'Mae Hong Son','Maha Sarakam','Mukdahan','Nakhon Pathom','Nakhon Phanom','Nakhon Ratchasima','Nakhon Sawan','Nakhon Si Thammarat','Nakornnayok','Nan','Narathiwat','Nong Bua Lam Phu','Nong Khai','Nonthaburi','Pathum Thani','Pattani','Phangnga','Phare','Phatthalung','Phayo','Phetchabun','Phetchaburi','Phichit', 'Phitsanulok', 'Phra Nakorn Si Ayuttaya', 'Phuket', 'Prachinburi', 'Prachuap Khiri Khan', 'Ranong', 'Ratchaburi', 'Rayong', 'Roi-Et', 'Sa Kaeo',
								'Sakon Nakhon', 'Samut Prakan', 'Samut Sakhon', 'Samut Songkhram', 'Saraburi', 'Satun', 'Sing Buri', 'Sisaket', 'Songkhla', 'Sukhothai', 'Suphanburi', 'Surat Thani', 'Surin', 'Tak', 'Trad', 'Trang', 'Ubon Ratchathani', 'Udon Thani', 'Udtradit', 'Uthai Thani', 'Yala', 'Yasothon']
	}

	update_field_errors( name, params, value )
	{
		const {title, default_value}	= params
		const trimvalue 				= trimmed_or_blank( value )

		const err = []

		if (!params.allow_blank && trimvalue == null)
			err.push( 'Must be filled in')
		if (params.allow_default == false && trimvalue == params.default_value)
			err.push( 'Must be filled in')

		let error_list = null
		if (err.length > 0)
			error_list = err.filter((value, index, self) => { return self.indexOf(value) === index })

		return error_list
	}


	check_field = (name, params) => (event) =>
	{
		const value 	= event.target.value
		const errors    = this.state.field_errors || {}
		errors[name] 	= this.update_field_errors( name, params, value )
		const new_state = {...this.state, field_errors: errors}

		const {target_field}	= params
		if (target_field)
		{
			new_state[target_field] = {...this.state[target_field]}
			new_state[target_field][name] = value
		}
		else
			new_state[name] 	= value

		new_state[`checked-${name}`] = true
		new_state.counter = new_state.counter + 1

		if (params.field_check)
			params.field_check( name, params, new_state )
		this.setState( new_state )
	}



	form_field_text( name, params )
	{
		const {title, subtitle, default_value, value}	= params
		const err 										= this.state.field_errors[name] || []

//		if (this.state.field_errors[name] === undefined)
//		{
//			this.update_field_errors( name, params, params.default_value )
//		}

		const checked_before = this.state[`checked-${name}`]

		return <div className='line'>
					<div key={`${name}-title`}  	className='title'>
						{title}
						{subtitle && <div key={`${name}-subtitle`}  	className='subtitle'>{subtitle}</div>}
					</div>

					<div key={`${name}-control`} 	className='control wide' >
	    				<input
								onChange	 = {this.check_field( name, params )}
	          					name		 = {name}
	          					type		 = {params.input_type || 'text'}
	          					placeholder	 = {params.placeholder}
	          					defaultValue = {value || default_value || ''} />
	          					{params.unit &&
	          						<span key={`${name}-unit`}>&nbsp;{params.unit}
	          						</span>}
	          		</div>
	          		<div key={`${name}-errors`} className='errors'>
	          			{
	          				checked_before &&
	          				err.map((e,i) => <div key={`err-${i}-${name}`} className='error'>{e}</div>)
	          			}
					</div>
				</div>
	}


	handleChange = (field) =>
	{
		return (event) =>
		{
			const value 	= `${event.target.value}`.trim()

			const state 	= {...this.state}

			let err 		= null

			if (value.length === 0)
				err 		= '⚠️'

    		this.setState( state );
  		}
  	}


  	handleSubmit = (event)  =>
  	{
	    event.preventDefault();
	    const form = event.target;
	    const data = new FormData(form);

		var object = {};
		data.forEach((value, key) => object[key] = value)
		var json = JSON.stringify(object);

		this.setState( {submitted: true, counter: this.state.counter + 1} )


		const {scenario}	= this.props
		const scenario_id	= scenario == 'custom' ? '_new' : (scenario ? `${scenario}` : '')
		const url 			= `${backend_config.server}/user/save`

	    axios.post(url,this.state.user)
	    .then((incoming) =>
	    {
	    	console.log( incoming )
			if (incoming.data && incoming.data.status == 'error')
			{
				this.setState( {save_error: incoming.data.error} )
			}
			else
			{
				if (this.props.callback)
					this.props.callback( this.state.user )
				this.setState( {save_error: null} )
			}

	    });
	}



	check_if_submit_disabled()
	{
		const {user} = this.state
		if (blank( user.username ) || blank( user.name) || blank( user.email ))
			return true

		return false
	}

	handle_role = (event) =>
	{
		const {user} = this.state
		let   {role} = user

		if (!role)
			role 	 = {kind: 'provincial'}

		const new_user = {...this.state.user}
		new_user.role  = {kind: event.target.value, province: role.province}

		this.setState( {user: new_user})
	}


	handle_province = (event) =>
	{
		const {user} = this.state
		let   {role} = user

		if (!role)
			role 	 = {kind: 'provincial'}

		const new_user = {...this.state.user}
		new_user.role  = {kind: role.kind, province: event.target.value}

		this.setState( {user: new_user})
	}

	check_password = (i18) => (name,params, new_state) =>
	{
		const {user}	= new_state
		const err 		= new_state.field_errors

		if (!user.doc_id && blank(this.state.password_text))
			err.password_text = [i18("SETUP:USER:FORM:PASSWORDDEFAULT")]

		if (user.password_text1 !== user.password_text)
			err.password_text = [i18("SETUP:USER:FORM:PASSWORDIDENTICAL")]
		else
			err.password_text = null
	}

	render()
	{
		if (!this.context || !this.context.ctx.i18nstr)
			return <div />

		const i18 = this.context.ctx.i18nstrp("SETUP:USER")

		let 	{user} 						= this.state

		console.log( user )

		let 	{role}						= user
		if (!role)
			role 	= {kind: 'admin'}

		const 	{province} 					= role



		return  <form className='inputform' onSubmit={this.handleSubmit}>
					<div className='head'>
						<div className='title'>{i18("SETUP:USER:FORM:HEADLINE")}</div>
						{this.state.save_error &&
							<div className='error'><br/>{this.state.save_error}</div>
						}
					</div>
					<div className='formdata'>
		        		{this.form_field_text( 'username',
		        		{
							title 			: i18("SETUP:USER:FORM:LOGIN"),
							value 			: user.username,
							target_field 	: 'user',

							placeholder 	: 'login',
							allow_default 	: false,
							allow_blank 	: false,
						})}
		        		{this.form_field_text( 'name',
		        		{
							title 			: i18("SETUP:USER:FORM:NAME"),
							value 			: user.name,
							target_field 	: 'user',

							placeholder 	: 'M. Smith',
							allow_default 	: false,
							allow_blank 	: false,
						})}

		        		{this.form_field_text( 'email',
		        		{
							title 			: i18("SETUP:USER:FORM:EMAIL"),
							value 			: user.email,
							target_field 	: 'user',

							placeholder 	: 'a@b.th',
							allow_default 	: false,
							allow_blank 	: true,
						})}

		        		<div className='line'>
							<div key={`role-title`}  	className='title'>
								{i18("SETUP:USER:FORM:ROLE")}
							</div>

							<div key={`role-control`} 	className='control wide' >
								<select onChange={this.handle_role} value={role.kind}>
									<option key='opt-admin' 	 value='admin'>
										{i18("SETUP:USER:ROLE:ADMIN:L3")}
									</option>
									<option key='opt-national' 	 value='national'>
										{i18("SETUP:USER:ROLE:ADMIN:L2")}
									</option>
									<option key='opt-provincial' value='provincial'>
										{i18("SETUP:USER:ROLE:ADMIN:L1")}
									</option>
								</select>
			          		</div>
			          		<div key={`role-errors`} className='errors'>
							</div>
						</div>

		        		<div className='line'>
							<div key={`role-title`}  	className='title'>
							</div>

							<div key={`role-control`} 	className='control wide' >
							{ 	role.kind == 'provincial' &&
								<select onChange={this.handle_province} value={province}>
								{
									this.province_names.map(p => {
										const key=p.toUpperCase().replace(" ", "-" )
										return <option key={`opt-${key}`} 	 value={key}>
											{p}
										</option>})
								}
								</select>
							}
			          		</div>
			          		<div key={`role-errors`} className='errors'>
							</div>
						</div>

						{
							this.form_field_text( 'password_text',
		        			{
								title: 				i18("SETUP:USER:FORM:PASSWORD"),
								value 			: '',
								target_field 	: 'user',
								input_type 		: 'password',
								allow_default 	: false,
								allow_blank 	: false,
								field_check 	: this.check_password(i18)
							})
						}
						{
							this.form_field_text( 'password_text1',
		        			{
								title 			: '',
								value 			: '',
								target_field 	: 'user',
								input_type 		: 'password',
								allow_default 	: false,
								allow_blank 	: false,
								field_check 	: this.check_password(i18)
							})
						}

					</div>
			    	<div className='form-controls'>
						<input 	className='action' 
								type='submit'
				 				value={i18("SETUP:USER:FORM:SUBMIT")}
								disabled={this.check_if_submit_disabled()}/>
						 &nbsp;
					</div>
				</form>
	}
}

export default UserEdit


