import React, { Component, Fragment }                                  from 'react';
import {DataNode}                                                      from './DataNode';
import classnames                                                      from 'classnames';


export class TreeGrid extends Component
{

  // Local state to prevent all the props stuff
  editcontroller  = null;
  select   = (event) => this.props.editcontroller.selectNode( this.props.node, this.props.column )

  constructor(props)
  {
    super(props)
    this.state              = {}
  }

  renderBlankDataRow()
  {
    const {column, editcontroller,
          node, view_model}             = this.props
    const {focusNode}                   = editcontroller
    const focusColumn                   = editcontroller.column
    const rowFocused                    = focusNode === node.key
    const cells                         = []

    var col                             = 1
    const axis_values                   = view_model.axisValuesX()

    for( let xi in axis_values )
    {
      const x             = axis_values[xi]
      const key           = `B${node.key}-${x}`
      const colFocused    = col === focusColumn
      const classes       = classnames( "cell", "nodata", {focused: rowFocused && colFocused} )

      cells.push( <div key={key} className={classes} onClick={this.select}>
                    <div className='cell-contents'>
                      <div className='value'>&nbsp;</div>
                    </div>
                  </div>)
      col++
    }
    cells.push(<div key={`B${node.key}-final`} className='cell final'><div className='value'>&nbsp;</div></div>)
    return <Fragment>{cells}</Fragment>
  }

  sshouldComponentUpdate(nextProps, nextState)
  {
    const {column, editcontroller,node, view_model}             = this.props
    const {focusNode}                   = editcontroller

    const rowFocused                    = focusNode === node.key

    if (rowFocused)
      return true
    if (nextProps.open === this.props.open && !this.props.node.nodes)
      return false
    return true
  }

  renderDataCells()
  {
    const {editcontroller, node, view_model}    = this.props

    const cells           = []
    const z_axis          = view_model.getSelectedValueOnAxis("z")
    const z_value         = z_axis == null ? null : z_axis.key
    const data_row        = view_model.getDataHashX( node.key, z_value )

    const axis_values     = view_model.axisValuesX()

    var   col             = 1
    let   oldValue        = null
    for( let xi in axis_values )
    {
      const index         = axis_values[xi]
      const key           = `D${node.key}-${index}`
      let   value     = data_row ? data_row[`${index}`] : null
      if (value !== null && value !== undefined  && typeof value == 'number')
        value = value.toFixed(2)

      let   markValue     = false
      if (value != null && this.props.mark_jumps)
      {
        if (oldValue != null && oldValue != 0 && value/oldValue > 10)
          markValue       = true
        if (oldValue != null && oldValue == 0 && value != 0)
          markValue       = true

        oldValue          = value
      }


      cells.push( <DataNode   key             = {key}
                              node            = {node}
                              column          = {col}
                              mark            = {markValue}
                              editcontroller  = {this.props.editcontroller}
                              index           = {index}
                              value           = {value} />)
      col++
    }
    cells.push(<div key={`D${node.key}-final`} className='cell final'><div className='value'>&nbsp;</div></div>)
    return <Fragment>{cells}</Fragment>
  }


  render()
  {
    const {view_model, node, open}    = this.props
    const {children}                  = this.props
    const key                         = `ROW-${node.key}`

    return  <Fragment>
              <div className='row' key={key}>
                  {
                    (node.nodes)?
                      this.renderBlankDataRow()
                    :
                      this.renderDataCells()
                  }
              </div>
              { open &&
                <Fragment>
                  {children}
                </Fragment>
              }
            </Fragment>
  }
}
