


export class PEMTViewModel
{
	constructor( store )
	{
		this.data_store		= store
		this.schema 		= store.schema
		this.available_axes = store.axes
		this.x_axis 		= this.data_store.axis_list[0].name
		this.y_axis 		= this.data_store.axis_list[1].name
		this.z_axis 		= this.data_store.axis_list[2].name

		this.selected_value = {}

		// This stuff needs to go into another class cause it has to do with layout
		this.grid_open 		= true
		this.sidebar_open 	= true
		this.filter_open 	= false
		this.chart_open		= false
		this.chart_node_key = null

		this.openClose 		= {}
		this.defaultOpen	= (node) => node.level < 10

		this.layout_callbacks = []

		this.extra_columns 	 	= ['description', 'comment', 'source']
	}

	addLayoutCallback( fn )
	{
		if (fn)
			this.layout_callbacks.push( fn )
	}

	triggerLayoutCallbacks()
	{
		for( const f of this.layout_callbacks )
		{
			f()
			//setTimeout( f, 0 )
		}
	}

	setSelectedValue( axis_name, value )
	{
//		console.log( "Set selected " + axis_name + " to "  )
//		console.log( value )
		this.selected_value[axis_name] = value
	}

	// This will set a value where the axis_type == 'x' or 'y' or 'z'
	setSelectedValueOnAxis( axis_type, value )
	{
		this.setSelectedValue( this.getAxisName(axis_type), value )
	}

	getSelectedValue( axis_name )
	{
		return this.selected_value[axis_name]
	}

	getSelectedValueOnAxis( axis_type )
	{
		return this.getSelectedValue( this.getAxisName(axis_type) )
	}

	setSelectedNodeCallback( fn )
	{
		this.selectedNodeCallback = fn
	}

	setSelectedNode( node, column, column_value )
	{
		if (this.selectedNodeCallback)
			this.selectedNodeCallback( node, column, column_value )
	}

	openChart()
	{
		this.chart_open = true
		this.grid_open  = false
	}

	closeChart()
	{
		this.chart_open = false
		this.grid_open  = true
		this.triggerLayoutCallbacks()
	}


	getAxisName( name )
	{
		if (name == 'x')
			return this.x_axis
		if (name == 'y')
			return this.y_axis
		if (name == 'z')
			return this.z_axis
		return this.x_axis
	}

	getAxisDisplayName( name )	{ return this.getAxis(name).display }

	getAxis( name )				{ return this.data_store.axes[this.getAxisName( name )] }
	getAxisX() 					{ return this.data_store.axes[this.x_axis] }
	getAxisY() 					{ return this.data_store.axes[this.y_axis] }
	getAxisZ() 					{ return this.data_store.axes[this.z_axis] }

	axisValues( name )			{ return this.data_store.getAxisValueList( name ) }
	axisValuesX()				{ return this.data_store.getAxisValueList( this.x_axis ) }
	axisValuesY()				{ return this.data_store.getAxisValueList( this.y_axis ) }
	axisValuesZ()				{ return this.data_store.getAxisValueList( this.z_axis ) }

	axisTree( name ) 			{ return this.data_store.getAxisValueTree( name ) }
	axisTreeX() 				{ return this.data_store.getAxisValueTree( this.x_axis ) }
	axisTreeY() 				{ return this.data_store.getAxisValueTree( this.y_axis ) }
	axisTreeZ() 				{ return this.data_store.getAxisValueTree( this.z_axis ) }

	axisKeyValueList( name ) 	{ return this.data_store.getAxisKeyValueList( name )}
	axisKeyValueListX() 		{ return this.data_store.getAxisKeyValueList( this.x_axis ) }
	axisKeyValueListY() 		{ return this.data_store.getAxisKeyValueList( this.y_axis ) }
	axisKeyValueListZ() 		{ return this.data_store.getAxisKeyValueList( this.z_axis ) }

	axisRenderer( name )		{ return this.data_store.axes[this.getAxisName( name )].value_renderer }
	axisRendererX() 			{ return this.data_store.axes[this.x_axis].value_renderer }
	axisRendererY() 			{ return this.data_store.axes[this.y_axis].value_renderer }
	axisRendererZ() 			{ return this.data_store.axes[this.z_axis].value_renderer }


	setAxisFilter( axis_name, values ) { this.data_store.setAxisFilter( axis_name, values ) }



	remaining_non_param( value )
	{
		return value == 'year' ? 'province' : 'year'
	}

	setXAxis( value )
	{
		if (value == 'parameter')
			return
		if (this.z_axis === value)
			this.z_axis = this.x_axis
		if (this.y_axis === value)
			this.y_axis = this.x_axis
		this.x_axis = value
		this.triggerLayoutCallbacks()
	}


	setYAxis( value )
	{
		if (this.x_axis === value)
		{
			if (this.y_axis == 'parameter')
			{
				this.z_axis == 'parameter'
				this.x_axis == this.remaining_non_param( value )
			}
			else
				this.x_axis = this.y_axis
		}
		if (this.z_axis === value)
			this.z_axis = this.y_axis
		this.y_axis = value
		this.triggerLayoutCallbacks()
	}


	setZAxis( value )
	{
		if (this.x_axis === value)
			this.x_axis = this.z_axis
		if (this.y_axis === value)
			this.y_axis = this.z_axis
		this.z_axis = value
		this.triggerLayoutCallbacks()
	}



	setAxis( axis_name, value )
	{
		if (axis_name == 'x')
			this.setXAxis( value )
		else if (axis_name == 'y')
			this.setYAxis( value )
		else if (axis_name == 'z')
			this.setZAxis( value )
	}


	setAxisHash( hash, axis_name, value )
	{
		hash[axis_name] = value
	}


	makeAxisHash( y_value, z_value )
	{
		const axis_hash	= {}
		let z_track 	= z_value
		if (z_track === undefined || z_track === null)
		{
			if (this.selected_value[this.z_axis])
			{
				z_track = this.selected_value[this.z_axis].name
			}
		}
		if (z_track === undefined || z_track === null)
		{
			const z_values = this.axisValuesZ()
		 	z_track = z_values[0]
		}
		this.setAxisHash( axis_hash, this.z_axis, z_track )
		this.setAxisHash( axis_hash, this.y_axis, y_value )
		return axis_hash
	}


	rowHasEmptyValue( y_value, z_value )
	{
		return this.data_store.rowHasEmptyValue( this.makeAxisHash( y_value, z_value ) )
	}

	// This is truly awful, I know
	getDataHashX( y_value, z_value )
	{
		const  values = this.data_store.dataRowHash( this.makeAxisHash( y_value, z_value ) )
		return values
	}

	getDataListX( y_value, z_value )
	{
		return this.data_store.dataRowList( this.makeAxisHash( y_value, z_value ) )
	}

	setDataPoint( y_value, x_value, value )
	{
		const province = this.getSelectedValueOnAxis('z').name
		console.log( province )
		this.data_store.set( {parameter: y_value, year: `${x_value}`, province: province}, value )
	}

	isOpen( node )
	{
		let open_close = this.openClose[this.y_axis]
		if (!open_close)
			return this.defaultOpen( node )
		const node_open = open_close[node.key]
		return node_open === undefined ? this.defaultOpen(node) : node_open == true ? true : false
	}

	setOpen( node, value )
	{
		let open_close = this.openClose[this.y_axis]
		if (!open_close)
		{
			open_close = {}
			this.openClose[this.y_axis] = open_close
		}
		open_close[node.key] = value
		this.triggerLayoutCallbacks()
		return value
	}

	toggleOpen( node )
	{
		return this.setOpen( node, !this.isOpen( node ) )
	}
}



