import React, { Component, Fragment }                                  from 'react';
import {findNode, addNewNode, normalizeSchema, mapTree,
        visitNodesWithParent, cloneNode}                               from './tree/tree.js';
import classnames                                                      from 'classnames';

import {fmt}                                                           from './utils/utils';



export class DataNode extends Component
{
  // Local state to prevent all the props stuff
  editcontroller = null;


  state = {
    editing   : false
  }

  select     = (event) => this.editcontroller.selectNode(   this.props.node, this.props.column )
  editStart  = (event) => this.editcontroller.startEditing( this.props.node, this.props.column )

  onFocus           = ()        => this.saveState();
  onChange          = (event)   => {
    this.setState( {value: event.target.value} )
  }

  // We cannot capture tabs with keyUp. It will trigger a blur event
  onBlur    = (event)   => {
                              console.log( "I is blurred")
                              const {node,editcontroller}   = this.props;
                              event.stopPropagation()
                              event.preventDefault()
                              editcontroller.stopEditing( node.key, this.mkTransferNode(), "BLUR" )
                           }

  tabDown = false
  onKeyDown = (event) =>
  {
    const key = event.keyCode || event.charCode || 0;
    const TAB_KEY           = 9;

    if (key == TAB_KEY)
    {
      this.tabDown    = true;
      event.stopPropagation()
      event.preventDefault()
    }
  }



  onKeyUp   = (event)   => {
                                  const ENTER_KEY         = 13;
                                  const TAB_KEY           = 9;
                                  const ESCAPE_KEY        = 27;

                                  const {node,editcontroller}   = this.props;
                                  const {saveState}             = this.state;
                                  const key = event.keyCode || event.charCode || 0;
                                  const  tabKey  = key == TAB_KEY || this.tabDown;

                                  if (key == ESCAPE_KEY)
                                  {
                                    console.log( "ESC")
                                    event.stopPropagation()
                                    event.preventDefault()
                                    if (saveState !== null)
                                        this.setState( {value: saveState.value} )
                                    editcontroller.stopEditing( node.key, this.mkTransferNode(), "ESC" )
                                  }
                                  else if ((key == ENTER_KEY)  ||
                                            key == TAB_KEY     || this.tabDown)
                                  {
                                    console.log( "ENTER/TAB " + tabKey)
                                    this.tabDown = false
                                    event.stopPropagation()
                                    event.preventDefault()
                                    const reason = tabKey ? "TAB" : "ENTER"
                                    editcontroller.stopEditing( node.key, this.mkTransferNode(), reason )
                                  }
                                }




  constructor()
  {
    super()
    this.refCell    = React.createRef();
    this.refInput   = React.createRef();
  }



  saveState()
  {
    this.setState( {saveState : {value: this.state.value}})
  }

  mkTransferNode()
  {
    return {value: this.state.value, index: this.props.index}
  }


  componentDidMount()
  {
    const {index,node,value}    = this.props
    this.setState( {value: value} )
  }


  componentDidUpdate()
  {
    this.editcontroller = this.props.editcontroller; // Check if this is necessary

    const {node}        = this.props
    const {focusNode}   = this.editcontroller

    if (focusNode === node.key)
    {
      if (this.editcontroller.column == this.props.column && this.refCell.current)
      {
        const editing = this.props.editcontroller.editing
        if (editing)
          this.refInput.current.focus()
        else
          this.refCell.current.focus()
      }
    }
  }

  find_errors()
  {
    const error_json = this.props.node.errors
    if (!error_json)
      return []
    if (error_json[this.props.index])
      return error_json[this.props.index]
    return []
  }

  render_errors(errors)
  {
      if (errors.length === 0)
        return null

      let error_class = ''
      if (errors.length > 0)
      {
        errors.forEach( (error,index) => {
          if (error.action === 'error')
          {
            error_class = 'error'
            return
          }
          if (error.action === 'warn')
            error_class = 'warn'
        })
      }
      return <i className={`${error_class} errors fa fa-exclamation-triangle`}></i>
  }

  error_text= (err) => err.map( e => e.error ).join(",")


  render()
  {
    let value = this.props.value
    if (value === null || value === undefined || `${value}`.trim().length == 0)
      value = null

    const errors = this.find_errors()

    const {column, editcontroller, node, mark}        = this.props
    const {editing, focusNode}                        = editcontroller
    const focusColumn                                 = editcontroller.column

    const rowFocused   = focusNode === node.key
    const colFocused   = column    === focusColumn
    const nameClasses  = classnames( "cell", "data",
                                      errors.length > 0 ? 'haserrors' : null,
                                      {focused: rowFocused && colFocused} )

    const value_shown  = fmt(value, '.')

    return <div
                  data-tip      = {errors.length > 0 ? this.error_text( errors ) : null}
                  className     = {nameClasses}
                  onDoubleClick = {this.editStart}
                  onClick       = {this.select}
                  ref           = {this.refCell}>
              {
                editing && rowFocused && colFocused ?
                  <input    ref             = {this.refInput}
                            type            = 'text'
                            className       = 'value editing'
                            onKeyUp         = {this.onKeyUp}
                            onFocus         = {this.onFocus}
                            onBlur          = {this.onBlur}
                            onChange        = {this.onChange}
                            defaultValue    = {value} />
                :
                  <div className='cell-contents'>
                    {this.render_errors(errors)}
                    <div className={classnames( "value", mark ? 'mark' : null, value === null ? "blank" : null )}>
                        {value_shown}
                    </div>
                  </div>
              }
            </div>
  }

}


