import {normalizeSchema, visitNodes}    from '../tree/tree.js';


export class Axis
{
	constructor( name, display, kind, hash, value_renderer )
	{
		this.name 				= name
		this.display			= display
		this.kind 				= kind
		this.value_renderer		= value_renderer || ((x)=>x)		// If no VR given, return samey same


		if (hash.list !== undefined)
		{
			this.value_kind		= 'list'
			this.value_list 	= hash.list
			this.value_tree 	= normalizeSchema( {name: "_ROOT_", nodes: hash.list.map( x => `${x}` )} )
			visitNodes( this.value_tree, false, (node) => {node.key = node.name} )
		}
		else if (hash.tree !== undefined)
		{
			this.value_kind 	= 'tree'
			this.value_tree 	= normalizeSchema( hash.tree )
			this.value_list  	= []
			visitNodes( this.value_tree, false, (node) =>
			{
				this.value_list.push( node.key )
			} )
		}

		this.key_value_list = []
		visitNodes( this.value_tree, false, (node) =>
		{
			this.key_value_list.push(
			{
				node : node,
				name : node.name,
				key :  node.key
			})
		})
	}


	length()
	{
		return this.value_list ? this.value_list.length : 0
	}
}