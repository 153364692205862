
import React, { Component, Fragment } 	from "react";
import classnames       				from 'classnames';
import MasterFrame						from '../app/MasterFrame'
import {AppController} 					from '../app/AppController'
import backend_config from "../config/runconfig.js"
import axios from 'axios'

const ILink = ({ to, children }) => {
	return <a href='/imprint'>Imprint</a>
}

class Imprint extends Component
{
	static contextType = AppController;

	constructor(props) {
		super(props)


	this.state = {
			lang: 'en',
			i18n: null
		}
	}

	fetch_expression() {
		if (this.inflight)
			return

		const component = this

		this.data_model = null
		const url = `${backend_config.server}/system/i18n`

		console.log(url)

		if (this.state.i18n)
			return
		const axios_ui = axios.create();
		axios_ui.get(url)
			.then((incoming) => {
				this.inflight = false

				const { data } = incoming

				console.log(data)
				if (data.status === 'error') {
					component.setState({ error: true, errorMsg: data.error, chart_data: null })
				}
				else {
					const new_state = {}
					new_state.error = false
					new_state.errorMsg = null
					new_state.i18n = data.result.translations
					component.setState(new_state)
				}
			})
	}


	componentDidUpdate() {
		this.fetch_expression()
	}



	componentDidMount() {
		this.fetch_expression()
	}
	

	handle_language = (e) => {
		this.setState({ lang: e.target.value })
	}


	render_language_select() {
		return <select onChange={this.handle_language} value={this.state.lang}>
			<option value='en'>English</option>
			<option value='th'>ภาษาไทย</option>
		</select>
	}



	render()
	{

		console.log( "IMPRINT")
		console.log( this.state)
		if (!this.context || !this.state.i18n)
			return <div />

		const i18 = (x) => this.state.i18n[this.state.lang][x]


		const {match,history,location} 	= this.props
		return 	 <div className="app-frame sidemenu-off">
					<div className="menu">
						<div className='province-selector mainsection'>
							{<div className='home'><a href="/"><i className='fa fa-home' /></a></div>}
						</div>
						<div className='mainsection infosection'>
							<div className='toolbuttons'>
								{this.render_language_select()}
							</div>
						</div>
					</div>
					<div className='province-master'>
						<div className='side-menu'>
							<div className='filler' />
							<section>
								<h2><ILink to={`/imprint`}>Imprint</ILink></h2>
							</section>
						</div>
						<div className='province-data'>
							<div className={classnames("bottom")}>
								<div className='sankey-main'>
									<div className='sankey-main'>
										<div className='sankey-menu'>
											&nbsp;
										</div>
										<div className='sankey-container'>
											<div className={classnames("bottom")} style={{backgroundColor:"white"}}>
												<div className='imprint'>
													<div className='h1'>
														<div className='title'>{i18("IMPRINT:HEADLINE")}</div>
													</div>
													<p>
														{i18("IMPRINT:TEXT1")}
													</p>
													<p>
														{i18("IMPRINT:TEXT2")} <a href='mailto:provincialplan@energy.go.th'>provincialplan@energy.go.th</a>
													</p>

													<div className='columns'>
														<div className='column'>
															<h1>{i18("IMPRINT:WORKPARTNER")}</h1>

															<div className='logo'>
																<img  className='img' src={`/static/logos/MOE-logo.png`}/>
															</div>

															<h2>The Ministry of Energy of Thailand</h2>
															<p>Ministry of Energy, Thailand 555/2</p>
															<p>Energy Complex Building B Vibhavadi Rungsit Road. </p>
															<p>Chatuchack Bangkok 10900</p>
															<p></p>
															<p><a href='https://www.energy.go.th'>www.energy.go.th</a></p>
														</div>

														<div className='column'>
															<h1>{i18("IMPRINT:CONTRACTINGENTITY")}</h1>

															<div className='logo'>
																<img  className='img' src={`/static/logos/GIZ-logo.png`}/>
															</div>

															<h2>Deutsche Gesellschaft fuer Internationale Zusammenarbeit (GIZ), GmbH</h2>
															<p>Thailand Office</p>
															<p>GIZ Office Thailand</p>
															<p>193/63 Lake Rajada Office Complex (16th floor)</p>
															<p>New Ratchadapisek Road,</p>
															<p>Klongtoey, Bangkok 10110</p>
															<p></p>
															<p><a href="https://www.giz.de">www.giz.de</a></p>
														</div>

														<div className='column'>
															<h1>{i18("IMPRINT:FUNDEDBY")}</h1>

															<div className='logo'>
																<img  className='img' src={`/static/logos/BMU-logo.png`}/>
															</div>

															<h2>Federal Ministry for the Environment, Nature Conservation and Nuclear Safety of Germany</h2>
															<p>Funding number: <b>17_I_322</b></p>
															<p><a href="https://www.bmu.de/en/">www.bmu.de/en/</a></p>
														</div>
													</div>

													<div className='columns'>
														<div className='column'>&nbsp;</div>

														<div className='column'>
															<h1>{i18("IMPRINT:CONDUCTINGENTITIES")}</h1>

															<div className='logo'>
																<img  className='img' src={`/static/logos/ISE-logo.png`}/>
															</div>

															<h2>Fraunhofer Institute for Solar Energy Systems ISE</h2>
															<p>Heidenhofstr. 2</p>
															<p>79110 Freiburg</p>
															<p>Germany</p>
															<p><a href="https://www.ise.fraunhofer.de">www.ise.fraunhofer.de</a></p>

														</div>

														<div className='column'>
															<h1>&nbsp;</h1>

															<div className='logo'>
																<img  className='img' src={`/static/logos/Mondas-logo.png`}/>
															</div>

															<h2>Mondas GmbH</h2>
															<p>Emmy-Noether-Str. 2</p>
															<p>79110 Freiburg</p>
															<p>Germany</p>
															<p><a href="https://www.mondas-iot.de">www.mondas-iot.de</a></p>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
	}
}

export default Imprint


