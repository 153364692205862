import React, { Component, Fragment } from "react";
import AxisPainter from "./AxisPainter"
import LineChart from "./LineChart"


export class LineChartSet extends Component {
	setup_data(set) {
		const chart_keys = []
		const chart_split = {}
		const charts = {}
		const presence = {}

		set.set_keys.forEach((key, idx) => {
			const incoming = set.data[key]
			const chart_id = incoming.chart ? incoming.chart : 'MASTER'
			if (!chart_split[chart_id]) {
				chart_keys.push(chart_id)
				chart_split[chart_id] = []
				presence[chart_id] = {}
			}
			chart_split[chart_id].push(key)
		})

		chart_keys.forEach((chart_key, idx) => {
			let max_y = null
			let min_y = null
			const data_sets = {}
			const set_keys = []

			chart_split[chart_key].forEach((key, idx) => {
				const incoming = set.data[key]

				if (incoming && incoming.data) {
					incoming.data.forEach(e => { presence[chart_key][incoming.label] = true })

					for (let v of incoming.data) {
						const value = v.value
						if (!isNaN(value)) {
							min_y = min_y == null ? value : Math.min(value, min_y)
							max_y = max_y == null ? value : Math.max(value, max_y)

							if (!data_sets[key])
								data_sets[key] = { label: incoming.label, data: {} }
							data_sets[key].data[v.id] = value
						}
					}

					if (data_sets[key])
						set_keys.push(key)
				}
			})

			charts[chart_key] = {
				chart_key: chart_key,
				presence: presence[chart_key],
				set_keys: set_keys,
				data: data_sets,

				min_y: min_y ? min_y : 0,
				max_y: max_y ? max_y : 0,

				min_x: set.min_x,
				max_x: set.max_x,

				header: set.headers ? set.headers[chart_key] : null || '',
				footer: set.footer ? set.footer :
					set.footers ? set.footers[chart_key] :
						null || ''
			}

		})


		return {
			chart_keys: chart_keys,
			charts: charts
		}
	}

	render_charts() {
		const { data, ...chartprops } = this.props

		const chart_data = this.setup_data(data)

		const chart_divs = chart_data.chart_keys.map((chart_key, idx) => {
			const data_for_chart = chart_data.charts[chart_key]

			console.log(data_for_chart.chart_key, data_for_chart.footer)
			const download = this.props.download ? this.props.download.replace("_IDX_", '' + idx)
				.replace("_IDX2_", '' + (idx + 2)) : null
			const svg_chart = <LineChart key={chart_key}
				chart_key={chart_key}
				download={download}
				index={idx}
				data={data_for_chart} {...chartprops} />

			if (data_for_chart.footer) {
				return <div className='labelled-chart'>
					{svg_chart}
					<div className='textlabel'>
					{data_for_chart.footer}
					</div>
				</div>
			}
			else {
				return svg_chart
			}
		})

		return chart_divs
	}

	render() {
		return <Fragment>
			{this.render_charts()}
		</Fragment>
	}

}

export default LineChartSet