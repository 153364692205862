import React, { Component } from "react";
import classnames       from 'classnames';
import SetupFrame from "../setup/SetupFrame";

import { AppController } from "../app/AppController";

import backend_config					from "../config/runconfig.js"
import axios 							from 'axios'

import UserEdit 						from './UserEdit'

import {Help} 							from '../help/Help'

const API_KEY 		= 'ExgWFH9XHHyXIHyKEHIH'


class UserList extends Component {
	static contextType = AppController

	constructor(props) {
		super(props)
		this.state = {users: null, edit_user: null}
	}

	fetch_users(force)
	{
		const url 		= `${backend_config.server}/user/list?api_key=${API_KEY}`

		if (!force && (url === this.displayed_url || this.inflight))
			return
		console.log(url)
		const component = this
		this.inflight 	= true
		axios.get(url)
		 .then( (incoming) =>
		{
			this.inflight 			= false

			const {data}		= incoming

			console.log( incoming )

			if (data.status === 'error' )
			{
				console.log( "Error In Query")
				console.log( data.error )
				this.displayed_url  = url
				this.setState( {error: true, users: null, edit_user: null})
			}
			else
			{
				this.displayed_url 	= url
				this.timestamp 		= data.timestamp
				component.setState( {
										error: 			false,
										timestamp: 		data.timestamp,
									 	users: 			data.result,
									 	edit_user: 		null
									 } )
			}
		})
	}


	componentDidMount()
	{
		this.fetch_users()
	}

	componentDidUpdate()
	{
		this.fetch_users()
	}

	edit_user = (user) => (event) =>
	{
		this.setState( {edit_user: user} )
	}

	edit_user_complete = (event) =>
	{
		this.fetch_users( true )
	}


	render_user( i18, user, idx )
	{
		let role 		= <div>--No Roles--</div>
		let province 	= null
		let level 		= 0
		if (user.role )
		{
			const {kind} = user.role
			if (kind == 'provincial')
			{
				role 		= i18('ROLE:ADMIN:L1')
				level 		= 1
				province 	= user.role.province
			}
			else if (kind == 'national')
			{
				role		= i18('ROLE:ADMIN:L2')
				level 		= 2
			}
			else if (kind == 'admin')
			{
				role 		= i18('ROLE:ADMIN:L3')
				level 		= 3
			}
			else
			{
				role 		= kind
				level 		= '?'
			}

		}

		const pf 	= !province ? '' : i18(`PROVINCE:${province}`)

		return 	<div className='user' key={`user-${idx}`}>
					<div className='username'>{user.username}</div>
					<div className='name'>{user.name}</div>
					<div className='role'>{role}</div>
					<div className='province'>{pf}</div>
					<div className='edit'>
				<button onClick={this.edit_user(user)}>{i18("SETUP:USER:EDIT")}</button>
					</div>
				</div>
	}


	render_tables(i18, users)
	{
		if (!users) return <div></div>

		const list = []
		return	<form className='inputform' onSubmit={this.handleSubmit}>
					<div className='head'>
						<div className='title'>{i18('TITLE')}</div>
					</div>
					<div className='formdata'>
						<div className="user-list">

						<div className='user head' key='head'>
							<div className='head'>{i18('TABLE:COL1')}</div>
							<div className='head'>{i18('TABLE:COL2')}</div>
							<div className='head'>{i18('TABLE:COL3')}</div>
							<div className='head'>{i18('TABLE:COL4')}</div>
							<div className='head'>
							</div>
						</div>

						{users.map((u,idx) => this.render_user(i18,u,idx))}
						</div>
					</div>
		    		<div className='form-controls'>
		    			<input className='action' type='submit' onClick={this.edit_user({})} value={i18("SETUP:USER:ADDUSER")}/>
	    		</div>
			</form>
	}



	
	render() 
	{
		const { history, match } 	= this.props
		const {users,edit_user}		= this.state

		if (!this.context || !this.context.ctx.i18nstr)
			return <div />

		const i18 = this.context.ctx.i18nstrp("SETUP:USER")

		return (
			<SetupFrame history={history} match={match}>
				<div className="sankey-main">
					<div className="sankey-menu">
						<div className='section'>&nbsp;</div>
						<Help id='userlist'/>
					</div>
					<div className="sankey-container">
						<div className={classnames("bottom")}
							 style={{ backgroundColor: "white" }}>
							{ edit_user && 		 <UserEdit callback={this.edit_user_complete} user={edit_user}/>}
							{!edit_user && users && this.render_tables(i18, users)}
						</div>
					</div>
				</div>
			</SetupFrame>
		)
	}
}

export default UserList
