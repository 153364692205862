import React, { Component,Fragment }			from "react";
import {blank, 			  null_or_undef, make_list,
		trimmed_or_blank, always_to_string}		from "../utils/utils"

const FormContext 	= React.createContext()


export class FormComponent extends Component
{
	static contextType = FormContext;

	constructor(props)
	{
		super(props)

		const {name}	= props
		if (name)
			this.path = name.split( "." )
						.map( x => `${x}`.trim() )
						.filter(x => !!x)
	}

	transformValue(v)
	{
		if (this.props.transform && v !== null && v !== undefined)
			return this.props.transform(v)
		else
			return v
	}

	getFieldValue( default_value = null)
	{
		return this.transformValue( this.context.form.getFieldValue( this.path, default_value ) )
	}

	getRawValue()
	{
		const raw = this.context.form.getRawValue( this.path )
		return this.transformValue( raw )
	}

	getClassName()
	{
		const hasErrors  = this.context.form.fieldHasErrors( this.props.name )
		if (!hasErrors)
			return this.props.className
		const errorClass = this.props.errorClass || 'error'
		return `${this.props.className} ${errorClass}`
	}


	evaluate_boolean( off_prop, on_prop, none_value = false)
	{
		if (!on_prop || !off_prop)
			return none_value


		if (this.props[on_prop] == undefined && this.props[off_prop] == undefined)
			return none_value

		const invert 	= this.props[off_prop] !== undefined

		let exp 	 	= invert ? this.props[off_prop] : this.props[on_prop]

		if (exp === undefined)
			return none_value
		if (exp === true)
			return invert
		if (exp === false)
			return !invert
		if (typeof exp === 'string')
		{
			if (exp == 'true')
				return invert
			if (exp == 'false')
				return !invert
			const split = exp.split("=").map( x => trimmed_or_blank(x))
			if (split.length == 2)
				exp = (data) => (data[split[0]] === split[1] )
			else if (split.length == 1)
				exp = (data) => !!(data[split[0]])
			else
				exp = (x) => false
		}

		const shown = exp( this.context.form.getData())
		if (invert)
			return !shown
		else
			return  shown
	}

}


export class Error extends FormComponent
{
	render()
	{
		if (!this.context || !this.context.form)
			return null
		let {names}	= this.props
		if (!names)
			return null
		const all_errors 	= this.context.form.getErrors()
		if (!all_errors)
			return null

		names = make_list(names)

		const errors = []
		names.forEach( (n) =>
		{
			if (all_errors[n])
			{


				all_errors[n].forEach( e => errors.push(e) )
			}
		})

		if (errors.length > 0)
			return 	<div className={this.props.className}>
						{[...new Set(errors)].map( (err,i) => <div key={`err-${i}`}>{err}</div>)}
					</div>
		else
			return 	<div></div>
	}
}


export class HideShow  extends FormComponent
{
	constructor(props)
	{
		super(props)
	}

	render()
	{
		const show = this.evaluate_boolean( 'hide', 'show' )

		return 	<Fragment>
					{show && this.props.children}
				</Fragment>
	}
}

export class CheckBox  extends FormComponent
{
	constructor(props)
	{
		super(props)
	}

	componentDidMount()
	{
		const {required, name} 			= this.props
		const {form}					= this.context

		if (required)
			form.addRequired( name, this.path )

		const value_initial = this.getFieldValue(false)
		const value 		= !blank( value_initial )
		const raw 			= `${value}`

		this.context.form.setInitialValue( this.props.name, value, raw )
	}


	onChange = (event) => {
		const checked 	= event.target.checked
		this.context.form.setFieldValue( this.props.name, checked, `${checked}` )
	}


	render()
	{
		let checked 	= this.getFieldValue(false)
		checked = !(checked == undefined || checked == null || checked == false)

		const disabled 	= this.evaluate_boolean( 'disabled', 'enabled', false )

		return 	<input 	name={this.props.name}
						className={this.getClassName()}
						disabled={disabled}
					   	id={this.props.id}
					   	checked={checked}
					   	onChange={this.onChange}
					   	type='checkbox' />
	}
}



export class Select  extends FormComponent
{
	constructor(props)
	{
		super(props)
	}

	componentDidMount()
	{
		const {required, name} 			= this.props
		const {form}					= this.context

		if (required)
			form.addRequired(  name, this.path )

		let value_initial = this.getFieldValue()
		if (value_initial === null || value_initial === undefined)
			value_initial = this.props.default_value
		const value 		= this.transformValue( value_initial )
		const raw 			= always_to_string( value )

		this.context.form.setInitialValue( this.props.name, value, raw )
	}


	onChange = (event) => {
		const new_raw 		= event.target.value
		const new_data 		= this.transformValue( new_raw )
		this.context.form.setFieldValue( this.props.name, new_data, new_raw )
	}

	createOptionList( options )
	{
		if (!options)
			return []

		if (Array.isArray( options ))
		{
			const result = []
			options.forEach( opt =>
			{
				if (typeof opt == 'string')
				{
					const full_opt = trimmed_or_blank( opt )
					if (opt)
						result.push( {label: opt, value: opt} )
				}
				else
				{
					let label = opt.label
					let value = opt.value

					if (!blank(label) || !blank( value ))
					{
						if (blank( label ))
							label = value
						if (blank( value ))
							value = label

						result.push( {
							label: trimmed_or_blank( label ),
							value: trimmed_or_blank( value )
						})
					}
				}
			})
			return result
		}
		else if (typeof options == 'string')
		{
			return this.createOptionList( options.split(",") )
		}
		else
		{
			const result = []
			for( let value in options )
				result.push( {label: trimmed_or_blank( options[value]), value: value})
			return result
		}
		return []
	}


	render()
	{
		const options = this.createOptionList( this.props.options )
		const raw 	= this.getRawValue() 	|| ''
		const disabled 	= this.evaluate_boolean( 'disabled', 'enabled', false )

		return 	<select	value={raw}
						disabled={disabled}
						className={this.getClassName()}
						onChange={this.onChange}>
				{
					options.map((o,n) => <option key={`KEY-${n}`} value={`${o.value}`}>{o.label}</option>)
				}
				</select>
	}
}


export class TextBox extends FormComponent
{
	onChange = (event) => {
		const new_raw 		= this.transformValue( event.target.value )
		const new_data 		= this.createData( new_raw )
		this.context.form.setFieldValue( this.props.name, new_data, new_raw )
	}


	createData( raw_data )
	{
		const {type} 	= this.props

		if (type == 'numeric')
			return parseFloat( `${raw_data}`.trim() )
		else
			return raw_data
	}

	verifyNumeric = ( form, data, raw_data, errors ) =>
	{
		const {name, required} 	= this.props
		const raw 				= form.getRawData( 	 raw_data,  this.path )
		const value 			= this.createData( raw )


		const has_data 			= raw.length > 0

		if (value != 'Unlimited')
		{
			if ( required && has_data && isNaN( value ))
				return form.addError( errors, name, 'Only numeric values' )
			if (!required && has_data && isNaN( value ))
				return form.addError( errors, name, 'Only blank or numeric values' )

			const min_value = this.props.min_value
			if (min_value != null && min_value != undefined)
			{
				if (min_value == 0 && value < 0)
					return form.addError( errors, name, 'Must be positive (> 0)')
				else if (value < min_value)
					return form.addError( errors, name, `Must be > ${min_value}`)
			}

			const max_value = this.props.max_value
			if (max_value != null && max_value != undefined)
			{
				if (max_value == 0 && value > 0)
					return form.addError( errors, name, 'Must be negative (< 0)')
				else if (value < max_value)
					return form.addError( errors, name, `Must be > ${max_value}`)
			}
		}
		return false
	}



	componentDidMount()
	{
		const {max_length, min_length}			= this.props
		const {verify}							= this.props
		const {required, name, type} 			= this.props
		const {form}							= this.context

		if (required)
			form.addRequired(  name, this.path )
		if (min_length)
			form.addMinLength( name, this.path )
		if (max_length)
			form.addMaxLength( name, this.path )
		if (type == 'numeric')
			form.addVerifier(  name,  this.verifyNumeric )

		if (verify && Array.isArray( verify ))
			verify.map( v => form.addVerifier( name, v ))
		else if (verify)
			form.addVerifier( name, verify )

		let value_initial = this.getFieldValue()
		if (value_initial === null || value_initial === undefined)
			value_initial = this.props.default_value
		const value 		= this.transformValue( value_initial )
		const raw 			= always_to_string( value )
		const final_value	= this.createData( raw )

		this.context.form.setInitialValue( this.props.name, final_value, raw )
	}


	render()
	{
		let type 	= this.props.type
		if (type == 'numeric')
			type = 'text'
		else if (type == 'password')
			type = 'password'
		else
			type = 'text'



		const disabled 	= this.evaluate_boolean( 'disabled', 'enabled', false )



		const raw 		= this.getRawValue() 	|| ''

		return <input 	type={type}
						value={raw}
						disabled={disabled}
						placeholder={this.props.placeholder}
						className={this.getClassName()}
						onChange={this.onChange} />
	}
}

export class SubmitButton extends FormComponent
{
	constructor(props)
	{
		super(props)
		this.state = {count: 0}
	}
	componentDidMount()
	{
		this.setState( {count: this.state.count + 1})
	}

	render()
	{
		const text = this.props.value || "Submit"
		if (this.state.count > 0)
		{
			const errors = this.context.form.formHasErrors()
			return <input disabled={errors} type='submit' value={text}/>
		}
		else
			return null
	}
}



export class ActionLink extends FormComponent
{
	constructor(props)
	{
		super(props)
		this.state = {count: 0}
	}
	componentDidMount()
	{
		this.setState( {count: this.state.count + 1})
	}

	trigger_action = (event) =>
	{
		event.preventDefault()
		event.stopPropagation()

		if (this.props.onClick)
			this.props.onClick( event )
	}


	render()
	{
		if (this.state.count > 0)
		{
			const errors = this.context.form.formHasErrors()
			return 	<div className='action link' disabled={errors} onClick={this.trigger_action}>
						{this.props.icon && <span className={`icon fa fa-${this.props.icon}`}/>}
						{!this.props.value && this.props.children}
						{ this.props.value && this.props.value}
					</div>
		}
		else
			return null
	}
}

export class ActionButton extends FormComponent
{
	constructor(props)
	{
		super(props)
		this.state = {count: 0}
	}


	componentDidMount()
	{
		this.setState( {count: this.state.count + 1})
	}

	trigger_action = (event) =>
	{
		event.preventDefault()
		event.stopPropagation()

		if (this.props.onClick)
			this.props.onClick( event )
	}


	render()
	{
		if (this.state.count > 0)
		{
			return <button onClick={this.trigger_action}>{this.props.value}</button>
		}
		else
			return null
	}
}


export class Form extends Component
{
	constructor( props )
	{
		super( props )

		this.initial_data 		= {}
		this.initial_raw_data 	= {}

		const {data,errors, id, verify} 	= this.props
		this.state 	= {
			settled 		: 	false,
			data 			: 	data 	|| {},
			raw_data 		: 	{},

			errors 			: 	errors 	|| {},
			static_errors	: 	errors ? {...errors} : {}
		}

		this.verifiers 		= {form: this.form_verifiers(), field: {}}
		this.name 			= `Test Form ${id}`
	}

	getError 	  = (name) 	=> this.state.errors[name]
	getErrors 	  = () 		=> this.state.errors

	updateErrors( use_static, data, raw_data )
	{
		const errors 	= use_static ? {...this.state.static_errors} : {}
		for( let field_name in this.verifiers.field )
		{
			for( let v of this.verifiers.field[field_name])
				v( this, data, raw_data, errors )
		}
		for( let v of this.verifiers.form)
			v( this, data, raw_data, errors )

		return errors
	}

	formHasErrors()
	{
		const errors = {}

		for( let field_name in this.verifiers.field )
		{
			for( let v of this.verifiers.field[field_name])
			{
				if (v( this, this.state.data, this.state.raw_data, errors ))
					return true
			}
		}
		for( let v of this.verifiers.form)
		{
			if (v( this, this.state.data, this.state.raw_data, errors ))
				return true
		}

		return false
	}

	fieldHasErrors( field_name )
	{
		if (!this.verifiers.field[field_name])
			return false

		const errors = {}
		for( let v of this.verifiers.field[field_name])
		{
			if (v( this, this.state.data, this.state.raw_data, errors ))
				return true
		}

		return false
	}


	addError(errors, n,e)
	{
		if (!n || !e)
			return false
		if (!errors[n])
			errors[n] = []
		errors[n].push(e)
		return true
	}

	form_verifiers()
	{
		const {verify} 	  = this.props
		let formverifiers = []
		if (verify)
		{
			if (Array.isArray( verify ))
				formverifiers = [...verify]
			else
				formverifiers.push( verify )
		}
		return  formverifiers
	}

	addVerifier(name, v)
	{
		if (!v || !name)
			return
		if (!this.verifiers.field[name])
			this.verifiers.field[name] = []
		this.verifiers.field[name].push(v)
	}

	addRequired(name, path )
	{
		this.addVerifier( name, (form, data, raw_data, errors) =>
		{
			const v = this.getRawData(raw_data, path)
			const l = !v ? 0 : `${v}`.trim().length
			if (l == 0)
				return this.addError( errors, name, "" )
			return false
		})
	}

	addMinLength(name, path, n )
	{
		this.addVerifier( name, (form, data, raw_data, errors) =>
		{
			const v = this.getRawData(raw_data, path )
			const l = !v ? 0 : `${v}`.trim().length
			if (l < n)
				return this.addError(errors, name, `At least ${n} characters` )
			return false
		})
	}

	addMaxLength(name, path, n )
	{
		this.addVerifier( name, (form, data, raw_data, errors) =>
		{
			const v = this.getRawData(raw_data, path )
			const l = !v ? 0 : `${v}`.trim().length
			if (l > n)
				return this.addError(errors, name, `At most ${n} characters` )
			return false
		})
	}

	setInitialValue = (name, value, raw_value) =>
	{
		if (!name)
			return

		const raw = raw_value ? `${raw_value}` : ''

		this.initial_data[name] 	= value
		this.initial_raw_data[name]	= raw
	}

	searchPathTarget( start, path, end_offset )
	{
		if (!start || !path)
			return null
		const plen 	= path.length
		if (path.length == 0)
			return null
		let track 	= start
		for( let j = 0; j < plen - end_offset; j++ )
		{
			if (null_or_undef( track ))
				return null
			track = track[path[j]]
		}
		if (null_or_undef( track ))
			return null
		return track
	}

	getPathTargetSetter( start, path )
	{
		return this.searchPathTarget( start, path, 1 )
	}

	getPathTargetGetter( start, path )
	{
		 return this.searchPathTarget( start, path, 0 )
	}


	searchPathTargetV( start, path, end_offset )
	{
		if (!start || !path)
			return null
		const plen 	= path.length
		if (path.length == 0)
			return null
		let track 	= start
		for( let j = 0; j < plen - end_offset; j++ )
		{
			if (null_or_undef( track ))
				return null
			track = track[path[j]]
		}
		if (null_or_undef( track ))
			return null
		return track
	}

	getPathTargetSetterV( start, path )
	{
		return this.searchPathTargetV( start, path, 1 )
	}

	getPathTargetGetterV( start, path )
	{
		 return this.searchPathTargetV( start, path, 0 )
	}


	setFieldValue = (name, value, raw_value) =>
	{
		if (!name)
			return


		const raw = raw_value ? `${raw_value}` : ''
		const data 		= {...this.state.data, 		[name] : value}
		const raw_data 	= {...this.state.raw_data, 	[name] : raw}

		const errors 	= this.updateErrors(true, data, raw_data)
		this.setState( {errors, data, raw_data} )

		if (this.props.onChange)
			this.props.onChange( {name, value, raw_value, data} )
		return errors
	}


	getRawData = ( raw_data, name ) =>
	{
		const value = this.getPathTargetGetter( raw_data, name )
		if (value === null)
			 return ''
		else return value
	}

	getRawValue = ( name ) => this.getRawData( this.state.raw_data, name )

	getData 	= () => this.state.data


	getFieldData = ( data, path_i, default_value = null) =>
	{
		if (data && path_i)
		{
			let path = path_i
			if (typeof path_i == 'string')
				path = path_i.split( "." ).map( x => `${x}`.trim() ).filter(x => !!x)

			const value = this.getPathTargetGetter( data, path )
			if (null_or_undef( value ))
					return default_value
			else	return value
		}
		else        return default_value
	}

	getFieldDataV = ( data, path_i, default_value = null) =>
	{
		console.log( "DEBUG ", path_i )
		console.log( data )
		if (data && path_i)
		{
			let path = path_i
			if (typeof path_i == 'string')
				path = path_i.split( "." ).map( x => `${x}`.trim() ).filter(x => !!x)
			console.log( "PATH", path )

			const value = this.getPathTargetGetterV( data, path )
			console.log( "PTG", value )
			if (null_or_undef( value ))
					return default_value
			else	return value
		}
		else        return default_value

		console.log( "RESULT", v )
		return v
	}

	getFieldValue  = ( name, default_value ) => this.getFieldData(  this.state.data, name, default_value = null )
	getFieldValueV = ( name, default_value ) => this.getFieldDataV( this.state.data, name, default_value = null )

	handleSubmit = (event) 	=>
	{
		event.preventDefault();
		const data = {...this.state.data}

		if (this.props.onSubmit)
			this.props.onSubmit( event, data )
	}


	handleSubmit = (event) 	=>
	{
		event.preventDefault();
		const data = {...this.state.data}

		if (this.props.onSubmit)
			this.props.onSubmit( event, data )
	}


	componentDidMount()
	{
		const errors 	= this.updateErrors(true, this.initial_data, this.initial_raw_data)
		this.setState( {settled: 	true,
						errors: 	errors,
						data: 		this.initial_data,
						raw_data: 	this.initial_raw_data})
	}

	render()
	{
		const FormProvider 	= FormContext.Provider
		return 	<FormProvider value={ {form: this} }>
					<form 	onSubmit={this.handleSubmit}
							className={this.props.className} >
						{this.props.children}
					</form>
				</FormProvider>
	}
}

