


export class GridManager
{
	constructor( axes )
	{
		this.store 				= {}
		this.axis_list 			= axes
		this.axis_names 		= axes.map( x => x.name )
		this.axes 				= {}
		this.axis_list.map( x 	=> this.axes[x.name] = x )
		this.axis_filter		= {}
		this.axis_filter_map	= {}

		this.name1 				= this.axis_names[0]
		this.name2 				= this.axis_names[1]
		this.name3 				= this.axis_names[2]

		this.hash_cache 	= new WeakMap([])
	}


	setAxisFilter( axis_name, values )
	{
		if (values === null || values === undefined)
		{
			this.axis_filter[axis_name] 		= null
			this.axis_filter_map[axis_name]		= null
		}
		else
		{
			this.axis_filter[axis_name] 		= values
			this.axis_filter_map[axis_name]		= {}
			values.map( (x) => this.axis_filter_map[axis_name][x] = true)
		}
	}

	getAxisKeyValueList( axis_name )
	{

		const axis = this.axes[axis_name]
		if (!axis)
			return []
		else
		{
			const values = axis.key_value_list || []
			if (this.axis_filter[axis_name])
			{
				const filtered = this.axis_filter_map[axis_name]
				return values.filter( (x) => filtered[x.key] === true)
			}
			else
				return values

		}
	}

	getAxisValueList( axis_name )
	{
		const axis = this.axes[axis_name]
		if (!axis)
			return []
		else
		{
			const values = axis.value_list || []
			if (this.axis_filter[axis_name])
			{
				const filtered = this.axis_filter_map[axis_name]
				return values.filter( (x) => filtered[x] === true)
			}
			else
				return values
		}
	}

	getAxisValueTree( axis_name )
	{
		const axis = this.axes[axis_name]
		if (!axis)
			return {name:'_ROOT_'}
		else
			return axis.value_tree || {name:'_ROOT_'}
	}


	makeKey(  value1, value2, value3 )
	{
		return `${value1}-${value2}-${value3}`
	}


	set( data_point,  value )
	{
		this.store[this.makeKey( data_point[this.name1], data_point[this.name2], data_point[this.name3] )] = value
	}

	get( data_point )
	{
		const key = this.makeKey( data_point[this.name1], data_point[this.name2], data_point[this.name3] )
		//console.log( key + " -- " + this.store[key] )
		return this.store[key]
	}

	remainingAxis( axis_hash )
	{
		if (axis_hash[this.name2]	=== undefined)
			return this.name2
		if (axis_hash[this.name1] 	=== undefined)
			return this.name1
		if (axis_hash[this.name3]	=== undefined)
			return this.name3
		return null
	}



	rowHasEmptyValue( axis_hash )
	{
		const remaining = this.remainingAxis( axis_hash )
		for( let xa of this.getAxisValueList( remaining ) )
		{
			axis_hash[remaining] = xa
			const data = this.get( axis_hash )
			if (data === undefined || data === null)
				return true
		}
		return false
	}

	dataRowHash( axis_hash )
	{
		const found = this.hash_cache.get( axis_hash )
		if (found)
			return found
		const remaining = this.remainingAxis( axis_hash )
		const data = {}
		for( let xa of this.getAxisValueList( remaining ) )
		{
			axis_hash[remaining] = xa
			data[xa] = this.get( axis_hash )
		}
		this.hash_cache.set( axis_hash, data )
		return data
	}

	dataRowList( axis_hash )
	{
		const remaining = this.remainingAxis( axis_hash )
		const data = []
		for( let xa of this.getAxisValueList( remaining ) )
		{
			axis_hash[remaining] = xa
			data.push( this.get( axis_hash ) )
		}
		return data
	}
}


