

export const sk_colors = {
	col0: { rgb: "rgb(200,0,10)", thai: "---", description: "---" },
	col1: { rgb: "rgb(93,166,244)", thai: "ไฟฟ้า", description: "Electricity" },
	col2: { rgb: "rgb(241,75,105)", thai: "ความร้อน", description: "Heat" },
	col4: {
		rgb: "rgb(215,200,66)", thai: "เชื้อเพลิงฟอสซิลสำหรับโรงไฟฟ้า หรือการผลิตความร้อน",
		description: "Non-RE fuel for Power Plant or Boiler"
	},
	col5: {
		rgb: "rgb(100,200,110)", thai: "พลังงานทดแทนสำหรับโรงไฟฟ้า หรือการผลิตความร้อน",
		description: "RE fuel for Power Plant or Boiler"
	},
	col6: {
		rgb: "rgb(137,135,140)", thai: "เชื้อเพลิง (การขนส่ง)",
		description: "Fuel for Transportation"
	},
	col7: {
		rgb: "rgb(203,198,154)", thai: "เชื้อเพลิงที่ไม่ได้นำไปใช้ในเชิงพลังงาน",
		description: "Fuel for non-Energetic Purposes"
	},
	col8: { rgb: "rgb(204,201,207)", thai: "สูญเสียระหว่างกระบวนการผลิต", description: "Losses" },
	col9: { rgb: "rgb(255,193,94)", thai: "ความร้อนจาก/สู่ สิ่งแวดล้อม", description: "Ambient" },
	colpp: { rgb: "rgb(255,193,94)", thai: "แผนผังพลังงาน", description: "Energy Flow" },

	error: { rgb: "rgb(255,0,0)", thai: "???", description: "Error" }
}



export const thai_labels = {

 A1:	"เชื้อเพลิงฟอสซิล",
 A1S1: 	"ปริมาณก๊าซธรรมชาติที่ใช้ในโรงไฟฟ้า",
 A1S2: 	"น้ำมันเตาที่ใช้เป็นเชื้อเพลิงในโรงไฟฟ้า",
 A1S3: 	"ถ่านหินสำหรับการผลิตไฟฟ้า",
 A1S4: 	"ปริมาณดีเซลที่ใช้ในโรงไฟฟ้า",
 A1S5: 	"ปริมาณเชื้อเพลิงฟอสซิลที่ใช้ในหม้อต้ม",
 A2:	"ปริมาณชีวมวลที่ใช้ผลิตความร้อน",
 A2S1: 	"ปริมาณชีวมวลที่ใช้ในโรงไฟฟ้า",
 A2S2: 	"ก๊าซชีวภาพที่ใช้เป็นเชื้อเพลิงในโรงไฟฟ้า",
 A2S3: 	"ขยะที่ใช้เป็นเชื้อเพลิงในโรงไฟฟ้า",
 A2S4: 	"ปริมาณพลังงานทดแทนที่ใช้ในหม้อต้ม",
 A3:	"เชื้อเพลิงที่ใช้ในการขนส่ง",
 A4:	"เชื้อเพลิงที่ใช้ใน กิจกรรมที่ไม่ใช่พลังงาน",
 A5:	"พลังงานจาก/สู่ สิ่งแวดล้อม",
 A5S1: 	"พลังงานแสงอาทิตย์",
 A5S2: 	"พลังงานลม",
 A5S3: 	"พลังงานน้ำ",
 A5S4: 	"พลังงานความร้อนใต้พิภพ",
 A6:	"ไฟฟ้าที่นำเข้า",
 A5S1:	"พลังงานแสงอาทิตย์",
 A5S2:	"พลังงานลม",
 A5S3:	"พลังงานน้ำ",
 A5S4:	"พลังงานความร้อนใต้พิภพ",
 B1:	"เชื้อเพลิงฟอสซิลที่ใช้เป็นเชื้อเพลิงในการผลิตไฟฟ้า",
 B1S1: 	"โรงไฟฟ้าก๊าซธรรมชาติ",
 B1S2: 	"น้ำมันเตาที่ใช้เป็นเชื้อเพลิงในการผลิตไฟฟ้า",
 B1S3: 	"โรงไฟฟ้าถ่านหิน",
 B1S4: 	"โรงไฟฟ้าดีเซล",
 B2:	"พลังงานทดแทนที่ใช้เป็นเชื้อเพลิงในการผลิตไฟฟ้า",
 B2S1: 	"โรงไฟฟ้าชีวมวล",
 B2S2: 	" ก๊าซชีวภาพที่ใช้เป็นเชื้อเพลิงในโรงไฟฟ้า",
 B2S3: 	"ขยะที่ใช้เป็นเชื้อเพลิงในโรงไฟฟ้า",
 B3:	"โรงไฟฟ้าพลังงานทดแทน",
 B3S1: 	"โรงไฟฟ้าพลังงานแสงอาทิตย์",
 B3S2: 	"โรงไฟฟ้าพลังงานลม",
 B3S3: 	"โรงไฟฟ้าพลังงานน้ำ",
 B3S4: 	"โรงไฟฟ้าพลังงานความร้อนใต้พิภพ",
 B4:	"เชื้อเพลิงฟอสซิลที่ใช้เป็นเชื้อเพลิงใน Boiler",
 B5:	"พลังงานทดแทนที่ใช้เป็นเชื้อเพลิงใน Boiler",
 C1:	"ไฟฟ้าที่ผลิตได้",
 D1:	"ไฟฟ้าที่ส่งออก",
 E1:	"ปริมาณความต้องการไฟฟ้ารวม",
 E2:	"ปริมาณความต้องการความร้อนรวม",
 E3:	"ปริมาณความต้องการเชื้อเพลิงรวม สำหรับการขนส่ง",
 E4:	"ปริมาณความต้องการเชื้อเพลิงรวม สำหรับกิจกรรมที่ไม่ใช่พลังงาน",
 F1:	"ความต้องการไฟฟ้า สาขาบ้านอยู่อาศัย",
 F2:	"ความต้องการไฟฟ้า สาขาเกษตรกรรม",
 F3:	"ความต้องการไฟฟ้า สาขาอุตสาหกรรม",
 F4:	"ความต้องการไฟฟ้า สาขาธุรกิจการค้า",
 F5:	"ปริมาณการใช้ไฟฟ้าภาคการขนส่ง",
 F6:	"ความต้องการไฟฟ้า สาขาอื่นๆ",
 F7:	"ความต้องการความร้อน สาขาบ้านอยู่อาศัย",
 F8:	"ความต้องการความร้อนสาขาเกษตร",
 F9:	"ความต้องการความร้อน สาขาอุตสาหกรรม",
 F10:	"ความต้องการความร้อน สาขาธุรกิจการค้า",
 F11:	"ความต้องการความร้อนสาขาขนส่ง",
 F12:	"ความต้องการความร้อนสาขาอื่นๆ",
 L1:	"พลังงานที่สูญเสีย",
 G1:	"ความต้องการไฟฟ้า ที่รวม",
 H1:	"ปั๊มความร้อน",
 J1:	"การกักเก็บไฟฟ้า",
 J2:	"การกักเก็บความร้อน",
 K1:	"ความร้อนจาก/สู่ สิ่งแวดล้อม"
}


export const sk_labels = {
	A1:		{ label: "Fossil fuel", 								abbr: "Fos. fuel"},
	A1S1: 	{ label: "Natural gas for power plants", 				abbr: "Nat."},
	A1S2: 	{ label: "Fuel oil for power plants", 					abbr: "Oil"},
	A1S3: 	{ label: "Coal for power plants", 						abbr: "Coal"},
	A1S4: 	{ label: "Diesel for power plants", 					abbr: "Diesel"},
	A1S5: 	{ label: "Fossil fuel for boilers", 					abbr: "Fos. fuel"},
	A2:		{ label: "Re fuel for heat", 							abbr: "Re. fuel for heat"},
	A2S1: 	{ label: "Biomass for power plants", 					abbr: "Biomass"},
	A2S2: 	{ label: "Biogas for power plants", 					abbr: "Biogas"},
	A2S3: 	{ label: "MSW for power plants", 						abbr: "MSW"},
	A2S4: 	{ label: "Renewable fuel for boilers", 					abbr: "Re. fuel"},
	A3:		{ label: "Fuel for transportation", 					abbr: "Fuel"},
	A4:		{ label: "Fuel for non-energetic purposes", 			abbr: "Fuel"},
	A5:		{ label: "Ambient Energy", 								abbr: "Ambient"},
	A6:		{ label: "Electricity Import", 							abbr: "EL. Import"},

	A5S1:	{ label: "Used solar energy",							abbr: "Used solar en."},
	A5S2:	{ label: "Used wind energy",							abbr: "Used wind en"},
	A5S3:	{ label: "Used hydro energy",							abbr: "Used hydro en."},
	A5S4:	{ label: "Used geothermal energy",						abbr: "Used geoth. en."},

	B1:		{ label: "Fossil fuel-fired power plants", 				abbr: "Fos. fuel PP"},
	B1S1: 	{ label: "Natural gas-fired power plants", 				abbr: "Nat. gas PP"},
	B1S2: 	{ label: "Fuel oil-fired power plants", 				abbr: "Oil PP"},
	B1S3: 	{ label: "Coal-fired power plants", 					abbr: "Coal PP"},
	B1S4: 	{ label: "Diesel-fired power plants", 					abbr: "Diesel PP"},
	B2:		{ label: "Renewable fuel-fired power plants", 			abbr: "Re. fuel PP"},
	B2S1: 	{ label: "Biomass-fired power plants", 					abbr: "Biomass PP"},
	B2S2: 	{ label: "Biogas-fired power plants", 					abbr: "Biogas PP"},
	B2S3: 	{ label: "MSW-fired power plants", 						abbr: "MSW PP"},
	B2S4: 	{ label: "B2S4???", 									abbr: "B2S4"},
	B3:		{ label: "Other renewable power plants", 				abbr: "Other re. PP"},
	B3S1: 	{ label: "Solar power plants", 							abbr: "Solar PP"},
	B3S2: 	{ label: "Wind power plants", 							abbr: "Wind PP"},
	B3S3: 	{ label: "Hydroelectric power plants", 					abbr: "Hydro PP"},
	B3S4: 	{ label: "Geothermal power plants", 					abbr: "Geoth. PP"},
	B4:		{ label: "Fossil fuel-fired boilers", 					abbr: "Fos. fuel boiler for heat"},
	B5:		{ label: "Renewable fuel-fired boilers", 				abbr: "Re. fuel boiler for heat"},
	C1:		{ label: "Total electricity generation", 				abbr: "Total El. generated"},
	D1:		{ label: "Electricity exchange", 						abbr: "El Export"},
	E1:		{ label: "Total electricity demand", 					abbr: "Final El. Dem."},
	E2:		{ label: "Total heat demand", 							abbr: "Final heat Dem."},
	E3:		{ label: "Total fuel demand for transportation", 		abbr: "Mobility fuel Dem."},
	E4:		{ label: "Total fuel demand for non-energetic purposes",abbr: "Non-energ. fuel Dem."},
	F1:		{ label: "Electricity demand in residential sector", 	abbr: "Res. El. Dem.", percent: true},
	F2:		{ label: "Electricity demand in agricultural sector", 	abbr: "Agr. El. Dem.", percent: true},
	F3:		{ label: "Electricity demand in industrial sector", 	abbr: "Ind. El. Dem.", percent: true},
	F4:		{ label: "Electricity demand in commercial sector", 	abbr: "Com. El. Dem.", percent: true},
	F5:		{ label: "Electricity demand in transportation sector", abbr: "Transp. El. Dem.", percent: true},
	F6:		{ label: "Electricity demand in other sectors", 		abbr: "Other El. Dem.", percent: true},
	F7:		{ label: "Heat demand in residential sector", 			abbr: "Res. heat Dem.", percent: true},
	F8:		{ label: "Heat demand in agricultural sector", 			abbr: "Agr. heat Dem.", percent: true},
	F9:		{ label: "Heat demand in industrial sector", 			abbr: "Ind. heat Dem.", percent: true},
	F10:	{ label: "Heat demand in commercial sector", 			abbr: "Com. heat Dem.", percent: true},
	F11:	{ label: "Heat demand in transportation sector", 		abbr: "Transp. heat Dem.", percent: true},
	F12:	{ label: "Heat demand in other sectors", 				abbr: "Other heat Dem.", percent: true},
	L1:		{ label: "Energy loss", 								abbr: "Loss"},


	G1:		{ label: "Electricity demand including integration", 	abbr: "Total El. Dem."},
	H1:		{ label: "Heat pump", 									abbr: "Heat pump"},
	J2:		{ label: "Heat storage", 								abbr: "Total Heat Demand"}
}


class sankey_config
{
	constructor( name, diagram )
	{
		this.diagram = diagram
		this.name 	 = name

		this.lower_f 	= ["F7", "F8", "F9", "F10",  "F11"]
		this.upper_f 	= ["F1", "F2", "F3", "F4",  "F5", "F6"]
	}


	setup_graph()
	{
		const linklist = this.links()
		this.diagram.links = []

		for( const link of linklist)
		{
			const new_link = {...link}
			if (new_link.to 	 === "L1" || new_link.from === "K1")
				new_link.layer = 1
			else if (new_link.to === "D1")
				new_link.layer = 2
			else
				new_link.layer = 3

			this.diagram.links.push( new_link )
		}
		this.add_nodes()
	}

	make_final_adjustment()
	{}


	links_demand()
	{
		return [
			{from: "E1", to: "F1", col: "col1"},
			{from: "E1", to: "F2", col: "col1"},
			{from: "E1", to: "F3", col: "col1"},
			{from: "E1", to: "F4", col: "col1"},
			{from: "E1", to: "F5", col: "col1"},
			{from: "E1", to: "F6", col: "col1"},

			{from: "E2", to: "F7", col: "col2"},
			{from: "E2", to: "F8", col: "col2"},
			{from: "E2", to: "F9", col: "col2"},
			{from: "E2", to: "F10", col: "col2"},
			{from: "E2", to: "F11", col: "col2"},
		]
	}


	add_nodes_demand(w)
	{
		this.diagram.create_node( "F1", null, w, 1)
		this.diagram.create_node( "F2", null, w, 2)
		this.diagram.create_node( "F3", null, w, 3)
		this.diagram.create_node( "F4", null, w, 4)
		this.diagram.create_node( "F5", null, w, 5)
		this.diagram.create_node( "F6", null, w, 6)

		this.diagram.create_node( "F7", null,  w, 7)
		this.diagram.create_node( "F8", null,  w, 8)
		this.diagram.create_node( "F9", null,  w, 9)
		this.diagram.create_node( "F10", null, w, 10)
		this.diagram.create_node( "F11", null, w, 11)

		this.diagram.create_node( "E3", null, w, 12 )
		this.diagram.create_node( "E4", null, w, 13 )

	}

	add_nodes_no_demand(w)
	{
		this.diagram.create_node( "E3", null, w, 3 )
		this.diagram.create_node( "E4", null, w, 4 )
	}

	add_nodes_no_supply()
	{
		this.diagram.create_node( "A5", null, 0, 1)
		this.diagram.create_node( "A6", null, 0, 0 )
		this.diagram.create_node( "A2", null, 0, 2 )
		this.diagram.create_node( "A1", null, 0, 3 )

		this.diagram.create_node( "B2", null, 1, 1 )
		this.diagram.create_node( "B3", null, 1, 2 )

		this.diagram.create_node( "B5", null, 1, 3 )
		this.diagram.create_node( "B1", null, 1, 4 )
		this.diagram.create_node( "B4", null, 1, 5 )
	}


	add_nodes_supply()
	{
		this.diagram.create_node( "A6", 	null, 0, 0 )
		this.diagram.create_node("A5S1", 	null, 0, 1)
		this.diagram.create_node("A5S2", 	null, 0, 2)
		this.diagram.create_node("A5S3", 	null, 0, 3)
		this.diagram.create_node("A5S4", 	null, 0, 4)

		this.diagram.create_node("A2S1", 	null, 0, 5)
		this.diagram.create_node("A2S2", 	null, 0, 6)
		this.diagram.create_node("A2S3", 	null, 0, 7)
		this.diagram.create_node("A2S4", 	null, 0, 8)
		this.diagram.create_node( "A2", 	null, 0, 9)

		this.diagram.create_node( "A1", 	null, 0, 1)
		this.diagram.create_node("A1S1", 	null, 0, 2)
		this.diagram.create_node("A1S2", 	null, 0, 3)
		this.diagram.create_node("A1S3", 	null, 0, 4)
		this.diagram.create_node("A1S4", 	null, 0, 5)


		this.diagram.create_node( "A3", 	null, 0, 10)
		this.diagram.create_node( "A4", 	null, 0, 11)

		this.diagram.create_node("B3S1", null, 1, 9)
		this.diagram.create_node("B3S2", null, 1, 10)
		this.diagram.create_node("B3S3", null, 1, 11)
		this.diagram.create_node("B3S4", null, 1, 12)

		this.diagram.create_node("B2S1", null, 1, 5)
		this.diagram.create_node("B2S2", null, 1, 6)
		this.diagram.create_node("B2S3", null, 1, 7)
		this.diagram.create_node("B2S4", null, 1, 8)

		this.diagram.create_node( "B5", null, 1, 3 )
		this.diagram.create_node( "B4", null, 1, 5 )

		this.diagram.create_node("B1S1", null, 1, 1)
		this.diagram.create_node("B1S2", null, 1, 2)
		this.diagram.create_node("B1S3", null, 1, 3)
		this.diagram.create_node("B1S4", null, 1, 4)
	}


}


class current extends sankey_config
{
	links_supply()
	{
		return [

			{from: "A5S1",	to: "B3S1", 	col: "col9"},
			{from: "A5S2",	to: "B3S2", 	col: "col9"},
			{from: "A5S3",	to: "B3S3", 	col: "col9"},
			{from: "A5S4",	to: "B3S4", 	col: "col9"},

			{ from: "A1", 	to: "B4"		, col: "col4" },
			{from: "A1S1", 	to: "B1S1" 		, col: "col4"},
			{from: "A1S2", 	to: "B1S2" 		, col: "col4"},
			{from: "A1S3", 	to: "B1S3" 		, col: "col4"},
			{from: "A1S4", 	to: "B1S4" 		, col: "col4"},

			{from: "A2", 	to: "B5"		, col: "col5" },
			{from: "A2S3", 	to: "B2S3" 		, col: "col5"},
			{from: "A2S1", 	to: "B2S1" 		, col: "col5"},
			{from: "A2S2", 	to: "B2S2" 		, col: "col5"},
			{from: "B1S1", 	to: "C1" 		, col: "col1"},
			{from: "B1S2", 	to: "C1" 		, col: "col1"},
			{from: "B1S3", 	to: "C1" 		, col: "col1"},
			{from: "B1S4", 	to: "C1" 		, col: "col1"},
			{from: "B2S3", 	to: "C1" 		, col: "col1"},
			{from: "B2S1", 	to: "C1" 		, col: "col1"},
			{from: "B2S2", 	to: "C1" 		, col: "col1"},

			{from: "B2S3", 	to: "J2" 		, col: "col1"},
			{from: "B2S1", 	to: "J2" 		, col: "col1"},
			{from: "B2S2", 	to: "J2" 		, col: "col1"},


			{from: "B3S1", 	to: "C1" 		, col: "col1"},
			{from: "B3S2", 	to: "C1" 		, col: "col1"},
			{from: "B3S3", 	to: "C1" 		, col: "col1"},
			{from: "B3S4", 	to: "C1" 		, col: "col1"},
			{from: "B1S1", 	to: "E2" 		, col: "col2"},
			{from: "B2S3", 	to: "L1" 		, col: "col8"},
			{from: "B2S2", 	to: "L1" 		, col: "col8"},
			{from: "B2S1", 	to: "L1" 		, col: "col8"},
			{from: "B1S4", 	to: "L1" 		, col: "col8"},
			{from: "B1S3", 	to: "L1" 		, col: "col8"},
			{from: "B1S2", 	to: "L1" 		, col: "col8"},
			{from: "B1S1", 	to: "L1" 		, col: "col8"},


			{ from: "A3", 	to: "E3"		, col: "col6" },
			{ from: "A4", 	to: "E4"		, col: "col7" },
		]
	}

	links_no_supply()
	{
		return [
			{from: "A5", to: "B3", col: "col9"},
			{from: "A2", to: "B2", col: "col5"},
			{from: "A2", to: "B5", col: "col5"},
			{from: "A1", to: "B1", col: "col4"},
			{from: "A1", to: "B4", col: "col4"},
			{from: "A1", to: "E3", col: "col6"},
			{from: "A1", to: "E4", col: "col7"},

			{from: "B3", to: "C1", col: "col1"},
			{from: "B2", to: "C1", col: "col1"},

//			{from: "B2", to: "J2", col: "col2"},



			{from: "B1", to: "C1", col: "col1"},


			{from: "B1", to: "E2", col: "col2"},

			{from: "B1", to: "L1", col: "col8"},

			{from: "B2", to: "L1", col: "col8"}
		]

	}


	links_base()
	{
		return [
			{from: "A6", to: "E1", col: "col1"},
			{from: "C1", to: "D1", col: "col1"},
			{from: "C1", to: "E1", col: "col1"},
			{from: "C1", to: "L1", col: "col8"},

			{from: "B5", to: "E2", col: "col2"},
			{from: "B4", to: "E2", col: "col2"},
			{from: "B5", to: "L1", col: "col8"},
			{from: "B4", to: "L1", col: "col8"},
		]
	}




	add_nodes_base()
	{
		this.diagram.create_node( "C1", null, 2, 1 )
		this.diagram.create_node( "D1", null, 3, 1, (node) => node.input = "bottom" )
		this.diagram.create_node( "L1", null, 4, 4, (node) => node.input = "top"    )

		this.diagram.create_node( "E1", null, 5, 2, (node) => node.force_cluster = true )
		this.diagram.create_node( "E2", null, 5, 1, (node) => node.force_cluster = true )
	}
}



class simple_current extends current
{
	links()
	{
		return 		super.links_base()
		.concat( 	super.links_no_supply())
	}

	add_nodes()
	{
		super.add_nodes_base()
		super.add_nodes_no_demand(5)
		super.add_nodes_no_supply()
	}
}

class demand_current extends current
{
	links()
	{
		return 		super.links_base()
		.concat( 	super.links_demand())
		.concat( 	super.links_no_supply())
	}

	add_nodes()
	{
		super.add_nodes_base()
		super.add_nodes_demand(6)
		super.add_nodes_no_supply()
	}

	make_final_adjustment()
	{
		this.diagram.center_cluster( "E1", "E1>" )
		this.diagram.center_cluster( "E2", "E2>" )
	}
}






class supply_current extends current
{
	links()
	{
		return 		super.links_base()
		.concat( 	super.links_supply())
	}

	add_nodes()
	{
		super.add_nodes_base()
		super.add_nodes_no_demand(5)
		super.add_nodes_supply()
	}

}


class demand_supply_current extends current
{
	links()
	{
		return 		super.links_base()
		.concat( 	super.links_demand())
		.concat( 	super.links_supply())

	}

	add_nodes()
	{
		super.add_nodes_base()
		super.add_nodes_demand(6)
		super.add_nodes_supply()
	}

	make_final_adjustment()
	{
		this.diagram.center_cluster( "E1", "E1>" )
		this.diagram.center_cluster( "E2", "E2>" )
	}
}



/// ================================================================================================================


class future extends sankey_config
{
	links_supply()
	{
		return [
			{from: "A5S1",	to: "B3S1", 	col: "col9"},
			{from: "A5S2",	to: "B3S2", 	col: "col9"},
			{from: "A5S3",	to: "B3S3", 	col: "col9"},
			{from: "A5S4",	to: "B3S4", 	col: "col9"},

			{ from: "A1", 	to: "B4"		, col: "col4" },
			{from: "A1S1", 	to: "B1S1" 		, col: "col4"},
			{from: "A1S2", 	to: "B1S2" 		, col: "col4"},
			{from: "A1S3", 	to: "B1S3" 		, col: "col4"},
			{from: "A1S4", 	to: "B1S4" 		, col: "col4"},

			{from: "A2", 	to: "B5"		, col: "col5" },
			{from: "A2S1", 	to: "B2S1" 		, col: "col5"},
			{from: "A2S2", 	to: "B2S2" 		, col: "col5"},
			{from: "A2S3", 	to: "B2S3" 		, col: "col5"},
			{from: "A2S4", 	to: "B2S4" 		, col: "col5"},


			{from: "B1S1", 	to: "C1" 		, col: "col1"},
			{from: "B1S2", 	to: "C1" 		, col: "col1"},
			{from: "B1S3", 	to: "C1" 		, col: "col1"},
			{from: "B1S4", 	to: "C1" 		, col: "col1"},
			{from: "B2S3", 	to: "C1" 		, col: "col1"},
			{from: "B2S1", 	to: "C1" 		, col: "col1"},
			{from: "B2S2", 	to: "C1" 		, col: "col1"},

			{from: "B3S1", 	to: "C1" 		, col: "col1"},
			{from: "B3S2", 	to: "C1" 		, col: "col1"},
			{from: "B3S3", 	to: "C1" 		, col: "col1"},
			{from: "B3S4", 	to: "C1" 		, col: "col1"},
			{from: "B1S1", 	to: "E2" 		, col: "col2"},
			{from: "B2S3", 	to: "L1" 		, col: "col8"},
			{from: "B2S2", 	to: "L1" 		, col: "col8"},
			{from: "B2S1", 	to: "L1" 		, col: "col8"},
			{from: "B1S4", 	to: "L1" 		, col: "col8"},
			{from: "B1S3", 	to: "L1" 		, col: "col8"},
			{from: "B1S2", 	to: "L1" 		, col: "col8"},
			{from: "B1S1", 	to: "L1" 		, col: "col8"},


			{from: "B2S1", 	to: "J2" 		, col: "col2"},
			{from: "B2S3", 	to: "J2" 		, col: "col2"},
			{from: "B2S2", 	to: "J2" 		, col: "col2"},

			{from: "B2S4", 	to: "C1" 		, col: "col1"},
			{from: "B2S4", 	to: "J2" 		, col: "col2"},
			{from: "B2S4", 	to: "L1" 		, col: "col8"},


			{from: "B1S1", 	to: "J2" 		, col: "col2"},
			{from: "B1S3", 	to: "J2" 		, col: "col2"},

			{from: "B4", 	to: "J2"		, col: "col2"},
			{from: "B4", 	to: "L1"		, col: "col8"},
			{from: "B5", 	to: "J2"		, col: "col2"},
			{from: "B5", 	to: "L1"		, col: "col8"},

			{ from: "A3", 	to: "E3"		, col: "col6" },
			{ from: "A4", 	to: "E4"		, col: "col7" },
		]
	}

	links_no_supply()
	{
		return [
			{from: "A5", to: "B3", col: "col9"},
			{from: "A2", to: "B2", col: "col5"},
			{from: "A2", to: "B5", col: "col5"},
			{from: "A1", to: "B1", col: "col4"},
			{from: "A1", to: "B4", col: "col4"},
			{from: "A1", to: "E3", col: "col6"},
			{from: "A1", to: "E4", col: "col7"},

			{from: "B3", to: "C1", col: "col1"},
			{from: "B2", to: "C1", col: "col1"},
			{from: "B2", to: "J2", col: "col2"},

			{from: "B5", to: "E2", col: "col2"},
			{from: "B5", to: "J2", col: "col2"},
			{from: "B4", to: "J2", col: "col2"},

			{from: "B1", to: "C1", col: "col1"},


			{from: "B1", to: "E2", col: "col2"},
			{from: "B4", to: "E2", col: "col2"},

			{from: "B1", to: "J2", col: "col2"},


			{from: "B4", to: "L1", col: "col8"},
			{from: "B1", to: "L1", col: "col8"},
			{from: "B5", to: "L1", col: "col8"},
			{from: "B2", to: "L1", col: "col8"}
		]

	}


	links_base()
	{
		return [
			{from: "A6", to: "C1", col: "col1"},
			{from: "A6", to: "G1", col: "col1"},

			{from: "C1", to: "D1", col: "col1"},
			{from: "C1", to: "G1", col: "col1"},
			{from: "C1", to: "L1", col: "col8"},

			{from: "G1", to: "L1", col: "col8"},
			{from: "G1", to: "E1", col: "col1"},
			{from: "G1", to: "H1", col: "col1"},

			{from: "H1", to: "J2", col: "col2"},

			{from: "J2", to: "E2", col: "col2"},
			{from: "J2", to: "L1", col: "col8"}
		]
	}







	add_nodes_base()
	{
		this.diagram.create_node( "A6", null, 0, 0 )

		this.diagram.create_node( "C1", null, 2, 1, (node) => node.destress = true )
		this.diagram.create_node( "D1", null, 3, 1, (node) => node.input = "bottom" )

		this.diagram.create_node( "G1", null, 4, 1 )
		this.diagram.create_node( "H1", null, 5, 1 )
		this.diagram.create_node( "J2", null, 6, 1 )

		this.diagram.create_node( "L1", null, 7, 4, (node) => node.input = "top"    )

		this.diagram.create_node( "E1", null, 8, 2, (node) => node.force_cluster = true )
		this.diagram.create_node( "E2", null, 8, 1, (node) => node.force_cluster = true)
	}
}


class simple_future extends future
{
	links()
	{
		return 		super.links_base()
		.concat( 	super.links_no_supply())
	}

	add_nodes()
	{
		super.add_nodes_base()
		super.add_nodes_no_demand(8)
		super.add_nodes_no_supply()
	}

	make_final_adjustment()
	{
		this.diagram.force_below(    "B1", "A1,A1>",  1)
		this.diagram.force_below(    "G1", "H1",  1)
		this.diagram.force_below(    "H1", "J2",  1)
		this.diagram.force_below(    "J2", "E3,E4,A1",  1)
		this.diagram.center_cluster( "J2", "J2>" )
	}

}

class demand_future extends future
{
	links()
	{
		return 		super.links_base()
		.concat( 	super.links_demand())
		.concat( 	super.links_no_supply())
	}

	add_nodes()
	{
		super.add_nodes_base()
		super.add_nodes_demand(9)
		super.add_nodes_no_supply()
	}

	make_final_adjustment()
	{
		this.diagram.center_cluster( "E1", "E1>" )
		this.diagram.center_cluster( "E2", "E2>" )
		this.diagram.force_below(    "G1", "H1",  1)
	}
}



class supply_future extends future
{
	links()
	{
		return 		super.links_base()
		.concat( 	super.links_supply())
	}

	add_nodes()
	{
		super.add_nodes_base()
		super.add_nodes_no_demand(8)
		super.add_nodes_supply()
	}

	make_final_adjustment()
	{
		this.diagram.center_cluster( "J2", "B*,E1,E2" )
	}

}


class demand_supply_future extends future
{
	links()
	{
		return 		super.links_base()
		.concat( 	super.links_demand())
		.concat( 	super.links_supply())

	}

	add_nodes()
	{
		super.add_nodes_base()
		super.add_nodes_demand(9)
		super.add_nodes_supply()
	}

	make_final_adjustment()
	{
		this.diagram.center_cluster( "J2", "B*,E1,E2" )
		this.diagram.center_cluster( "E1", "E1>" )
		this.diagram.center_cluster( "E2", "E2>" )
	}
}





/// ================================================================================================================


class trivial1 extends sankey_config
{
	links()
	{
		return [
			{from: "X1", 	to: "Z1", col: "col1", weight : 2},
			{from: "X2", 	to: "Z2", col: "col2", weight : 4},
			{from: "X3", 	to: "Z3", col: "col3", weight : 3},

		]
	}


	add_nodes()
	{
		this.diagram.create_node( "X1", null, 0, 1 )
		this.diagram.create_node( "X2", null, 0, 2 )
		this.diagram.create_node( "X3", null, 0, 3 )
		this.diagram.create_node( "Z1", null, 1, 1 )
		this.diagram.create_node( "Z2", null, 1, 2 )
		this.diagram.create_node( "Z3", null, 1, 3 )

	}


	make_final_adjustment()
	{
	}

}


class trivial2 extends sankey_config
{
	links()
	{
		return [
			{from: "X1", 	to: "A1", col: "col1", weight : 2},
			{from: "X2", 	to: "A1", col: "col2", weight : 4},
			{from: "X3", 	to: "A1", col: "col3", weight : 3},

			{from: "A1", 	to: "B4", col: "col4", weight : 1},
			{from: "A1", 	to: "E3", col: "col6", weight : 6},
			{from: "A1", 	to: "E4", col: "col7", weight : 2},
		]
	}


	add_nodes()
	{
		this.diagram.create_node( "X1", null, 0, 1 )
		this.diagram.create_node( "X2", null, 0, 2 )
		this.diagram.create_node( "X3", null, 0, 3 )

		this.diagram.create_node( "A1", null, 1, 1 )

		this.diagram.create_node( "B4", null, 2, 1 )

		this.diagram.create_node( "E3", null, 3, 1 )
		this.diagram.create_node( "E4", null, 3, 2 )
	}


	make_final_adjustment()
	{
	}

}


class trivial extends sankey_config
{
	links()
	{
		return [
			{from: "Z1", 	to: "X1", col: "col1", weight : 2},
			{from: "Z2", 	to: "X2", col: "col2", weight : 20},
			{from: "Z3", 	to: "X3", col: "col3", weight : 3},

			{from: "X1", 	to: "A1", col: "col1", weight : 2},
			{from: "X2", 	to: "A1", col: "col2", weight : 20},
			{from: "X3", 	to: "A1", col: "col3", weight : 3},

			{from: "X1", 	to: "E4", col: "col3", weight : 3},

			{from: "X3", 	to: "A2", col: "col3", weight : 3},

			{from: "A1", 	to: "B4", col: "col4", weight : 1},
			{from: "A1", 	to: "E3", col: "col6", weight : 18},
			{from: "A1", 	to: "E4", col: "col7", weight : 2},

			{from: "A2", 	to: "B4", col: "col4", weight : 4},
			{from: "A2", 	to: "E3", col: "col7", weight : 2},
		]
	}


	add_nodes()
	{
		this.diagram.create_node( "Z1", null, 0, 1 )
		this.diagram.create_node( "Z2", null, 0, 2 )
		this.diagram.create_node( "Z3", null, 0, 3 )

		this.diagram.create_node( "X1", null, 1, 1 )
		this.diagram.create_node( "X2", null, 1, 2 )
		this.diagram.create_node( "X3", null, 1, 3 )

		this.diagram.create_node( "A1", null, 2, 1 )
		this.diagram.create_node( "A2", null, 2, 2 )

		this.diagram.create_node( "B4", null, 3, 1 )

		this.diagram.create_node( "E3", null, 4, 1 )
		this.diagram.create_node( "E4", null, 4, 2 )
	}


	make_final_adjustment()
	{
	}

}


export const sk_definition_current = {
	"simple": 						simple_current,
	"structure_demand": 			demand_current,
	"structure_demand_powerplant": 	demand_supply_current,
	"powerplant": 					supply_current
}

export const sk_definition_future = {
	"simple": 						simple_future,
	"structure_demand": 			demand_future,
	"structure_demand_powerplant": 	demand_supply_future,
	"powerplant": 					supply_future
}


// Demand-NAN