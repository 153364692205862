import React, { Component } from "react";
import classnames from 'classnames';

export const ActionButton = ( {className,type, icon, children,...props} ) =>
{
	const classN = classnames(className, 'action')
	let icon_text = null
	if (icon)
		icon_text = <i className={`icon fa fa-${icon}`}/>
	if (type === 'button')
		return <button className={classN} {...props} >{icon_text} {children}</button>
	else
		return <div className={classN} {...props}>{icon_text} {children}</div>
}


export const SubmitButton = ( {className, icon, text,...props} ) =>
{
	const classN = classnames(className, 'action')
	let icon_text = null
	if (icon)
		icon_text = <i className={`icon fa fa-${icon}`}/>
	const finaltext = `${icon-text} ${text}`

	return <input type='submit' className={classN} value={finaltext} {...props} />
}
