import React, { Component } from "react";
import MasterRouter from "./MasterRouter.js"
import { AppProvider, app_connection } from "./AppController"
import backend_config from "../config/runconfig.js"
import axios from "axios";


class App extends Component {
	constructor(props) {
		super(props)
		this.state = {
			last_serial: Date.now(),
			connected: false,
			connection_status: "unknown",
			auth_token: null,
			user: null,
			lang: 'en'
		}
		this.inflight = false
		this.message_id = 10000

		this.setup_axios_authentication()
	}


	start_base_data_fetch() {
		setTimeout(() => {
				this.fetch_i18()
				this.fetch_provinces()
		}, 1)
	}

	get_auth_token() {
		let token = this.state.auth_token || localStorage.getItem('auth-token')
		if (token == 'null')
			token = null
		return token
	}


	fetch_i18() {

		const component = this

		this.data_model = null
		const url = `${backend_config.server}/system/i18n`

		if (this.i18n)
			return

		const axios_ui = axios.create();
		axios_ui.get(url)
		.then((incoming) => 
		{
			const { data } = incoming
			if (data.status === 'error') {
				component.setState({ error: true, errorMsg: data.error })
			}
			else {
				this.i18n = data.result.translations
				this.i18n_now = data.result.translations ? data.result.translations[this.state.lang] : null
				this.i18nstr = (...keys) => this.i18nlookup(...keys)
				this.i18nstrp = (...prefix) => (...keys) => this.i18nlookupN(...prefix, ...keys) || this.i18nlookup(...keys)
				this.setState({ error: false, last_serial: Date.now() })
			}
		})
	}


	fetch_provinces(province_id = null) 
	{
		const token = this.get_auth_token()

		if (!token || this.inflight)
			return
		//		console.log( "AUTH ", token )
		const component = this

		this.inflight = true
		let url
		if  (province_id)
			url = `${backend_config.server}/system/provinces?message_id=${this.message_id}&province_id=${province_id}`
		else
			url = `${backend_config.server}/system/provinces?message_id=${this.message_id}`

		console.log( url )

		axios.get(url)
			.then((incoming) => {
				this.inflight = false
				if (!incoming)
					return
				const { data } = incoming

				if (!data || !data.timestamp || !data.status) {
					return
				}



				// Check if message got overlapped
				if (data.message_id != this.message_id)
					return
				else
					this.message_id++

//				console.log( "Provinces")
//				console.log( data)

				this.timestamp = data.timestamp

				if (data.status === "error") {
					console.log("Error")
					console.log(data.error)

					this.provinces = null
					this.tasks = null
					this.user = null
					this.pdata_schema = null
					this.year_refs = null
					this.units = null
					this.i18n = null
				}
				else {
					if (province_id)
						this.provinces[province_id] = data.result.provinces[province_id]
					else
						this.provinces = data.result.provinces

					this.tasks = data.result.tasks
					this.user = data.result.user
					this.pdata_schema = data.result.pdata_schema
					this.year_refs = data.result.year_refs
					this.lang = this.state.lang
					this.units = data.result.units

					this.year_refs.key = `${this.year_refs.reference_year}-${this.year_refs.target_year}-${this.year_refs.pd_file}`

					this.setState({ last_serial: Date.now() })
				}
			})
	}



	update_province(msg) 
	{
		if (msg.province_data) {
			const {province_id} = msg

			this.provinces[province_id] 	= msg.province_data.provinces[province_id]
			this.tasks 						= msg.province_data.tasks
			this.pdata_schema 				= msg.province_data.pdata_schema
			this.year_refs 					= msg.province_data.year_refs
			
			setTimeout( () => this.setState({ last_serial: Date.now() }), 0 )
		}
		else {
			this.fetch_provinces(msg.province_id)
		}
	}




	setup_axios_authentication() {
		axios.interceptors.request.use(config => {
			const auth_token = this.get_auth_token()
			if (auth_token) {
				config.headers.Authorization = `Bearer ${auth_token}`;
			}
			return config;
		},
			error => {
				console.log("Promise was rejected")
				return Promise.reject(error);
			});


		axios.interceptors.response.use(response => 
		{
			if (response.data &&
				response.data.status &&
				response.data.status == 'login') {
				this.setState({ force_login: true, auth_token: null })
				localStorage.setItem('auth-token', null)
			}
			else if (this.state.force_login) 
			{
				this.setState({ force_login: false })
			}

			return response
		},
			error => {
				return Promise.reject(error);
			})
	}


	componentDidMount() {

		app_connection.add_notification_callback("provinces",
			(msg) => this.update_province( msg ))

		app_connection.add_notification_callback("task",
			(msg) => this.update_province( msg ))

		app_connection.add_notification_callback("connection", (msg) => {
			this.inflight = false
			if (msg.connected && !this.state.connected) 
			{
				console.log("Reconnecting")
				this.fetch_i18()
				this.fetch_provinces()
			}

			this.setState({
				connected: msg.connected,
				connection_status: msg.connection_status
			})
		})
		this.start_base_data_fetch()
	}

	user_controller = () => {
		return {
			login_user: (data) => {
				const { user, auth_token } = data
				console.log("LOGIN", user);
				localStorage.setItem('auth-token', auth_token)
				this.setState({ auth_token })
				this.start_base_data_fetch()
			},
			logout_user: () => {
				console.log("LOGOUT");
				localStorage.setItem('auth-token', null)
				this.setState({ auth_token: null })
				this.start_base_data_fetch()
			}
		}
	}


	i18nlookupN = (...keys_in) => 
	{		
		const keys = keys_in.filter(k => (!!k))
							.map(k => `${k}`.trim())
							.filter( k => k.length > 0)

		if (!this.i18n)
			return ""
		
		if (!keys || keys.length == 0)
			return " "

		let disp_error = true
		const idx = keys.indexOf(false)
		if (idx > -1) 
		{
			disp_error = false
			keys.splice( idx, 1 )
		}

		if (keys[0] === false)
			console.log("NO ERROR ", keys)


		const keyuc = `${keys.join(":")}`.trim().toUpperCase().replace(/[^:_a-zA-Z0-9]/g, '')
		const lookup = this.i18n_now[keyuc]

		if (!lookup)
			return (disp_error ? null : " ")

		return lookup
	}


	// The first key can be false inwhich case a blank string is returned if the key does not exist instead of an error
	i18nlookup = (...keys) => 
	{
		if( keys.length == 1 && keys[0] == '--BLANK--')
			return " "
		const v = this.i18nlookupN(...keys)

		if (v === " ")
			return ""
		else if (!v)
			return `ERROR: ${keys}`
		return v
	}


	i18n_controller = () => {
		return {
			set_lang: (lang) => {
				if (!lang)
					return
				const l = `${lang.toLowerCase()}`.trim()
				if (l.length == 0)
					return

				this.i18n_now = this.i18n ? this.i18n[l] : null
				this.setState({ lang: l })
			}
		}
	}



	render() {
		const auth_token = this.get_auth_token()
		const imprint = window.location.pathname == "/imprint"
		const login = !imprint && (!auth_token || this.state.force_login)

		//			console.log( window.location.pathname )
		//			console.log( "AUTH token = " + auth_token, "FL=", this.state.force_login, "login=", login, "imp=", imprint )

		if (!auth_token && !this.state.force_login) {
			setTimeout(() => this.setState({ force_login: true }), 0)
			return null
		}

		if (!this.i18n)
			return <div/>

		return <AppProvider value={
			{
				ctx: this,
				pdata_schema: this.pdata_schema,
				provinces: this.provinces,
				tasks: this.tasks,
				i18n: this.i18n,
				i18nstrp: this.i18nstrp,
				i18nstr:  this.i18nstr,
				units: this.units,
				lang: this.state.lang,
				i18n_now: this.i18n_now = this.i18n ? this.i18n[this.state.lang] : null,
				year_refs: this.year_refs,
				year_key: this.year_refs ? this.year_refs.key : 'LOADING',

				user: this.user,
				user_controller: this.user_controller(),
				i18n_controller: this.i18n_controller(),

				units: this.units,

				...this.state
			}
		} >
			{login &&  <MasterRouter forcelogin={true}/>}
			{!login && <MasterRouter />}
		</AppProvider>
	}
}

export default App