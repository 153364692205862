import React, { Component, Fragment } 	from "react";
import classnames       				from 'classnames';
import ProvinceTreeViewer 				from './province/ProvinceTreeViewer'
import Dropzone 						from 'react-dropzone'

import backend_config					from './config/runconfig.js'

import ReactTooltip 					from 'react-tooltip'
import SetupFrame						from './setup/SetupFrame'
import {AppController} 					from './app/AppController'
import axios 							from 'axios'

import {Help} 							from './help/Help'


const  upload_icon  	= require('../public/icons/file-upload.svg')

class XLSUpload extends Component
{
	constructor( props )
	{
		super( props )
		this.state = {
			status: 			"waiting",
			message: 			null,
			update_status: 		null
		}
	}

	update_progress( filename, progress, message )
	{
		const uploads = this.state.uploads


		if (progress == null)
			delete uploads[filename]
		else
		{
			if (!uploads[filename])
				uploads[filename] = {}

			uploads[filename].progress 	= progress
			uploads[filename].message 	= message
		}
		const status = Object.keys( this.state.uploads ).length == 0 ? 'waiting' : 'uploading'
		this.setState( {status: status, uploads: this.state.uploads} )
	}



	componentDidMount()
	{
		this.refresh_update_status()
	}

	refresh_update_status()
	{
			const url 			= `${backend_config.server}/system/download-status`
			console.log( url )
			const req = axios.get( url )
			req.then( response => {
				this.setState( {update_status: response.data.result})
			})


			setTimeout( () => this.refresh_update_status(), 2000 )
	}


	upload_file( target, files )
	{
		files.forEach(file =>
		{
			this.update_progress( file.name, 0 )

			const form_data 	= new FormData();

			const url 			= `${backend_config.server}/system/upload`
			console.log( url )

			form_data.append("file", file)
			const req = axios.post(url, form_data,
			{
    			headers: {'Content-Type': 'multipart/form-data'},
    			onUploadProgress: (progress_event) =>
    			{
    				let percent = 0
    				if (progress_event.total > 0)
    					percent = Math.floor( progress_event.loaded * 100 / progress_event.total )
    				this.update_progress( file.name, percent )
    			}
    		})

      		req.catch( err => {
				this.setState(  {
									status: "error",
									message: "Connection Failure"
								})
      		})

	    	req.then( response =>
	    	{
				const {data}   = response
    			if (data)
    			{
    				if (data.status === 'success')
    				{
							this.update_progress( file.name, 1000 )
							setTimeout( () => this.update_progress( file.name, null), 5000 )
					}
    				else
    				{
    					const error = result.error
    					this.update_progress( file.name, -1, error )
    				}
    			}
	    	});
    	});
	}


	render_upload( filename, status )
	{
		let msg 				= ''
  	  	let progress 			= 0
  	  	let progress_percent 	= 0

  	  	if (status.progress === 0)
  	  	{
  	  		msg = 'Connecting...'
  	  	}
   	  	else if (status.progress > 100)
  	  	{
  	  		msg = 'Success'
  	  	}
  	  	else if (status.progress)
  	  	{
  	  		progress_percent = Math.round( status.progress )
			if (progress_percent > 99)
				msg = 'Analyzing File'
			else
				msg = `Uploading ${progress_percent} %`
		}

		return 	<div key={`UP-${filename}`} className='single_upload'>
							<div className='filename'>{filename}</div>
							<div className='progress'>{msg}</div>
				</div>
	}

	render_uploads()
	{
		const uploads = []
		for( let filename in this.state.uploads )
			uploads.push( this.render_upload( filename, this.state.uploads[filename] ) )
		return uploads
	}



	render_update_status( i18, s )
	{
		if (s == null)
			return <div></div>
		if (s.state == 'stopped')
			return <div></div>
		if (s.state == 'fetching-years')
			return <div>Getting Years</div>
		if (s.state == 'authenticating')
			return <div>Authenticating</div>

		const jobs = s.jobs
		const dlen = s.finished.length
		const errors = s.errors && s.errors.length > 0 ? ` (${s.errors.length} ${i18('SCN:STATUS:ERROR')})` : ''
		return <div>
			<div className='status'>{i18('STATUS:DNL')}  {dlen} / {jobs} {errors}</div>
			<div>&nbsp;</div>
		</div>
	}

	start_download()
	{
		axios.post( `${backend_config.server}/system/download-start` )
	}

	render()
	{
		const {i18} = this.props 
		return 	<div className='inputform'>
	    			<div className='head'>
	    				<div className='title'>{i18('TITLE')}</div>
	        		</div>
	        		<div className='upload-status'>
	        		{
	        			this.render_update_status( i18, this.state.update_status )
	        		}
	        		</div>
					<div className='help-text'>
						<p>{i18('TXT:1')}</p>
						<p>{i18('TXT:2')}</p>
						<p><b>{i18('TXT:3')}</b></p>
						<p>{i18('TXT:4')}</p>
						<p>{i18('TXT:5')}</p>
						<p/>
					</div>
					{
						backend_config.sync_active && this.state.update_status && this.state.update_status.state == 'stopped' &&
				<button onClick={() => this.start_download()}>{i18("SETUP:UPDATE:DOWNLOAD")}</button>
					}
				</div>
	}



}


class Upload extends Component
{
	static contextType = AppController;

	constructor( props )
	{
		super( props )
		this.state = {
			dropzone 			: false,
			selected_scenario	: null,
			selected_file		: null
		}
	}


	get_province()
	{
		return 	this.props.match.params.province
	}


	render()
	{
		const {history,match}		= this.props
		const province  			= this.get_province()


		if (!this.context || !this.context.ctx.i18nstr)
			return <div />

		const i18 = this.context.ctx.i18nstrp("SETUP", "UPDATE")

		const user 		= this.context.ctx.user
		const role 		= user && user.role && user.role.kind
		const is_admin	= role == 'admin'
		const is_nat	= role == 'national'
		const is_po		= role == 'provincial'

		const can_upload = is_admin || (is_po && `${user.role.province}`.toLowerCase() == province) || is_nat


		if (this.props.no_frame)
			return this.render_chart()
		else
			return <SetupFrame history={history} match={match} province={province}>
						<div className={classnames("bottom")}>
							<div className='sankey-main'>
								<div className='sankey-menu'>
									<div className='section'>&nbsp;</div>
									<Help id='syncprovince'/>
								</div>
								<div className='sankey-container'>
									{ can_upload && <XLSUpload i18={i18} />}
									{!can_upload && <div>You cannot upload data for this province</div>}
								</div>
							</div>
						</div>
					</SetupFrame>
	}
}

export default Upload
