import React, { Fragment, Component } from "react";

import axios from 'axios'
import { BarChartSet } from '../barchart/BarChartSet'
import { Navigation } from '../navigation/navigation'


import backend_config from '../config/runconfig.js'

const API_KEY = 'ExgWFH9XHHyXIHyKEHIH'

export class FrontPageChart extends Component
{

    constructor(props)
    {
        super(props)
        this.state = {chart_data : null, energy : 'ktoe'}
    }

    fetch_expression() 
    {
        if (this.inflight)
            return

        const component = this
        const url_params = this.get_backend_query()

        this.data_model = null
        const root = `${backend_config.server}/sdata/energydemandchartdata`

        const year_key = this.context ? this.context.year_key : 'NONE'
        const url = `${root}?${url_params}&api_key=${API_KEY}&year_key=${year_key}`
        console.log(url)

        if (url === this.displayed_url)
            return

        this.inflight = true

        const axios_ui = axios.create();
        axios_ui.get(url)
            .then((incoming) => {

                this.inflight = false
                this.displayed_url = url

                const { data } = incoming


                if (data.status === 'error') {
                    component.setState({ error: true, errorMsg: data.error, chart_data: null })
                }
                else if (!data.result.chart_data || Object.keys(data.result.chart_data).length == 0) {
                    component.setState({ error: true, errorMsg: "No Data", chart_data: null })
                }
                else {

                    console.log(data.result)


                    const new_state = {}
                    new_state.error = false
                    new_state.errorMsg = null
                    new_state.chart_data = data.result.chart_data
                    new_state.units = data.result.units
                    new_state.is_head_data = data.result.is_head_data
                    component.setState(new_state)
                }
            })
    }




    componentDidUpdate() {
        this.fetch_expression()
    }



    componentDidMount() {
        this.fetch_expression()
    }



    chart_labels = (i18) => {
        return {
            SD_BARD1: {
                label: i18("START:GRAPH1:LEGEND:EL"),
                color: 'rgba(36,62,110,0.8)'
            },

            SD_BARD2: {
                label: i18("START:GRAPH1:LEGEND:HEAT"),
                color: 'rgba(179,20,36,0.8)'
            },


            SD_BARS1: {
                label: i18("START:GRAPH2:LEGEND:NATGAS"),
                color: 'rgba(204,201,207,0.8)'
            },
            SD_BARS3: {
                label: i18("START:GRAPH2:LEGEND:COAL"),
                color: 'rgba(58,57,60,1)'
            },
            SD_BARSBIOM: {
                label: i18("START:GRAPH2:LEGEND:BIOMASS"),
                color: '#6EBD2E'
            },
            SD_BARSBIOGAS: {
                label: i18("START:GRAPH2:LEGEND:BIOGAS"),
                color: '#C5A0A0'
            },
            SD_SANKEYB5J2: {
                label: i18("START:GRAPH2:LEGEND:REBOILERS"),
                color: 'rgb(100,200,110)'
            },
            SD_BARS8: {
                label: i18("START:GRAPH2:LEGEND:PV"),
                color: 'rgba(243,223,43,0.8)'
            },
            SD_BARS9: {
                label: i18("START:GRAPH2:LEGEND:WIND"),
                color: 'rgba(14,27,223,0.8)'
            },
            SD_BARS10: {
                label: i18("START:GRAPH2:LEGEND:HYDRO"),
                color: 'rgba(11,17,142,0.8)'
            },
            SD_BARS13: {
                label: i18("START:GRAPH2:LEGEND:MSW"),
                color: 'rgba(109,45,8,0.8)'
            },
            SD_BARS5: {
                label: i18("START:GRAPH2:LEGEND:FOSBOILERS"),
                color: 'rgba(219,69,0,0.8)'
            },
            SD_BARS14: {
                label: i18("START:GRAPH2:LEGEND:ELIMPORT"),
                color: 'rgba(93,166,244)'
            }
        }
    }





    add_value_to_chart(set, id, v) {
//        if (v > 0 || v < 0)
            set.push({ id: id, value: v })
    }


    setup_subresult_top(data, prefix, postfix, data_set) {
        if (!data)
            return

        const key_list = Object.keys(data)
            .filter(k => k.startsWith(prefix) && k.endsWith(postfix))

        key_list.sort()


        for (let k of key_list) {
            let sort_key = k.replace('QD_BAR', 'SD_BAR')
            const sp = sort_key.split("_")
            if (sp.length == 3)
                sort_key = `${sp[0]}_${sp[1]}`
            this.add_value_to_chart(data_set, sort_key, data[k])
        }
    }



    setup_chart_data_top(i18, data) {
        if (!data)
            return null

        data.ref_year = this.context && this.context.year_refs ? `${this.context.year_refs.reference_year}` : ''
        data.tgt_year = this.context && this.context.year_refs ? `${this.context.year_refs.target_year}` : ''

        const dataset_current = []
        const dataset_bau = []
        const dataset_ee = []
        const dataset_eeplus = []


        const result = {
            set_keys: ['current', 'bau', 'ee', 'eeplus'],
            data: {
                current: {
                    label: i18('START:GRAPH1:HEADER:TODAY'),
                    sub_label: data.ref_year,
                    data: [{ data: dataset_current }]
                },
                bau: {
                    label: i18('SCN:BAU'),
                    sub_label: data.tgt_year,
                    data: [{ data: dataset_bau }]
                },
                ee: {
                    label: i18('SCN:EE'),
                    sub_label: data.tgt_year,
                    data: [{ data: dataset_ee }]
                },
                eeplus: {
                    label: i18('SCN:EEPLUS'),
                    sub_label: data.tgt_year,
                    data: [{ data: dataset_eeplus }]
                }
            }
        }


        this.setup_subresult_top(data, "QD_BARD", "", dataset_current)
        this.setup_subresult_top(data, "SD_BARD", "1", dataset_bau)
        this.setup_subresult_top(data, "SD_BARD", "7", dataset_ee)
        this.setup_subresult_top(data, "SD_BARD", "13", dataset_eeplus)

        return result
    }




    setup_chart_data_for_bot(i18, data, key_set) {
        if (!data)
            return null

        const labels = {}
        const result = {}
        const keys = []

        for (let entry_i in key_set) {
            const entry = key_set[entry_i]
            if (entry.index.startsWith("SD_BARD"))
                continue

            const index = entry.index

            const data_set_bau = []
            const data_set_ee = []
            const data_set_eeplus = []

            const data_set = [
                { label: "BAU", data: data_set_bau },
                { label: "EE", data: data_set_ee },
                { label: "EE+", data: data_set_eeplus }
            ]

            this.add_value_to_chart(data_set_bau, index, data[`${index}_1`])
            this.add_value_to_chart(data_set_ee, index, data[`${index}_7`])
            this.add_value_to_chart(data_set_eeplus, index, data[`${index}_13`])

            const key = `P${entry_i}`
            if (keys.indexOf(key) < 0)
                keys.push(key)

            result[key] = {
                //                label: i18(`LABEL-${entry.index}`),
                top_label: "TOP LABEL",
                sub_label: null,
                target: null,
                data: data_set,
                labels: labels
            }
        }

        const rv = {
            data: result,
            set_keys: keys,
            draw_legend: false,
            labels: labels
        }

        return rv
    }





    setup_chart_data_bot(i18, data) {
        if (!data)
            return null


        const key_map = [
            { index: "SD_BARD1" },
            { index: "SD_BARD2" },
            { index: "SD_BARS1" },
            { index: "SD_BARS3" },
            { index: "SD_BARSBIOM" },
            { index: "SD_BARSBIOGAS" },
            { index: "SD_SANKEYB5J2" },
            { index: "SD_BARS8" },
            { index: "SD_BARS9" },
            { index: "SD_BARS10" },
            { index: "SD_BARS13" },
            { index: "SD_BARS5" },
            { index: "SD_BARS14" },
        ]

        return this.setup_chart_data_for_bot(i18, data, key_map)
    }



    setup_chart_data(i18, data) {
        if (!data)
            return null

        const result = {}


        return this.setup_chart_data_top(i18, data)
    }


    render_chart(i18, data_model) {

        if (!data_model || !data_model.top)
            return <div />
        return <div className='chart-container'>
                    {
                        data_model.top &&
                        <div className='subchart titled'>
                            <div className='title'>{i18("START:GRAPH1:HEADLINE")}</div>
                            <div className='chart-set'>
                                <BarChartSet data={data_model.top}
                                    min_legend_height={300}
                                    y_axis_label={i18('START:GRAPH1:YLABEL')}
                                    y_axis_unit={i18(`${this.get_energy()}`)}
                                    groups={[0]}
                                    labels={this.chart_labels(i18)} />
                            </div>
                        </div>
                    }
                    {
                        data_model.bot &&
                        <div className='subchart titled'>
                            <div className='title'>{i18("START:GRAPH2:HEADLINE")}</div>
                            <div className='chart-set'>
                                <BarChartSet data={data_model.bot}
                                    min_legend_height={300}
                                    y_axis_label={i18('START:GRAPH2:YLABEL')}
                                    y_axis_unit={i18(`${this.get_energy()}`)}
                                    groups={[0, 1, 2]}
                                    labels={this.chart_labels(i18)} />
                            </div>
                        </div>
                    }
                </div>
    }


    render()
    {
        if (!this.props.i18 || !this.state.chart_data)
            return <div/>

        if (!this.data_model)
            this.data_model = {
                top: this.setup_chart_data_top(this.props.i18, this.state.chart_data),
                bot: this.setup_chart_data_bot(this.props.i18, this.state.chart_data),
            }

        return  <Fragment>
                    {!this.state.error && this.data_model && this.render_chart(this.props.i18, this.data_model)}
                    <br />
                    <br />
                    <br />
                    <br />
                </Fragment>
    }
}
Object.assign(FrontPageChart.prototype, Navigation)


export default FrontPageChart