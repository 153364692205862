import React, { Component, Fragment }	from "react";
import AxisPainter 					  	from "./AxisPainter"
import ResizeComponent 					from "./ResizeComponent"


const HEAD_SIZE      = 10
const subchart_heigt = 120
const LEGEND_SIZE    = 80
const TICK_WIDTH     = 120
const MARGIN         = 30


export class LineChart extends ResizeComponent
{
	constructor(props)
	{
		super(props)

		this.state = {
			count: 0,
			focused: null
		}

	}



	draw_dataset(svg, scaling, set_index, set)
	{
		const path = []
		for( let x = scaling.min_x; x <= scaling.max_x; x++ )
		{
			const value = set.data[x]
			if (value)
			{
				const x_g 	= scaling.axis_x_0 + scaling.interval_x*(x - scaling.min_x)
				const y_g 	= scaling.axis_y_0 - value / scaling.range * scaling.chart_height
				if (path.length == 0)
					path.push(`M ${x_g} ${y_g}`)
				else
					path.push(`L ${x_g} ${y_g}`)
			}
		}

		let color = 'red'
		if (this.props.labels && this.props.labels[set.label] && this.props.labels[set.label].color)
			color = this.props.labels[set.label].color

		svg.push( <path key={`PATH-${set.label}`} d={path.join(' ')} strokeWidth={3} stroke={color} fill='transparent'/> )
	}

	draw_sets(svg, scaling, chart_data )
	{
		chart_data.set_keys.forEach( (set_i, idx) =>
		{
			const data_set  = chart_data.data[set_i]
			this.draw_dataset(svg, scaling, idx, data_set)
		})
	}

	draw_legend(svg, chart_data, scaling)
	{
		let y = scaling.chart_bottom + 60
		let x = scaling.axis_x_0

		for (let index of Object.keys(chart_data.presence))
		{
			const label = this.props.labels[index]
			if (!label)
				continue

			const color = label.color
			svg.push(<rect  key={`LEGR-${index}`} x={x} y={y} width={15} height={15} fill={color} />)
			svg.push(<text  fontSize='10px' key={`LEGT-${index}`} x={x + 30} y={y + 15}>{label.label}</text>)
			x += 80
			if (x > scaling.chart_width + scaling.axis_x_0) {
				x = scaling.axis_x_0
				y += 30
			}
		}
		return y
	}


	draw_svg_chart(chart_data, scaling)
	{
		const axis_painter 	= new AxisPainter()
		const svg = []
		this.draw_sets(svg, scaling, chart_data)
		axis_painter.draw_axis( svg, scaling, this.props )
		if (chart_data.header)
			svg.push( <text x={scaling.axis_x_0 + scaling.chart_width/2 - 30}
							y={scaling.chart_top-18} key={`HEAD-${this.props.chart_key}`} fontSize={12} textAnchor="center">{chart_data.header}</text>)

		this.draw_legend( svg, chart_data, scaling )

		return svg
	}






	get_tickdist(factor, scale)
	{
		if (factor < 4)
			return scale / 2
		if (factor < 8)
			return scale
		else
			return scale * 2
	}


		// Calculates the Axis ticks from the min and max of the data values
	// mx > 0 and mn < 0 represents the min and max on the axes. if there are no negative
	// values in the dataset mn = 0 (and correspondingly for mx and no positive values)
	calc_scale(mx, mn)
	{
		const range = Math.max(mx, -mn)

		const exp = Math.floor(Math.log10(range))
		const scale = Math.pow(10, exp)

		const factor_max = mx > 0 ? Math.floor(mx / scale) + 1 : 0
		const factor_min = mn < 0 ? Math.floor(mn / scale) - 1 : 0
		const tick = this.get_tickdist(Math.max(factor_max, factor_min), scale)

		const max = tick * (mx > 0 ? mx / tick + 0.1 : 0)
		const min = tick * (mn < 0 ? mn / tick - 0.1 : 0)

		return { max: max, min: min, range: max - min, tick: tick, exp: exp }
	}


	setup_y_scaling( data, scaling )
	{
		if (data.max_y > 0)
			data.min_y = 0
		else
			data.max_y = 0

		const y_axis_fragment    = (0 - data.min_y) / (data.max_y - data.min_y);

		scaling.chart_top 		 = scaling.chart_bottom - scaling.chart_height
		scaling.viewport_bottom	 = scaling.chart_bottom + 100
		scaling.axis_y_0         = scaling.chart_bottom - scaling.chart_height * y_axis_fragment;

		const { max, min, range, tick, exp } = this.calc_scale( data.max_y, data.min_y )

		scaling.max 	= max
		scaling.min 	= min
		scaling.max_y 	= max
		scaling.min_y 	= min
		scaling.min_x 	= data.min_x
		scaling.max_x 	= data.max_x
		scaling.range 	= range
		scaling.tick 	= tick
		scaling.exp 	= exp
	}


	calculate_svg_dimensions(scaling, rect, data )
	{
		scaling.tick_width 			= TICK_WIDTH
		scaling.margin 	 			= MARGIN
		scaling.container_height  	= rect.height
		scaling.container_width   	= rect.width
		scaling.axis_x_0          	= TICK_WIDTH

		scaling.chart_height     	= rect.height - HEAD_SIZE - MARGIN - 90
		scaling.chart_width       	= rect.width - TICK_WIDTH - MARGIN

		scaling.chart_bottom     	= HEAD_SIZE + MARGIN + scaling.chart_height;

		if (this.props.top_labels)
			scaling.chart_height 	= scaling.chart_height 	  - MARGIN

		scaling.interval_x       		= scaling.chart_width / (data.max_x - data.min_x);
	}



	get_scaling( data, rect )
	{
		const result = {}
		if (rect == null || !data)
		{
			return null
		}
		else
		{
			this.calculate_svg_dimensions( result, rect, data );
			return result
		}
	}


	render()
	{
		const {width, height, view_box, rect} = this.paint_surface()

		const {index,data, chart_key}		= this.props
		const grow_style 					= this.props.auto_grow ? {flexGrow: data.set_keys.length} : null

		const scaling 						= this.get_scaling( data, rect )
		if (!scaling)
			return 	<div className='single-chart' style={grow_style} key={`CHART-${chart_key}`} ref={this.ref}></div>

		this.setup_y_scaling( data, scaling )

		if (scaling.max_y == 0 && scaling.min_y == 0)
			return <div className='single-chart' style={grow_style} key={`CHART-${chart_key}`} ref={this.ref}></div>


		const download 	= this.props.download ? this.props.download.replace( "_IDX_", '' +  (index+1) )
																   .replace( "_IDX2_", '' + (index+3) ) 
																   .replace( "_IDX3_", '' + (index+5)): null



		return 	<div className='single-chart' key={`CHART-${chart_key}`} ref={this.ref}>
					{this.props.download ?
						<a className='download' href={download} target='_chart'>
							<i className='warning fa fa-download'/>
						</a> : null}

					<svg
							version={1}
							xmlns='http://www.w3.org/2000/svg'
							preserveAspectRatio='xMidYMid meet'
							viewBox={view_box}>
					{
						this.draw_svg_chart(data, scaling)
					}
					</svg>
				</div>
	}
}




export default LineChart