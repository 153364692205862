
import React, { Component, Fragment } 	from "react";

import classnames       				from 'classnames';
import {AppController} 					from '../app/AppController'
import backend_config					from "../config/runconfig.js"
import axios 							from 'axios'

import {FrontPageChart} 				from '../chart/FrontPageChart'


const API_KEY = 'ExgWFH9XHHyXIHyKEHIH'

const ILink = ({to, children}) =>
{
	return <a href='/imprint'>Imprint</a>
}

class UserLogin extends Component
{
	static contextType = AppController;

	constructor( props )
	{
		super(props)


		this.state = {
			username 		: '',
			password		: '',
			lang 			: 'en',
			i18n 			: null
		}
	}

	fetch_i18() {
		if (this.inflight)
			return

		const component = this

		this.data_model = null
		const url = `${backend_config.server}/system/i18n`

		console.log(url)

		if (this.state.i18n)
			return
		const axios_ui = axios.create();
		axios_ui.get(url)
			.then((incoming) => 
			{
				this.inflight = false

				const { data } = incoming

				console.log( data )
				if (data.status === 'error') {
					component.setState({ error: true, errorMsg: data.error, chart_data: null })
				}
				else 
				{
					const new_state 	= {}
					new_state.error 	= false
					new_state.errorMsg 	= null
					new_state.i18n  	= data.result.translations
					component.setState(new_state)
				}
			})
	}




	componentDidUpdate() {
		this.fetch_i18()
	}



	componentDidMount() {
		this.fetch_i18()
	}


	handle_language = (e) => 
	{
		this.setState( {lang: e.target.value})
	}


	render_language_select() {
		return <select onChange={this.handle_language} value={this.state.lang}>
			<option value='en'>English</option>
			<option value='th'>ภาษาไทย</option>
		</select>
	}


	handleChange = (field) => {
		return (event) =>
		{
			const state 	= {...this.state}
			state[field] 	= event.target.value
    		this.setState( state );
  		}
  	}

	handleSubmit = (event) =>
	{
		event.preventDefault();
		const url 					= `${backend_config.server}/user/login`
		const {username,password}	= this.state
		const data 					= {username, password}

	    axios.post(url,data)
	    .then((incoming) =>
	    {
	    	if (incoming.data.status == 'success')
	    	{
				this.context.user_controller.login_user( incoming.data.result )
				this.setState( {error: null} )
			}
			else
			{
				console.log( incoming.data.error  )
				this.setState( {error: incoming.data.error} )
			}
	    })
	}

	check_if_submit_disabled()
	{
		if (!this.state.username || `${this.state.username}`.trim().length == 0)
			return true
		if (!this.state.password || `${this.state.password}`.trim().length == 0)
			return true
		return false
	}


	unit_selector({params})
	{
		return <div>Unit</div>
	}

	render()
	{
		if (!this.context || !this.state.i18n)
			return <div/>

		const i18 = (x) => this.state.i18n[this.state.lang][x]

		const {match,history,location} 	= this.props
		return  <div className="app-frame sidemenu-off">
					<div className="menu">
						{this.context && this.context.connected != true && <span>Connection failure</span>}
						<div className='province-selector mainsection'>
							{<div className='home'><a href="/"><i className='fa fa-home' /></a></div>}
						</div>
						<div className='mainsection infosection'>
							<div className='toolbuttons'>
								{this.render_language_select()}
							</div>
						</div>
					</div>
					<div className='province-master'>
						<div className='side-menu'>
							<div className='filler'/>
							<section>
								<h2><ILink to={`/imprint`}>Imprint</ILink></h2>
							</section>
						</div>
						<div className='province-data'>
							<div className={classnames("bottom")}>
								<div className='sankey-main'>
									<div className='sankey-menu'>
										{
//											this.unit_selector({ area: false, currency: false })
										}
										<div className='section'>&nbsp;</div>
									</div>
									<div className='sankey-container  userlogin'>
										<div className='loginform'>
									      	<form className='inputform' onSubmit={this.handleSubmit}>
												<div className='head'>
													<div className='title'>{i18("START:HEADLINE")}</div>
												</div>
													<div className='help'>
													<p>{i18("START:TEXT1")}</p>
												<ol>
													<li>{i18("START:TEXT2")}</li>
													<li>{i18("START:TEXT3")}</li>
												</ol>
													</div>
												<div className='head'>
													<div className='title'>{i18("START:LOGIN")}</div>
												</div>
												<div className='formdata'>
													<div className='line'>
														<div key={`er-title`}  	className='title'>
														</div>

														<div key={`er-control`} 	className='control' >
														{this.state.error}
														</div>
													</div>
													<div className='line'>
														<div key={`un-title`}  	className='title'>
													{i18("START:USERNAME")}
														</div>

														<div key={`un-control`} 	className='control' >
															<input name='username' 	type='text'
																					defaultValue={this.state.username}
																					onChange={this.handleChange('username')} />
														</div>
													</div>
													<div className='line'>
														<div key={`pa-title`}  	className='title'>
													{i18("START:PASSWORD")}
														</div>

														<div key={`pa-control`} 	className='control' >
															<input name='password' 	defaultValue={this.state.password}
																					onChange={this.handleChange('password')}
																					type='password'/>
														</div>
													</div>
												</div>
												<div className='form-controls'>
													<input 	className='action' type='submit' value={i18("START:LOGIN_BUTTON")} 
															disabled={this.check_if_submit_disabled()}/>
												</div>
											</form>
										</div>

										

										<div className='frontpage-charts'>
											<div className='subtitle'>{i18("START:CHART:HEADLINE")}</div>
											<div className="text">{i18("START:CHART:TEXT")}</div>
											<div className='chart-block'>
												<FrontPageChart i18={i18} history={history} location={location} match={match} />
											</div>
										</div>
										<div>
											<br/>
											<br />
											<br />
											<br />

											<br />
											<br />
											<br />
											<br />

											<br />
											<br />
											<br />
											<br />
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
	}
}
export default UserLogin


