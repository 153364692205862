import React, { Component } from "react";
import classnames       from 'classnames';
import SetupFrame from "./SetupFrame";

import { AppController } from "../app/AppController";

import { Help } from '../help/Help'

class Setup extends Component {
	static contextType = AppController

	constructor(props) {
		super(props)
	}

	render() {
		const { history, match } = this.props

		if (!this.context || !this.context.tasks)
			return <div/>

		const tasks = this.context.tasks

		if (!this.context || !this.context.ctx.i18nstr)
			return <div />

		const i18 = this.context.ctx.i18nstrp("SETUP","START")

		return (
			<SetupFrame history={history} match={match}>
				<div className="sankey-main">
					<div className="sankey-menu">
						<div className='section'>&nbsp;</div>
						<Help id='setup'/>
					</div>
					<div className="sankey-container">
						<div className='helptext'>
							<h1>{i18('HEADLINE')}</h1>
							<p>{i18('TEXT')}</p>

							<h2>{i18('SUBHEADLINE1')}</h2>
							<p>{i18('SUBTEXT1')}</p>

							<h2>{i18('SUBHEADLINE1')}</h2>
							<p>{i18('SUBTEXT2')}</p>

							<h2>{i18('SUBHEADLINE3')}</h2>
							<p>{i18('SUBTEXT3')}</p>

							<h2>{i18('SUBHEADLINE4')}</h2>
							<p>{i18('SUBTEXT4')}</p>

							<h2>{i18('SUBHEADLINE5')}</h2>
							<p>{i18('SUBTEXT5')}</p>


						</div>
					</div>
				</div>
			</SetupFrame>
		)
	}
}

export default Setup
