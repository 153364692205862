import React, { Component } from "react"
import classnames from "classnames"
import NationalFrame from "../app/NationalFrame"

import { AppController } from "../app/AppController"

class TaskViewerFrame extends Component {
	static contextType = AppController

	constructor(props) {
		super(props)
	}

	render_provinces() {
		if (!this.context.provinces) return <div>No Tasks Scheduled</div>

		const keys = Object.keys( this.context.provinces ).sort()
		return keys.map((key, idx) => 
		{
			const province = this.context.provinces[key]
			console.log(province)
			return (
				<div className={`province`} key={`${province.file_id}-${idx}`}>
					<div className="province">{province.name}</div>
					{['001', '002'].map( s => {
						if (province.scenario[s])
							return <div>Scenario {s}</div>
					})}
				</div>
			)
		})
	}


	render() {
		const { history, match } = this.props
		let renderer

		const tasks 		= this.context.tasks
		const provinces		= this.context.provinces

		return (
			<NationalFrame history={history} match={match}>
				<div className="sankey-main">
					<div className="sankey-menu"></div>
					<div className="sankey-container">
						<div className={classnames("bottom")} style={{ backgroundColor: "white" }}>
							<div className="task-list">{tasks && this.render_provinces()}</div>
						</div>
					</div>
				</div>
			</NationalFrame>
		)
	}
}

export default TaskViewerFrame
