import React, { Component, Fragment } 	from "react";
import classnames       				from 'classnames';
import backend_config					from "../config/runconfig.js"

import axios 							from 'axios'

import MasterFrame 						from '../app/MasterFrame'
import {AppController} 					from '../app/AppController'

import {Help}							from '../help/Help'

import {Navigation} 					from '../navigation/navigation'

const API_KEY 		= 'ExgWFH9XHHyXIHyKEHIH'

const present = [
	{
		varname: "QD_KpiguiPopNo",
		natvarname: "QD_KpiguiNatPopNo",
		description: "Number of inhabitants", unit: "persons"
	},
	{
		varname: "QD_KpiguiAreaTotal",
		natvarname: "QD_KpiguiNatAreaTotal",
		percapname: "QD_KpiguiAreaPerCapita",
		natpercapname: "QD_KpiguiNatAreaPerCapita",
		description: "Provincial area"
	},
	{
		varname: "QD_KpiguiGpp",
		natvarname: "QD_KpiguiNatGpp",
		percapname: "QD_KpiguiGppPerCapita",
		natpercapname: "QD_KpiguiNatGppPerCapita",
		description: "Gross Provincial Product"
	},
	{
		varname: "QD_KpiguiEnConsTotal",
		natvarname: "QD_KpiguiNatEnConsTotal",
		percapname: "QD_KpiguiEnConsPerCapita",
		natpercapname: "QD_KpiguiNatEnConsPerCapita",
		description: "Final energy demand",
		subdesc: "KPI:SUB_QD_KpiguiEnConsPerCapita"
	},
	{
		varname: "QD_KpiguiElConsTotal",
		natvarname: "QD_KpiguiNatElecConsTotal",
		percapname: "QD_KpiguiElecConsPerCapita",
		natpercapname: "QD_KpiguiNatElecConsPerCapita",
		description: "Electricity demand",
	},
	{
		varname: "QD_KpiguiEnShareRes",
		natvarname: "QD_KpiguiNatEnShareRes",
		description: "Share of residential energy demand on final energy demand"
	},
	{
		varname: "QD_KpiguiEnShareInd",
		natvarname: "QD_KpiguiNatEnShareInd",
		description: "Share of industrial energy demand on final energy demand"
	},
	{
		varname: "QD_KpiguiEnShareMot",
		natvarname: "QD_KpiguiNatEnShareMot",
		description: "Share of energy demand for mobility on final energy demand"
	},
	{
		varname: "QD_KpiguiCapNonre",
		natvarname: "QD_KpiguiNatCapNonre",
		percapname: "QD_KpiguiCapNonrePerCapita",
		natpercapname: "QD_KpiguiNatCapNonrePerCapita",
		description: "Installed non-renewable electricity generation capacity"
	},
	{
		varname: "QD_KpiguiCapRe",
		natvarname: "QD_KpiguiNatCapRe",
		percapname: "QD_KpiguiCapRePerCapita",
		natpercapname: "QD_KpiguiNatCapRePerCapita",
		description: "Installed renewable electricity generation capacity"
	},
	{
		varname: "QD_KpiguiElecImp",
		natvarname: "QD_KpiguiNatElecImp",
		percapname: "QD_KpiguiElecImpPerCapita",
		natpercapname: "QD_KpiguiNatElecImpPerCapita",
		description: "Electricity import into the province",
		subdesc: "(PEMT)"
	},
	{
		varname: "QD_KpiguiElecExp",
		natvarname: "QD_KpiguiNatElecExp",
		percapname: "QD_KpiguiElecExpPerCapita",
		natpercapname: "QD_KpiguiNatElecExpPerCapita",
		description: "Electricity export outside the province",
		subdesc: "(PEMT)"
	},
	{
		varname: "QD_KpiguiEnShareRe",
		natvarname: "QD_KpiguiNatEnShareRe",
		description: "Share of renewable energy generation on provincial energy demand",
		subdesc: "(Electricity and Heat)"

	},
	{
		varname: "QD_KpiguiElecShareRe",
		natvarname: "QD_KpiguiNatElecShareRe",
		description: "Share of renewable electricity generation on provincial electricity demand"
	},
	{
		varname: "QD_KpiguiGhgEmission",
		natvarname: "QD_KpiguiNatGhgEmission",
		percapname: "QD_KpiguiGhgEmissionPerCapita",
		natpercapname: "QD_KpiguiNatGhgEmissionPerCapita",
		description: "Greenhouse gas emissions",
		subdesc: "(Thai Database)"
	},
	{
		varname: "QD_KpiguiGhgEmissionPemt",
		natvarname: null,
		percapname: "QD_KpiguiGhgEmissionPemtPerCapita",
		natpercapname: null,
		description: "Greenhouse gas emissions",
		subdesc: "(w/o fuel-driven transport; PEMT)"
	},
	{
		varname: "QD_KpiguiGhgEmissionPemtInclM",
		natvarname: null,
		percapname: "QD_KpiguiGhgEmissionPemtInclMPerCapita",
		natpercapname: null,
		description: "Greenhouse gas emissions",
		subdesc: "(incl. fuel-driven transport; PEMT)"
	}

]


const future = [
	{
		varname: "SD_KpiguiPopNo",
		natvarname: "SD_KpiguiNatPopNo",
		description: "Number of inhabitants", unit: "persons"
	},
	{
		varname: "SD_KpiguiAreaTotal",
		percapname: "SD_KpiguiAreaPerCapita",
		description: "Provincial area"
	},
	{
		varname: "SD_KpiguiGpp",
		percapname: "SD_KpiguiGppPerCapita",
		description: "Gross Provincial Product"
	},
	{
		varname: "SD_KpiguiEnConsTotal",
		percapname: "SD_KpiguiEnConsPerCapita",
		description: "Final energy demand",
		subdesc: "KPI:SUB_SD_KpiguiEnConsPerCapita"
	},
	{
		varname: "SD_KpiguiElConsTotal",
		percapname: "SD_KpiguiElecConsPerCapita",
		description: "Electricity demand"
	},
	{
		varname: "SD_KpiguiEnShareRes",
		description: "Share of residential energy demand on final energy demand"
	},
	{
		varname: "SD_KpiguiEnShareInd",
		description: "Share of industrial energy demand on final energy demand"
	},
	{
		varname: "SD_KpiguiEnShareMot",
		description: "Share of energy demand for mobility on final energy demand"
	},
	{
		varname: "SD_KpiguiCapNonre",
		percapname: "SD_KpiguiCapNonrePerCapita",
		description: "Installed non-renewable electricity generation capacity"
	},
	{
		varname: "SD_KpiguiCapRe",
		percapname: "SD_KpiguiCapRePerCapita",
		description: "Installed renewable electricity generation capacity"
	},
	{
		varname: "SD_KpiguiElecImp",
		percapname: "SD_KpiguiElecImpPerCapita",
		description: "Electricity import into the province "
	},
	{
		varname: "SD_KpiguiElecExp",
		percapname: "SD_KpiguiElecExpPerCapita",
		description: "Electricity export outside the province"
	},
	{
		varname: "SD_SCENRESHARE",
		description: "Renewable Energy (RE) share",
		subdesc: "(electricity and heat)"
	},
	{
		varname: "SD_KpiguiEnShareRe",
		description: "Share of provincial renewable energy generation on provincial energy demand",
		subdesc: "(electricity and heat) "
	},
	{
		varname: "SD_KpiguiElecShareRe",
		description: "Share of provincial renewable electricity generation on provincial electricity demand"
	},
	{
		varname: "SD_KpiguiGhgEmissionPEMT",
		percapname: "SD_KpiguiGhgEmissionPEMTPerCapita",
		description: "Greenhouse gas emissions",
		subdesc: "(w/o fuel-driven transport)"
	},
	{
		varname: "SD_KpiguiGhgEmissionPEMTInclM",
		percapname: "SD_KpiguiGhgEmissionPEMTInclMPerCapita",
		description: "Greenhouse gas emissions",
		subdesc: "(incl. fuel-driven transport)"
	}
]


export class KPI extends Component
{
	static contextType = AppController;
	constructor( props )
	{
		super( props )
		this.state 				= {}

		this.inflight 			= false
	}

	fetch_expression()
	{

		const component 	= this
		const province 		= this.get_province()
		const {kind}		= this.props
		const keys = kind == 'qd' ? "QD_KPIGUI*" : "SD_KPIGUI*,SD_SCENRESHARE"

		const url_params 	= this.get_backend_query()

		const controller	= kind == 'qd' ? 'qdata' : 'sdata'
		const year_key      = this.context ? this.context.year_key : 'NONE'
		const uri 			= `${backend_config.server}/${controller}/query?api_key=${API_KEY}&year_key=${year_key}`
		const url 			= `${uri}${url_params}&key=${keys}&province=${province}`

		if (this.display_url === url)
			return

		this.inflight 	= true
		console.log( url )
		axios.get(url)
		 .then( (incoming) =>
		{
			this.display_url 		= url
			this.inflight 			= false
			const {data}			= incoming
			if (data.status === 'error' )
			{
				component.setState( {error: true, expressions: null} )
			}
			else
			{
				component.setState( {error: false, expressions: data.result.value, units: data.result.units } )
			}
		})
	}


	componentDidMount()
	{
		this.fetch_expression()
	}


	componentDidUpdate()
	{
		this.fetch_expression()
	}





	line_future( id, title, subtitle, value_st, value_pc, unit, orig )
	{
		return 	<tr key={id}>
		<td className='kpi-title' >{title}{subtitle && <div>{subtitle}</div>}</td>
					<td className='kpi-value' >{value_st}</td>
					<td className='kpi-value' >{value_pc}</td>
					<td className='kpi-separator'>{unit}</td>
				</tr>
	}


	line_present( id, title, subtitle, value_st, value_pc, value_nst, value_npc, unit_p, unit_n, orig )
	{
		
		return 	<tr key={id}>
					<td className='kpi-title'>{title}{subtitle && <div>{subtitle}</div>}</td>
					<td className='kpi-value'>{value_st}</td>
					<td className='kpi-value'>{value_pc}</td>
					<td className='kpi-unit' >{unit_p}</td>
					<td className='kpi-value'>{value_nst}</td>
					<td className='kpi-value'>{value_npc}</td>
					<td className='kpi-unit' >{unit_n}</td>
				</tr>
	}



	val(entry, cap)
	{
		if (!entry)
			return null

		if (entry.kind === 'scalar')
		{
			let v = entry.value
			if (v === null || v === undefined)
				return null
			if (entry.unit === '%' && v < 0)
				v = 0

			if (entry.unit == '1')
				return Math.round(v)
			if (cap)
				return v.toFixed(5)
			else
				return v.toFixed(2)
		}
		return null
	}


	unit(entry)
	{
		if (!entry)
			return null
		else if (entry.kind === 'scalar')
			return entry.unit
		else
			return null
	}


	render_entry( i18n, entry, is_present )
	{
		const st_id 		= entry.varname 		? entry.varname.trim().toUpperCase()		: null
		const pc_id 		= entry.percapname 		? entry.percapname.trim().toUpperCase() 	: null
		const nst_id 		= entry.natvarname 		? entry.natvarname.trim().toUpperCase()		: null
		const npc_id 		= entry.natpercapname 	? entry.natpercapname.trim().toUpperCase() 	: null

		const entry_st 	= st_id ? 	this.state.expressions[st_id] : null
		const entry_pc 	= pc_id ? 	this.state.expressions[pc_id] : null

		const entry_nst = nst_id ? 	this.state.expressions[nst_id] : null
		const entry_npc = npc_id ? 	this.state.expressions[npc_id] : null


		console.log(entry.varname, entry_nst )

		if (is_present && (entry_st || entry_pc || entry_nst || entry_npc))
		{
				return this.line_present(entry.varname,
											i18n('KPI', entry.varname),
											entry.subdesc ? i18n('KPI', `SUB_${entry.varname}`) : '',
											this.val(entry_st, false),
											this.val(entry_pc, true),
											this.val(entry_nst, false),
											this.val(entry_npc, true ),
											i18n(entry.unit || this.unit(entry_st)),
											i18n(entry.unit || this.unit(entry_nst)),
											entry.orig)
		}

		if (!is_present && (entry_st || entry_pc))
		{
				return this.line_future(entry.varname,
											i18n('KPI', entry.varname),
											entry.subdesc ?i18n('KPI', `SUB_${entry.varname}`) : '',
											this.val(entry_st, false),
											this.val(entry_pc, true),
											i18n(entry.unit || this.unit(entry_st)),
											entry.orig)
		}
		else
				return null
	}

	render_kpi(i18n)
	{
		const is_present 	= this.props.kind == 'qd'
		const schema 		= is_present ? present : future


		return (<table className='kpi-table'>
					<thead>
						<tr key='headrow1'>
							<th className='kpi-head-title'></th>
							<th colSpan={2} className='kpi-head-title center'>{i18n("KPI:HEADER_PROVINCE")}</th>
							<th className='kpi-head-title'></th>

					{is_present && <th colSpan={2} className='kpi-head-title center'>{i18n("KPI:HEADER_NATIONAL")}</th>}
							{is_present && <th className='kpi-head-title'></th>}
						</tr>
						<tr key='headrow2'>
							<th className='kpi-head-subtitle'></th>
							<th className='kpi-head-subtitle'>{i18n('KPI', 'SD_VALUE')}</th>
							<th className='kpi-head-subtitle'>{i18n('KPI', 'SD_PERCAP')}</th>
							<th className='kpi-head-subtitle'></th>

							{is_present && <th className='kpi-head-subtitle'>{i18n('KPI', 'SD_VALUE')}</th>}
							{is_present && <th className='kpi-head-subtitle'>{i18n('KPI', 'SD_PERCAP') }</th>}
							{is_present && <th className='kpi-head-subtitle'></th>}
						</tr>
					</thead>
					<tbody>
					{
						schema.map((x) => this.render_entry(i18n,x, is_present ))
					}
					</tbody>
				</table>
			   )
	}



						// {this.line("Number of Inhabitants", this.val('QD_POPNO'), 'No')}
						// {this.line("Provincial area", this.val('QD_AREATOTAL')/1000000, 'Mio. m²')}
						// {this.line('Gross Provincial Product', this.val('QD_GPP')/this.val('EDA_ConvThbToEur')/1000,'Mio. THB')}
						// {this.line('Final energy demand',	this.val('QD_KPIENCONSTOTAL'),	'MWh/a')}
						// {this.line('Share of electricity demand of final energy demand',
											// this.val('D_KpiEnShareElec')*100,'%')}
						// {this.line('Share of residential energy demand on final energy demand',			this.val('QD_KpiEnShareRes')*100,'%')}
						// {this.line('Share of agricultural energy demand on final energy demand',			this.val('QD_KpiEnShareAgr')*100,'%')}
						// {this.line('Share of industrial energy demand on final energy demand',			this.val('QD_KpiEnShareInd')*100,'%')}
						// {this.line('Share of commercial energy demand on final energy demand',			this.val('QD_KpiEnShareCom')*100,'%')}
						// {this.line('Share of transportational energy demand on final energy demand',			this.val('QD_KpiEnShareTra')*100,'%')}
						// {this.line('Share of other sectors energy demand on final energy demand',			this.val('QD_KpiEnShareOth')*100,'%')}



	render_error()
	{
		if (!this.context || !this.context.provinces)
			return null
		const scenarios = this.context.provinces[this.get_province().toUpperCase()].scenarios

		if (!scenarios || scenarios.length == 0)
			return 	<div className='warning section'>No Scenarios have been calculated</div>
		else
			return 	<div className='warning section'>Data for scenario {this.get_scenario()} not available</div>
	}

	render()
	{
		if (this.goto())
			return <div/>

		const {history,match}	= this.props
		const expressions		= this.state.expressions
		const is_present 		= this.props.kind == 'qd'

		const province_data		= this.get_province_data()
		if (!province_data)
			return <div/>

		if (!this.context || !this.context.ctx.i18nstr)
			return <div />

		const i18n = this.context.ctx.i18nstr

		const tgt_year = this.context && this.context.year_refs ? `${this.context.year_refs.target_year}` : ''
		const ref_year = this.context && this.context.year_refs ? `${this.context.year_refs.reference_year}` : ''

		return 	<MasterFrame parent={this} history={history} match={match} province={province_data.key}>
					<div className='sankey-main'>
						<div className='sankey-menu'>
						{
							!is_present &&
							this.variant_selector()
						}
						{
							!is_present &&
							this.scenario_selector()
						}
						{
							!this.state.error &&
							this.unit_selector()
						}
						{
							this.state.error &&
							this.render_error()
						}

						<Help id={`kpi-${is_present ? 'current' : 'future'}`}/>
						</div>
						{is_present &&
							<div className='helpblock'>
								<div className='title'>
									<span>{i18n("KPI:QD_HEADLINE1")}</span>
									&nbsp;
									<span>{i18n("PROVINCE", this.get_province())}</span>
									&nbsp;
									<span>{i18n("KPI:QD_HEADLINE2")}</span>
									&nbsp;
									<span>{ref_year}</span>
								</div>
								<div className='text'>{i18n("KPI:QD_HELPTEXT")}</div>
								<div className='text'>{i18n("TXT:INPUTDATA")}</div>
							</div>
						}
						{!is_present &&
							<div className='helpblock'>
								<div className='title'>
									<span>{i18n("KPI:SD_HEADLINE:LINE1")}</span>
									&nbsp;
									<span>{i18n("PROVINCE", this.get_province())}</span>
									&nbsp;
									<span>{i18n("KPI:SD_HEADLINE:LINE2")}</span>
									&nbsp;
									<span>{tgt_year}</span>
								</div>
								<div className='text'>{i18n("KPI:SD_HELPTEXT:LINE1")}</div>
								<div className='text'>{i18n("KPI:SD_HELPTEXT:LINE2")}</div>
								<div className='text'>{i18n("TXT:INPUTDATA")}</div>
							</div>
						}
						<div className='sankey-container'>
							<div className={classnames("bottom")} style={{backgroundColor:"white"}}>
							{
								expressions &&
								<div className='kpi-container'>
								{this.render_kpi(i18n)}
								</div>
							}
							</div>
						</div>
					</div>
				</MasterFrame>
	}
}

// Add the Navigator to the KPI Component
Object.assign( KPI.prototype, Navigation)


export default KPI
