
import React, { Component, Fragment, 
					useContext } 		from "react";
import classnames       				from 'classnames';
import MasterFrame						from '../app/MasterFrame'
import {AppController} 					from '../app/AppController'
import backend_config					from "../config/runconfig.js"
import axios 							from 'axios'
import { Navigation } 					from '../navigation/navigation'

import {Help} 							from '../help/Help'


import {Form, TextBox, CheckBox, SubmitButton, ActionButton,
		HideShow, Select, Error}					from '../form/form'


const API_KEY = 'ExgWFH9XHHyXIHyKEHIH'





export const ElecExchShow = ({parent, data,sets,selected, province, can_operate}) =>
{


	const context = useContext( AppController )
	if (!context || !context.ctx.i18nstrp)
		return <div />

	const i18 = context.ctx.i18nstrp("EE")


	const set_selected = (event) =>
	{
		const value	= event.target.value
		parent.goto({ee_set_id: value} )
	}

	const render_set_selector = () => <select value={selected} onChange={set_selected}>
				{sets.map( e => <option key={e.id} value={e.id}>{e.name}</option>)}
			</select>


	return 	<div className='form'>
				<div className='formdata'>

					<div className='line'>
						<div className='title'>{i18("PSET")}</div>
						<div className='control'>
							{can_operate  && render_set_selector()}
							{!can_operate && <div>{sets.find( x => x.id == selected).name}</div>}
						</div>
					</div>

					<div className='line'>
						<div className='title'>{i18("DET")}</div>
						<div className='control'>
							{data.constraint == 'constrained' ? i18("CONSTRAINED") : i18("UNCONSTRAINED") }
						</div>
					</div>


					<div className='line'>
						<div className='title'>{i18("MAXPOWER")}</div>
						<div className='control'>
							{data.max_power !== undefined && data.max_power !== null &&
								<span>{data.max_power} {i18("MW")}</span>}
							{(data.max_power === undefined || data.max_power === null) &&
								<span><i>{i18("UNCONSTRAINED")}</i></span>}

						</div>
					</div>


					<div>&nbsp;</div>

					<div className='line'>
						<div className='title'></div>
						<div className='control title'>
							{i18("MIN")}
						</div>
						<div className='control title'>
							{i18("MAX")}
						</div>
						<div className='errors'>
						</div>
					</div>

					<div className='line'>
						<div className='title'>{i18("ANNUALIMPORT")} </div>
						<div className='control'>
							{ data.min_import !== undefined && data.min_import !== null  &&
								<span>{data.min_import} {i18("MWh")}</span>}
							{(data.min_import === undefined || data.min_import === null) &&
								<span><i>{i18("UNCONSTRAINED")}</i></span>}

						</div>
						<div className='control'>
							{ data.max_import !== undefined && data.max_import !== null  &&
								<span>{data.max_import} {i18("MWh")}</span>}
							{(data.max_import === undefined || data.max_import === null) &&
								<span><i>{i18("UNCONSTRAINED")}</i></span>}

						</div>
					</div>


					<div className='line'>
						<div className='title'>{i18("ANNUALEXPORT")} </div>
						<div className='control'>
							{ data.min_export !== undefined && data.min_export !== null  &&
								<span>{data.min_export} {i18("MWh")}</span>}
							{(data.min_export === undefined || data.min_export === null) &&
								<span><i>{i18("UNCONSTRAINED")}</i></span>}

						</div>
						<div className='control'>
							{ data.max_export !== undefined && data.max_export !== null  &&
								<span>{data.max_export} {i18("MWh")}</span>}
							{(data.max_export === undefined || data.max_export === null) &&
								<span><i>{i18("UNCONSTRAINED")}</i></span>}

						</div>
					</div>
				</div>
			</div>
}

Object.assign(ElecExchShow.prototype, Navigation)

export class ElecExchEdit  extends Component
{

	static contextType = AppController;

	constructor(props)
	{
		super( props )

		const default_data = {
			name 		: "",
			constraint 	: "unconstrained",
			max_power 	: null,
			min_import	: null,
			max_import	: null,
			min_export	: null,
			max_export	: null
		}

		this.form_data = this.props.data || default_data
		this.form_errors	= {}
	}


	onSubmit = (event, data) =>
	{
		data.id = this.props.id
		this.props.onSubmit(event,data)
	}


	onCancel = () =>
	{
		this.props.history.push(`/scenario/${this.props.match.params.province}/predefined`)
	}


	formErrors( form, data, raw_data, errors )
	{
		if (data.constraint == 'constrained')
		{
			if (data.min_import != null && data.min_import != undefined &&
				data.max_import != null && data.max_import != undefined &&
				data.min_import > data.max_import)
			{
				form.addError( errors, "min_import", "Min Import must be < Max Import")
			}

			if (data.min_export != null && data.min_export != undefined &&
				data.max_export != null && data.max_export != undefined &&
				data.min_export > data.max_import)
			{
				form.addError( errors, "min_export", "Min Export must be < Max Export")
			}		}
	}

	render()
	{
		if (!this.context || !this.context.ctx.i18nstrp)
			return <div />

		const i18 = this.context.ctx.i18nstrp("EE")

		return 	<div className='form'>
					<Form
						data 		= {this.form_data}
						errors 		= {this.form_errors}
						verify 		= {this.formErrors}
						onSubmit 	= {this.onSubmit}>
							<div>{i18("EE:TEXT")}</div>
						<div className='formdata'>


							<div className='errors'>
								<Error 		names='form'/>
							</div>


							<div className='line'>
								<div className='title'>{i18("NAME")}</div>
								<div className='control wide' colSpan="3">
									<TextBox 	name		= 'name'
												required 	= {true}/>
								</div>
								<div className='errors'>
									<Error 	names	= 'constraint' />
								</div>
							</div>

							<div>&nbsp;</div>

							<div className='line'>
								<div className='title'>{i18("DET")}</div>
								<div className='control'>
									{i18("EE:CONSTRAINED")}
								</div>
								<div className='control'>
								</div>
								<div className='errors'>
									<Error 	names	= 'constraint' />
								</div>
							</div>

							<div>&nbsp;</div>

								<div className='line'>
									<div className='title'>{i18("MAXPOWER")}</div>
									<div className='control'>
										<TextBox 	name		= 'max_power'
													type 		= 'numeric'
													min_value={0} /> {i18("MW")}
									</div>
									<div className='errors'>
										<Error 		names		= 'max_power' />
									</div>
								</div>

								<div>&nbsp;</div>

								<div className='line'>
									<div className='title'></div>
									<div className='control title'>
										{i18("MIN")}
									</div>
									<div className='control title'>
										{i18("MAX")}
									</div>
									<div className='errors'>
									</div>
								</div>

								<div className='line'>
									<div className='title'>{i18("ANNUALIMPORT")} </div>
									<div className='control'>
										<TextBox 	name		= 'min_import'
													type 		= 'numeric'
													min_value 	= {0} />
									</div>
									<div className='control'>
										<TextBox 	name		= 'max_import'
													type 		= 'numeric'
													min_value 	= {0} /> {i18("MWh")}
									</div>
									<div className='errors'>
										<Error 		names		= 'min_import, max_import' />
									</div>
								</div>


								<div className='line'>
									<div className='title'>{i18("ANNUALEXPORT")} </div>
									<div className='control'>
										<TextBox 	name		= 'min_export'
													type 		= 'numeric'
													min_value 	= {0} />
									</div>

									<div className='control'>
										<TextBox 	name		= 'max_export'
													type 		= 'numeric'
													min_value 	= {0} /> {i18("MWh")}
									</div>
									<div className='errors'>
										<Error 		names		= 'min_import, max_export' />
									</div>
								</div>

						</div>

						<div className='form-controls'>
			    			<SubmitButton value={i18("TXT:SAVE")}/>
							{<ActionButton className='action' onClick={this.onCancel} value={i18("TXT:CANCEL")}/>}
			    		</div>

					</Form>
				</div>
	}

}



class EESetFrame extends Component
{
	static contextType = AppController;

	constructor( props )
	{
		super( props )
		this.inflight 	= false
		this.state 		= {timestamp: Date.now()}
	}


	onSubmit = (event, data) =>
	{
		const {match,history} 			= this.props
		const {province, ee_set_id}		= match.params


  		const url = `${backend_config.server}/elec-exchange/save/${province}/${ee_set_id}`


		axios.post( url, {parameters: data} ).then( incoming =>
			{
				console.log( "RES ", incoming.data.result )
				this.new_page(`/scenario/${this.props.match.params.province}/predefined`,  
						{ee_set_id: incoming.data?.result?.ee_set_id || ee_set_id})
			})
	}





	render()
	{
		if (!this.context || !this.context.ctx.i18nstrp)
			return <div />

		const i18 = this.context.ctx.i18nstrp("EE")

		const {match,history} 			= this.props
		const {province, ee_set_id}		= match.params

		const provinces 		= this.context.provinces
		if (!provinces)
			return <div></div>

		const province_key  	= province.toUpperCase()
		const province_data		= provinces[province_key]
		if (!province_data)
			return <div>Province not found {province}</div>

		const sets 			= province_data.ee_sets || []

		let ee_data 		= sets ? sets.find( x => x.id === ee_set_id ) : null
		if (!ee_data)
			ee_data			=  {name:"", constraint: 'constrained'}


		return  <MasterFrame province={this.props.match.params.province} history={history} match={match}>
					<div className={classnames("bottom")}>
						<div className='sankey-main'>
							<div className='sankey-menu'>
								<div className='section'>&nbsp;</div>
								<Help id='eexchange'/>
							</div>
							<div className='sankey-container'>

							<div className='inputform ee'>
								<div className='head'>
									<div className='title'>{i18("TITLE")}</div>
								</div>
								<div className='contents'>
									<ElecExchEdit data={ee_data}
												  id={ee_set_id}
												  province={province}
												  history={history} match={match}
												  onSubmit={this.onSubmit} />
								</div>
							</div>

							</div>
						</div>
					</div>
				</MasterFrame>
	}


}
Object.assign(EESetFrame.prototype, Navigation)


export default EESetFrame
