export function blank( s )
{
  if (s === null || s === undefined || s === false)
    return true;
  if (`${s}`.trim().length == 0)
    return true;
  return false;
}

export function null_or_undef( x )
{
  if (x === null || x === undefined)
    return true;
  return false;
}

export function default_value( x, def )
{
  if (x === null || x === undefined)
    return def;
  return x;
}

// Maps a hash
export function visit(hash, fn )
{
	if (!hash || !fn)
		return
	for( const k of Object.keys(hash) )
		fn( k, hash[k])
}


export function visit_dir(hash, forward, fn )
{
	if (!hash || !fn)
		return
	let keys = Object.keys(hash)
	if (!forward)
		keys = keys.reverse()
	for( const k of keys )
		fn( k, hash[k])
}


// Uppercase-trimmed or null if blank of list, stripping nulls
export function make_list( list, def = null )
{
	if (list && !Array.isArray(list))
		return make_list( `${list}`.split(",") )
	if (list === null || list === undefined)
		return def
	const rv = list.map( (x) => trimmed_or_blank(x) )
				   .filter( (x) => x !== null )
	if (rv.length === 0)
		return def
	return rv
}


export function expand_string_from_data( string, data )
{
	return string.replace( /\{((\w|\.)*)\}/g,  (match) => {
		let index = match.substring(1).substring( 0,match.length - 2 )
		var value = data;
		for( let el of index.split("."))
		{
			value = value[el];
			if (!value)
				return match;
		}
		return value;
	} )
}


export function string_default( x, def )
{
	if (x === null || x === undefined)
		return def;

	const n = `${x}`.trim();
	if (n.length === 0)
		return def;
	return n;
}


export function trimmed_or_blank(x)
{
	return string_default( x, null);
}

export function always_to_string(x, default_value = '')
{
	if (x === null)
		return default_value
	if (x === undefined)
		return default_value
	return `${x}`
}



export function format_val2( n, def_if_null  = '')
{
	if (n === null || n === undefined)
		return def_if_null

	const abs 		= Math.abs( n )
	const scale 	= Math.floor(Math.log( abs )/Math.log( 10 ))
	let   fix
	if (scale < 0)
		fix = (-scale + 2)
	else if (scale < 4)
		fix = 3
	else if (scale < 5)
		fix = 2
	else if (scale < 6)
		fix = 2
	else
		fix = 0

	const minus = n < 0 ? '-' : ''
	return  `${minus}${abs.toFixed( 0 )}`
}




export function fmt(x, def_if_null  = '', maximum_frac = 1000, minimum_frac = 0, debug = false)
{
	if (x === null || x === undefined)
		return def_if_null
	if (typeof x !== 'number')
		return x

	let exp

	if (x > 0)
		exp  = Math.floor(Math.log10(x))
	else if (x < 0)
		exp  = Math.floor(Math.log10(-x))
	else
		exp  = 0
	let maxfd = 5 - exp

	//console.log( exp, maxfd, maxfd + exp)

//	if (exp >= 6)
//		maxfd = 0
//	if (maxfd > 8)
//		maxfd = 8
	if (maxfd < 0)
		maxfd = 0

	if (maxfd > maximum_frac)
		maxfd = maximum_frac

	let minfd 	= exp < 0 ? exp + 1 : exp - 7
	if (minfd > minimum_frac)
		minfd = minimum_frac
	if (minfd > maxfd)
		minfd   = maxfd
	if (minfd < 0)
		minfd   = 0


	try
	{
		let result
		if (maxfd == 0)
			result = Math.floor(x).toLocaleString('en-US', {minimumFractionDigits: 0, maximumFractionDigits: maxfd})
		else
			result = x.toLocaleString('en-US', {minimumFractionDigits: minfd, maximumFractionDigits: maxfd})

		if (debug)
			console.log( 'F ', x, "E", exp, maximum_frac, minimum_frac, maxfd, minfd, '--> ', result )

		return result
	}
	catch(e)
	{
		console.log( e )
		console.log( 'NFormat Err ', x, typeof(x), isNaN(x), maximum_frac, minimum_frac, exp, maxfd, minfd)
		return '-'
	}

}

// Format or null, returns a null on null input as opposed to a blank string
export function fmtn( x )
{
	return fmt( x, null )
}

export function format_val(x, def_if_null  = '', maximum_frac = 1000, minimum_frac = 0)
{
	return fmt( x, def_if_null, maximum_frac, minimum_frac )
}




