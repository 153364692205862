import React, { Component, Fragment } from "react";
import classnames from 'classnames';
import backend_config from "../config/runconfig"
import axios from 'axios'

import { AppController } from '../app/AppController'
import MasterFrame from '../app/MasterFrame'
import BarChartSet from './BarChartSet'
import LineChartSet from './LineChartSet'
import { Navigation } from '../navigation/navigation'

import { Help } from '../help/Help'

const API_KEY = 'ExgWFH9XHHyXIHyKEHIH'


const SDVar = (props) => {
	const { data, unit, scale, precision } = props
	const id = `${props.id}`.toUpperCase()
	if (!data || !data[id] || data[id].value === undefined)
		return <span />
	if (data[id].kind == 'scalar') {
		const val = data[id].value
		const display = scale ? val * scale : val
		return <span>{display.toFixed(precision || 1)} {unit}</span>
	}
	else
		return <span>{data[id].value} {unit}</span>
}

const SDDiff = (props) => {
	const { data, unit, scale, precision } = props
	const id1 = `${props.id1}`.toUpperCase()
	const id2 = `${props.id2}`.toUpperCase()
	if (!data || !data[id1] || data[id1].value === undefined
		|| !data[id2] || data[id2].value === undefined)
		return <span />
	if (data[id1].kind == 'scalar' && data[id2].kind == 'scalar') {
		const val = data[id2].value - data[id1].value
		const display = scale ? val * scale : val
		const disp = precision == 0 ? Math.floor(display) : display.toFixed(precision || 1)
		return <span>{disp} {unit}</span>
	}
	else
		return <span>{data[id1].value} {unit}</span>
}


const SDPercent = (props) => {
	const { data, unit, scale, precision, factor } = props
	const id = `${props.id}`.toUpperCase()
	//console.log( id, data[id] )
	if (!data || !data[id] || data[id].value === undefined)
		return <span></span>
	if (data[id].kind == 'scalar')
		return <span>{((factor || 100) * (data[id].value - 1.0)).toFixed(precision || 3)} {unit}</span>
	else
		return <span></span>
}



export class DemandDevelopment extends Component {
	static contextType = AppController;
	constructor(props) {
		super(props)
		this.state = {}
	}


	get_province = () => this.props.match.params.province


	fetch_expression() {
		if (this.inflight)
			return

		const component = this
		const province = this.get_province()
		const url_params = this.get_backend_query()
		this.province_displayed = province

		this.data_model = null
		const root = `${backend_config.server}/sdata/demandchartdata`

		const year_key = this.context ? this.context.year_key : 'NONE'
		const url = `${root}?${url_params}&province=${province}&api_key=${API_KEY}&year-key-${year_key}`

		if (url === this.displayed_url)
			return
		console.log(url)

		this.inflight = true
		axios.get(url)
			.then((incoming) => {

				this.inflight = false
				this.displayed_url = url

				const { data } = incoming

				if (data.status === 'error') {
					component.setState({ error: true, errorMsg: data.error, chart_data: null })
				}
				else if (!data.result.chart_data || Object.keys(data.result.chart_data).length == 0) {
					component.setState({ error: true, errorMsg: "No Data", chart_data: null })
				}
				else {
					const new_state = {}
					new_state.error = false
					new_state.errorMsg = null
					new_state.chart_data = data.result.chart_data
					new_state.units = data.result.units
					new_state.is_head_data = data.result.is_head_data
					component.setState(new_state)
				}
			})
	}







	chart_labels(i18) {
		return {
			BAU: {
				label: "BAU",
				description: "BAU",
				color: '#58e'
			},
			EE: {
				label: "EE",
				description: "EE",
				color: '#d55'
			},
			EEPLUS: {
				label: "EE+",
				description: "EE+",
				color: '#6d9'
			},

			RESTBAU: {
				label: "BAU",
				description: "BAU",
				color: '#8af'
			},
			RESTEE: {
				label: "EE",
				description: "EE",
				color: '#fbb'
			},
			RESTEEPLUS: {
				label: "EE+",
				description: "EE+",
				color: '#AFC'
			},

			'EE+': {
				label: "EE+",
				description: "EE+",
				color: '#6d9'
			},


			SEPOP: {
				label: i18('SOC:POPULATION'),
				description: i18('SOC:POPULATION'),
				color: '#4ad'
			},

			SEGPP: {
				label: `${i18('SOC:GPP')} ${i18(this.get_currency())}`,
				description: `${i18('SOC:GPP')} ${i18(this.get_currency())}`,
				color: '#d84'
			}

		}
	}




	single_group(set, id, label, scale = 1) {
		const value = set[id]
		if (!value || !value.kind || value.kind != 'scalar')
			return []

		let v = value.value
		return [{ data: [{ id: label || id, value: v * scale }] }]
	}




	setup_socio_economic(i18, data_model, data) {
		const result = {
			QD_POPNO: 	{ chart: 'Pop', label: `${data_model.ref_year}`, data: this.single_group(data, 'QD_POPNO', 'SEPOP') },
			SD_POPNO: 	{ chart: 'Pop', label: `${data_model.tgt_year}`, data: this.single_group(data, 'SD_POPNO', 'SEPOP') },
			QD_GPP:	 	{ chart: 'GPP', label: `${data_model.ref_year}`, data: this.single_group(data, 'QD_GPP', 'SEGPP') },
			SD_GPP: 	{ chart: 'GPP', label: `${data_model.tgt_year}`, data: this.single_group(data, 'SD_GPP', 'SEGPP') }
		}

		const key_list = ['QD_POPNO', 'SD_POPNO', 'QD_GPP', 'SD_GPP']

		const rv = {
			data: result,
			set_keys: key_list,
			draw_legend: false,
			headers: {
				Pop: i18('SOC:POPULATION'),
				GPP: `${i18('SOC:GPP')} (${i18(this.get_currency())})`
			}
		}

		return rv
	}


	setup_electric_share(i18, data) {
		const result = {
			SD_PROJEXTRASELEC_BAU: {
				label: 'BAU',
				data: this.single_group(data, 'SD_PROJEXTRASELEC:BAU', 'BAU', 100)
			},
			SD_PROJEXTRASELEC_EE: {
				label: 'EE',
				data: this.single_group(data, 'SD_PROJEXTRASELEC:EE', 'EE', 100)
			},
			SD_PROJEXTRASELEC_EEPLUS: {
				label: 'EE+',
				data: this.single_group(data, 'SD_PROJEXTRASELEC:EEPLUS', 'EEPLUS', 100)
			},
		}

		const key_list = ['SD_PROJEXTRASELEC_BAU', 'SD_PROJEXTRASELEC_EE', 'SD_PROJEXTRASELEC_EEPLUS']

		const rv = {
			data: result,
			set_keys: key_list,
			draw_legend: false,
			header: i18('DET:TITLE')
		}

		return rv
	}






	ri_interpolate(year0, year1, x0, x1) {
		if (year0 >= year1 || x1 <= 0 || x0 <= 0)
			return null

		const a = x0
		const b = x1 / x0

		const result = []
		for (let year = year0; year <= year1; year++) {
			const x = (year - year0) / (year1 - year0)
			const y = a * b ** x

			result.push({ id: year, value: y })
		}

		return result
	}



	create_ri_dataset(data, yr, yt, vr, vt) {
		const entry_r = data[vr]
		if (!entry_r || !entry_r.value || entry_r.kind != 'scalar') {
			console.log("Entry for " + vr + " not found ")
			return null
		}

		const entry_t = data[vt]
		if (!entry_t || !entry_t.value || entry_t.kind != 'scalar') {
			console.log("Entry for " + vt + " not found ")
			return null
		}

		const value_r = entry_r.value
		const value_t = entry_t.value

		return this.ri_interpolate(yr, yt, value_r, value_t)
	}




	setup_ri_data_curve1(i18, data_model, data) {
		const year_0 = data_model.ref_year
		const year_1 = data_model.tgt_year
		const result = {
			BAU_ED: {
				chart_type: 'line', chart: 'ED', label: 'BAU',
				data: this.create_ri_dataset(data, year_0, year_1,
					'QD_DEMDEVEPLOTSUPP1', 'SD_FINALE:BAU')
			},
			EE_ED: {
				chart_type: 'line', chart: 'ED', label: 'EE',
				data: this.create_ri_dataset(data, year_0, year_1,
					'QD_DEMDEVEPLOTSUPP1', 'SD_FINALE:EE')
			},
			EEPLUS_ED: {
				chart_type: 'line', chart: 'ED', label: 'EE+',
				data: this.create_ri_dataset(data, year_0, year_1,
					'QD_DEMDEVEPLOTSUPP1', 'SD_FINALE:EEPLUS')
			},

			BAU_HD: {
				chart_type: 'line', chart: 'HD', label: 'BAU',
				data: this.create_ri_dataset(data, year_0, year_1,
					'QD_FINALHTOTHO', 'SD_FINALH:BAU')
			},
			EE_HD: {
				chart_type: 'line', chart: 'HD', label: 'EE',
				data: this.create_ri_dataset(data, year_0, year_1,
					'QD_FINALHTOTHO', 'SD_FINALH:EE')
			},
			EEPLUS_HD: {
				chart_type: 'line', chart: 'HD', label: 'EE+',
				data: this.create_ri_dataset(data, year_0, year_1,
					'QD_FINALHTOTHO', 'SD_FINALH:EEPLUS')
			},
		}

		const key_list = ['BAU_ED', 'EE_ED', 'EEPLUS_ED', 'BAU_HD', 'EE_HD', 'EEPLUS_HD']

		const rv = {
			data: result,
			set_keys: key_list,
			min_x: year_0,
			max_x: year_1,

			headers: {
				ED: i18('RID:EDM'),
				HD: i18('RID:HDM')
			},

			draw_legend: false
		}

		return rv
	}


	setup_ri_data_curve2(i18, data_model, data) {
		const year_0 = data_model.ref_year
		const year_1 = data_model.tgt_year
		const result = {
			BAU_ED: {
				chart_type: 'line', chart: 'ED', label: 'BAU',
				data: this.create_ri_dataset(data, year_0, year_1,
					'QD_FINALMTOTXI', 'SD_FINALM:BAU')
			},

			EE_ED: {
				chart_type: 'line', chart: 'ED', label: 'EE',
				data: this.create_ri_dataset(data, year_0, year_1,
					'QD_FINALMTOTXI', 'SD_FINALM:EE')
			},

			EEPLUS_ED: {
				chart_type: 'line', chart: 'ED', label: 'EE+',
				data: this.create_ri_dataset(data, year_0, year_1,
					'QD_FINALMTOTXI', 'SD_FINALM:EEPLUS')
			},


			BAU_HD: {
				chart_type: 'line', chart: 'HD', label: 'BAU',
				data: this.create_ri_dataset(data, year_0, year_1,
					'QD_FINALNTOTXI', 'SD_FINALN:BAU')
			},

			EE_HD: {
				chart_type: 'line', chart: 'HD', label: 'EE',
				data: this.create_ri_dataset(data, year_0, year_1,
					'QD_FINALNTOTXI', 'SD_FINALN:EE')
			},

			EEPLUS_HD: {
				chart_type: 'line', chart: 'HD', label: 'EE+',
				data: this.create_ri_dataset(data, year_0, year_1,
					'QD_FINALNTOTXI', 'SD_FINALN:EEPLUS')
			},

		}

		const key_list = ['BAU_ED', 'EE_ED', 'EEPLUS_ED', 'BAU_HD', 'EE_HD', 'EEPLUS_HD']

		const rv = {
			data: result,
			set_keys: key_list,
			min_x: year_0,
			max_x: year_1,



			headers: {
				ED: i18('RID:MDM'),
				HD: i18('RID:NDM')
			},

			draw_legend: false
		}

		return rv
	}


	setup_ri_data_curve3(i18, data_model, data) {
		const year_0 = data_model.ref_year
		const year_1 = data_model.tgt_year

		const result = {
			BAU_ED: {
				chart_type: 'line', chart: 'ED', label: 'BAU',
				data: this.create_ri_dataset(data, year_0, year_1,
					'QD_FINALENERGY', 'SD_FINALENERGY:BAU')
			},

			EE_ED: {
				chart_type: 'line', chart: 'ED', label: 'EE',
				data: this.create_ri_dataset(data, year_0, year_1,
					'QD_FINALENERGY', 'SD_FINALENERGY:EE')
			},

			EEPLUS_ED: {
				chart_type: 'line', chart: 'ED', label: 'EE+',
				data: this.create_ri_dataset(data, year_0, year_1,
					'QD_FINALENERGY', 'SD_FINALENERGY:EEPLUS')
			},


			BAU_HD: {
				chart_type: 'line', chart: 'HD', label: 'BAU',
				data: this.create_ri_dataset(data, year_0, year_1,
					'QD_FINALX', 'SD_FINALX:BAU')
			},

			EE_HD: {
				chart_type: 'line', chart: 'HD', label: 'EE',
				data: this.create_ri_dataset(data, year_0, year_1,
					'QD_FINALX', 'SD_FINALX:EE')
			},

			EEPLUS_HD: {
				chart_type: 'line', chart: 'HD', label: 'EE+',
				data: this.create_ri_dataset(data, year_0, year_1,
					'QD_FINALX', 'SD_FINALX:EEPLUS')
			},

		}

		const key_list = ['BAU_ED', 'EE_ED', 'EEPLUS_ED', 'BAU_HD', 'EE_HD', 'EEPLUS_HD']

		const rv = {
			data: result,
			set_keys: key_list,
			min_x: year_0,
			max_x: year_1,



			headers: {
				ED: i18('RID:TED'),
				HD: i18('RID:FED')
			},

			footers: {
				ED: i18('RID:TED:TEXT'),
				HD: i18('RID:FED:TEXT')
			},
			draw_legend: false
		}

		return rv
	}



	add_to_eid_list1(i18, set, chart, group) {
		const chart_data = []
		const vars = ['RES', 'AGR', 'IND', 'COM', 'OTH']
		const diffgroup = `REST${group}`

		vars.forEach(varname => {
			const sd_varname = `SD_PROJF${chart}${varname}:${group}`
			const value = set[sd_varname]
			if (!value || !value.kind || value.kind != 'scalar') {
				chart_data.push({ label: i18(`CHART:DEMDEV:GRAPH:SECTOR:${varname}`), data: [] })
			}
			else {
				let v1 = value.value * 100
				let v2 = 100 - v1

				chart_data.push({ label: i18(`CHART:DEMDEV:GRAPH:SECTOR:${varname}`),
					data: [{ id: group, value: v1 }, { id: diffgroup, value: v2 }] })
			}
		})
		return chart_data
	}


	setup_eid_data1(i18, data) {

		const c_data = {}

		const groups = ['BAU', 'EE', 'EEPLUS']
		const charts = ['ED', 'HD']
		charts.forEach(chart => {
			c_data[chart] = {}
			groups.forEach(group => c_data[chart][group] = this.add_to_eid_list1(i18, data, chart, group))
		})

		const result = {
			BAU_ED: { chart: 'ED', label: 'BAU', data: c_data.ED.BAU },
			EE_ED: { chart: 'ED', label: 'EE', data: c_data.ED.EE },
			EEPLUS_ED: { chart: 'ED', label: 'EE+', data: c_data.ED.EEPLUS },
			BAU_HD: { chart: 'HD', label: 'BAU', data: c_data.HD.BAU },
			EE_HD: { chart: 'HD', label: 'EE', data: c_data.HD.EE },
			EEPLUS_HD: { chart: 'HD', label: 'EE+', data: c_data.HD.EEPLUS },
		}

		const key_list = ['BAU_ED', 'EE_ED', 'EEPLUS_ED', 'BAU_HD', 'EE_HD', 'EEPLUS_HD']

		const rv = {
			data: result,
			set_keys: key_list,
			draw_legend: false,
			headers: {
				ED: i18('EID:EIE'),
				HD: i18('EID:EIH')
			}
		}

		return rv

	}



	single_multiplied(set, id1, id2, label, scale = 1) {
		const value1 = set[id1]
		if (!value1 || !value1.kind || value1.kind != 'scalar')
			return { id: label || id, value: 0 }

		const value2 = set[id2]
		if (!value2 || !value2.kind || value2.kind != 'scalar')
			return { id: label || id, value: 0 }

		let v = value1.value * value2.value
		return { id: label || id, value: v * scale }
	}


	single_const(value, label, scale = 1) {
		return { id: label || id, value: value * scale }
	}

	stacked_datapoint(set, id, label, scale = 1) {
		const value = set[id]
		if (!value || !value.kind || value.kind != 'scalar')
			return { id: label || id, value: 0 }

		let v = value.value
		return [{ id: label || id, value: v * scale },
		{ id: 'REST' + (label || id), value: scale - v * scale }]
	}


	stacked_datapoint2(set, id1, id2, label, scale = 1) {
		const value1 = set[id1]
		if (!value1 || !value1.kind || value1.kind != 'scalar')
			return { id: label || id, value: 0 }

		const value2 = set[id2]
		if (!value2 || !value2.kind || value2.kind != 'scalar')
			return { id: label || id, value: 0 }


		let v = value1.value * value2.value

		return [{ id: label || id, value: v * scale },
		{ id: 'REST' + (label || id), value: scale - v * scale }]
	}

	setup_eid_data2(i18, data) {

		const label_fuel = i18("CHART:DEMDEV:GRAPH:SECTOR:FUEL")
		const label_ev = i18("CHART:DEMDEV:GRAPH:SECTOR:EV")

		const result = {
			BAU: {
				chart: "EID2",
				label: 'BAU',
				data: [
					{ label: label_fuel, data: this.stacked_datapoint(data, 'SD_DEMDEVFMOB:BAU', 'BAU', 100) },
					{
						label: label_ev, data: this.stacked_datapoint2(data, 'SD_DEMDEVFMOB:BAU',
							'PD_PROJEXTRARFUELTOELEC:BAU', 'BAU', 100)
					}
				]
			},
			EE: {
				chart: "EID2",
				label: 'EE',
				data: [
					{ label: label_fuel, data: this.stacked_datapoint(data, 'SD_DEMDEVFMOB:EE', 'EE', 100) },
					{
						label: label_ev, data: this.stacked_datapoint2(data, 'SD_DEMDEVFMOB:EE',
							'PD_PROJEXTRARFUELTOELEC:EE', 'EE', 100)
					}
				]
			},
			EEPLUS: {
				chart: "EID2",
				label: 'EE+',
				data: [
					{ label: label_fuel, data: this.stacked_datapoint(data, 'SD_DEMDEVFMOB:EEPLUS', 'EEPLUS', 100) },
					{
						label: label_ev, data: this.stacked_datapoint2(data, 'SD_DEMDEVFMOB:EEPLUS',
							'PD_PROJEXTRARFUELTOELEC:EEPLUS', 'EEPLUS', 100)
					}
				]
			}
		}

		const key_list = ['BAU', 'EE', 'EEPLUS']

		const rv = {
			data: result,
			set_keys: key_list,
			draw_legend: false,
			headers: {
				EID2: i18('EID:EIM')
			}
		}

		return rv
	}


	setup_eid_data3(i18, data) {
		const result = {
			BAU: {
				chart: "EID3",
				label: 'BAU',
				data: [
					{ data: [this.single_const(1, 'BAU', 100)] },
					{ data: this.stacked_datapoint(data, 'SD_PROJFN:BAU', 'BAU', 100) }
				]
			},
			EE: {
				chart: "EID3",
				label: 'EE',
				data: [
					{ data: [this.single_const(1, 'EE', 100)] },
					{ data: this.stacked_datapoint(data, 'SD_PROJFN:EE', 'EE', 100) }
				]
			},
			EEPLUS: {
				chart: "EID3",
				label: 'EE+',
				data: [
					{ data: [this.single_const(1, 'EEPLUS', 100)] },
					{ data: this.stacked_datapoint(data, 'SD_PROJFN:EEPLUS', 'EEPLUS', 100) }
				]
			}
		}


		const key_list = ['BAU', 'EE', 'EEPLUS']

		const rv = {
			data: result,
			set_keys: key_list,
			draw_legend: false,
			headers: {
				EID3: i18('EID:EINE')
			}
		}

		return rv
	}


	eid_line(data, idx, { varname, title1, title2, unit }) {
		return <tr key={`EID1-${varname}-${idx}`}>
			<td key={`EID-${varname}-${idx}-1`}>{title1}</td>
			<td key={`EID-${varname}-${idx}-2`}>{title2}</td>
			<td key={`EID-${varname}-${idx}-3`}><SDPercent factor={100} data={data} id={`${varname}:BAU`} unit="% p.a." /></td>
			<td key={`EID-${varname}-${idx}-4`}><SDPercent factor={100} data={data} id={`${varname}:EE`} unit="% p.a." /></td>
			<td key={`EID-${varname}-${idx}-5`}><SDPercent factor={100} data={data} id={`${varname}:EEPLUS`} unit="% p.a." /></td>
			<td key={`EID-${varname}-${idx}-6`}>{unit}</td>
		</tr>
	}

	rid_line(data, { varname, title1, title2, unit }) {
		return <tr key={`RID-${varname}`}>
			<td key={`RID-${varname}-1`}>{title1}</td>
			<td key={`RID-${varname}-2`}>{title2}</td>
			<td key={`RID-${varname}-3`}><SDVar precision={3} data={data} id={`${varname}:BAU`} unit="% p.a." /></td>
			<td key={`RID-${varname}-4`}><SDVar precision={3} data={data} id={`${varname}:EE`} unit="% p.a." /></td>
			<td key={`RID-${varname}-5`}><SDVar precision={3} data={data} id={`${varname}:EEPLUS`} unit="% p.a." /></td>
			<td key={`RID-${varname}-6`}></td>
		</tr>
	}

	render_chart(i18, data_model, data) {

		const gi_data = [
			{ varname: "SD_DEMDEVGUIEDRES", title1: i18('EFORM:ELEC'), title2: i18('SECTOR:RES'), unit: 'Energy/Pop.' },
			{ varname: "SD_DEMDEVGUIEDAGR", title1: i18('EFORM:ELEC'), title2: i18('SECTOR:AGR'), unit: 'Energy/GPP' },
			{ varname: "SD_DEMDEVGUIEDIND", title1: i18('EFORM:ELEC'), title2: i18('SECTOR:IND'), unit: 'Energy/GPP' },
			{ varname: "SD_DEMDEVGUIEDCOM", title1: i18('EFORM:ELEC'), title2: i18('SECTOR:COM'), unit: 'Energy/GPP' },
			{ varname: "SD_DEMDEVGUIEDTRA", title1: i18('EFORM:ELEC'), title2: i18('SECTOR:TRN'), unit: 'Energy/GPP' },
			{ varname: "SD_DEMDEVGUIEDOTH", title1: i18('EFORM:ELEC'), title2: i18('SECTOR:ORH'), unit: 'Energy/Pop.' },
			{ varname: "SD_DEMDEVGUIE", title1: i18('EFORM:ELEC'), title2: i18('SECTOR:ALL'), unit: 'Energy/GPP' },

			{ varname: "SD_DEMDEVGUIHDRES", title1: i18('EFORM:HEAT'), title2: i18('SECTOR:RES'), unit: 'Energy/GPP' },
			{ varname: "SD_DEMDEVGUIHDAGR", title1: i18('EFORM:HEAT'), title2: i18('SECTOR:AGR'), unit: 'Energy/GPP' },
			{ varname: "SD_DEMDEVGUIHDIND", title1: i18('EFORM:HEAT'), title2: i18('SECTOR:IND'), unit: 'Energy/GPP' },
			{ varname: "SD_DEMDEVGUIHDCOM", title1: i18('EFORM:HEAT'), title2: i18('SECTOR:COM'), unit: 'Energy/GPP' },
			{ varname: "SD_DEMDEVGUIHDOTH", title1: i18('EFORM:HEAT'), title2: i18('SECTOR:ORH'), unit: 'Energy/Pop.' },
			{ varname: "SD_DEMDEVGUIH", title1: i18('EFORM:HEAT'), title2: i18('SECTOR:ALL'), unit: 'Energy/Pop.' },

			{ varname: "SD_DEMDEVGUIM", title1: i18('EFORM:FMOB'), title2: i18('SECTOR:TRN'), unit: 'Energy/Pop.' },
			{ varname: "SD_DEMDEVGUIN", title1: i18('EFORM:FNEU'), title2: i18('SECTOR:ALL'), unit: 'Energy/GPP' },
			{ varname: "SD_DEMDEVGUIX", title1: i18('EFORM:ALL'), title2: i18('SECTOR:ALL'), unit: 'Energy/Pop.' },
		]



		const ei_data = [
			{ varname: "PD_PROJGJPOPERES", title1: i18('EFORM:ELEC'), title2: i18('SECTOR:RES'), unit: 'Energy/Pop.' },
			{ varname: "PD_PROJGJGPPEAGR", title1: i18('EFORM:ELEC'), title2: i18('SECTOR:AGR'), unit: 'Energy/GPP' },
			{ varname: "PD_PROJGJGPPEIND", title1: i18('EFORM:ELEC'), title2: i18('SECTOR:IND'), unit: 'Energy/GPP' },
			{ varname: "PD_PROJGJGPPECOM", title1: i18('EFORM:ELEC'), title2: i18('SECTOR:COM'), unit: 'Energy/GPP' },
			{ varname: "PD_PROJGJGPPEOTH", title1: i18('EFORM:ELEC'), title2: i18('SECTOR:ORH'), unit: 'Energy/GPP' },

			{ varname: "PD_PROJGJPOPHRES", title1: i18('EFORM:HEAT'), title2: i18('SECTOR:RES'), unit: 'Energy/Pop.' },
			{ varname: "PD_PROJGJGPPHAGR", title1: i18('EFORM:HEAT'), title2: i18('SECTOR:AGR'), unit: 'Energy/GPP' },
			{ varname: "PD_PROJGJGPPHIND", title1: i18('EFORM:HEAT'), title2: i18('SECTOR:IND'), unit: 'Energy/GPP' },
			{ varname: "PD_PROJGJGPPHCOM", title1: i18('EFORM:HEAT'), title2: i18('SECTOR:COM'), unit: 'Energy/GPP' },
			{ varname: "PD_PROJGJGPPHOTH", title1: i18('EFORM:HEAT'), title2: i18('SECTOR:ORH'), unit: 'Energy/GPP' },

			{ varname: "PD_PROJGJPOPM", title1: i18('EFORM:EMOB'), title2: i18('SECTOR:TRN'), unit: 'Energy/Pop.' },
			{ varname: "PD_PROJGJGPPM", title1: i18('EFORM:EMOB'), title2: i18('SECTOR:TRN'), unit: 'Energy/GPP' },
			{ varname: "PD_PROJGJPOPM", title1: i18('EFORM:FMOB'), title2: i18('SECTOR:TRN'), unit: 'Energy/Pop.' },
			{ varname: "PD_PROJGJGPPM", title1: i18('EFORM:FMOB'), title2: i18('SECTOR:TRN'), unit: 'Energy/GPP' },


		]

		const province = this.props.match.params.province

		return <div className='chart-container-grid'>
			<div className='title'>{i18('SOC:TITLE')}</div>
			<div className='descriptions'>
				<table>
					<thead>
						<tr>
							<th>{i18('TABLE:PARAM')}</th>
							<th>{i18('TABLE:GROWTH')}</th>
							<th colSpan='2'>{i18('TABLE:INC')} {data_model.ref_year} -> {data_model.tgt_year}</th>
						</tr>
						<tr>
							<th></th>
							<th></th>
							<th>{i18('TABLE:RELATIVE')}</th>
							<th>{i18('TABLE:ABSOLUTE')}</th>
						</tr>
					</thead>
					<tbody>
						<tr>
							<td>{i18('SOC:POPULATION')}</td>
							<td><SDPercent data={data} id='SD_PROJGPOP:BAU' unit='% p.a' /></td>
							<td><SDPercent data={data} id='SD_PROJFPOP:BAU' unit='%' /></td>
							<td><SDDiff data={data} precision={0} id2='SD_POPNO' id1='QD_POPNO' /> persons</td>
						</tr>
						<tr>
							<td>{i18('SOC:GPP')}</td>
							<td><SDPercent data={data} id='SD_PROJGGPP:BAU' unit='% p.a' /></td>
							<td><SDPercent data={data} id='SD_PROJFGPP:BAU' unit='%' /></td>
							<td><SDDiff data={data} precision={0} id2='SD_GPP' id1='QD_GPP' />{i18(this.get_currency())}</td>
						</tr>
					</tbody>
				</table>
			</div>

			<div className='chart-grid'>
				<BarChartSet data={data_model.socio_economic}
					y_axis_label=''
					y_axis_unit=''


					download={`${backend_config.server}/chart/demdev_sed_IDX_?lang=${this.get_lang()}&province=${province}&energy=${this.get_energy()}`}

					labels={this.chart_labels(i18)}
					groups={[0]} />
			</div>


			<div className='title'>{i18('DET:TITLE')}</div>
			<div className='descriptions'>
				<table>
					<thead>
						<tr>
							<th>{i18('TABLE:PARAM')}</th>
							<th colSpan='3'>{i18('TABLE:VALUE_IN')} {data_model.tgt_year}</th>
						</tr>
						<tr>
							<th></th>
							<th>BAU</th>
							<th>EE</th>
							<th>EE+</th>
						</tr>
					</thead>
					<tbody>
						<tr>
							<td>{i18('DET:TITLE')}</td>
							<td><SDVar scale={100} precision={3} data={data} id="SD_PROJEXTRASELEC:BAU" unit="%" /></td>
							<td><SDVar scale={100} precision={3} data={data} id="SD_PROJEXTRASELEC:EE" unit="%" /></td>
							<td><SDVar scale={100} precision={3} data={data} id="SD_PROJEXTRASELEC:EEPLUS" unit="%" /></td>
						</tr>
					</tbody>
				</table>
			</div>

			<div className='chart-grid'>

					<BarChartSet data={data_model.electric_share}
						y_axis_label=''
						y_axis_unit={"%"}

						download={`${backend_config.server}/chart/demdev_set?lang=${this.get_lang()}&province=${province}&energy=${this.get_energy()}`}

						labels={this.chart_labels(i18, i18, this.state)}
						groups={[0]} />

			</div>

			<div className='title'>{i18('EID:TITLE')}</div>
			<div className='descriptions'>
				<table>
					<thead>
						<tr>
							<th>{i18('TABLE:EFORM')}</th>
							<th>{i18('TABLE:DEM')}</th>
							<th colSpan='3'>{i18('TABLE:AIR')}</th>
							<th>{i18('TABLE:UNIT')}</th>
						</tr>
						<tr>
							<th className='main-title'></th>
							<th className='main-title'></th>
							<th className='eff-title'>BAU</th>
							<th className='eff-title'>EE</th>
							<th className='eff-title'>EE+</th>
							<th className='eff-title'></th>
						</tr>
					</thead>
					<tbody>
						{ei_data.map((x,i) => this.eid_line(data, i, x))}
						<tr key={`EID-Huh`}>
							<td key={`EID-Huh-1`}>{i18('EFORM:FNEU')}</td>
							<td key={`EID-Huh-2`}>{i18('EFORM:ALL')}</td>
							<td key={`EID-Huh-3`}>0.000 % p.a.</td>
							<td key={`EID-Huh-4`}>0.000 % p.a.</td>
							<td key={`EID-Huh-5`}>0.000 % p.a.</td>
							<td key={`EID-Huh-6`}>Energy/GPP</td>
						</tr>
					</tbody>
				</table>
			</div>

			<div className='chart-grid'>

					<BarChartSet data={data_model.eid_1}
						y_axis_label={i18("CHART:DEMDEV:GRAPH:YLABEL") + " " + data_model.tgt_year}

						download={`${backend_config.server}/chart/demdev_eid_IDX_?lang=${this.get_lang()}&province=${province}&energy=${this.get_energy()}`}

						detailClick={true}
						labels={this.chart_labels(i18, this.state)}
						groups={[0, 1, 2, 3, 4, 5]}
						legend={false} />
					<BarChartSet data={data_model.eid_2}
						y_axis_label={i18("CHART:DEMDEV:GRAPH:YLABEL") + " " + data_model.tgt_year}

						download={`${backend_config.server}/chart/demdev_eid3?lang=${this.get_lang()}&province=${province}&energy=${this.get_energy()}`}

						chart_width='50'
						detailClick={true}
						labels={this.chart_labels(i18, this.state)}
						groups={[0, 1]}
						legend={false} />
					<BarChartSet data={data_model.eid_3}
						y_axis_label={i18("CHART:DEMDEV:GRAPH:YLABEL") + " " + data_model.tgt_year}

						download={`${backend_config.server}/chart/demdev_eid4?lang=${this.get_lang()}&province=${province}&energy=${this.get_energy()}`}

						chart_width='50'
						labels={this.chart_labels(i18, this.state)}
						groups={[0]}
						legend={false} />

			</div>

			<div className='title'>{i18('RID:TITLE')}</div>
			<div className='descriptions'>
				<table>
					<thead>
						<tr>
							<th>{i18('TABLE:EFORM')}</th>
							<th>{i18('TABLE:DEM')}</th>
							<th colSpan='4'>{i18('TABLE:AGR')}</th>
						</tr>
						<tr>
							<th className='main-title'></th>
							<th className='main-title'></th>
							<th className='eff-title'>BAU</th>
							<th className='eff-title'>EE</th>
							<th className='eff-title'>EE+</th>
							<th className='eff-title'></th>
						</tr>
					</thead>
					<tbody>
						{gi_data.map(x => this.rid_line(data, x))}
					</tbody>
				</table>
			</div>

			<div className='chart-grid'>

					<LineChartSet data={data_model.ri_3}
						y_axis_label={i18("CHART:DEMDEV:RID:YLABEL")}
						y_axis_unit={i18(this.get_energy())}
						x_axis_label_0={`${data_model.ref_year}`}
						x_axis_label_1={`${data_model.tgt_year}`}

						download={`${backend_config.server}/chart/demdev_rid_IDX3_?lang=${this.get_lang()}&province=${province}&energy=${this.get_energy()}`}

						labels={this.chart_labels(i18, this.state)}
						groups={[0]} />

					<LineChartSet data={data_model.ri_1}
						y_axis_label={i18("CHART:DEMDEV:RID:YLABEL")}
						y_axis_unit={i18(this.get_energy())}
						x_axis_label_0={`${data_model.ref_year}`}
						x_axis_label_1={`${data_model.tgt_year}`}

						download={`${backend_config.server}/chart/demdev_rid_IDX_?lang=${this.get_lang()}&province=${province}&energy=${this.get_energy()}`}

						labels={this.chart_labels(i18, this.state)}

						groups={[0]} />

					<LineChartSet data={data_model.ri_2}
						y_axis_label={i18("CHART:DEMDEV:RID:YLABEL")}
						y_axis_unit={i18(this.get_energy())}
						x_axis_label_0={`${data_model.ref_year}`}
						x_axis_label_1={`${data_model.tgt_year}`}

						download={`${backend_config.server}/chart/demdev_rid_IDX2_?lang=${this.get_lang()}&province=${province}&energy=${this.get_energy()}`}


						labels={this.chart_labels(i18, this.state)}
						groups={[0]} />



			</div>

		</div>
	}


	render_error() {
		return <div className='sankey-menu'>
			<div className='warning section'>Data for scenario is not available</div>
		</div>
	}


	componentDidUpdate() {
		this.fetch_expression()
	}


	componentDidMount() {
		this.fetch_expression()
	}

	render()
	{

		if (!this.context || !this.context.ctx.i18nstr)
			return <div />

		const i18 = this.context.ctx.i18nstrp("CHART:DEMDEV")


		if (!this.data_model && this.state.chart_data) {
			this.data_model = {}
			this.data_model.ref_year = this.context && this.context.year_refs ? `${this.context.year_refs.reference_year}` : ''
			this.data_model.tgt_year = this.context && this.context.year_refs ? `${this.context.year_refs.target_year}` : ''

			this.data_model.socio_economic = this.setup_socio_economic(i18, this.data_model, this.state.chart_data)
			this.data_model.electric_share = this.setup_electric_share(i18, this.state.chart_data)

			this.data_model.eid_1 = this.setup_eid_data1(i18, this.state.chart_data)
			this.data_model.eid_2 = this.setup_eid_data2(i18, this.state.chart_data)
			this.data_model.eid_3 = this.setup_eid_data3(i18, this.state.chart_data)


			this.data_model.ri_1 = this.setup_ri_data_curve1(i18, this.data_model, this.state.chart_data)
			this.data_model.ri_2 = this.setup_ri_data_curve2(i18, this.data_model, this.state.chart_data)
			this.data_model.ri_3 = this.setup_ri_data_curve3(i18, this.data_model, this.state.chart_data)
		}


		const { match, history } = this.props
		const p_key = this.get_province().toUpperCase()
		const province_data = this.context.provinces ? this.context.provinces[p_key] : null

		if (!province_data)
			return <div />

		const tgt_year = this.context && this.context.year_refs ? `${this.context.year_refs.target_year}` : ''

		return <MasterFrame province={this.props.match.params.province} history={history} match={match}>
			<div className='sankey-main'>
				<div className='sankey-menu'>
					{
						this.unit_selector({ area: false })
					}
					{
						this.data_model && this.state && this.state.is_head_data == false &&
						<div className='warning section'>
							<i className='fa fa-exclamation-circle' />
							Diagram is not based on latest data
						</div>
					}
					{
						this.state.error &&
						<div className='section warning'>Data for scenario not available</div>
					}
					<Help id="demand-development" />
				</div>
				<div className='sankey-container with-expression'>
					<div className={classnames("bottom", "grid-on",
						"sidebar-on")}>
						<div className='fill-with-title'>
							<div className='helpblock'>
								<div className='title'>
									<span>{i18("HEADLINE:LINE1")}</span>
									&nbsp;
									<span>{i18("PROVINCE", this.get_province())}</span>
									&nbsp;
									<span>{i18("HEADLINE:LINE2")}</span>
									&nbsp;
									<span>{tgt_year}</span>
								</div>
								<div className='text'>{i18("HEADLINE:HELPTEXT")}</div>
								<div className='text'>{i18("TXT:INPUTDATA")}</div>
							</div>
							<div className='chartblock'>
								{!this.state.error && this.data_model && this.render_chart(i18, this.data_model, this.state.chart_data)}
							</div>
						</div>
					</div>
				</div>
			</div>
		</MasterFrame>
	}
}


Object.assign(DemandDevelopment.prototype, Navigation)


// TotalDemand	S3
// Fossil Fuel  S2
// RE Supply	S1



export default DemandDevelopment;