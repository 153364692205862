import React, { Component } from "react";
import {BrowserRouter,
		Route,
		Switch,Redirect} 	from "react-router-dom"
import MainEditor 			from "../MainEditor"
import SankeyFrame			from "../sankey/SankeyFrame"
import ProvinceSelector		from "../province/ProvinceSelector"

import NotFound 			from "./NotFound"
import ApiExplorer 			from "../api-explorer/ApiExplorer"
import QDataFrame 			from "../qdata-explorer/QDataFrame"
import SDataFrame      		from "../qdata-explorer/SDataFrame"
import CDataFrame      		from "../qdata-explorer/CDataFrame"
import RawData 				from "../province/RawData"
import KPI 					from "../kpi/KPI"
import Upload 				from "../Upload"
import SystemUpload 		from "../SystemUpload"
import LoadProfile			from "../loadprofile/LoadProfile"
import OverviewImage		from "../OverviewImage"
import ScenarioFrame 		from "../scenario/ScenarioFrame"
import Potential 			from "../generaldata/Potential"
import TechParams 			from "../generaldata/TechParams"
import SummaryChart 		from "../barchart/SummaryChart"
import OverviewChart 		from "../barchart/OverviewChart"
import CapacityChart 		from "../barchart/CapacityChart"
import DemandDevelopment 	from "../barchart/DemandDevelopment"
import SupplyDemandChart 	from "../barchart/SupplyDemandChart"
import TaskViewerFrame		from "../task/TaskViewerFrame"
import ResultViewer     	from "../task/ResultViewer"
import KommodViewer 		from "../qdata-explorer/KommodViewer"
import UserLogin	 		from "../user/UserLogin"
import Setup 				from "../setup/Setup"
import TargetYear 			from "../setup/TargetYear"
import UploadParams 		from "../setup/UploadParams"
import UserList 			from "../user/UserList"

import SvgTest				from "../svgtest/SvgTest"

import FormTest 			from "../form/FormTest"

import EESetFrame 			from "../scenario/ElecExch"

import Imprint 				from "../imprint/Imprint"



class MasterRouter extends Component
{
	constructor(props)
	{
		super(props)
	}

	render()
	{
		if (this.props.forcelogin)
			return 	<BrowserRouter>
						<Switch>
							<Route component={UserLogin} />
						</Switch>
					</BrowserRouter>

			return (

				<BrowserRouter>
					<Switch>
						<Route exact path="/" 			render={() => <Redirect to="/province" />} />
						<Route exact path="/imprint" 	component={Imprint} />

						<Route exact path="/svgtesta" component={SvgTest} />
						<Route exact path="/sync/:province?" component={Upload} />
						<Route exact path="/system-upload" component={SystemUpload} />
						<Route exact path="/tasks" component={TaskViewerFrame} />
						<Route exact path="/results" component={ResultViewer} />
						<Route exact path="/kommod-viewer/:province/:scenario/:folder/:filename" component={KommodViewer} />
						<Route exact path="/kommod-viewer/:province/:scenario" 					 component={KommodViewer} />
						<Route exact path="/kommod-viewer/:province" 							 component={KommodViewer} />


						<Route exact path="/province" component={ProvinceSelector} />
						<Route exact path="/province/:province?" component={ProvinceSelector} />
						<Route exact path="/rawdata/:province" component={RawData} />
						<Route exact path="/loadprofile-current/:province"
							render={routeProps => (
								<LoadProfile {...routeProps} kind="qd" />
							)} />
						<Route exact path="/loadprofile-future/:province"
							render={routeProps => (
								<LoadProfile {...routeProps} kind="sd" />
							)} />

						<Route
							exact
							path="/sankey-current/:province/:type?"
							render={routeProps => <SankeyFrame key="skf-qd" {...routeProps} kind="qd" />}
						/>
						<Route
							exact
							path="/sankey-current-noframe/:province/:type?"
							render={routeProps => (
								<SankeyFrame key="skf-qd" {...routeProps} kind="qd" no_frame={true} />
							)}
						/>

						<Route
							exact
							path="/sankey-future/:province/:type?"
							render={routeProps => <SankeyFrame key="skf-sd" {...routeProps} kind="sd" />}
						/>



						<Route
							exact
							path="/sankey-future/:province/:type"
							render={routeProps => <SankeyFrame key="skf-sd" {...routeProps} kind="sd" />}
						/>

						<Route
							exact
							path="/sankey-future/:province/:type/:ee/:scenario"
							render={routeProps => <SankeyFrame key="skf-sd" {...routeProps} kind="sd" />}
						/>

						<Route
							exact
							path="/sankey-future-noframe/:province/:type?"
							render={routeProps => (
								<SankeyFrame key="skf-sd" {...routeProps} kind="sd" no_frame={true} />
							)}
						/>

						<Route
							exact
							path="/kpi-current/:province/"
							render={routeProps => <KPI {...routeProps} kind="qd" />}
						/>
						<Route
							exact
							path="/kpi-future/:province"
							render={routeProps => <KPI {...routeProps} kind="sd" />}
						/>

						<Route exact path="/alldata" 							component={MainEditor} />
						<Route path="/qdata/:province/:year?/:varname?" 		component={QDataFrame} />

						<Route exact path="/sdata/:province" 					component={SDataFrame} />
						<Route exact path="/sdata/:province/:ee" 				component={SDataFrame} />
						<Route exact path="/sdata/:province/:ee/:scenario" 		component={SDataFrame} />

						<Route path="/cdata/:province" 							component={CDataFrame} />


						<Route exact path="/apitest" 							component={ApiExplorer} />


						<Route 	path="/debugscenario/:province/:scenario?"
								render={routeProps => (<ScenarioFrame  {...routeProps} allow_download={true} />)} />


						<Route 	path="/scenario-custom/:province/:scenario?"
								render={routeProps => (<ScenarioFrame  {...routeProps} edit={true} />)} />

						<Route 	path="/scenario-eex/:province/:ee_set_id"
								render={routeProps => (<EESetFrame  {...routeProps} />)} />


						<Route path="/scenario/:province" 						component={ScenarioFrame} />

						<Route exact path="/demand-development/:province" 					component={DemandDevelopment} />
						<Route exact path="/demand-development/:province/:ee" 				component={DemandDevelopment} />

						<Route exact path="/summary-chart" 									component={SummaryChart} />

						<Route exact path="/overview-chart/:province" 						component={OverviewChart} />

						<Route exact path="/supply-demand-chart/:province" 					component={SupplyDemandChart} />

						<Route exact path="/capacity-chart/:province" 						component={CapacityChart} />

						<Route exact path="/general/techparams/:province" 					component={TechParams} />
						<Route exact path="/general/potential/:province" 				component={Potential} />

						<Route exact path="/techparams/:province" 					component={TechParams} />
						<Route exact path="/potential/:province" 				component={Potential} />


						<Route exact path="/overviewimage/:province/:number" 			component={OverviewImage} />

						<Route exact path="/login" 										component={UserLogin} />
						<Route exact path="/targetyear" 								component={TargetYear} />
						<Route exact path="/upload-params" 								component={UploadParams} />

						<Route exact path="/setup" 										component={Setup} />
						<Route exact path="/users" 										component={UserList} />

						<Route exact path="/formtest" 								component={FormTest} />

						<Route component={NotFound} />
					</Switch>
				</BrowserRouter>
			)
	}
}

export default MasterRouter