import React 				from "react";
import backend_config		from "../config/runconfig.js"



class AppConnection
{
	constructor()
	{
		this.connect()
		this.callbacks 				= {}
		this.connection_status 		= "start"
		this.connected 				= false
	}



	connect()
	{
		console.log( "Connecting " + backend_config.server )
		if (!backend_config.websocket)
		{
			console.log( "Websocket not configured")
			return
		}
		this.ws_socket 	= new WebSocket(`${backend_config.websocket}` )

		this.ws_socket.onclose = () =>
		{
			console.log( "Reconnecting...." )
			this.set_connection_status( false,  "closed" )
			setTimeout( () => { this.connect() }, 10000 )
		}

		this.ws_socket.onerror = (e) =>
		{
			// Closing will call onclose which will auto-reconnect
			this.set_connection_status( false,  "error" )
			this.ws_socket.close()
		}

		this.ws_socket.onopen = () =>
		{
			this.set_connection_status( true, "connected" )
		}

		this.ws_socket.onmessage =  (event) =>
		{
			const message 		= JSON.parse( event.data )
			console.log( "MSG ", message.province_id )
			try
			{
				if (message.kind 		 === "notification")
					this.trigger_callbacks( message )
			}
			catch(e)
			{
				console.log( "WS Callback failed " )
				console.log( e )
			}
		}
	}

	set_connection_status( connected, status )
	{
		this.connected 			= connected
		this.connection_status 	= status
		this.trigger_notification("connection",
		{
			status: status,
			connected: connected
		})
	}

	trigger_notification( notification, rest )
	{
		const message = {kind: "notification", notification, ...rest}
		this.trigger_callbacks( message )
	}

	trigger_callbacks( message )
	{
		if (message.notification)
		{
			for( let ci in this.callbacks[message.notification])
			{
				if (this.callbacks[message.notification])
					this.callbacks[message.notification][ci]( message )
			}
		}

		for( let ci in this.callbacks["*"] && message.notification !== "*")
		{
			if (this.callbacks["*"])
				this.callbacks["*"][ci]( message )
		}

	}

	add_notification_callback( kind, callback )
	{
		if (!this.callbacks[kind])
			this.callbacks[kind] = []
		this.callbacks[kind].push( callback )
	}
}



export const AppController	= React.createContext()
export const AppProvider 	= AppController.Provider
export const AppConsumer 	= AppController.Consumer
export const app_connection = new AppConnection()

