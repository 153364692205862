import React, { Component, Fragment } 	from "react";
import classnames       				from 'classnames';
import backend_config					from "../config/runconfig.js"
import axios 							from 'axios'


import MasterFrame 					from '../app/MasterFrame'
import {AppController} 					from '../app/AppController'
import { Expression, node_color, get_origin } 		from "./Expression"
import {ScenarioSelector}				from '../scenario/ScenarioSelector'



const API_KEY 		= 'ExgWFH9XHHyXIHyKEHIH'


export class KommodViewer extends Component
{
	static contextType = AppController;

	constructor( props )
	{
		super( props )
		this.state = {selected_var : null, varnames: true, filenames: []}


		this.chartRef   	= React.createRef()
        this.containerRef	= React.createRef()
	}


	fetch_files()
	{

		const scenario = this.props.match.params.scenario
		const province = this.props.match.params.province

		const component = this

		const url = `${backend_config.server}/task/data-json-files`
		console.log(url)
		axios.get(url)
			.then((incoming) => {
				const { data } = incoming
				if (data.status === 'error') {
				}
				else {
					console.log( data.result.sort() )
					component.setState({
						filenames: data.result.sort()
					})
				}
			})
	}

	fetch_expression()
	{
		const scenario	= this.props.match.params.scenario
		const province 	= this.props.match.params.province
		const filename 	= this.props.match.params.filename
		const folder 	= this.props.match.params.folder

		if (!filename || !folder)
			return

		const component = this
		const year 	    = this.props.year

		const url = `${backend_config.server}/task/data-json/${province}/${scenario}/${folder}/${filename}`

		if (url === this.displayed_url || this.inflight)
			return
		console.log(url)

		this.inflight 	= true
		axios.get(url)
		 .then( (incoming) =>
		{
			this.inflight 			= false

			const {data}		= incoming

			if (data.status === 'error' )
			{
				console.log( "Error In Query")
				console.log( data.error )
				this.displayed_url  = url
				this.setState( {error: true, expressions: null})
			}
			else
			{
				this.displayed_url 	= url
				this.timestamp 		= data.timestamp
				component.setState( {
										error: 			false,
										timestamp: 		data.timestamp,
										body: 			data.result.body,
									 	expressions: 	data.result.expressions
									 } )
			}
		})
	}


	componentDidMount()
	{
		this.fetch_files()
		this.fetch_expression()
	}

	componentDidUpdate()
	{
		this.fetch_expression()
	}

	get_scenario ()
	{
		return this.props.match.params.scenario || 'P001'
	}
	get_province()
	{
		return 	this.props.match.params.province
	}

	setScenario 	= ({scenario})	=>
	{
		const folder 	= this.props.match.params.folder
		const filename  = this.props.match.params.filename
		const province  = this.get_province()
		if (folder && filename)
			this.props.history.replace(`/kommod-viewer/${province}/${scenario}/${folder}/${filename}`)
		else
			this.props.history.replace(`/kommod-viewer/${province}/${scenario}`)
	}

	render_error()
	{
		return 	<div className='depgraph-menu'>
					<div className='warning section'>Data for province not available</div>
				</div>
	}
	render_menu()
	{
		const p_key 		= this.get_province().toUpperCase()
		const province_data = this.context.provinces ? this.context.provinces[p_key] : null
		const scenario 		= this.get_scenario()
		const province 		= this.get_province()
		const zip_url		= `${backend_config.server}/task/data-zip/${province}/${scenario}`
		return <div className='depgraph-menu sankey-menu'>
					<div className='section'>
							{<ScenarioSelector province_data={province_data} scenario={this.get_scenario()} callback={this.setScenario} />}
					</div>
					<div className={classnames('itemlight', this.state.varnames ? 'selected' : false)} onClick={this.handle_varnames} />
					<div className={classnames('item', this.state.varnames ? 'selected' : false)} onClick={this.handle_varnames}>
						Variable Names
					</div>
					<div className='item selected'><a href={zip_url}>Download</a>
					</div>
				</div>
	}


	handle_varnames 	= (event) => this.setState({ varnames: !this.state.varnames })

	handle_varname = (varname) => {return (event) => {this.setState( {selected_var : varname}) }}

	handle_filename = (filename) => { return (event) => {
			const currentpath = this.props.history.location.pathname
			const scenario = this.props.match.params.scenario || '1'
			const province = this.props.match.params.province

			this.props.history.replace(`/kommod-viewer/${province}/${scenario}/${filename}`)
		}
	}


	make_segments(line)
	{
		const result = []
		const sp = line.split(/{{(.+?)\|(.+?)}}/)

		for (let i = 0; i < sp.length; i++) {
			const segment = sp[i]
			const ii = i % 3
			if (ii == 0)
				result.push(<div className='string segment'>{segment}</div>)
			else if (ii == 1)
			{
				const value = sp[i+1]
				const error = value == 'Error' ? 'error' : ''
				if (this.state.varnames)
					result.push(<div onClick={this.handle_varname(segment)}
									 className={`varname segment ${error}`}>{segment}</div>)
				else
					result.push(<div onClick={this.handle_varname(segment)}
									 className={`value segment ${error}`}>{value}</div>)
			}
		}
		return result
	}

	render_body()
	{
		const result = []
		const {body} = this.state
		const split  = body.split("\n")
		split.map((tline,idx) => {
			if (idx < 100)
			{
				const line 		= `${tline}`.trim()
				const segments 	= this.make_segments( line )
				result.push( <div className='line' key={`line-${idx}`}>{segments}</div> )
			}
		})

		return 	<div className='kommod-body'>
					{result}
				</div>
	}


	make_expression()
	{
		if (!this.state.selected_var)
			return null
		const s = this.state.selected_var.toUpperCase()
		const root = this.state.expressions[s]
		if (!root)
			return `Node Not found : ${this.props.selected_node}`

		const {varname, dependencies} = root
		const node 					  = dependencies[varname]

	   if (!node)
	      return <div>Node {s} not found</div>

	    const origin = get_origin(node)
	    if (node.source)
	      return  <div>
	                  <div className='expression-text'>{node.expression}</div>
	                  {origin && <div className='origin'>{origin}</div>}
	                  <Expression  node={node}
	                               year={this.props.year}
	                               expressions={dependencies}/>
	              </div>
	    else if (node.expression)
	      return <div>
	                  {`=${node.expression}`}
	                  {origin && <div className='origin'>{origin}</div>}
	             </div>

	    return <div>
	                  Input
	                  {origin && <div className='origin'>{origin}</div>}
	            </div>

	}

	render_filenames()
	{
		return this.state.filenames.map( (fn,idx) =>
		{
			const selected = fn == this.props.match.params.filename ? 'selected' : ''
			return <div onClick={this.handle_filename(fn)} className={`filename ${selected}`} key={`FN:${idx}`}>{fn}</div>
		})
	}

	render()
	{
		const { match, history } = this.props

		return 	<MasterFrame province={this.props.match.params.province} history={history} match={match}>
					<div className='side-menu'>
						{this.state.filenames && this.render_filenames()}
					</div>
					<div className='province-data'>
						<div className={classnames("bottom")}>
						{
							<div className='depgraph'>
								<div className='graph-container'>
									{this.render_menu()}
									<div className='expression-display term'>
										{
											this.state.selected_var && this.make_expression()
										}
										{
											!this.state.selected_var && <i>Click on any node to display the formula or data source</i>
										}
									</div>


									<div ref={this.containerRef} className='graph'>
										{this.state.body && this.render_body()}
									</div>
								</div>
							</div>
						}
						</div>
					</div>
				</MasterFrame>

	}
}

export default KommodViewer

