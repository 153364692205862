
import React, { Component, Fragment } 	from "react";
import classnames       				from 'classnames';
import MasterFrame						from '../app/MasterFrame'
import {AppController} 					from '../app/AppController'
import backend_config					from "../config/runconfig.js"
import {ScenarioEdit} 					from './ScenarioEdit'
import axios 							from 'axios'
import queryString 						from 'query-string'

import { Navigation } 					from '../navigation/navigation'
import {ElecExchShow}					from './ElecExch'

import {Help}							from '../help/Help'

const API_KEY = 'ExgWFH9XHHyXIHyKEHIH'







class ScenarioView  extends Component
{
	static contextType = AppController;

	constructor( props )
	{
		super(props)
		this.state = {starting: [], cancelling: [], editing_set: null}
		this.queue = []
	}

	popQueue = (n) =>
	{
		if (this.queue.length == 0)
		{
			if (n > 0)
				setTimeout( () => this.popQueue(n), 50*n )
			return
		}

		const {list,province} 	= this.queue.shift()


		const chp 				= this.get_chp()
		const ee_set_id 		= this.get_ee_set_id() || 'E0000'

		list.forEach( sid =>
		{
			const start_key 		= `${province}${sid}${chp}${ee_set_id}`.toUpperCase()

			if (province == this.props.province)
			{
				setTimeout( () => this.setState( {starting: this.state.starting.concat([start_key])}), 			1 )
				setTimeout( () => this.setState( {starting: this.state.starting.filter( x => x != start_key)}), 10000)
			}
		})

		const url = `${backend_config.server}/task/create/${province}/${list.join(",")}?chp=${chp}&ee_set_id=${ee_set_id}`
		axios.post( url )
			 .then(  () => {console.log( "Success" ); this.popQueue(n)} )
			 .catch( () => {console.log( "Fail"); this.queue.push( {list,province} ); this.popQueue(n)} )
	}

	componentDidMount()
	{
		this.popQueue(1)
	}


	startCalculation = (outstanding_list, full_list) =>
	{
		return (event) =>
		{
			const list = (outstanding_list.length > 0 ? outstanding_list : full_list) || []
			event.stopPropagation()
      		event.preventDefault()
			const {province} 	= this.props

			this.queue.push( {province, list} )
			this.popQueue(0)
		}
	}


	cancelCalculation = (key, sid) =>
	{
		return (e) =>
		{
			const {province} 	= this.props
			axios.post(`${backend_config.server}/task/delete/${key}`)
				 .then((incoming) => console.log(incoming))


			const chp = this.get_chp()
			const ee_set_id = this.get_ee_set_id() || 'E0000'
			const start_key = `${province}${sid}${chp}${ee_set_id}`.toUpperCase()
			
			this.setState( {starting: this.state.starting.concat([start_key])})
			setTimeout( () => this.setState( {starting: this.state.starting.filter( x => x != start_key)}), 5000)
		}
	}


	openclose	= (group_id, opened) =>
	{
		return (e) =>
		{
			let new_opened
			if (opened.indexOf( group_id ) > -1)
				new_opened 	= opened.filter( x => x != group_id )
			else
				new_opened 	= opened.concat( [group_id])

			const open_list  = new_opened.map( x => encodeURIComponent(x).replace("%20", "+")).join(',')

			this.goto( {open: open_list})
		}
	}





	addEESet 	= (e) =>
	{
		this.new_page(`/scenario-eex/${this.get_province()}/_new`)
	}


	editEESet 	= (id) => (e) =>
	{
		this.new_page(`/scenario-eex/${this.get_province()}/${id}`)
	}




	get_scenario_status( i18, sid )
	{
		const {province_data} 	= this.props
		const scenario 		= this.get_scenario_data( sid )

		const ee_set_id  	= this.get_ee_set_id()
		const chp 			= this.get_chp() == 'on' ? "ON" : "OFF"

		const key 			= `${sid}-${chp}-${ee_set_id}`
		const start_key 	= `${this.get_province()}${sid}${chp}${ee_set_id}`.toUpperCase()

		const task = (province_data.tasks && province_data.tasks.queue ? province_data.tasks.queue[key] : null) || null

		const calculated 	= (scenario && scenario.calculated)
		const error 		= (scenario && scenario.status == 'error')

		if (task)
		{
			const status = task.status == 'queued' ? 'calculating...' : `${task.status}`
			const msg 	 = task.message ? <i> ({task.message})`</i> : null
			return 	{
						lamp: 	 task.status,
						cmd: 	 task.status == 'waiting' ? 'Cancel' : i18("SCN:ACTION:RESTART"),
						serial:  task.serial,
						content: <div className={`calcstatus task taskstatus-${task.status}`}>{status} {msg}</div>
					}
		}
		else if (error)
		{
			return 	{
						lamp: 	 'error',
						cmd: 	 i18("SCN:ACTION:RESTART"),
						content: <div className='calcstatus calculated'>Error</div>,
						message:  scenario.message
					}
		}
		else if (calculated)
		{
			return 	{
						lamp: 	 'complete',
						cmd: 	 i18("SCN:ACTION:RESTART"),
						content: <div className='calcstatus calculated'>{i18("STATUS:CALCULATED")}</div>
					}
		}
		else if  (this.state.starting.indexOf( start_key ) > -1)
		{
			console.log( "STARTING ", start_key )
			return  {
						lamp: 	'starting',
						cmd: 	 null,
						content: <div className='calcstatus starting'>...</div>
					}
		}
		else
		{
			return 	{
						lamp: 	'none',
						cmd: 	i18("SCN:ACTION:START"),
						content: <div className='calcstatus'>{i18("STATUS:NOTCALCULATED")}</div>
					}
		}
	}


	get_scenario_data( sid )
	{
		const slist 	= this.props.scenarios.filter(x => x.id === sid)
		if (slist && slist.length > 0)
				return slist[0]
		else 	return null

	}

	get_scenario_status_list(   i18, sid_list )
	{
		const result = {}
		sid_list.map( sid =>
		{
			result[sid] 	= this.get_scenario_status(  i18, sid )
		})
		return result
	}


	parse_re( share )
	{
		const x = parseFloat( share )
		return x <=1 ? x * 100 : x
	}

	find_re_share( sid )
	{
		const id 			= sid.startsWith("P") ? parseInt( sid.replace("P", ""), 10 ) : parseInt( sid.split("-")[1], 10 )

		const re_share		= (id - 1) % 6 * 0.2
		const re_share_fmt 	= (id - 1) % 6 == 0 ? 'Min. Cost' : `${Math.floor(re_share * 100)}%`
		return {
			re_share: 		re_share,
			re_share_fmt: 	re_share_fmt
		}
	}

	translate_tempname( i18, n )
	{
		let template_name = n
		if (template_name == "Basis 1")
			template_name = i18("BASIS1")
		if (template_name == "Basis 2")
			template_name = i18("BASIS2")
		if (template_name == "Basis 3")
			template_name = i18("BASIS3")

		return template_name
	}


	translate_eff(i18, n) {
		let result = n
		if (result == "BAU")
			result = i18("BAU")
		if (result == "EE")
			result = i18("EE")
		if (result == "EEPlus")
			result = i18("EEPLUS")

		return result
	}

	render_scenario_table( i18, province_data, template, sid, scenario_status, can_operate )
	{
		let scenario = this.get_scenario_data( sid )
		if (scenario == null)
			scenario = this.find_re_share( sid )

		let template_name = this.translate_tempname( i18, template.name )

		const {cmd, content, serial}  	= scenario_status
		const download_link 	= `${backend_config.server}/task/data-zip/${this.get_province()}/${sid}`
		const kommod_link 		= `/kommod-viewer/${this.get_province()}/${sid}`
		return 	<tr className='scenario_detail' key={`SCEN-${sid}`}>
					<td className='head'>{template_name}</td>
					<td>{template.efficiency}</td>
					<td>{template.custom ? i18("SCN:POTCUSTOM") : i18("SCN:POTTHAI")}</td>
					<td>{scenario.re_share_fmt}</td>
					<td data-tip={scenario.message}>
						{content}
					</td>
					<td>
						{
							can_operate && cmd && <button
										onClick={cmd == 'Cancel' ? this.cancelCalculation(serial, sid)
																: this.startCalculation([sid])}
										className='trigger calcstatus start'>
									{cmd}
								</button>
						}
						{
							this.props.allow_download &&
							<a className='download-link' href={download_link}><i className='downloader fa fa-download' ></i></a>
						}
						&nbsp;
						{
							this.props.allow_download &&
							<a className='download-link' href={kommod_link}><i className='downloader fa fa-search' ></i></a>
						}
						&nbsp;
					</td>
					<td></td>
				</tr>
	}


	render_scenario_lamps( status_list )
	{
		return Object.keys(status_list).map( k =>
		{
			const lamp = status_list[k]
			return 	<div key={`LAMP-${k}`}
						data-tip={lamp.message}
						className={`lamp lamp-status-${lamp.lamp}`}>
					</div>
		} )
	}


	setDefault = (event) => {
			event.preventDefault()
			event.stopPropagation()
		const query = `${backend_config.server}/scenario/set-default-set/${this.props.province}?ee_set_id=${this.get_ee_set_id()}&chp=${this.get_chp()}`

		axios.post(query)
	}

	handle_select = (name) => {
		return (event) => {
			event.preventDefault()
			event.stopPropagation()
			axios.post(`${backend_config.server}/scenario/set-selected/${this.props.province}/${name}`).then((incoming) => console.log(incoming))
		}
	}

	handle_edit = (name) => {
		return (event) => {
			event.preventDefault()
			event.stopPropagation()
			this.props.history.push(`/scenario-custom/${this.get_province()}/${name}`)
		}
	}

	get_sid_list( template )
	{
		const {id} = template
		if (id == 'BASISBAU')
			return ['P001', 'P002', 'P003', 'P004', 'P005', 'P006']
		if (id == 'BASISEE')
			return ['P007', 'P008', 'P009', 'P010', 'P011', 'P012']
		if (id == 'BASISEEPLUS')
			return ['P013', 'P014', 'P015', 'P016', 'P017', 'P018']

		// Custom
		if (template.eff_key == 'BAU')
			return ['001', '002', '003', '004', '005', '006'].map( x => `${id}-${x}`)
		if (template.eff_key == 'EE')
			return ['007', '008', '009', '010', '011', '012'].map( x => `${id}-${x}`)
		if (template.eff_key == 'EEPLUS')
			return ['013', '014', '015', '016', '017', '018'].map( x => `${id}-${x}`)
	}

	addCustom 	= (e) => {
		this.props.history.push(`/scenario-custom/${this.get_province()}`)
	}






	render_group( i18, template, province_data, can_operate )
	{
		const {id} = template
		if (id == 'DEFAULT' || !id)
			return null
		const name 			= this.translate_tempname(i18, template.name)
		const sid_list 		= this.get_sid_list( template )

		const status_list 	= this.get_scenario_status_list(  i18, sid_list )


		const {scenarios} 	= this.props
		const {open_groups}	= this.props
		const open 			= open_groups.indexOf( id ) > -1 || open_groups.indexOf("*") > -1
		const openlist 		= !open ? [] : sid_list.map( sid =>
				this.render_scenario_table( i18, province_data, template, sid, status_list[sid],can_operate ))
		const custom 		= !id.startsWith( "BASIS")

		const outstanding 	= []
		for( let g of sid_list)
		{
			if (status_list[g] && status_list[g].lamp != "complete") 
				outstanding.push(g) 
		}
		const count =  6 - outstanding.length

		const select_class = id == province_data.selected_scenario ? 'selected' : 'unselected'

		const opencaret 	= open ? <i className='fa fa-caret-down' /> : <i className='fa fa-caret-right' />


		return [<tr className='group_detail' key={`GROUP-${name}`}
					onClick={this.openclose( id, open_groups )} >
			<td className='head'><div className="content">{opencaret}{name}</div></td>
			<td>{this.translate_eff( i18, template.efficiency)}</td>
			<td>{custom ? i18("SCN:POTCUSTOM") : i18("SCN:POTTHAI")}</td>
			<td></td>
			<td>
				{count}/{sid_list.length} {i18("CALS")}
				<br/>
				{this.render_scenario_lamps( status_list )}
			</td>
			<td>
			{can_operate ? <button className='trigger group'
												onClick={this.startCalculation(outstanding, sid_list)}>
												 {count < 6 ? i18("ACTION:START") : i18("ACTION:RESTARTALL") }
											 </button> : null}
			</td>
			<td>
				{custom && can_operate ? <button onClick={this.handle_select( id )}
												 className={select_class}>{i18("ACTION:SELECTED")}
										 </button> : null}
				&nbsp;&nbsp;
				{custom && can_operate ? <button onClick={this.handle_edit( id )}>{i18("ACTION:EDIT")}
										 </button> : null}
			</td>
		</tr>].concat( openlist )
	}



	set_chp = (event) => {
		const value = event.target.checked
		console.log( value != 'off' )
		this.goto( {chp : value ? 'on' : 'off'})

	}



	render()
	{
		if (!this.context || !this.context.ctx.i18nstrp)
			return <div />

		const i18 = this.context.ctx.i18nstrp("SCN")


		const { province} 	= this.props


		const user 		= this.context.ctx.user
		const role 		= user && user.role && user.role.kind
		const is_admin	= role == 'admin'
		const is_nat	= role == 'national'
		const is_po		= role == 'provincial'

		const can_operate = is_admin || is_nat || (is_po && `${user.role.province}`.toLowerCase() == province)


		let province_data = null
		if (this.context.provinces && province)
			province_data = this.context.provinces[province.toUpperCase()]

		if (!province_data || !province_data.templates)
			return <div/>	/// This will last a about half a second so no need to flash the poor user with an error


		let selected_ee 	= this.get_ee_set_id()
		let sets 			= province_data.ee_sets || []
		if (sets.length == 0)
		{
			selected_ee 	= "E0000"
			sets 			= [{id:"E0000", name: i18("EE:UNCONSTRAINED"), constraint:"unconstrained"}]
		}

		let ee_data 		= sets ? sets.find( x => x.id === selected_ee ) : null
		if (!ee_data)
			ee_data			=  {name:i18("EE:UNCONSTRAINED"), constraint: 'unconstrained'}

		const templates 	= province_data.templates

		console.log( province_data )

		const ee = province_data.ee_sets.find( e => e.id === province_data.selected_ee_set)
		const ee_string = ee ? (ee.id == 'E0000' ? i18("EE:UNCONSTRAINED") : ee.name) : ""
		const chp_string = province_data.selected_chp === true ? "ON" : "OFF"

		const ee_final = !ee ? '' : <div className='smaller'><br />{i18("MENU:EE")}: <i>{ee_string}</i> <br /> {i18("MENU:CHP")}: <i>{chp_string}</i></div>


		return <div className='scenario inputform'>

				<div className='chartblock'>
				<div className='ee'>
					<div className='head'>
						<span>{i18("HEADLINE")}</span>
						&nbsp;
						<span>{i18("PROVINCE", this.get_province())}</span>
					</div>

					<div className='helpblock'>
						<div className='text'>{i18("HELPTEXT:LINE1")}</div>
						<div className='text'>{i18("HELPTEXT:LINE2")}</div>
						<div className='text'>{i18("HELPTEXT:LINE3")}</div>
					</div>

						<div className='head'>
							<div className='title'>{i18("EE:TITLE")}</div>
						</div>

						<div className='helpblock'>
							<div className='text'>{i18("EE:HELPTEXT:LINE1")}</div>
							<div className='text'>{i18("EE:HELPTEXT:LINE2")}</div>
						</div>

						<div className='contents'>
							<ElecExchShow parent={this} can_operate={can_operate} data={ee_data} province={province} selected={selected_ee} sets={sets} />
						</div>

						{can_operate && <div className='form-controls'>
							{
								can_operate && <input className='action' type='submit' onClick={this.addEESet} value={i18("EE:ACTION:ADD")}/>
							}
							&nbsp;
							&nbsp;
							{
								can_operate && selected_ee !== 'E0000' &&
							<input className='action' type='submit' onClick={this.editEESet(selected_ee)} value={i18("EE:ACTION:EDIT")}/>
							}
						</div>}
					</div>


					<div className='ee'>
						<div className='head'>
							<div className='title'>{i18("CHP:TITLE")}</div>
						</div>
						<div className='helpblock'>
							<div className='text'>{i18("CHP:HELPTEXT:LINE1")}</div>
							<div className='text'>{i18("CHP:HELPTEXT:LINE2")}</div>
							<div className='text'>{i18("CHP:HELPTEXT:LINE3")}</div>
						</div>

						{
							can_operate && 
							<div className='form-controls'>
								<input className='action' type='checkbox' onChange={this.set_chp} checked={this.get_chp() == 'on'} />
								{i18("CHP:TEXT")}
							</div>
						}
					</div>



					<div className='ee'>
						<div className='head'>
						<div className='title'>{i18("DEFCONF:TITLE")}</div>
						<div className='title'>{ee_final}</div>
						</div>
						<div className='helpblock'>
							<div className='text'>{i18("DEFCONF:TEXT")}</div>
						</div>
						{
							can_operate &&
							<div className='form-controls'>
								<input className='action' type='submit' onClick={this.setDefault} value={i18("DEFCONF:ACTION:SET")} />
							</div>
						}
					</div>

					
					<div className='ee'>
						<div className='head'>
							<div className='title'>{i18( "SCN:HEADLINE" )}
							&nbsp;
							<span>{i18("PROVINCE", this.get_province())}</span></div>
						</div>

					<div className='helpblock'>
						<div className='text'>{i18("SCN:SCN:HELPTEXT:LINE1")}</div>
						<div className='text'>{i18("SCN:SCN:HELPTEXT:LINE2")}</div>
						<div className='text'>{i18("SCN:SCN:HELPTEXT:LINE3")}</div>
						<div className='text'>{i18("SCN:SCN:HELPTEXT:LINE4")}</div>
						<div className='text'>{i18("SCN:SCN:HELPTEXT:LINE5")}</div>					

					</div>

						<div className='contents'>

							<table className='scenario_table'>
								<thead>
									<tr>
									<td width='25em'>{i18("NAME")}</td>
										<td>{i18("DEM")}</td>
										<td>{i18("POT")}</td>
										<td>{i18("RE")}</td>
										<td>{i18("STATUS")}</td>
										<td></td>
										<td></td>
									</tr>
								</thead>
								<tbody>
									{
										templates.group_ids.map( gid =>
												this.render_group( i18, templates.groups[gid], province_data, can_operate ))
									}
								</tbody>
							</table>
						</div>

						{
							can_operate &&
							<div className='form-controls'>
								<input className='action' type='submit' onClick={this.addCustom} value={i18("ACTION:ADDCUSTOM")}/>
							</div>
						}
					</div>

				</div>
		</div>
	}
}


Object.assign(ScenarioView.prototype, Navigation)


class ScenarioFrame extends Component
{
	static contextType = AppController;

	constructor( props )
	{
		super( props )
		this.inflight 	= false
		this.state 		= {timestamp: Date.now()}
	}


//	find_scenario( pdata, scenario )
//	{
//		if (!pdata || !scenario || !pdata.scenarios)
//			return null
//		for( let p of pdata.scenarios )
//		{
//			if (p.base_id == scenario)
//			{
//				const sdata = {...p.parameters}
//				sdata.use_ghg_limit  		= sdata.CUSTOM_PD_SCENARIOGHGISCONSTRAINT > 0
//				sdata.use_area_competition	= sdata.CUSTOM_PD_SCENARIOAREACOMPETITION > 0
//				sdata.ghg_limit 			= sdata.CUSTOM_PD_GHGLIMIT
//				sdata.group_name 		 	= p.name
//				sdata.efficiency 			= 'EE'
//
//				return sdata
//			}
//		}
//
//		return null
//	}


	render_edit()
	{
		const {match,history} 			= this.props
		const {province, scenario}		= this.props.match.params

		const provinces 		= this.context.provinces
		if (!provinces)
			return <div></div>

		const province_data  	= this.get_province_data()

		if (!province_data)
			return <div>Province not found {province}</div>

		console.log( province_data )

		const scenario_data 	= province_data.templates.groups[scenario]


		return  <MasterFrame province={this.get_province()} history={history} match={match}>
					<div className={classnames("bottom")}>
						<div className='sankey-main'>
							<div className='sankey-menu'>
								<div className='section'>&nbsp;</div>
								<Help id='scenario-edit'/>
							</div>
							<div className='sankey-container'>
								<ScenarioEdit 	history={history}
												match={match}
												province={province}
												scenario={scenario}
												scenario_data={scenario_data} />
							</div>
						</div>
					</div>
				</MasterFrame>
	}


	render_view()
	{
		const {match,history, location} 	= this.props
		const params = queryString.parse(location.search)

		const {province}		= this.props.match.params

		const provinces 		= this.context.provinces
		if (!provinces)
			return <div></div>

			
		const province_key  	= province.toUpperCase()
		const province_data		= provinces[province_key]


		if (!province_data)
			return <div>Province not found {province}</div>

		const ee_set_id = this.get_ee_set_id()
		const chp 		= this.get_chp()
		const scen_key 	= ({ ee_set_id, chp }) => `${chp == 'on' ? 'ON' : 'OFF'}-${ee_set_id}`
		const key = scen_key({ ee_set_id: this.get_ee_set_id(), chp: this.get_chp() })
		const scenarios = province_data.all_scenarios[key]


		const open_groups = `${params.open || ''}`.split(",").map(x => x.trim())


		return  <MasterFrame province={this.get_province()} history={history} match={match}>
					<div className={classnames("bottom")}>
						<div className='sankey-main'>
							<div className='sankey-menu'>
								{
									this.variant_selector()
								}
								<Help id='scenario-view'/>
							</div>
							<div className='sankey-container'>
							{ scenarios && 
								<ScenarioView 	history={history}
												open_groups={open_groups}
												match={match}
												allow_download={this.props.allow_download}
												province={province}
												province_data={province_data}
												scenarios={scenarios} />}
							</div>
						</div>
					</div>
				</MasterFrame>
	}


	render()
	{
		const {match,history, location} 	= this.props
		const {edit} 						= this.props
		const params = queryString.parse(location.search)

		const {province, scenario}			= this.props.match.params
		if (scenario == 'custom')
			return this.render_edit()
		else if (edit)
			return this.render_edit()
		else
			return this.render_view( params )
	}
}

Object.assign(ScenarioFrame.prototype, Navigation)

export default ScenarioFrame
