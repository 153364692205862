import React, { Component, Fragment } 	from "react";
import classnames       				from 'classnames';
import Dropzone 						from 'react-dropzone'

import backend_config					from '../config/runconfig.js'

import ReactTooltip 					from 'react-tooltip'
import SetupFrame						from '../setup/SetupFrame'
import {AppController} 					from '../app/AppController'
import axios 							from 'axios'

import {Help} 							from '../help/Help'


const  upload_icon  	= require('../../public/icons/file-upload.svg')

class ParamUpload extends Component
{
	static contextType = AppController;
	constructor( props )
	{
		super( props )
		this.state = {
			status: 	"waiting",
			message: 	null,
			uploads:  	{}
		}
	}

	update_progress( filename, progress, message )
	{
		const uploads = this.state.uploads


		if (progress == null)
			delete uploads[filename]
		else
		{
			if (!uploads[filename])
				uploads[filename] = {}

			uploads[filename].progress 	= progress
			uploads[filename].message 	= message
		}
		const status = Object.keys( this.state.uploads ).length == 0 ? 'waiting' : 'uploading'
		this.setState( {status: status, uploads: this.state.uploads} )
	}


	upload_file( target, files )
	{
		files.forEach(file =>
		{
			this.update_progress( file.name, 0 )

			const form_data 	= new FormData();

			const url 			= `${backend_config.server}/system/upload-params`
			console.log( url )

			form_data.append("file", file)
			const req = axios.post(url, form_data,
			{
    			headers: {'Content-Type': 'multipart/form-data'},
    			onUploadProgress: (progress_event) =>
    			{
    				let percent = 0
    				if (progress_event.total > 0)
    					percent = Math.floor( progress_event.loaded * 100 / progress_event.total )
    				this.update_progress( file.name, percent )
    			}
    		})

      		req.catch( err => {
				this.setState(  {
									status: "error",
									message: "Connection Failure"
								})
      		})

	    	req.then( response =>
	    	{
				const {data}   = response
    			if (data)
    			{
    				if (data.status === 'success')
    				{
							this.update_progress( file.name, 1000 )
							setTimeout( () => this.update_progress( file.name, null), 5000 )
					}
    				else
    				{
    					const error = result.error
    					this.update_progress( file.name, -1, error )
    				}
    			}
	    	});
    	});
	}


	render_upload( filename, status )
	{
		let msg 				= ''
  	  	let progress 			= 0
  	  	let progress_percent 	= 0

  	  	if (status.progress === 0)
  	  	{
  	  		msg = 'Connecting...'
  	  	}
   	  	else if (status.progress > 100)
  	  	{
  	  		msg = 'Success'
  	  	}
  	  	else if (status.progress)
  	  	{
  	  		progress_percent = Math.round( status.progress )
			if (progress_percent > 99)
				msg = 'Analyzing File'
			else
				msg = `Uploading ${progress_percent} %`
		}

		return 	<div key={`UP-${filename}`} className='single_upload'>
							<div className='filename'>{filename}</div>
							<div className='progress'>{msg}</div>
				</div>
	}

	render_uploads()
	{
		const uploads = []
		for( let filename in this.state.uploads )
			uploads.push( this.render_upload( filename, this.state.uploads[filename] ) )
		return uploads
	}


	render()
	{
		if (!this.context || !this.context || !this.context.ctx)
			return <div />

		const lang = this.context.lang
		const i18 = this.context.ctx.i18nstrp("")

		return <div className='inputform'>
	    			<div className='head'>
						<div className='title'>{i18("SETUP:UPLOAD:TITLE")}</div>
	    				<div className='message'>
	    				{
	    					this.state.message
	        			}
	        			</div>
	        		</div>
					{lang == 'en' &&
		        	<div className='help-text'>
						<p>
						The Technology Parameters describe the characteristics of all technologies implemented and modelled in <b>PEMT</b>.
						</p>

						<p>These parameters comprise of data about costs, GHG emissions etc. of all the different technologies for all the years
							considered. The value inserted for the Technology Parameters strongly influence the results of the Provincial Energy Scenarios of the Future.
							Consequently, the adjustment of these parameters should <b>only be conducted by experts</b>.
						</p>

						<p>
							<b>Wrongly inserted parameters will force the calculation of the Provincial Energy Scenarios to crash!</b>
						</p>
						<p>
							You can choose whether you would like to use the <i>predefined</i> Technology Parameters provided by the developers of <b>PEMT</b> 
							&nbsp;or whether you would like to upload your own parameters <b>at your own risk</b>. Please use the provided Excel template to be filled 
							out from the download area below.
						</p>
						<p>
							<b>Please be careful about the values for the Technology Parameters.</b>
						</p>
						<p>
							The developers of PEMT are not responsible for any user upload nor the associated occurrence of bugs.
						</p>
						<p>
							<b>Please note that all calculations need to be refreshed when providing new Technology Parameters for PEMT.</b>
						</p>

						<p>The Excel template for the parameters may be found <b><a href="/static/TechnologyParameterTemplatePEMT.xlsx">here</a></b>.</p>
		        	</div >}

				{lang == 'th' &&
					<div className='help-text'>
						<p>
							ตัวแปรเทคโนโลยีเป็นตัวแปรที่อธิบายข้อมูลเฉพาะของเทคโนโลยีพลังงานทั้งหมดที่มีในระบบ PEMT.
						</p>

					<p>ตัวแปรเหล่านี้ประกอบด้วยข้อมูลประสิทธิภาพของโรงไฟฟ้า มูลค่าการดำเนินงาน ปริมาณการปล่อยก๊าซเรือนกระจก และตัวแปรอื่น ๆ ที่เป็นข้อมูลเฉพาะของเทคโนโลยีแต่ละประเภทที่พิจารณาในช่วงปีนั้น ค่าเฉพาะของเทคโนโลยีพลังงานที่กรอกเข้าไปในระบบเป็นตัวแปรสำคัญที่ส่งผลต่อผลการคำนวณภาพฉายอนาคตพลังงานจังหวัด ดังนั้นการกำหนดค่าดังกล่าวควรเป็นค่าที่ผ่านการพิจารณาจาก
						<b>ผู้เชี่ยวชาญเท่านั้น </b>.
							</p>

						<p>
					<b>หากกรอกตัวแปรไม่ตรงตามเงื่อนไขความต้องการของระบบ จะส่งผลให้การคำนวณภาพฉายอนาคตพลังงานของจังหวัดผิดพลาดได้!</b>
						</p>
						<p>
					ผู้ใช้งานสามารถเลือกได้ว่าจะใช้ตัวแปรด้านเทคโนโลยีที่ PEMT จัดหามาให้ หรืออัปโหลดค่าเฉพาะของประเทศเข้าไปใน PEMT สำหรับผู้ใช้งานที่ต้องการอัปโหลดค่าเฉพาะของประเทศเข้าไปในระบบ ให้ดาวน์โหลดแบบฟอร์ม Excel ด้านล่างเพื่อนำไปกรอกข้อมูลดังกล่าวแล้วอัปโหลดแบบฟอร์มที่กรอกข้อมูลแล้วกลับเข้าไปในระบบอีกครั้ง
						</p>
						<p>
					<b>กรุณาตรวจสอบตัวแปรด้านเทคโนโลยีให้ถูกต้องก่อนอัปโหลดข้อมูลกลับเข้าไปใน PEMT</b>
						</p>
						<p>
					ทีมผู้พัฒนา PEMT จะไม่รับผิดชอบความผิดพลาดที่เกิดจากการอัปโหลดข้อมูลโดยผู้ใช้งาน รวมถึงข้อบกพร่องที่เกิดกับผลการคำนวณภาพฉายอนาคตที่เกิดขึ้นจากการอัปโหลดข้อมูลโดยผู้ใช้งาน
						</p>
						<p>
					<b>โปรดทราบว่าภาพฉายอนาคตด้านพลังงานของจังหวัดจะถูกคำนวณใหม่ทั้งหมดทุกครั้ง เมื่อมีการอัปเดทตัวแปรด้านเทคโนโลยีที่ PEMT</b>
						</p>

				<p>แบบฟอร์ม Excel สำหรับกรอกตัวแปรสามารถดาวน์โหลดได้  <b><a href="/static/TechnologyParameterTemplatePEMT.xlsx">ที่นี่</a></b>.</p>
					</div >}



					<Dropzone onDrop={files => this.upload_file( "", files )}>
					  {({getRootProps, getInputProps}) =>
					  	{
					      return (<div {...getRootProps()}>
								        <input {...getInputProps()} />
								        <div className='dropzone'>
						        		{
						        			this.state.status === 'uploading' &&
								        	<div className='description'>
							        			<div className='message'>
							        			{
							        				this.render_uploads()
							        			}
							        			</div>
								       	 	</div>
								        }
							        	{
						        			this.state.status !== 'uploading' &&
						        			<div className='description'>
						        				<div className='message'>
							       	 				{i18("SETUP:UPLOAD:FUNCTIONFIELDTEXT1")}
							       	 				<br/>
											  		{i18("SETUP:UPLOAD:FUNCTIONFIELDTEXT2")}
													<br/>
								       	 		</div>
									       	 	<div className='drop-icon'>
									       	 		<img src={upload_icon}/>
									       	 	</div>
								       	 	</div>
								       	}
							       		</div>
							    </div>
					      	)
					 	}
					 }
					</Dropzone>
				</div>
	}
}


class UploadParams extends Component
{
	static contextType = AppController;

	constructor( props )
	{
		super( props )
		this.state = {
			dropzone 			: false,
			selected_scenario	: null,
			selected_file		: null
		}
	}


	get_province()
	{
		return 	this.props.match.params.province
	}


	render()
	{
		const {history,match}		= this.props
		const province  			= this.get_province()


		const user 		= this.context.ctx.user
		const role 		= user && user.role && user.role.kind
		const is_admin	= role == 'admin'
		const is_nat	= role == 'national'

		const can_upload = is_admin


		if (this.props.no_frame)
			return this.render_chart()
		else
			return 	<SetupFrame history={history} match={match} province={province}>
						<div className={classnames("bottom")}>
							<div className='sankey-main'>
								<div className='sankey-menu'>
									<div className='section'>&nbsp;</div>
									<Help id='uploadparams'/>
								</div>
								<div className='sankey-container'>
									{ can_upload && <ParamUpload/>}
									{!can_upload && <div>You cannot upload data for this province</div>}
								</div>
							</div>
						</div>
					</SetupFrame>
	}
}

export default UploadParams
