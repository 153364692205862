import React, { Component, Fragment } 	from "react";
import { AppController } 				from '../app/AppController'


export class UnitSelector extends Component
{

	static contextType = AppController;

	constructor( props )
	{
		super( props )
	}


	handle_unit = (unit_name) => {return (e) =>
	{
		const value = e.target.value
		const new_state = {}
		new_state[unit_name] = value
		if (this.props.callback)
			this.props.callback( new_state )
	}}


	render_unit_select( i18n, units, unit_name )
	{
		const unit_names = units[unit_name]
		return 	<select value={this.props.parent.get_units()[unit_name]}
						className='unit-select'
						onChange={this.handle_unit( unit_name )}>
				{
					unit_names.map( u => <option key  ={`unit-${unit_name}-${u}`}
							  						value={u}>{i18n(u)}
							  			 </option> )
				}
				</select>
	}


	render()
	{
		if (!this.context || !this.context.units || !this.context.ctx.i18nstrp)
			return <div/>
		
		const i18n = this.context.ctx.i18nstr

		const display_energy 	= this.props.display_energy 	!== false
		const display_area 		= this.props.display_area 		!== false
		const display_currency 	= this.props.display_currency 	!== false
		return 	<div className='section'>
					{
						display_energy &&
						<div className='unit-selector'>
							<div className='title'>{i18n('MENU:ENERGY')}</div>
							{
								this.render_unit_select( i18n, this.context.units, 'energy')
							}
						</div>
					}
					{
						display_area &&
						<div className='unit-selector'>
							<div className='title'>{i18n('MENU:AREA')}</div>
							{
								this.render_unit_select( i18n, this.context.units, 'area')
							}
						</div>
					}
					{
						display_currency &&
						<div className='unit-selector'>
							<div className='title'>{i18n('MENU:CURRENCY')}</div>
							{
								this.render_unit_select( i18n, this.context.units, 'currency')
							}
						</div>
					}
				</div>
	}
}

export default UnitSelector