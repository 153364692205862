import React, { Component } from "react"
import {useContext} 		from "react"
import classnames       	from 'classnames'

import MasterFrame			from '../app/MasterFrame'
import {PEMTDataModel}   	from "../datamodel/pemt_data_model.js"
import {TreeEditor}			from "../TreeEditor.js"
import axios 				from 'axios'

import backend_config		from "../config/runconfig.js"
import { AppController } 	from '../app/AppController'

import {Help}				from '../help/Help'

import { Navigation } 		from '../navigation/navigation'

class XLSViewer extends Component
{

	static contextType = AppController;

	constructor( props )
	{
		super( props )
		this.state 	= {
			error 		: 	null,
			excel		: 	null,
			view_model	: 	null,
		}
	}


	fetch_data()
	{
		const component 	= this
		const {province} 	= this.props

		const url_params = this.get_backend_query()
		console.log( url_params )


		const year_key    	= this.context ? this.context.year_key : 'NONE'
		const url 			= `${backend_config.server}/system/province-data?${url_params}&province=${province}&year-key=${year_key}`
		if (url === this.displayed_url)
			return

		console.log( "Fetching " + url )
		axios.get(url)
			.then( (incoming) =>
			{
				this.displayed_url 	= url
				this.inflight 		= false
				const {data}		= incoming

				if (data.status === "error")
				{
					console.log( "Error")
					console.log( data.error )
					component.setState( {error: true, message: data.error})
				}
				else
				{
					component.set_data( data.result )
				}
			})
	}



	set_data( excel )
	{
		if (!excel)
			this.setState( {error: true, task : null, view_model: null})
		else
		{
			console.log( "---------")
			console.log( excel )
			const params = {
				i18: 		this.props.i18,
				tree: 		excel.tree,
				years: 		excel.years,
				province: 	excel.province
			}
			const pemt_model 	= new PEMTDataModel(params)
			excel.pemt_model = pemt_model


			excel.pemt_model.view_model.addLayoutCallback( (x) =>
			{
				this.setState( {error:false, excel: this.state.excel, units: excel.units } )
			})
			this.setState(     {error:false, excel: excel, units: excel.units } )
		}
	}


	componentDidMount()
	{
		setTimeout( 1, this.fetch_data() )
	}


	componentDidUpdate() {
		setTimeout(1, this.fetch_data())
	}


	render()
	{
		if (this.state.error)
		{
			return <div className='notification error'>Error: {this.props.task_id} {this.state.error}</div>
		}
		else
		{
			if (!this.context || !this.context.ctx.i18nstr ||!this.state.excel || !this.state.excel.pemt_model)
				return <div />
			const i18 = this.context.ctx.i18nstrp("INPUT")
			this.state.excel.pemt_model.view_model.min_level = 2

			return 	<div className='sankey-main'>
						<div className='sankey-menu'>
							{this.unit_selector()}
							<Help id="raw-data"/>
						</div>
						<div className='sankey-container'>							
							{this.state.excel && 
								<div className={classnames("bottom", "grid-on", "sidebar-on", "chart-on")}>

									<div className='fill-with-title-spreadsheet'>
										<div className='helpblock'>
											<div className='title'>
												<span>{i18("HEADLINE")}</span>
												&nbsp;
												<span>{i18("PROVINCE", this.get_province())}</span>
											</div>
											<div className='text'>{i18("HELPTEXT:LINE1")}</div>
											<div className='text'>{i18("TXT:INPUTDATA")}</div>
										</div>
										<div className='sheetblock'>

											<TreeEditor 
														display_units={true}
														warnings_empty={false}
														view_model={this.state.excel.pemt_model.view_model}
														mark_jumps={true}
														i18={this.props.	i18}
														commit={this.commit} />
										</div>
									</div>			
								</div>
							}
							{!this.state.excel && <div></div>}
						</div>
					</div>
		}
	}
}

Object.assign(XLSViewer.prototype, Navigation)



const RawData = ( props ) =>
{
	const context = useContext( AppController );

	if (!context || !context.ctx.i18nstr)
		return <div />

	const i18 = context.ctx.i18nstr

	const {match,history} 	= props
	const province 			= match.params.province
	return  <MasterFrame history={history} match={match} province={province}>
				<XLSViewer province={province} match={match} history={history} i18={i18}/>
			</MasterFrame>
}

export default RawData
