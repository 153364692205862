import React, { Component, Fragment } from "react";
import classnames       from 'classnames';
import MasterFrame		from '../app/MasterFrame'
import '../index.css';
import Highcharts                       from 'highcharts'
import HighchartsReact                  from 'highcharts-react-official'
import backend_config					from "../config/runconfig.js"
import axios 							from 'axios'
import {AppController}                  from '../app/AppController'

import {Help}                           from '../help/Help'

const API_KEY 		= 'ExgWFH9XHHyXIHyKEHIH'



class LoadProfile extends Component
{

    static contextType = AppController;

    primary_qd     = [ 'ELEC',
                    'HEAT']

    secondary_qd   = [ 'RES',
                    'AGR',
                    'IND',
                    'COM',
                    'TRA',
                    'OTH',
                    'ALL' ]

    primary_sd     = [ 'ELEC',
                    'HEAT']

    secondary_sd   = [ 'RES',
                    'AGR',
                    'IND',
                    'COM',
                    'TRA',
                    'OTH',
                    'ALL' ]



    series_qd = {
                'ELEC':
                                {   'RES':       'QD_FinalEgTotXODresSeries',
                                    'AGR':      'QD_FinalEgTotXODagrSeries',
                                    'IND':        'QD_FinalEgTotXODindSeries',
                                    'COM':        'QD_FinalEgTotXODcomSeries',
                                    'TRA':         'QD_FinalEgTotXODtraSeries',
                                    'OTH':            'QD_FinalEgTotXODothSeries',
                                    'ALL':              'QD_FinalEgTotXOSeries'     },

                'HEAT':
                                {   'RES':       'QD_FinalHTotHODresSeries',
                                    'AGR':      'QD_FinalHTotHODagrSeries',
                                    'IND':        'QD_FinalHTotHODindSeries',
                                    'COM':        'QD_FinalHTotHODcomSeries',
                                    'TRA':         'QD_FinalHTotHODtraSeries',
                                    'OTH':            'QD_FinalHTotHODothSeries',
                                    'ALL':              'QD_FinalHTotHOSeries'  },

                'Fuel demand for mobility':
                                {   'RES':       'QD_FinalMTotXIDresSeries',
                                    'AGR':      'QD_FinalMTotXIDagrSeries',
                                    'IND':        'QD_FinalMTotXIDindSeries',
                                    'COM':        'QD_FinalMTotXIDcomSeries',
                                    'TRA':         'QD_FinalMTotXIDtraSeries',
                                    'OTH':            'QD_FinalMTotXIDothSeries',
                                    'ALL':              'QD_FinalMTotXISeries'  },

                'Fuel demand for non-energetic purposes':
                                {   'RES':       'QD_FinalNTotXIDresSeries',
                                    'AGR':      'QD_FinalNTotXIDagrSeries',
                                    'IND':        'QD_FinalNTotXIDindSeries',
                                    'COM':        'QD_FinalNTotXIDcomSeries',
                                    'TRA':         'QD_FinalNTotXIDtraSeries',
                                    'OTH':            'QD_FinalNTotXIDothSeries',
                                    'ALL':              'QD_FinalNTotXISeries'  }
            }



    series_sd = {
                'ELEC':
                                {   'RES':       'SD_KmdSiteEDres',
                                    'AGR':      'SD_KmdSiteEDagr',
                                    'IND':        'SD_KmdSiteEDind',
                                    'COM':        'SD_KmdSiteEDcom',
                                    'TRA':         'SD_KmdSiteEDtra',
                                    'OTH':            'SD_KmdSiteEDoth',
                                    'ALL':              'SD_KmdSiteED' },

                'HEAT':
                                {   'RES':       'SD_KmdSiteHDres',
                                    'AGR':      'SD_KmdSiteHDagr',
                                    'IND':        'SD_KmdSiteHDind',
                                    'COM':        'SD_KmdSiteHDcom',
                                    'OTH':            'SD_KmdSiteHDoth',
                                    'ALL':              'SD_KmdSiteHDall'  }
            }


	chartOptions(data, i18n )
	{
        function range( start, end, inc )
        {
            const st1   = Math.floor(start)
            const st    = Math.floor( st1 / 3 ) * 3
            const x = []
            for( let i = st; i <= end; i += inc)
                x.push( i )
            return x
        }

       const chart = {
                        marginBottom: 100,
                        margin: 100,
                        zoomType: 'x'
            }

        const rect  = this.containerRect()
        if (rect.height)
        {
            chart.height = rect.height
            chart.width  = rect.width

            this.lastRect.height = rect.height
            this.lastRect.width  = rect.width
        }

   		const options = {
   			chart: chart,
            title: {
                        text: `${i18n('LOADPROFILE_DEMAND', this.state.primary)}: ${i18n('LOADPROFILE_SECTOR',this.state.secondary)}`
            },
            subtitle: {
                text: document.ontouchstart === undefined ?
                    'Click and drag in the plot area to zoom in' : 'Pinch the chart to zoom in'
            },
            xAxis: {
                type: 'number',
                //tickPosMonth: this.tickPosMonth,
                tickPositioner: (min,max) => {
                    if (max-min < 50)
                        return range( min,max,1 )
                    if (max - min < 150 )
                        return range( min,max,6 )
                    if (max - min < 250 )
                        return range( min,max,12 )
                    if (max-min < 2500)
                        return this.tickPosDay

                    return this.tickPosMonth
                },
                labels:
                {
                    formatter: (v) => {
                        const val   = Math.floor(v.value)
                        const ti    = this.tickIndex[val]
                        let rv = ''
                        if (ti)
                            rv = ti
                        else
                            rv = `${(val % 24)}:00`
                        return rv
                    }
                }
            },
            yAxis: {
                title: {
                    text: i18n("LOADPROFILE_GRAPH_LABEL:YAXIS")
                },
                labels: {
                    format: '{value:.1f}'
                }
            },
            legend: {
                enabled: false
            },

            plotOptions: {
                line: {
                    marker: {
                        radius: 0
                    },
                    lineWidth: 2,
                    states: {
                        hover: {
                            lineWidth: 1
                        }
                    },
                    threshold: null
                }
            },

            series: [{
                name: 'Load Profile',
                data: data
            }]
        }
        return options
	}




	constructor( props )
	{
		super( props )
		this.state = {profiles : null, count : 0, primary: 'ELEC', secondary: 'ALL', group: 'BAU'}
        this.chartRef   = React.createRef()
        this.lastRect   = {height: -1000, width: -1000}


	}

    tickTick(i18n)
    {
        const month         = ['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec']
        const dpm           = [31,28,31,30,31,30,31,31,30,31,30,31]
        this.tickIndex      = {}
        this.tickPosMonth   = []
        this.tickPosDay     = []
        dpm.reduce( (acc,v,idx) => {
                    const hour = acc*24
                    this.tickPosMonth.push( hour );
                    this.tickPosMonth.push( hour + 10 * 24 );
                    this.tickPosMonth.push( hour + 20 * 24 );

                    for( let day = 0; day < dpm[idx]; day++)
                    {
                        this.tickIndex[hour + day*24] = `${day}`
                        this.tickPosDay.push( hour + day*24 )
                    }

                    this.tickIndex[hour] = i18n('LOADPROFILE_LABEL', month[idx])
                    return acc + v
                }, 0 )
    }

    containerRect()
    {
        if (this.chartRef.current)
        {
            const chartRect  = this.chartRef.current.getBoundingClientRect()
            return {
                        height: Math.floor( chartRect.height ),
                        width:  Math.floor( chartRect.width )
                    }
        }
        else
            return {
                        height: null,
                        width:  null
                    }
    }

    reRender = false
    checkForReRender(rect)
    {
        return (this.reRender ||
                rect.height == null ||
                rect.height != this.lastRect.height ||
                rect.width  != this.lastRect.width)

    }

    refresh()
    {
        this.setState( {count: this.state.count + 1})
    }

    checkAndSheduleRefresh()
    {
        const rect = this.containerRect()
        if (this.checkForReRender(rect))
           setTimeout( this.refresh(), 0 )

    }

    componentDidMount()
    {
        this.checkAndSheduleRefresh()
    }

    componentDidUpdate()
    {
        this.checkAndSheduleRefresh()
    }


	fetch_profile()
	{
        const sd        = this.props.kind == 'sd'
		const component = this
		const province 	= this.props.match.params.province

        let series_name
        let prim_set

        if (sd)
        {
            prim_set            = this.series_sd[this.state.primary]
            if (prim_set)
                series_name     = prim_set[this.state.secondary]
        }
        else
        {
            prim_set            = this.series_qd[this.state.primary]
            if (prim_set)
                series_name     = prim_set[this.state.secondary]
        }

        if (!series_name)
            return <div>Data Set not Found</div>

        const year_key          = this.context ? this.context.year_key : 'NONE'
		let url

        if (!sd)
            url = `${backend_config.server}/qdata/query?key=${series_name}&province=${province}&api_key=${API_KEY}&year-key=${year_key}`
        else
            url = `${backend_config.server}/sdata/query?key=${series_name}&scenario=${this.state.group}&province=${province}&api_key=${API_KEY}&year-key=${year_key}`

        if (this.displayed_url == url)
            return
		console.log( url )

        this.inflight = true
		axios.get(url)
		 .then( (incoming) =>
		{
            this.displayed_url  = url
			const {data}		= incoming
			if (data.status === 'error' )
			{
				console.log( "Error Focus")
                console.log( data.error )
                component.setState( {error: true, data: null})
			}
			else
			{
				component.setState( {error: false, data: data.result.value[series_name.toUpperCase()].data } )
			}
		})
	}

    handle_group = (e) => {
        this.setState( {group: e.target.value})
    }

    render_group_select(i18n)
    {
        if (this.props.kind !== 'sd')
            return null

        return (
        <div className='section'>
            <div className='unit-selector'>
                <div className='title'>{i18n("TXT:SCENARIO")}</div>
                <select onChange={this.handle_group} value={this.state.group} className='unit-select'>
                    <option key='group-select-BAU'      value='BAU'   >{i18n('SCN:BAU')}</option>
                    <option key='group-select-EE'       value='EE'    >{i18n('SCN:EE')}</option>
                    <option key='group-select-EEPLUS'   value='EEPLUS'>{i18n('SCN:EEPLUS')}</option>
                </select>
            </div>
        </div>
        )
    }


    handle_primary = (e) => {
        let primary      = e.target.value
        let secondary   = null // No state change
        if (this.props.kind == 'sd')
        {
            if (primary == 'Fuel demand for mobility' ||
                primary == 'Fuel demand for non-energetic purposes' ||
                primary == 'Total Energy Demand')
                secondary = 'ALL'
        }

        if (secondary != null)
            this.setState({ primary: primary, secondary })
        else
            this.setState({primary: primary} )
    }

    render_primary(i18n)
    {
        const primary = this.props.kind == 'sd' ? this.primary_sd : this.primary_qd
        return  <div className='unit-selector'>
                    <div className='title'>{i18n('LOADPROFILE_LABEL','DEMAND')}</div>
                    <select onChange={this.handle_primary} value={this.state.primary}>
                    {
                    primary.map((value, i) => <option key={`p${i}`} value={value}>{i18n('LOADPROFILE_DEMAND', value)}</option>)
                    }
                    </select>
                </div>

    }

    handle_secondary = (e) => { this.setState({ secondary: e.target.value }) }
    render_secondary(i18n)
    {
        const secondary = this.props.kind == 'sd' ? this.secondary_sd : this.secondary_qd

        return  <div className='unit-selector'>
            <div className='title'>{i18n('LOADPROFILE_LABEL', 'SECTOR')}</div>
                    <select onChange={this.handle_secondary} value={this.state.secondary}>
                    {
                    secondary.map((value, i) => <option key={`p${i}`} value={value}>{i18n('LOADPROFILE_SECTOR', value)}</option>)
                    }
                    </select>
                </div>

    }

    componentDidUpdate()
    {
        this.fetch_profile()
    }

	componentDidMount()
	{
		this.fetch_profile()
	}



	render()
	{
        if (!this.context || !this.context.ctx.i18nstr)
            return <div />

        const i18n = this.context.ctx.i18nstr

        this.tickTick( i18n )

		const {match,history} 	= this.props
		const {data}		= this.state


        this.reRender = this.containerRect().height == null

		return  <MasterFrame province={this.props.match.params.province} history={history} match={match}>
					<div className={classnames("bottom", "chart-on")}>
						<div className='sankey-main'>
                            <div className='sankey-menu'>
                                {this.render_group_select(i18n)}
                                <div className='section'>
                                    {this.render_primary(i18n)}
                                </div>
                                <div className='section'>
                                    {this.render_secondary(i18n)}
                                </div>

                                <Help id="loadprofile" />
							</div>
							<div className='sankey-container' ref={this.chartRef}>
                                <div className='fill-with-title'>
                                    <div className='helpblock'>
                                        <div className='title'>
                                            <span>{i18n("LOADPROFILE:HEADLINE")}</span>
                                            &nbsp;
                                             <span>{i18n("PROVINCE", this.props.match.params.province)}</span>
                                        </div>
                                        <div className='text'>{i18n("LOADPROFILE:HELPTEXT")}</div>
                                        <div className='text'>{i18n("TXT:INPUTDATA")}</div>
                                    </div>
                                    <div className='chartblock'>
                                    {
                                        data &&
                                        <div >
                                        {
                                        this.reRender ?
                                        <div/> :
                                        <HighchartsReact
                                            highcharts={Highcharts}
                                                options={this.chartOptions( data, i18n ) } />
                                        }
                                        </div>
                                    }
                                    </div>
                                </div>
		    		        </div>
	    		       </div>
    		       </div>
				</MasterFrame>
	}
}

export default LoadProfile
