import React, { Component } from "react";


export class AxisSelect extends Component
{

    handleChange = (e) =>
    {
        var value = e.target.value;
 		console.log( value )
		const {view_model, axis_name} 	= this.props
		view_model.setAxis( axis_name, value )
    }


	render()
	{
		const {view_model, axis_name} 	= this.props
		const list_only 				= (this.props.list_only === true) ? true : false
		const {data_store}				= view_model
		const {axis_list}				= data_store

		return 	<select className='axis-select' value={view_model.getAxisName( axis_name )} onChange={this.handleChange}>
				{
					axis_list.filter( ax => !list_only || ax.value_kind == 'list' ).
							  map(    ax => <option key  ={`axis-${ax.name}`}
							  						value={ax.name}>{ax.display}
							  				</option> )
				}
				</select>
	}
}