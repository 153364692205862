
import React, { Component, Fragment } 		from "react";
import classnames       					from 'classnames';
import MasterFrame							from '../app/MasterFrame'
import {AppController} 						from '../app/AppController'
import backend_config						from "../config/runconfig.js"
import axios 								from 'axios'
import queryString 							from 'query-string'
import {fmt, default_value, trimmed_or_blank} from  '../utils/utils'

import {Help}							from '../help/Help'

import {Form, TextBox, CheckBox, SubmitButton, ActionButton, ActionLink,
		HideShow, Select, Error}		from '../form/form'


const API_KEY 		= 'ExgWFH9XHHyXIHyKEHIH'

export class ScenarioEdit extends Component
{
	static contextType = AppController;

	constructor(props)
	{
		super(props)

		const incoming 	= this.props.scenario_data || {}

		if (!incoming.name)
			incoming.name 		= ''
		if (!incoming.efficiency)
			incoming.efficiency = 'BAU'
		if (!incoming.parameters)
			incoming.parameters = {}

		this.form_data = {
			name: 			incoming.name,
			efficiency: 	incoming.efficiency,
			...incoming.parameters
		}

		this.form_data.use_area_competition = (incoming.parameters.CUSTOM_PD_SCENARIOAREACOMPETITION == 1)
		this.form_data.use_ghg_limit  	  	= (incoming.parameters.CUSTOM_PD_SCENARIOGHGISCONSTRAINT == 1)


		this.form_errors	= {}

		this.state 			= {mode: "display", ghg: incoming.parameters.CUSTOM_PD_GHGLIMIT, efficiency: incoming.efficiency}
	}


	handleChange = ({data})	=>
	{
		const {efficiency} 	= data
		const ghg_p  		= parseFloat( data.CUSTOM_PD_GHGLIMIT )
		const ghg 			= ghg_p && !isNaN(ghg_p) ? ghg_p : 0

		this.setState( {efficiency, ghg})
	}


  	handleSubmit = (event, json)  =>
  	{
		const {scenario}	= this.props
		const scenario_id	= scenario == 'custom' || !scenario ? '_new' : (scenario ? `${scenario}` : '')
		const url 			= `${backend_config.server}/scenario/save/${this.props.province}/${scenario_id}`

	    axios.post(url,
	    {
	      parameters: json,
	    }).then((incoming) =>
	    {
			this.close()
	    });
	}


	close = () => {
		this.props.history.push(`/scenario/${this.props.match.params.province}/predefined`)
	}

	handleDelete = (event) =>
	{
		this.setState( {mode: "confirm-delete"})
	}

	handleCancel = (event) =>
	{
		if (this.state.mode == 'confirm-delete')
			this.setState( {mode: "display"})
		else
			this.close()
	}

	handleConfirmDelete = (event) =>
	{
		const {scenario}	= this.props
		const scenario_id	= scenario == 'custom' || !scenario ? '_new' : (scenario ? `${scenario}` : '')

		const url 			= `${backend_config.server}/scenario/delete/${this.props.province}/${scenario_id}`
	    axios.post(url)
	    	 .then((incoming) =>
	    {
			this.close()
	    });
	}

	form_field_const( params )
	{
		const {title, subtitle, value, unit}	= params

		return <div className='line'>
					<div key={`title`}  	className='title'>
						{title}
					</div>

					<div key={`${name}-control`} 	className='control wide' >
					{value} {unit}
	          		</div>
	          		<div key={`${name}-errors`} className='errors'>
					</div>
				</div>
	}

	form_field_text( name, params )
	{
		const {title, subtitle, default_value, unit}	= params

		return <div className='line'>
					<div key={`${name}-title`}  	className='title'>
						{title}
						{subtitle && <div key={`${name}-subtitle`}  	className='subtitle'>{subtitle}</div>}
					</div>

					<div key={`${name}-control`} 	className='control wide' >
						<TextBox 	name={name}
									enabled = {this.state.mode == 'display'}
									required={params.required}
		          					placeholder={params.placeholder}
          							defaultValue={default_value} /> {unit}
	          		</div>
	          		<div key={`${name}-errors`} className='errors'>
	          			{<Error className='error' names={name} />}
					</div>
				</div>
	}

	input_field(name, params, select, other_name, is_min)
	{
		if (!select)
			return <div className='control inactive'/>

		const default_v = name.indexOf( "MIN" ) > -1 ? 100 : 200


		const verify_minmax = ( form, data, raw_data, errors ) =>
		{
			if (is_min)
			{
				const value 		= default_value( data[name], 		Number.NaN)
				const other_value 	= default_value( data[other_name], 	Number.NaN)

				if (value < 0)
					form.addError( errors, name, `${value} must be > 0`)

				if (other_value < 0)
					form.addError( errors, name, `${other_value} must be > 0`)


				if (!isNaN( value ) && !isNaN( other_value ) && other_value <= value)
					form.addError( errors, name, `${value} must be < ${other_value}`)
			}
		}


		return (
			<div key={`${name}-control`} 	className='control'>
				<TextBox  	errorClassName	= 'error'
							enabled 		= {this.state.mode == 'display'}
	  						name			= {`${name}`}
	  						required 		= {true}
	  						verify 			= {verify_minmax}
	  						type			= "numeric"
	  						default_value 	= {default_v}
	  						placeholder		= {params.placeholder} />
	  		</div>
	  	)
	}



	fetch_expression()
	{
		const province = this.props.province

		const component = this


		const uri 	=  `${backend_config.server}/sdata/scenarioeditdata?api_key=${API_KEY}`

		const url 	= `${uri}&province=${province}`

		if (url === this.displayed_url || this.inflight)
			return

		this.inflight 	= true

		console.log( url )
		axios.get(url)
		 .then( (incoming) =>
		{
			this.inflight 			= false

			const {data}		= incoming

			if (data.status === 'error' )
			{
				console.log( "Error In Query")
				console.log( data.error )
				this.displayed_url  = url
				this.setState( {error: true, expressions: null})
			}
			else
			{
				this.displayed_url 	= url
				this.timestamp 		= data.timestamp
				component.setState( {
										error: 			false,
										timestamp: 		data.timestamp,
									 	expressions: 	data.result.value,
									 } )
			}
		})
	}


	componentDidMount()
	{
		this.fetch_expression()
	}

	componentDidUpdate()
	{
		this.fetch_expression()
	 	if (this.chartRef && this.chartRef.current)
	 	{
 			this.containerRect()
      		this.chartRef.current.scrollLeft = 0
      		this.chartRef.current.scrollTop  = 0
    	}
	}



	form_field_scenario( i18, params )
	{
		const {name, title, subtitle, default_value, example_value, e,h,c }	= params
		const prefix 				= `CUSTOM_${name.toUpperCase()}`
		let   err 					=  []

		const name_emin 			= `${prefix}PMINEL`
		const name_emax 			= `${prefix}PMAXEL`
		const name_hmin 			= `${prefix}PMINTH`
		const name_hmax 			= `${prefix}PMAXTH`
		const name_cmin 			= `${prefix}PMINCHP`
		const name_cmax 			= `${prefix}PMAXCHP`

		const error_names = [name_emin, name_emax, name_hmin, name_hmax, name_cmin, name_cmax]
							.map( x => `${x}`)

		let example 				= null
		if (example_value &&
			this.state.expressions &&
			this.state.expressions[example_value.toUpperCase()])
		{
			example 	= this.state.expressions[example_value.toUpperCase()].value.toFixed( 0 )
		}

		return <div className='line gridline'>
					<div key={`${prefix}-title`}  	className='title'>
						{i18(`SCN:EDIT:ROWHEAD`, title)}
						{subtitle && <div key={`${prefix}-subtitle`}  	className='subtitle'>{subtitle}</div>}
					</div>

					{this.input_field( name_emin, params, e, name_emax, true )}
					{this.input_field( name_emax, params, e, name_emin, false )}
					{this.input_field( name_hmin, params, h, name_hmax, true )}
					{this.input_field( name_hmax, params, h, name_hmin, false )}
					{this.input_field( name_cmin, params, c, name_cmax, true )}
					{this.input_field( name_cmax, params, c, name_cmin, false )}

					<div key={`${prefix}-ex`} className='example'>
					{
						example_value && <div className='value'>
						{example} {i18('MW')}
						</div>
					}
					</div>

	          		<div key={`${prefix}-errors`} className='errors'>
	          			{
	          				<Error className='error' names={error_names}/>
	          			}
					</div>
				</div>
	}


  render()
  {

	  if (!this.context || !this.context.ctx.i18nstrp)
		  return <div />

	  const i18 = this.context.ctx.i18nstrp("SCN:EDIT")

  	const {mode} 	= this.state
  	const disabled 	= mode !== 'display'

  	const sd_coodtrafi_o 	= this.state.expressions ? this.state.expressions[`SD_COODTRAFI:${this.state.efficiency}`.toUpperCase()] : null
  	const sd_coodtrafi 		= sd_coodtrafi_o ? sd_coodtrafi_o.value : null

  	let   sd_coodtrafi_added 	= null
  	if (this.state.expressions && this.state.ghg && sd_coodtrafi)
  		sd_coodtrafi_added = sd_coodtrafi + this.state.ghg

    const result = (
      <Form className={classnames('inputform', disabled ? 'disabled' : null)}
      		data={this.form_data}
      		errors={this.form_errors}
      		onSubmit={this.handleSubmit}
      		onChange={this.handleChange}
      		verify={this.verify_form}>
		<div className='head'>
				<div className='title'>{i18("SCN:EDIT:TITLE")}</div>
		</div>
		<div className='formdata'>
        		{this.form_field_text( 'name', {
									title: i18("SCN:EDIT:NAME") ,
        							placeholder 	: '',
        							col_span 		: 3,
        							required 		: true
        						})}

				<div className='line'>
					<div className='title'>{i18("SCN:EDIT:EFF")}</div>
					<div className='control'>
						<Select name='efficiency' options={[{label: 'BAU'},{label:'EE'},{label: 'EE+', value: 'EEPlus'}]} />
					</div>
				</div>
		</div>

		<div className='formdata'>
				<div className='line'>
					<div className='title'>
					</div>

					<td colSpan={2} key='T1' 	className='control maintitle'>{i18('COLHEAD:ELEC')}</td>
					<td colSpan={2} key='T2' 	className='control maintitle'>{i18('COLHEAD:HEAT')}</td>
					<td colSpan={2} key='T3' 	className='control maintitle'>{i18('COLHEAD:COMBI')}</td>
					<td colSpan={2} key='T4' 	className='control maintitle'>{i18('COLHEAD:INPUT')}</td>

	          		<div  className='errors'>
					</div>
				</div>

				<div className='line'>
					<div className='title'>
					</div>

					<div key='T1' 	className='control subtitle'>{i18("COLHEAD:MIN")}</div>
					<div key='T2' 	className='control subtitle'>{i18("COLHEAD:MAX")}</div>
					<div key='T3' 	className='control subtitle'>{i18("COLHEAD:MIN")}</div>
					<div key='T4' 	className='control subtitle'>{i18("COLHEAD:MAX")}</div>
					<div key='T5' 	className='control subtitle'>{i18("COLHEAD:MIN")}</div>
					<div key='T6' 	className='control subtitle'>{i18("COLHEAD:MAX")}</div>

					<td colSpan={2} className='control subtitle'><center>{i18("SCN:EDIT:COLHEAD:POT1")}<br />{i18("SCN:EDIT:COLHEAD:POT2")}</center></td>

	          		<div  className='errors'>
					</div>
				</div>


        		{this.form_field_scenario(i18,  {
					title 			: 'PVR',
					name 			: 'pd_pvroof',
					example_value	: 'QD_PVroofPotEx',
					type 			: 'numeric',
					unit 			: 'MW',
					e 				: true,
					h 				: false,
					c 				: false
				})}

        		{this.form_field_scenario(i18,  {
					title 			: 'PVG',
					name 			: 'pd_pvground',
					example_value	: 'QD_PVgroundPotAlt',
					type 			: 'numeric',
					unit 			: 'MW',
					e				: true,
					h 				: false,
					c 				: false
				})}



        		{this.form_field_scenario(i18,  {
					title 			: 'WND',
					name 			: 'pd_wind',
					example_value	: 'QD_WindPotAlt',

					type 			: 'numeric',
					unit 			: 'MW',
					e				: true,
					h 				: false,
					c 				: false
				})}



        		{this.form_field_scenario(i18,  {
					title 			: 'HLG',
					name 			: 'pd_hydrolarge',

					type 			: 'numeric',
					unit 			: 'MW',
					e				: true,
					h 				: false,
					c 				: false
				})}


        		{this.form_field_scenario(i18,  {
					title 			: 'HSM',
					name 			: 'pd_hydrosmall',

					type 			: 'numeric',
					unit 			: 'MW',
					e				: true,
					h 				: false,
					c 				: false
				})}


        		{this.form_field_scenario(i18,  {
					title 			: 'HVS',
					name 			: 'pd_hydrovsmall',

					type 			: 'numeric',
					unit 			: 'MW',
					e				: true,
					h 				: false,
					c 				: false
				})}



        		{this.form_field_scenario(i18,  {
					title 			: 'BIM',
					name 			: 'pd_biom',
					example_value	: 'QD_BioMassPotEx',

					type 			: 'numeric',
					unit 			: 'MW',
					e				: true,
					h 				: true,
					c 				: true
				})}

        		{this.form_field_scenario(i18,  {
					title 			: 'NAP',
					name 			: 'pd_nap',
					example_value	: 'QD_NepierPotAlt',

					type 			: 'numeric',
					unit 			: 'MW',
					e				: true,
					h 				: true,
					c 				: true
				})}

        		{this.form_field_scenario(i18,  {
					title 			: 'EUC',
					name 			: 'pd_euc',
					example_value	: 'QD_EucalPotAlt',

					type 			: 'numeric',
					unit 			: 'MW',
					e				: true,
					h 				: true,
					c 				: true
				})}

        		{this.form_field_scenario(i18,  {
					title 			: 'BIG',
					name 			: 'pd_biog',
					example_value	: 'QD_BiogasPotEx',

					type 			: 'numeric',
					unit 			: 'MW',
					e				: true,
					h 				: false,
					c 				: true
				})}

        		{this.form_field_scenario(i18,  {
					title 			: 'MSW',
					name 			: 'pd_msw',
					example_value	: 'QD_MSWPotEx',

					type 			: 'numeric',
					unit 			: 'MW',
					e				: true,
					h 				: false,
					c 				: true
				})}

        		{this.form_field_scenario(i18,  {
					title 			: 'LNG',
					name 			: 'pd_ngas',

					type 			: 'numeric',
					unit 			: 'MW',
					e				: true,
					h 				: true,
					c 				: true
				})}

        		{this.form_field_scenario(i18,  {
					title 			: 'LPG',
					name 			: 'pd_lpg',

					type 			: 'numeric',
					unit 			: 'MW',
					e				: false,
					h 				: true,
					c 				: false
				})}

        		{this.form_field_scenario(i18,  {
					title 			: 'COL',
					name 			: 'pd_coal',

					type 			: 'numeric',
					unit 			: 'MW',
					e				: true,
					h 				: false,
					c 				: true
				})}

        		{this.form_field_scenario(i18,  {
					title 			: 'OIL',
					name 			: 'pd_oil',

					type 			: 'numeric',
					unit 			: 'MW',
					e				: false,
					h 				: true,
					c 				: false
				})}

		</div>

		<div className='formdata' style={ {width: '100%'} } >

			<div className='line'>
				<td colSpen={7}>
						{i18('SCN:EDIT:TEXT')}
				</td>
			</div>

			<div className='line'>

				<td colSpan={7} key='T1' 	className='control'>
			        <label>
			          <CheckBox
			          	enabled 		= {this.state.mode == 'display'}
			            name="use_area_competition" />
			            {i18("SCN:EDIT:AAC")}
	        		</label>
				</td>
	      		<div  className='errors'>
				</div>
			</div>


			<div className='line'>


				<td colSpan={7} key='T1' 	className='control'>
			        <label>
			          <CheckBox
			          	enabled 		= {this.state.mode == 'display'}
			            name="use_ghg_limit" />
							{i18("SCN:EDIT:GGLIMIT")}
	        		</label>
				</td>
	      		<div  className='errors'>
				</div>
			</div>

			<HideShow show="use_ghg_limit">
       		{this.form_field_text( 'CUSTOM_PD_GHGLIMIT', {
        							title 			: i18("SCN:EDIT:GHG:WOFUELTRANS"),
        							placeholder 	: '',
  									enabled 		: 'use_ghg_limit',
        							allow_default 	: true,
        							allow_blank 	: true,
        						})}

       		{this.form_field_const( {
									title 			: i18("SCN:EDIT:GHG:FROMFUELTRANS"),
        							value 			: fmt(sd_coodtrafi),
									unit 			: i18('t CO2 equiv.')
        						})}

       		{this.form_field_const( {
									title 			: i18("SCN:EDIT:GHG:TOTAL"),
        							value 			: fmt(sd_coodtrafi_added),
        							unit 			: i18('t CO2 equiv.')
        						})}

       		</HideShow>
        </div>


        <div className='actions'>
        	{mode == 'display' && <ActionLink	className='action' onClick={this.handleDelete} icon='trash'>
        							{i18("SCN:EDIT:DELETE")}
        						  </ActionLink>}
        </div>

    	<div className='form-controls'>
    		{mode == 'display' 		  && <SubmitButton 	className='action'
					value={i18("TXT:SAVE")}/>}
    		{mode == 'confirm-delete' && <ActionButton 	className='action' onClick={this.handleConfirmDelete}
					value={i18("SCN:ACTION:CONFIRMDELETE")}/>}
				&nbsp;&nbsp;
				&nbsp;&nbsp;
				&nbsp;&nbsp;
				&nbsp;&nbsp;

			{<ActionButton className='action' onClick={this.handleCancel} value={i18("TXT:CANCEL")} />}
    	</div>
      </Form>)

    	return result
	}


}