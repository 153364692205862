import React, { Component, Fragment } 	from "react";
import AxisPainter 					  	from "./AxisPainter"
import ResizeComponent 					from "./ResizeComponent"

const HEAD_SIZE      = 0
const TICK_WIDTH     = 140
const MARGIN         = 30


export class BarChart extends ResizeComponent
{
	constructor(props)
	{
		super(props)

		this.state = {
			count: 0,
			focused: null
		}

	}


	draw_group_labels_y_axis(svg, scaling, set_index, group_index, x, set )
	{
		if (scaling.bar_width_paint < 50)
		{
			svg.push(<text key={`SET-LABEL-${set_index}-${group_index}`} fontSize={8} textAnchor='middle'
							x={x + scaling.bar_width_paint / 2 - 3} y={scaling.chart_bottom + 15}
							transform={`rotate(90 ${x + scaling.bar_width_paint / 2 -3} ${scaling.chart_bottom + 15})`}
							>
				{set.label}</text>)
		}
		else
		{
			svg.push(<text key={`SET-LABEL-${set_index}-${group_index}`} fontSize={10} textAnchor='middle'
							x={x + scaling.bar_width_paint / 2} y={scaling.chart_bottom + 20}>
				{set.label}</text>)
		}
	}


	draw_set_labels_y_axis_top(svg, scaling, set_index, set )
	{
		if (!this.props.top_labels)
			return
		const y 	= scaling.chart_top
		const x0 	= scaling.axis_x_0 + set_index * scaling.group_width + scaling.bar_offset_paint
		const x 	= x0 + scaling.group_width_paint / 2

		const click = (this.props.detail_target && set.target) ? this.props.detail_target( encodeURIComponent(set.target) ) : null
		svg.push(<text key={`X-TITLE-TOP${x}${set_index}`} fontSize={13} textAnchor='middle' x={x} y={y - 15}>
			{set.top_label}
		</text>);
	}

	draw_set_labels_y_axis_bottom(svg, scaling, set_index, set )
	{
		const y 	= scaling.chart_bottom
		const x0 	= scaling.axis_x_0 + set_index * scaling.group_width + scaling.bar_offset_paint
		const x1 	= x0 + scaling.group_width_paint
		const x 	= x0 + scaling.group_width_paint / 2

		const click = (this.props.detail_target && set.target) ? this.props.detail_target( encodeURIComponent(set.target) ) : null
		svg.push(<text key={`X-TITLE-MAIN${x}${set_index}`} fontSize={10} textAnchor='middle' x={x} y={y + scaling.y_label_offset}>
			{set.label}
		</text>);
		svg.push(<text key={`X-TITLE-SUB${x}${set_index}`} fontSize={10} textAnchor='middle' x={x} y={y+ scaling.y_label_offset + scaling.y_sublabel_offset}>
			{set.sub_label}
		</text>);

		if (this.props.detail_target && set.target)
		{
			svg.push(<rect className='detail-link' key={`X-DETAIL-R-${x}${set_index}`} onClick={click} x={x - 35} y={y + scaling.y_label_offset + 30}
						width={70} height={15}
						stroke='#888' fill='transparent' strokeWidth={1}/>)

			svg.push(<text  className='detail-text' key={`X-DETAIL-L-${x}${set_index}`} onClick={click} fontSize={10} textAnchor='middle' x={x}
							fill='#888'
				y={y + scaling.y_label_offset + 41}>
				{this.props.detail_label} &gt;
			</text>);
		}
	}





	draw_bar_segment(svg, scaling, set_index, group_index, entry, x, y )
	{
		const id 		= entry.id
		const focus_id	= entry.focus_id

		const { focused } = this.state
		const opacity = focused ? (focused == focus_id ? '1' : '0.7') : null
		const stroke  = 'transparent'

		const label = this.props.labels[id]

		const color = label ? label.color : 'red'
		if (color == 'red')
			console.log( `COLOR FOR ${id} unknown`)
		const click = this.nodeClick(focus_id)
		const height = entry.value / scaling.range * scaling.chart_height
		y = y - height

		const key = `BAR-${set_index}-${group_index}-${entry.id}`

		const infoLabel = <title>{entry.id||''} {entry.sub_id || ''}<br/> : {entry.value.toFixed(1)}</title>


		if (height > 0)
			svg.push(<rect onClick={click} key={key} x={x} y={y} width={scaling.bar_width_paint}
						height={height} fill={color} stroke={stroke} opacity={opacity}>
						<title>{infoLabel}</title>
						</rect>)
		else
			svg.push(<rect onClick={click} key={key} x={x} y={y + height}
					width={scaling.bar_width_paint} height={-height} fill={color} stroke={stroke} opacity={opacity}>
						{infoLabel}
					</rect>)
		return y;
	}


	draw_sub_dataset_bars(svg, scaling, set_index, group_index, set  )
	{
		const x = scaling.axis_x_0 + set_index * scaling.group_width + group_index * scaling.bar_width + scaling.bar_offset_paint

		let y
		y = scaling.axis_y_0

		for (let entry of set.pos)
			y = this.draw_bar_segment(svg, scaling, set_index, group_index, entry, x, y)

		y = scaling.axis_y_0
		for (let entry of set.neg)
			y = this.draw_bar_segment(svg, scaling, set_index, group_index, entry, x, y)
	}


	draw_chart_set_labels(svg, scaling, set_index, group_index, entry, x,y)
	{
		if (!this.props.labels)
			return y
		const height = entry.value / scaling.range * scaling.chart_height;
		y = y - height;
		const key = `BAR2-${group_index}-${set_index}-${entry.id}`;
		const label = this.props.labels[entry.id]

		const value_text = entry.value !== undefined && entry.value !== null && entry.value > 0 ? `${entry.value.toPrecision(3)}` : '0'

		const yc 		 = entry.value > 0 ? y : y + height + 65
		if (entry.focus_id == this.state.focused && entry.focus_id )
		{
			const click = this.nodeClick(entry.id)
			svg.push(<text onClick={click} fontSize='14px' className="sk_label" textAnchor="middle"
				key={`${key}-text2`} x={x + scaling.bar_width /2 - 5} y={yc - 10} className="varname">{value_text}</text>);
		}
		return y;
	}


	draw_sub_dataset_labels(svg, scaling, set_index, group_index, set  )
	{
		const x = scaling.axis_x_0 + set_index * scaling.group_width + group_index * scaling.bar_width +
				  scaling.bar_offset_paint

		let y

		y = scaling.axis_y_0

		for (let entry of set.pos)
			y = this.draw_chart_set_labels(svg, scaling, set_index, group_index, entry, x, y)

		y = scaling.axis_y_0
		for (let entry of set.neg)
			y = this.draw_chart_set_labels(svg, scaling, set_index, group_index, entry, x, y)

		y = scaling.axis_y_0
		for (let entry of set.zer)
		{
			if (set.pos.length == 0 && set.neg.length == 0)
				this.draw_chart_set_labels(svg, scaling, set_index, group_index, entry, x, y - 65 )
		}

		this.draw_group_labels_y_axis(svg, scaling, set_index, group_index, x, set )
	}


	draw_dataset(svg, scaling, set_index, set)
	{
		set.data.forEach( (sub_set, group_index) =>
			this.draw_sub_dataset_bars(svg, scaling, set_index, group_index, sub_set ) )

		this.draw_set_labels_y_axis_bottom(	svg, scaling, set_index, set )
		this.draw_set_labels_y_axis_top(	svg, scaling, set_index, set )
		set.data.forEach( (sub_set, group_index) =>
			this.draw_sub_dataset_labels(svg, scaling, set_index, group_index, sub_set ) )
	}

	draw_bars(svg, scaling, chart_data )
	{
		chart_data.set_keys.forEach( (set_i, idx) =>
		{
			const set  = chart_data.data_sets[set_i]
			this.draw_dataset(svg, scaling, idx, set)
		})
	}





	nodeClick = (label) => {
		return (event) => 
		{
			if (this.state.focused == label)
				this.setState({ focused: null })
			else
				this.setState({ focused: label })
		}
	}

	create_legend_structure( chart_data, x0, xw )
	{
		const entries = []

		let y = 0
		let x = 0

		let max_labels = 0
		const label_list = {}
		let maxLen = 10
		let rows   = 1
		for (let index of Object.keys(chart_data.presence)) 
		{
			const full_label = this.props.labels[index]
			if (!full_label || !full_label.label)
			{
				label_list[index] = ['ERROR']
				continue
			}
			const split = full_label.label.split("\n")
			split.forEach(l => maxLen = Math.max(l.trim().length, maxLen))
			label_list[index] 	= split
			max_labels 			= Math.max( max_labels, split.length )
		}

		const row_height = max_labels * 5 + 20

		for (let index of Object.keys(chart_data.presence)) 
		{
			if (x + x0 > xw + MARGIN ) 
			{
				x 	 = 0
				y 	+= row_height
				rows ++
			}


			const label = this.props.labels[index]
			if (!label)
				continue

			const color = label.color
			const entry = {index, x, y, color, labels: []}
			label_list[index].forEach((l, idx) => {
				const yi 		= label_list[index].length == 1 ? 10 : 5
				entry.labels.push(	{
										x: x + 22,
										y: y + yi + idx * 10,
										txt: l
									})
			})
			entries.push(entry)
			x += 20 + maxLen * 6
		}

		return { y: y + row_height, entries, max_labels, rows}
	}

	draw_legend( svg, scaling, legend )
	{
		const y0 = scaling.legend_y0
		const x0 = scaling.axis_x_0 

		for( let entry of legend.entries )
		{
			const {x,y,color,labels, index } = entry
			const click = this.props.detailFocus ? null : this.nodeClick(index)
			svg.push( <rect onClick={click} key={`LEGR-${index}`} x={x0+x} y={y0+y} width={15} height={15} fill={color} />)

			labels.forEach( (label,idx) => 
			{
				const {x,y,txt} = label
				svg.push(
					<text onClick={click} fontSize='10px' key={`LEGT-${index}-${idx}`} x={x0 + x} y={y0 + y}>
						{txt}
					</text>)
			})
		}
	}

	draw_legend2(svg, chart_data, scaling)
	{
		let y = scaling.chart_bottom + scaling.legend_y
		let x = scaling.axis_x_0

		const label_list = {}
		let maxLen = 10
		for (let index of Object.keys(chart_data.presence))
		{
			const 	full_label 	= this.props.labels[index]
			if (!full_label)
				continue;
			const 	split 	= full_label.label.split("\n")
			split.forEach( l => maxLen = Math.max( l.trim().length, maxLen ))
			label_list[index] = split
		}

		for (let index of Object.keys(chart_data.presence))
		{
			const label = this.props.labels[index]
			if (!label)
				continue

			const color = label.color
			const click = this.props.detailFocus ? null : this.nodeClick(index)
			svg.push(<rect onClick={click} key={`LEGR-${index}`} x={x} y={y} width={15} height={15} fill={color} />)
			label_list[index].forEach( (l,idx) => {
				const yi = label_list[index].length == 1 ? 10 : 5
				svg.push(
					<text onClick={click} fontSize='10px' key={`LEGT-${index}-${idx}`} x={x+30} y={y + yi + idx*10}>
						{l}
					</text>)
			})
			x += maxLen * 8
			if (x > scaling.chart_width + scaling.axis_x_0) {
				x = scaling.axis_x_0
				y += 30
			}
		}
		return y
	}

	draw_top_label( svg, i, x,y, varname, data )
	{
		if (!this.state.chart_data[i])
			return
		const mvalue = this.state.chart_data[i][varname]
		if (mvalue && mvalue.kind == 'scalar')
		{
			let v = mvalue.value
			svg.push(<text fontSize='10' key={`TOP_VAL-${x}-${y}`} x={x} y={y}>{v}</text>)

		}

	}





	draw_svg_chart(chart_data, legend, scaling)
	{
		const axis_painter 	= new AxisPainter()
		const svg = []
		this.draw_bars(svg, scaling, chart_data)
		axis_painter.draw_axis( svg, scaling, this.props )
		if (chart_data.header)
			svg.push( <text x={scaling.axis_x_0 + scaling.chart_width/2}
							y={scaling.chart_top-18} key={`HEAD-${this.props.chart_key}`}
							fontSize={13} textAnchor="middle">{chart_data.header}</text>)

		this.draw_legend( svg, scaling, legend )
		return svg
	}






	get_tickdist(factor, scale)
	{
		if (factor < 4)
			return scale / 2
		if (factor < 8)
			return scale
		else
			return scale * 2
	}


		// Calculates the Axis ticks from the min and max of the data values
	// mx > 0 and mn < 0 represents the min and max on the axes. if there are no negative
	// values in the dataset mn = 0 (and correspondingly for mx and no positive values)
	calc_scale(mx, mn)
	{
		const range = Math.max(mx, -mn)

		const exp = Math.floor(Math.log10(range))
		const scale = Math.pow(10, exp)

		const factor_max = mx > 0 ? Math.floor(mx / scale) + 1 : 0
		const factor_min = mn < 0 ? Math.floor(mn / scale) - 1 : 0
		const tick = this.get_tickdist(Math.max(factor_max, factor_min), scale)

		const max = tick * (mx > 0 ? mx / tick + 0.1 : 0)
		const min = tick * (mn < 0 ? mn / tick - 0.1 : 0)

		return { max: max, min: min, range: max - min, tick: tick, exp: exp }
	}


	setup_y_scaling( data, scaling )
	{
		const { max, min, range, tick,exp } = this.calc_scale( data.max, data.min )

		scaling.chart_top = scaling.chart_bottom - scaling.chart_height

		scaling.valid = (max - min > 0.001)
		if (!scaling.valid)
		{
			scaling.max = 1
			scaling.min = 0
			scaling.tick = 5
			scaling.range = 1
			scaling.exp = 1

			scaling.axis_y_0 = scaling.chart_bottom
		}
		else
		{
			const y_axis_fragment = (0 - data.min) / (data.max - data.min);

			scaling.axis_y_0 = scaling.chart_bottom - scaling.chart_height * y_axis_fragment;

			scaling.max 	= max
			scaling.min 	= min
			scaling.range 	= range
			scaling.tick 	= tick
			scaling.exp 	= exp
		}
	}



	subset_labels_exist(chart_data) 
	{
		for (let set_i of chart_data.set_keys) {
			const set = chart_data.data_sets[set_i]

			for (let sub_set of set.data) 
			{
				if (sub_set.label && `${sub_set.label}`.length > 0)
					return true
			}
		}
		return false
	}



	calculate_svg_dimensions(rect, data )
	{
		const scaling 				= {}

		
		scaling.chart_width 		= rect.width - TICK_WIDTH - MARGIN
		scaling.tick_width 			= TICK_WIDTH
		scaling.margin 	 			= MARGIN
		scaling.container_height  	= rect.height
		scaling.container_width   	= rect.width
		scaling.axis_x_0          	= TICK_WIDTH


		let sets = data.set_keys.length
		const groups = data.group_indices.length

		if (this.props.min_groups && sets < this.props.min_groups)
			sets = this.props.min_groups

		scaling.group_width = scaling.chart_width / sets;
		scaling.bar_width = scaling.group_width / groups * 0.7;
		scaling.bar_width_paint = scaling.bar_width * (this.props.bar_width_factor || 0.8);

		scaling.group_width_paint = scaling.bar_width_paint * groups + (scaling.bar_width - scaling.bar_width_paint) * (groups - 1)

		scaling.bar_offset_paint = (scaling.group_width - scaling.group_width_paint) / 2;



		const legend = this.create_legend_structure(data, scaling.axis_x_0, scaling.chart_width)


		scaling.subset_labels_exist = this.subset_labels_exist(data)

		scaling.y_sublabel_offset = 15
		if (scaling.subset_labels_exist)
			scaling.y_label_offset = 40
		else
			scaling.y_label_offset = 18

		let bottom_size = 30 + scaling.y_label_offset
		if (this.props.detail_target)
			bottom_size += 30

		let legend_height = legend.y
		if (this.props.min_legend_height)
			legend_height = this.props.min_legend_height;
		scaling.legend_y0 = rect.height - legend_height

		legend_height += 10

		scaling.chart_height     	= rect.height - HEAD_SIZE - MARGIN  - bottom_size - legend_height

		scaling.chart_bottom     	= HEAD_SIZE + MARGIN + scaling.chart_height;

		if (this.props.top_labels)
			scaling.chart_height 	= scaling.chart_height 	  - MARGIN

		return {scaling, legend}
	}



	get_scaling( data, rect )
	{
		if (rect == null || !data)
			return {scaling: null, legend: null}
		else
			return this.calculate_svg_dimensions( rect, data );
	}


	render()
	{
		const {view_box, rect} = this.paint_surface()

		const {data, chart_key}				= this.props
		const grow_style 					= this.props.auto_grow ? {flexGrow: data.set_keys.length} : null



		const {scaling,legend} 				= this.get_scaling( data, rect )
		if (!scaling)
			return 	<div className='single-chart' style={grow_style} key={`CHART-${chart_key}`} ref={this.ref}></div>

		this.setup_y_scaling( data, scaling )

		const download 	= this.props.download ? this.props.download.replace( "_IDX_", '' + (this.props.index + 1) ) : null

		return 	<div className='single-chart'  key={`CHART-${chart_key}`} ref={this.ref}>

					{this.props.download ?
						<a className='download' href={download} target='_chart'>
							<i className='warning fa fa-download'/>
						</a> : null}
					<svg
							version={1}
							xmlns='http://www.w3.org/2000/svg'
							preserveAspectRatio='xMidYMid meet'
							viewBox={view_box}>
					{
						this.draw_svg_chart(data, legend, scaling)
					}
					</svg>
				</div>
	}
}




export default BarChart