
import React 			from 'react'
import {useState}		from 'react'

export const Help = ({id}) =>
{
	const [open, set_open] 		= useState( false )

	if (open)
	{

		return  <div className='help-master'>
					<div className='help-master-background'/>
					<div className='help-overlay'>
						<div className='title'>
							Help
							<div  onClick={(_) => set_open( false )} className='close-button fa fa-times'/>
						</div>
						<div className='body'>
							<iframe
								width="100%"
								height="100%"
								src={`https://pemt-test.mondas.io/static/pemt-manual.html#${id}`} className='help-text'/>
						</div>
					</div>
				</div>

	}

	return (
		<div className='help help-finito'>
			<span onClick={(_) => set_open( true )}  className='fa fa-question-circle'></span>
		</div>
	)
}