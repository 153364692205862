import React, { Fragment, Component }   from 'react';
import ReactDOM                         from 'react-dom';
import '../index.css';
import Highcharts                       from 'highcharts'
import HighchartsReact                  from 'highcharts-react-official'


export class Chart extends Component
{
    constructor(props)
    {
        super( props )
        this.chartRef   = React.createRef()
        this.state      = {count : 0}
        this.lastRect   = {height: -1000, width: -1000}
    }


    containerRect()
    {
        if (this.chartRef.current)
        {
            const chartRect  = this.chartRef.current.getBoundingClientRect()
            return {
                        height: Math.floor( chartRect.height ),
                        width:  Math.floor( chartRect.width )
                    }
        }
        else
            return {
                        height: null,
                        width:  null
                    }
    }


    findAvailableKeys( view_model, selected_nodes)
    {
        const available_keys = {}
        selected_nodes.map( (node) => {
            const hash              = view_model.getDataHashX( node.key )
            for( var index in hash )
            {
                const data_point = hash[index]
                if (data_point !== undefined && data_point !== null)
                    available_keys[index] = true
            }
        })
        return available_keys;
    }


    createChartOptions( chart_type, view_model, selected_nodes )
    {
        const available_keys    = this.findAvailableKeys( view_model, selected_nodes )
        const series = selected_nodes.map( (node) => {
            const hash              = view_model.getDataHashX( node.key )
            const data_values       = []
            for( var index in hash )
            {
                if (available_keys[index])
                {
                    let data_point = hash[index]
                    if (data_point === undefined || data_point === null)
                        data_point = null
                    data_values.push( data_point )
                }
            }

            return {
                name: node.name,
                data: data_values
            }
        })

        const axisValuesX = view_model.axisValuesX().filter( (x) => available_keys[x] == true)

        let stacked = false
        if (chart_type == 'stacked_bar')
        {
            stacked     = true
            chart_type  = "column"
        }
        else if (chart_type == 'stacked_area')
        {
            stacked     = true
            chart_type  = 'area'
        }
        else if (chart_type == 'bar')
        {
            stacked     =  true
            chart_type  = "column"
        }

        const chart = {
                        marginBottom: 100,
                        margin: 0,
            }

        const rect  = this.containerRect()
        if (rect.height)
        {
            chart.height = rect.height
            chart.width  = rect.width

            this.lastRect.height = rect.height
            this.lastRect.width  = rect.width
        }


        chart.type = chart_type

        const options = {
            chart: chart,
            title: {
                text: "PEMT"
            },

            xAxis: {
                categories: axisValuesX
            },

            yAxis: {
                title: {
                    text: selected_nodes[0].unit
                }
            },
            legend: {
                align: 'center',
                verticalAlign: 'bottom',
                x: 0,
                y: 0
            },
            series: series
        }

        if (stacked)
        {
            options.plotOptions  = {
                series: {
                    stacking: 'normal',
                    marker: {
                        enabled: false
                    }
                },
                area: {
                    stacking: 'normal',
                    marker: {
                        enabled: false
                    }
                }
            }
        }
        return options
    }

    reRender = false
    checkForReRender(rect)
    {
        return (this.reRender ||
                rect.height == null ||
                rect.height != this.lastRect.height ||
                rect.width  != this.lastRect.width)

    }

    refresh()
    {
        this.setState( {count: this.state.count + 1})
    }

    checkAndSheduleRefresh()
    {
        const rect = this.containerRect()
        if (this.checkForReRender(rect))
           setTimeout( this.refresh(), 0 )

    }

    componentDidMount()
    {
        this.checkAndSheduleRefresh()
    }


    chartOptions( view_model, selected_nodes, chart_type )
    {
        const x_axis = view_model.getAxisX()
        if (x_axis.kind == "numeric" &&
            (chart_type == 'bar' || chart_type == 'stacked_bar'))
            chart_type = 'line'
        if (x_axis.kind == "alpha")
            chart_type = 'stacked_bar'


        if (chart_type)
            return this.createChartOptions( chart_type,  view_model, selected_nodes )
        else if (x_axis.kind == "numeric")
            return this.createChartOptions( "line",      view_model, selected_nodes )
        else
            return this.createChartOptions( "bar",       view_model, selected_nodes )
    }


    createKey( length = 16 )
    {
        const stri  = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz1234567890'
        const key   = []
        for( let i  = 0; i < (length + 1); i++ )
        {
            if (i == 5 || i == 11)
                key.push("-")
            else
            {
                const keyVal = (Math.round(Math.random() * 10000) * Date.now()) % stri.length
                key.push( stri[keyVal] )
            }
        }
        return key.join('')
    }

    render(props)
    {
        // This will creat the container first and re render the chart afterwards
        this.reRender = this.containerRect().height == null

        const {view_model, selected_nodes, chart_type} = this.props
    	return (
        <Fragment>
            <div onClick={()=>view_model.closeChart()} className='x'>
                <i className='fa fa-times'/>
            </div>
            <div className='chartcontainer' ref={this.chartRef}>
            {
                this.reRender ? <div/> :
    		      <HighchartsReact
    		      highcharts={Highcharts}
    		      options={this.chartOptions( view_model, selected_nodes, chart_type )}/>
            }
    		</div>
        </Fragment>)
    }
}

