import React, { Component, Fragment } 	from "react";




class AutoResize extends Component
{
	constructor(props)
	{
		super(props)

		this.lastRect 		= { height: -1000, width: -1000 }
		this.masterRef 		= React.createRef()
	}


	reRender = false
	checkForReRender( r )
	{
		const rect = r ? r : this.containerRect()

		console.log( "Checking on ", this.lastRect , rect, this.reRender,
				this.lastRect.width < 0,
				this.lastRect.height < 0,
				rect.height == null,
				rect.height != this.lastRect.height,
				rect.width  != this.lastRect.width
		)

		return 	this.reRender 						||
				this.lastRect.width < 0 			||
				this.lastRect.height < 0 			||
				rect.height == null 				||
				rect.height != this.lastRect.height	||
				rect.width  != this.lastRect.width
	}

	checkAndSheduleRefresh()
	{
		const rect 		= this.containerRect()
		const rerend 	= this.checkForReRender(rect)
		if (rerend)
		{
			this.lastRect  = rect
			console.log( "Forcing a refresh" )
			setTimeout( () => {this.run_refresh()}, 0)
		}
		console.log( "Checked " + rerend + " ", rect )
		return rerend
	}


	containerRect()
	{
		if (this.masterRef.current)
		{
			const rect = this.masterRef.current.getBoundingClientRect()
			return {
				height: 	Math.floor(rect.height),
				width: 		Math.floor(rect.width)
			}
		}
		else
			return {
				height: 	null,
				width: 		null
			}
	}


	run_refresh = () => {
							console.log( "Run Refresh")
							const rect = this.containerRect()
							if (this.checkForReRender( rect ))
							{
								console.log( "Rerender " + this.lastRect.height + " " + this.lastRect.width + " -> " + rect.height + " " + rect.width )
								this.setState( {container_height: rect.height, container_width: rect.width})
							}
							else
							{
								console.log( "Samey Same " + rect.width + ' ' + rect.height)
							}

							this.lastRect = rect
						}


	componentDidMount()
	{
		window.addEventListener("resize", this.run_refresh )
	}

	componentWillUnmount()
	{
		window.removeEventListener("resize", this.run_refresh )
	}

	get ref()
	{
		return this.masterRef
	}

	get must_paint()
	{
		this.state.container_width > 0 && this.state.container_height > 0
	}

	paint_surface()
	{
		const width 		= this.state.container_width
		const height		= this.state.container_height
		const must_paint	= width > 0 && height > 0

		const cstyle = !must_paint ? null : {
			width 	: width,
			height  : height
		}

		const view_box 		= must_paint ? `0 0 ${width} ${height}` : `0 0 0 0`

		return {
			width : 	width 	|| 0,
			height: 	height 	|| 0,
			must_paint: must_paint,
			cstyle: 	cstyle,
			view_box: 	view_box
		}
	}

}



export class SvgBlob extends AutoResize
{
	constructor(props)
	{
		super(props)

		this.state = {
			count: 0,
			focused: null
		}

	}


	render()
	{
		this.checkAndSheduleRefresh()
//			return <div className='svg-container' key='svg-test' ref={this.ref}/>

		const {width, height, view_box, cstyle} = this.paint_surface()

		console.log( "Rendering with ", width, height )

		return 	<div className='svg-container' style={cstyle} key='svg-test' ref={this.ref}>
						<svg
								version={1}
								xmlns='http://www.w3.org/2000/svg'
								preserveAspectRatio='xMidYMid meet'
								viewBox={view_box}>
								<rect 	x={50} y={50} width={100} height={100} fill='indianred'/>
								<rect 	x={20} y={20}
										width={width-40} height={height-40}
										fill='transparent' stroke='black' strokeWidth={2} />
								<line 	x1={100} y1={0} x2={100} y2={200} stroke='black' strokeWidth='1'/>
								<line 	y1={100} x1={0} y2={100} x2={200} stroke='black' strokeWidth='1'/>
								<text 	x={10} y={10}>{`SIZE: ${width}x${height}`}</text>
						</svg>
				</div>
	}
}

const SvgTest = (props) => 	<div className='svg-master'>
								<SvgBlob />
								<div className='empty'/>
								<div className='empty'/>
								<div className='empty'/>
							</div>




export default SvgTest


