import React, { Component, Fragment }                         from 'react';
import {Chart}                                                from "./chart/Chart.js"
import {findNode,visitNodesWithParent}                        from './tree/tree.js';
import {TreeNode}                                             from './TreeNode.js';
import {FilterNode}                                           from './FilterNode.js';
import {TreeGrid}                                             from './TreeGrid.js';
import {AxisSelect}                                           from "./gui/AxisSelect.js"
import {blank}                                                from './utils/utils.js';
import classnames                                             from 'classnames';

function renderFilterTree(view_model, node, editcontroller, props)
{
  const name        = node.name
  const sub_nodes   = node.nodes

  if (node.level == -1)
    <div>
      { sub_nodes.map( (node) => renderFilterTree( view_model, node,editcontroller, props ) ) }
    </div>
  else if (sub_nodes)
    return <FilterNode  key            = {node.key}
                        editcontroller = {editcontroller}
                        node           = {node}
                        view_model     = {view_model}>
              { sub_nodes.map( (node) => renderFilterTree( view_model, node,editcontroller, props ) ) }
           </FilterNode>
  else
    return <FilterNode  key            = {node.key}
                        editcontroller = {editcontroller}
                        node           = {node}
                        view_model     = {view_model}/>
}



function renderNodeTree(min_level, view_model, node, editcontroller, props, n)
{
  const name        = node.name
  const sub_nodes   = node.nodes
  let   ctr         = n


  if (node.level < min_level)
    return  sub_nodes.map( (node) => renderNodeTree(min_level,  view_model, node,editcontroller, props, ctr++ ) )
  else if (sub_nodes)
    return <TreeNode  key               = {`TN1-${ctr++}-${node.key}`}
                      warnings_empty    = {props.warnings_empty}
                      editcontroller    = {editcontroller}
                      display_units     = {props.display_units}
                      node              = {node}
                      i18               = {props.i18}

                      view_model        = {view_model}>
              { sub_nodes.map( (node) => renderNodeTree(min_level,  view_model, node,editcontroller, props, ctr++ ) ) }
           </TreeNode>
  else
    return <TreeNode  key               = {`TN2-${ctr++}-${node.key}`}
                      warnings_empty    = {props.warnings_empty}
                      editcontroller    = {editcontroller}
                      display_units     = {props.display_units}
                      node              = {node}
                      i18               = {props.i18}

                      view_model        = {view_model}/>
}


function renderNodeGrid( min_level, view_model, node, editcontroller, props, n)
{
  const name          = node.name
  const sub_nodes     = node.nodes
  let   ctr           = n

  if (node.level < min_level)
    return sub_nodes.map(      (node) => renderNodeGrid( min_level, view_model, node, editcontroller, props, ctr++ ) )
  else if (sub_nodes)
    return <TreeGrid  key            = {`TG1-${ctr++}-${node.key}`}
                      open           = {view_model.isOpen( node )}
                      editcontroller = {editcontroller}
                      display_units  = {props.display_units}
                      mark_jumps     = {props.mark_jumps}
                      display_units  = {props.display_units}
                      node           = {node}
                      i18            = {props.i18}
                      view_model     = {view_model}>
              {sub_nodes.map((node, idx) => renderNodeGrid(min_level, view_model, node, editcontroller, props, ctr++ ) )}
           </TreeGrid>
  else
    return <TreeGrid  key            ={`TG2-${ctr++}-${node.key}`}
                      open           = {view_model.isOpen( node )}
                      editcontroller = {editcontroller}
                      display_units  = {props.display_units}
                      mark_jumps     = {props.mark_jumps}
                      display_units  = {props.display_units}
                      node           = {node}
                      i18            = {props.i18}
                      view_model     = {view_model}/>
}



export class TreeEditor extends Component
{

  constructor(props)
  {
    super(props)
    this.refBodyContainer     = React.createRef()
    this.refColHeadContainer  = React.createRef()
    this.refRowHeadContainer  = React.createRef()

    this.refFocusedHead       = React.createRef()

    this.state                = {}
    this.refreshTimeout       = null
  }

  getSchema()
  {
    return this.props.view_model.axisTreeY()
  }

  getFilterSchema()
  {
    return this.props.view_model.axisTreeZ()
  }

  refreshVisible()
  {
    this.state.visible = this.visibleList( this.getSchema() )
  }

  refresh(reload_schema = false, trigger_layout = false)
  {
    const new_state =  {}
    const schema    = this.getSchema()

    new_state.visible = this.visibleList( schema )

    if (!this.editcontroller.focusNode && schema.nodes && schema.nodes.length > 0)
      this.editcontroller.focusNode = schema.nodes[0].key

    this.setState( new_state )

    if (trigger_layout)
    {
      if (this.refreshTimeout)
        clearTimeout( this.refreshTimeout )

      // Stupid JS this means the view_model is not visible in the timeout Callback
      const vmodel = this.props.view_model
      this.refreshTimeout = setTimeout( function() {
          vmodel.triggerLayoutCallbacks()
          this.refreshTimeout = null
      }, 300 )
    }
  }



  findVisible = ( search ) =>
  {
    var index = 0
    for( let node of this.state.visible )
    {
      if (node && (node.id === search || node.key === search ))
        return {node: node, index: index}
      index++
    }
    return {};
  }

  down = (node_key) =>  {
    const {index} = this.findVisible( node_key )
    if (index > -1 && index < this.state.visible.length - 1)
      this.selectNode( this.state.visible[index+1] )
  }

  new_down = (node_key) =>  {
    const length    = this.state.visible ? this.state.visible.length : -10
    const {index}  = this.findVisible( node_key )
    let new_index  = index+1
    while (this.state.visible[new_index].nodes && new_index > -1 && new_index < length - 1)
      new_index = new_index + 1
    if (new_index < length - 1 && !this.state.visible[new_index].nodes)
      this.selectNode( this.state.visible[new_index] )
  }

  up = (node_key) =>
  {
    const {index} = this.findVisible( node_key )
    if (index > 0)
      this.selectNode( this.state.visible[index-1] )
  }

  new_up = (node_key) =>
  {
    const length    = this.state.visible ? this.state.visible.length : -10
    const {index}  = this.findVisible( node_key )
    let new_index  = index-1
    while (this.state.visible[new_index].nodes && new_index >= 0)
      new_index = new_index - 1
    if (new_index >= 0 && !this.state.visible[new_index].nodes)
      this.selectNode( this.state.visible[new_index] )
  }


  nextTabbed = (node_key) =>
  {
      if (this.editcontroller.column == 0)
      {
        this.editcontroller.column = 1
        this.refresh()
      }
      else
      {
        this.editcontroller.column = 0
        this.down( node_key )
      }
  }


  prevTabbed = (node_key) =>
  {
      if (this.editcontroller.column == 1)
      {
        this.editcontroller.column = 0
        this.refresh()
      }
      else
      {
        this.editcontroller.column = 1
        this.up( node_key )
      }
  }





  printTree = (schema, level = 0) =>
  {
    if (!schema)
      return
    print = schema.name
    for( let i = 0; i < level; i++ )
      print = '  ' + print
    console.log( print )
    if (schema.nodes)
    {
      for( let j in schema.nodes)
        this.printTree( schema.nodes[j], level+1 )
    }
  }


  openClose = ( node ) =>
  {
    const {view_model}  = this.props
    if (node && node.has_nodes)
    {
      const open = view_model.isOpen( node )
      // If we clicked the button and just closed the selected node we need to move it up
      const focused   = findNode( this.getSchema(), this.editcontroller.focusNode )
      if (!open && focused && focused.tag.id.startsWith( node.id ))
        this.editcontroller.focusNode = node.key
      view_model.setOpen( node, !open )
      this.refresh()
    }
  }


  startEditing = ( node, column ) =>
  {
    if (!this.editcontroller.editing)
    {
      this.editcontroller.editing  = true
      if (column != undefined)
        this.editcontroller.column = column
    }
    this.setupKeyBindings();
    this.refresh()
  }


  stopEditing  = (node_key, node_values = null, reason = null ) =>
  {
    const found = findNode( this.getSchema(), node_key );

    if (found)
    {
      let {index,parent,tag}  = found;


      if (reason == "ESC" || reason == "BLUR")
      {
        this.editcontroller.editing = false
        this.setupKeyBindings()
        this.refresh()
        return
      }

      if (node_values)
      {
        let value = null
        if (node_values.value)
          value = parseFloat( '' + node_values.value )
        if (isNaN( value))
          value = null
        if (!tag.data)
          tag.data = {}

        const {view_model} = this.props

        view_model.setDataPoint( node_key, node_values.index, value )
      }

      // If we were editing the name we simply go up to the note
      // Except if the tag was blank, in which case we have to go through the other logic
      if (reason == "TAB")
        this.editcontroller.column = this.editcontroller.column + 1
      else if (reason == "ENTER")
      {
        let vis_found   = this.findVisible( tag.key )
        if (vis_found)
        {
          let vis_index   = vis_found.index
          if (vis_index > -1 && index < this.state.visible.length - 1)
            this.editcontroller.focusNode = this.state.visible[vis_index+1].key
        }
      }
      // If reason is blur (he clicked somewhere else) we let it go

      this.setupKeyBindings()
      this.refresh( true )
    }

//    if (this.props.commit)
//      this.props.commit( this.getSchema() )
  }


  chartNode  = ( node, column ) =>
  {
    this.props.view_model.openChart()
    this.selectNode( node, column )
  }

  selectNode = ( node, column ) =>
  {
    if (this.editcontroller.editing)
    {
      this.editcontroller.editing = false
      this.setupKeyBindings()
    }

    const {view_model}        = this.props
    const axis_key_values_x   = view_model.axisKeyValueListX()
    if (column != null)
    {
      if (column < 0)
        column = 0
      // We have to use > and not >= cause we have one header column
      if (column > axis_key_values_x.length)
        column = axis_key_values_x.length
      this.editcontroller.column  = column
    }

    let y_value = null
    if (column > 0)
    {
      y_value = axis_key_values_x[this.editcontroller.column-1].node
      view_model.setSelectedValueOnAxis( "x", y_value )
    }
    view_model.setSelectedValueOnAxis(   "y", node )

    if (node)
    {
      this.props.view_model.setSelectedNode( node, column, y_value )
      this.editcontroller.focusNode = node.key
    }


    this.refresh( false, this.editcontroller.column === 0)
  }





  // Keyboard handlers
  levelUp = (node_key) =>
  {
    const found = findNode( this.getSchema(), node_key );
    if (found && found.tag.level > 1)
      this.editcontroller.focusNode = found.parent.key
    this.refresh()
  }

  levelDown = (node_key) =>
  {
    const found = findNode( this.getSchema(), node_key );
    if (found && found.tag && found.tag.nodes && found.tag.nodes.length > 0)
    {
      this.editcontroller.focusNode = found.tag.nodes[0].key
      found.tag.opened = true
    }
    this.refresh()
  }


  right = (node_key) =>
  {
      this.selectNode( null, this.editcontroller.column + 1 )
  }


  left = (node_key) =>
  {
      this.selectNode( null, this.editcontroller.column - 1 )
  }


  shiftDown = false;
  ctrlDown  = false;
  altDown   = false;
  metaDown  = false;
  tabDown   = false;

  // We need this to filter for shift key events, which are a bit complicated
  keyDownHandler = (event) =>
  {
    const TAB_KEY           = 9;

    const key = event.keyCode || event.charCode || 0;
    if (event.shiftKey)
      this.shiftDown  = true
    if (event.ctrlKey)
      this.ctrlDown   = true
    if (event.altKey)
      this.altDown    = true
    if (event.metaKey)
      this.metaDown   = true
    if (key == TAB_KEY)
    {
      this.tabDown    = true;
      event.stopPropagation()
      event.preventDefault()
    }
//    console.log( `EN: Key Down ${event.keyCode} : ${event.shiftKey}  ${this.shiftDown}` )
  }

  keyUpHandler  = (event) =>
  {
      const ENTER_KEY         = 13;
      const ESCAPE_KEY        = 27;
      const DOWN_ARROW        = 40;
      const UP_ARROW          = 38;
      const RIGHT_ARROW       = 39;
      const LEFT_ARROW        = 37;
      const TAB_KEY           = 9;
      const ESC_KEY           = 27;
      const SPACE_KEY         = 32;

      const ec = this.editcontroller
      const key = event.keyCode || event.charCode || 0;

      event.stopPropagation();
      event.preventDefault();

      // Debounce the key events
      if      (this.shiftDown && !event.shiftKey)
        this.shiftDown  = false
      else if (this.ctrlDown  && !event.ctrlKey)
        this.ctrlDown   = false
      else if (this.altDown && !event.altKey)
        this.altDown    = false
      else if (this.metaDown && !event.metaKey)
        this.metaDown   = false
      else if  (this.editcontroller.editing)
        return    // We should realllly not be here

//      console.log( `ST: Key Up ${event.keyCode} : ${event.shiftKey}  ${this.shiftDown} TB: ${this.tabDown}` )

      if (key == ENTER_KEY)
        this.startEditing(  ec.focusNode )
      else if (key == DOWN_ARROW)
      {
        if      (this.shiftDown)
          this.levelDown( ec.focusNode )
        else if (this.ctrlDown || this.metaDown)
          this.down( ec.focusNode )
        else
          this.down( ec.focusNode )
      }
      else if (key == UP_ARROW)
      {
        if      (this.shiftDown)
          this.levelUp(    ec.focusNode )
        else if (this.ctrlDown || this.metaDown)
          this.up( ec.focusNode )
        else
          this.up( ec.focusNode )
      }
      else if (key == LEFT_ARROW)
      {
        if      (this.shiftDown)
          this.levelUp(    ec.focusNode )
        else if (this.ctrlDown || this.metaDown)
          this.left(    ec.focusNode )
        else
          this.left(    ec.focusNode )
      }
      else if (key == RIGHT_ARROW)
      {
        if (this.shiftDown)
          this.levelDown(    ec.focusNode )
        else if (this.ctrlDown || this.metaDown)
          this.right(    ec.focusNode )
        else
          this.right(    ec.focusNode )
      }
      else if (this.tabDown)
      {
        this.tabDown = false
        if      (this.shiftDown)
          this.prevTabbed( ec.focusNode )
        else
          this.nextTabbed( ec.focusNode )
      }
      else if (key == SPACE_KEY)
        this.openClose( ec.focusNode )
  }


  setupKeyBindings = () =>
  {
    if (this.editcontroller.editing)
      this.unbindGlobalKeyHandler()
    else
      this.bindGlobalKeyHandler()
  }


  bindGlobalKeyHandler()
  {
    if (!this.editcontroller.bound)
    {
      document.body.addEventListener('keyup',   this.keyUpHandler )
      document.body.addEventListener('keydown', this.keyDownHandler )
      this.editcontroller.bound = true
    }
  }

  unbindGlobalKeyHandler()
  {
    if (this.editcontroller.bound)
    {
      document.body.removeEventListener('keyup',    this.keyUpHandler   )
      document.body.removeEventListener('keydown',  this.keyDownHandler )
      this.editcontroller.bound = false
    }
  }

  visibleList( schema )
  {
    var result = []
    visitNodesWithParent( schema, (parent, node) =>
    {
      if (this.props.view_model.isOpen( parent))
        result.push( node )
    })
    return result
  }

  printScroll()
  {
    if (this.refBodyContainer.current)
    {
          console.log(  this.refBodyContainer.current.scrollLeft,
                        this.refBodyContainer.current.scrollTop )
    }
    else
    {
//      console.log( "Lost Ref")
    }
  }


  // Scroll handlers
  bodyScrollHandler = (event) =>
  {
    // God I hate FireFrack
    const isFireFox =   typeof InstallTrigger !== 'undefined';

    if (this.resetScroll && this.scrollPos)
    {
      console.log( "Reset")
      if (this.refColHeadContainer.current)
        this.refColHeadContainer.current.scrollLeft = this.scrollPos.left;
      if (this.refRowHeadContainer.current)
        this.refRowHeadContainer.current.scrollTop  = this.scrollPos.top;
      this.resetScroll = false
    }
    else if (this.refBodyContainer.current)
    {
//      console.log( this.refRowHeadContainer.current.matches(":hover"), this.refBodyContainer.current.matches(":hover"))
//      if (isFireFox && this.refRowHeadContainer.current && this.refRowHeadContainer.current.matches(":hover"))
//        return
//      if (isFireFox && this.refColHeadContainer.current && this.refColHeadContainer.current.matches(":hover"))
//        return

      this.saveScroll()
      if (this.refColHeadContainer.current  &&
          this.refBodyContainer.current.dataset.scrollLeft != this.refBodyContainer.current.scrollLeft)
      {
        this.refColHeadContainer.current.scrollLeft           = this.refBodyContainer.current.scrollLeft
        this.refColHeadContainer.current.dataset.scrollLeft   = this.refBodyContainer.current.scrollLeft
      }

      if (this.refRowHeadContainer.current &&
          this.refBodyContainer.current.dataset.scrollTop != this.refBodyContainer.current.scrollTop)
      {
        this.refRowHeadContainer.current.scrollTop            = this.refBodyContainer.current.scrollTop;
        this.refRowHeadContainer.current.dataset.scrollTop    = this.refBodyContainer.current.scrollTop;
      }
    }
  }


  colHeadScrollHandler = (event) =>
  {
    if (this.refColHeadContainer.current && this.refBodyContainer.current)
    {
      if (this.refColHeadContainer.current.dataset.scrollLeft != this.refColHeadContainer.current.scrollLeft)
      {
        this.refBodyContainer.current.scrollLeft         = this.refColHeadContainer.current.scrollLeft;
        this.refBodyContainer.current.dataset.scrollLeft = this.refColHeadContainer.current.scrollLeft;
      }
    }
  }


  rowHeadScrollHandler = (event) =>
  {
    if (this.refRowHeadContainer.current && this.refBodyContainer.current)
    {

      if (this.refRowHeadContainer.current.dataset.scrollTop != this.refRowHeadContainer.current.scrollTop)
      {
        this.refBodyContainer.current.scrollTop         = this.refRowHeadContainer.current.scrollTop;
        this.refBodyContainer.current.dataset.scrollTop = this.refRowHeadContainer.current.scrollTop;
      }
    }
  }

  saveScroll()
  {
    if (this.refBodyContainer.current) {
      this.scrollPos =  {
                          left:   this.refBodyContainer.current.scrollLeft,
                          top:    this.refBodyContainer.current.scrollTop
                        }
    }
//    console.log("Saving " + this.scrollPos.top )
  }

  componentWillUpdate()
  {
    this.refreshVisible()
    this.resetScroll = !this.refBodyContainer.current
//    console.log("WillUpdate " + this.resetScroll )
  }

  componentDidUpdate()
  {
//    console.log("DidUpdate Current? " + this.refBodyContainer.current + " Reset? " + this.resetScroll )
    if (this.resetScroll)
      this.bodyScrollHandler()
    //this.printScroll()
  }


  componentDidMount()
  {
    this.refresh()
    this.setupKeyBindings()

    if (this.refFocusedHead.current)
    {
        console.log( "Go...")
        this.refFocusedHead.current.scrollIntoView( {inline: "start"})
    }
  }

  componentDidUnMount()
  {
    this.unbindGlobalKeyHandler()
  }


  editcontroller =  {
                      focusNode     : '',
                      column          : 0,
                      editing         : false,
                      completed       : this.completed,
                      down            : this.down,
                      up              : this.up,
                      completed       : this.completed,
                      selectNode      : this.selectNode,
                      chartNode       : this.chartNode,
                      startEditing    : this.startEditing,
                      stopEditing     : this.stopEditing,
                      openClose       : this.openClose
                    }


  renderTableHead()
  {
    const cells                     = []
    const axis_values               = this.props.view_model.axisValuesX()
    const axis_renderer             = this.props.view_model.axisRendererX()

    for( let xi in axis_values )
    {
      const x        = axis_values[xi]
      const key      = `HEAD-${x}`

      const rendered = axis_renderer( x, xi )

      let   targetRef   = null
      if (x == '2560' && !this.hasFocusedColumn)
      {
          this.hasFocusedColumn = true
          targetRef             = this.refFocusedHead
      }

      cells.push( <div ref={targetRef}  key = {key} className="cell head"><div className='value'>{rendered}</div></div>)
    }

    cells.push(<div key={`HEAD-final`} className='cell head final'><div className='value'>&nbsp;</div></div>)

    return  <div className  = 'table-head-container'
                onScroll    = {this.colHeadScrollHandler}
                ref         = {this.refColHeadContainer}>
                <div className='table-head'>
                  <div className='row'>
                    {cells}
                  </div>
                </div>
            </div>
  }


  renderAxisSelector()
  {
    return
      console.log( "AXIS")
        const multi_z       = this.props.view_model.getAxisZ().value_list.length > 0

        if (!multi_z)
          return null

        return <div>  X<AxisSelect list_only = {true} view_model = {view_model} axis_name="x"/>
                     &nbsp;
                      Y <AxisSelect view_model = {view_model} axis_name="y"/>
                </div>

  }


  render()
  {
    if (!this.state)
      return <div></div>

    const {view_model}  = this.props
    const schema        = this.getSchema()
    const filter_schema = this.getFilterSchema()
    const filter_open   = view_model.filter_open
    const min_level     = view_model.min_level || 1


    return  <Fragment>
              {
                filter_open &&
                  <div className='filter'>
                    { renderFilterTree( view_model, filter_schema, this.editcontroller, this.props ) }
                  </div>
              }
              <div className={classnames( 'spreadsheet',
                                          this.props.high_header   ? "high-header"   : null,
                                          this.props.narrow_header ? "narrow-header" : null, )}>
              {
                view_model.sidebar_open &&
                  <div  className = 'table-left'>
                      <div className='table-head-corner'>
                      { this.props.corner_header ? this.props.corner_header : this.renderAxisSelector() }
                      </div>
                      <div className='table-tree-container'
                        ref       = {this.refRowHeadContainer}
                        onScroll  = {this.rowHeadScrollHandler}>
                        <div className='table-tree'>
                          { renderNodeTree(min_level,  view_model, schema, this.editcontroller, this.props, 0 ) }
                          <div className="row level-0">
                            <div className="cell name row-head">
                              <div className='cell-contents'>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                  </div>
              }
              {
                view_model.chart_open && view_model.chart_node &&
                  <div className='chart'>
                    <Chart  view_model     = {view_model}
                            chart_type     = {view_model.chart_type}
                            selected_nodes = {view_model.chart_node}/>
                  </div>
              }
              {
                view_model.grid_open &&
                  <div className='table-right'>
                    {
                      this.renderTableHead()
                    }
                    <div  className       = 'table-grid-container'
                          ref             = {this.refBodyContainer}
                          onScroll        = {this.bodyScrollHandler}>
                      <div className       ='table-grid'>
                        { renderNodeGrid( min_level, view_model, schema, this.editcontroller, this.props, 0 ) }
                      </div>
                    </div>
                  </div>
              }
              </div>
            </Fragment>
  }
}




