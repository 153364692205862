import React, { Component, Fragment } 	from "react";
import {GridManager} 		from '../datamodel/grid_manager.js';
import {PEMTViewModel}	from '../viewmodel/pemt_view_model.js'
import {Axis} 				from '../datamodel/axis.js'
import {normalizeSchema}	from "../tree/tree.js"


export class QDataModel
{

	setup_data_store( tree, province, years )
	{
		for( const varname in tree)
		{
			const node = tree[varname]
			node.key   = varname
			node.id    = varname

			if (node.data) {
				for (let year in node.data)
				{
					const value = node.data[year];
					if (value !== null && value !== undefined)
					{
						const rounded = Math.round(value * 100.0) / 100.0;
						this.data_store.set(
									{ province: province, year: year, parameter: node.key },
									rounded
						);
					}
				}
			}
			else if (node.value !== undefined)
			{
				const value = node.value;
				if (isNaN( value ))
				{
					for (let y of years)
					{
						this.data_store.set( { province: province, year: `${y}`, parameter: node.key }, value);
					}
				}
				else
				{
					const rounded = Math.round(value * 100.0) / 100.0;
					for (let y of years)
					{
						this.data_store.set( { province: province, year: `${y}`, parameter: node.key },
						rounded
						);
					}
				}
			}
		}
	}

	make_value_list( tree )
	{

		const rv = []
		for( const varname in tree)
		{
			const node = tree[varname]
			rv.push({
				key: varname,
				data: node.data,
				name: varname
			})
		}

		if (rv.length == 0)
		{
			rv.push({
				key: 'NONE',
				data: {},
				name: 'NONE'
			})
		}


		return {key:'_ROOT_', nodes: rv}
	}

	focusYear( year )
	{
		if (year == null)
			this.view_model.setAxisFilter( 'year', null )
		else
			this.view_model.setAxisFilter( 'year', [`${year}`] )
	}

	constructor( {tree, years, province} )
	{
		this.years 				= years
		const schema 			= normalizeSchema( this.make_value_list( tree ))


		const axis_province 	= new Axis( "province",  "Province", 	"alpha", 	{list: [province]} )
		const axis_year 		= new Axis( "year", 	 "Year", 		"numeric",  {list: years}, (x) => <Fragment>{x}<br/><i>({x-543})</i></Fragment> )
		const axis_parameter 	= new Axis( "parameter", "Parameter", 	"alpha",  	{tree: schema}	)

		this.data_store 		= new GridManager( [axis_year, axis_parameter, axis_province] )
		this.view_model 		= new PEMTViewModel(this.data_store )

		// Select the 0 element of the axes
		this.view_model.axisKeyValueListX()

		this.view_model.setSelectedValue( 'year', 		this.view_model.axisKeyValueListX()[0].node )
		this.view_model.setSelectedValue( 'parameter', 	this.view_model.axisKeyValueListY()[0].node )
		this.view_model.setSelectedValue( 'province', 	this.view_model.axisKeyValueListZ()[0].node )

		this.setup_data_store( tree, province, years )
	}
}

