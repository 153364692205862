import React, { Component, Fragment } from "react";



class ResizeComponent extends Component
{
	constructor(props)
	{
		super(props)

		this.lastRect 				= { height: -1000,  width: -1000 }
		this.currentContainerRect 	= { height: 0, 		width: 0}
		this.masterRef 				= React.createRef()

		this.observer = null
	}


	setupObserver()
	{
		if (!this.masterRef.current || this.observer !== null)
			return

		this.observer = new ResizeObserver(entries => {
			for (let entry of entries) {
				const rect 		= entry.contentRect;
				const height	= Math.floor(rect.height)
				const width 	= Math.floor(rect.width)

				this.currentContainerRect = {width, height}
				
//				console.log('Element:', entry.target);
//				console.log(`Element size: ${rect.width}px x ${rect.height}px`);
//				console.log(`Element padding: ${rect.top}px ; ${rect.left}px`);

				this.checkAndSheduleRefresh()
			}
		});

		this.observer.observe( this.masterRef.current )
	}

	reRender = false
	checkForReRender( r )
	{
		const rect = r ? r : this.currentContainerRect

		return 	this.reRender 						||
				this.lastRect.width < 0 			||
				this.lastRect.height < 0 			||
				rect.height == null 				||
				rect.height != this.lastRect.height	||
				rect.width  != this.lastRect.width
	}

	checkAndSheduleRefresh()
	{
		const rect 		= this.currentContainerRect
		const rerend 	= this.checkForReRender(rect)
		if (rerend)
		{
			setTimeout( () => {this.run_refresh()}, 0)
		}
		return rerend
	}



	run_refresh = () => {	
							const rect = this.currentContainerRect
							if (this.checkForReRender(rect))
							{
								this.setState({ container_height: rect.height, container_width: rect.width })
							}
							this.lastRect = rect
						}


	componentDidMount()
	{
		this.setupObserver()
//		window.addEventListener("resize", this.run_refresh )
	}

	componentWillUnmount()
	{
//		window.removeEventListener("resize", this.run_refresh )
	}

	get ref()
	{
		return this.masterRef
	}

	get must_paint()
	{
		this.currentContainerRect.width > 0 && this.currentContainerRect.height > 0
	}

	paint_surface()
	{
		const width 		= this.currentContainerRect.width
		const height		= this.currentContainerRect.height
		const must_paint	= width > 0 && height > 0

		const rect 			= !must_paint ? null : {
			width 	: width,
			height  : height < 250 ? 250 : height
		}


		const view_box 		= must_paint ? `0 0 ${width} ${height}` : `0 0 0 0`

		return {
			width : 	width 	|| 0,
			height: 	height 	|| 0,
			must_paint: must_paint,
			rect: 		rect,
			view_box: 	view_box,
		}
	}

}


export default ResizeComponent