import React, { Component } from "react";
import classnames       from 'classnames';
import QDataExplorer	from './QDataExplorer'
import MasterFrame		from '../app/MasterFrame'
import queryString 		from 'query-string'



class QDataFrame extends Component
{
	constructor( props )
	{
		super( props )
	}


	render()
	{
		const {match,history,location} = this.props
		const params = queryString.parse(location.search )
		return  <MasterFrame province={this.props.match.params.province} history={history} match={match}>
			<QDataExplorer match={match} history={history} province={this.props.match.params.province}
							keys={params.keys}
							year={params.year} varname={params.varname} selected_node={params.selected_node}
						qd={this.props.match.params.qd}/>
				</MasterFrame>
	}
}

export default QDataFrame
