import React, { Component, Fragment }       from "react";
import axios                                from 'axios'
import backend_config                       from "../config/runconfig"
import {Expression,node_color,get_origin}   from "./Expression"

const API_KEY = 'ExgWFH9XHHyXIHyKEHIH'

const NODE_WIDTH  = 180
const NODE_HEIGHT = 50
const NODE_SPACE_X  = 300
const NODE_SPACE_Y  = 80



export class DependencyGraph extends Component
{
  constructor(props)
  {
        super(props)

        this.state 			  = {scale:  9}

        this.message_id   = 200000

        this.year_i       = parseInt( this.props.year, 10 )

        this.chartRef   	= React.createRef()
        this.containerRef	= React.createRef()
  }


	containerRect()
	{
	    if (this.containerRef.current)
	    {
	        const chartRect  = this.containerRef.current.getBoundingClientRect()
	        return {
	                    height: Math.floor( chartRect.height ),
	                    width:  Math.floor( chartRect.width )
	                }
	    }
	    else
	        return {
	                    height: 100,
	                    width:  100
	                }
  }

  fetch_expression( param )
  {
    if (this.inflight)
      return
    const {year, varname, province}  = this.props
    const component = this

    const base = this.props.kind == 'qd' ? 'qdata' : 'sdata'

    const scenario = this.props.scenario ? `scenario=${this.props.scenario}` : ''

    const url = `${backend_config.server}/${base}/query?${scenario}&year=${year}&key=${varname}&province=${province}&dependencies=true&source=true&api_key=${API_KEY}`
    if (url == this.url_displayed)
      return
    this.inflight = true

    console.log(url)
    axios.get(`${url}&message_id=${this.message_id}`)
      .then((incoming) =>
      {
        this.inflight       = false

        const { data } = incoming

        // Check if message got overlapped
        if (data.message_id != this.message_id)
        {
          console.log( "Skipping " + data.message_id + " cause " + this.message_id )
          return
        }
        else
          this.message_id++

        this.url_displayed = url

        if (data.status === 'error') {
          console.log("Error Focus")
          console.log(data.error)
          component.setState({ expression: null })
        }
        else {
          console.log(data )
          component.setState({expression: data.result})
        }
      })
  }

  nodeClick = (id) => {

    return (event) => {
      const currentpath                     = this.props.history.location.pathname

      const { year, varname,keys}  = this.props
      const keydisp = keys ? `&keys=${keys}` : ''


      if (id)
        this.props.history.push(`${currentpath}?year=${year}&varname=${varname}&selected_node=${id}${keydisp}`)
      else
        this.props.history.push(`${currentpath}?year=${year}&varname=${varname}${keydisp}`)
    }
  }

  get_value( id, node )
  {
    const node_value = node.value
    if (!node_value)
      return null
    else if (node_value.kind == 'scalar')
      return node_value.value
    else if (node_value.kind == 'string')
      return node_value.value
    else if (node_value.kind == 'yearly')
    {
      const data = node_value.data
      if (!data)
        return '[...]'
      const v = data[`${this.props.year}`]
      if (v !== undefined && v !== null)
        return v
      else
      {
        if (data.length > 1)
        {
          const yy = Object.keys(data)[0]
          let yv = v[yy]
          if (!isNaN(yv))
            yv = yv*1.0.toFixed(2)
          return `[${yy}: ${yv}]`
        }
        else
          return '[]'
      }
    }
    else
      return null
  }


  walk_depths( tree, id, depth )
  {
  	const node = tree[id]
  	if (!node || node.depth === depth || node.depth > depth)
  		return // We have already visited it
  	node.depth = depth
  	if (node.used_by)
  	{
  		for( const varname of node.used_by )
  		{
  			const next_node = tree[varname]
  			this.walk_depths( tree, varname, depth + 1)
  		}
  	}
  }

  mk_svg_lines( tree, from_id, list )
  {
  	const  from = tree[from_id]
  	if (from.uses)
  	{
	  	from.uses.map( (to_id) =>
	  	{
	  		const to = tree[to_id]
	  		const x1 = 10 + from.depth * NODE_SPACE_X
	  		const y1 = 10 + from.yc + (NODE_HEIGHT/2)
	  		const x2 = 10 + to.depth    * NODE_SPACE_X + NODE_WIDTH
	  		const y2 = 10 + to.yc   + (NODE_HEIGHT/2)

	  		list.push( <path d={`M ${x1},${y1} C ${x1-50},${y1} ${x2+50},${y2}, ${x2},${y2}`}
	  					stroke="black" fill="transparent"  strokeWidth="3" strokeOpacity='0.6'
	  					key={`LINE-${from_id}-${to_id}`}/>)
	  	})
	}
  }

  mk_svg_node( tree, id, list  )
  {
  	const node 	 = tree[id]
    const value  = this.get_value( id, node )

    const origin = node.value == null ? 'Error' : node.value.column ? `[${node.value.line}:${node.value.column}]` : null

  	const xc = 10 + node.depth * NODE_SPACE_X
  	const yc = 10 + node.yc


    const selected_year =
          node.input && node.value && node.value.selected_year !== undefined
            ? node.value.selected_year
            : null;

    const color   = node_color( id, node, value, "#46a" )

    const stroke  = this.props.selected_node === node ? '#999' : 'transparent'
    const swidth  = this.props.selected_node === node ? '5px'   : '0px'
    const opacity = selected_year ? 0.8 : null

    const title  = `${id}     ${selected_year ? ("(" + selected_year + ")") : ''}`.trim()

  	list.push( <rect className='node' opacity={opacity} onClick={this.nodeClick(id)} key={id} x={xc} y={yc}
                      width={NODE_WIDTH} height={NODE_HEIGHT} strokeWidth={swidth} stroke={stroke} fill={color} rx={NODE_WIDTH/25}/> )
  	list.push( <text  onClick={this.nodeClick(id)} key={`${id}-t1`} x={xc + 5} y={yc + 20} className="varname">{title}</text>)
    list.push( <text  onClick={this.nodeClick(id)} key={`${id}-t2`} x={xc + 5} y={yc + 38} className="value">{value}</text>)

    if (selected_year)
      list.push(<circle cx={xc + NODE_WIDTH - 10} cy={yc + 15} r={5} fill='red' />);

    if (origin)
      list.push( <text onClick={this.nodeClick(id)} key={`${id}-t3`} textAnchor="end" x={xc + 160} y={yc + 38} className="value">{origin}</text>)
  }


  mk_svg_tree( tree )
  {
  	const rv = []
  	for( const varname in tree )
  		this.mk_svg_lines( tree, varname, rv )

  	for( const varname in tree )
  		this.mk_svg_node( tree, varname, rv )

  	return rv
  }

  fill_depth( tree )
  {
  	for( const id in tree )
  	{
  		const node = tree[id]
  		if (node.input)
	  		this.walk_depths( tree, id, 0 )
  	}
  }


  sort_depths( tree )
  {
  	const buckets = {}
  	for( const varname in tree )
  	{
  		const node = tree[varname]
  		if (buckets[node.depth] === undefined)
  			buckets[node.depth] = {nodes: [varname], layed_out: [], y: 0}
  		else
  			buckets[node.depth].nodes.push( varname )
  	}
  	return buckets
  }

  get_yc( tree, buckets, varname )
  {
  	const node 					= tree[varname]
  	const {depth, uses} 		= node
  	const this_bucket 			= buckets[depth]
  	if (node.yc !== undefined || this_bucket.layed_out.indexOf( varname ) !== -1)
  		return
  	if (node.depth > 0)
  	{
  		for( const used_name of node.uses)
  		{
  			const subnode = tree[used_name]
  			if (subnode)		// The subnode might not exist..
  			{
  				this.get_yc( tree, buckets, used_name )
  			}
  		}
  		buckets[node.depth-1].y = buckets[node.depth-1].y + NODE_HEIGHT/2
  	}

  	this_bucket.layed_out.push( node.id )
  	node.yc = this_bucket.y

  	this_bucket.y = this_bucket.y + NODE_HEIGHT + NODE_HEIGHT/4

  }

  average_y( tree, varname )
  {
  	const node = tree[varname]
  	if (node.depth == 0 || !node.uses || node.uses.length == 0)
  		return
  	let sum = 0
  	for( const vn in node.uses )
  	{
  		const use_node = tree[node.uses[vn]]
  		if (use_node)
  			sum = sum + use_node.yc + (node.depth - use_node.depth -1) * 20
  	}

  	node.yc = sum/node.uses.length
  }



  layout_nodes( tree )
  {
  	this.fill_depth( tree )
    const buckets 	= this.sort_depths( tree )
  	const max_depth = Object.keys( buckets ).length

  	for( const varname of buckets[max_depth-1].nodes)
  		this.get_yc( tree, buckets, varname )

  	// The average we have to run from the lowest bucket upwards to calculate the averages.
  	for( const bucket_i in buckets)
  	{
  		for( const varname of buckets[bucket_i].nodes)
  			this.average_y( tree, varname )
  	}

    const max_width = Math.max( ... Object.keys(tree).map( (x) => tree[x].yc ) ) + NODE_HEIGHT * 3
  	return {max_depth, max_width}
  }


  componentDidMount() {
    this.fetch_expression( "Mount" )
  }

  componentDidUpdate()
  {
    this.fetch_expression("Update")
    if (this.chartRef && this.chartRef.current)
    {
      this.containerRect()
      this.chartRef.current.scrollLeft = 0
      this.chartRef.current.scrollTop  = 0
    }
  }

  reset_scrollbar()
  {
    if (this.containerRef.current)
    {
        this.containerRef.current.scrollLeft = 0
        this.containerRef.current.scrollTop  = 0
    }
  }

  constrain_scale( new_scale )
  {
    if (new_scale > 20)
      return 20
    if (new_scale < 1)
      return 1
    return  Math.round( new_scale )
  }

  zoomOut = () => 	{
    						if (this.state.scale > 1)
    							this.setState({scale: this.state.scale - 1})
  					}
  zoomIn  = () => 	{
    						if (this.state.scale < 20)
    							this.setState({scale: this.state.scale + 1})
  					}
  zoomFitH = () => 	{
                this.reset_scrollbar();
                const rect      = this.containerRect()
                let   new_scale = this.constrain_scale(rect.width / this.width * 10)
                this.setState({scale: new_scale})
  					}
  zoomFitV = () => 	{
                this.reset_scrollbar()
                const rect        = this.containerRect()
    						let   new_scale   = this.constrain_scale(rect.height / this.height * 10)
    						this.setState({scale: new_scale})
  					}




  make_expression()
  {
    if (!this.props.selected_node)
      return null
    const s = this.props.selected_node.toUpperCase()

    const node = this.state.expression.dependencies[s]
    if (!node)
      return <div>Node {s} not found</div>

    const origin = get_origin(node)
    if (node.source)
      return  <div>
                  <div className='expression-text'>{node.expression}</div>
                  {origin && <div className='origin'>{origin}</div>}
                  <Expression  node={node}
                               year={this.props.year}
                               keys={this.props.keys}
                               expressions={this.state.expression.dependencies}/>
              </div>
    else if (node.expression)
      return <div>
                  {`=${node.expression}`}
                  {origin && <div className='origin'>{origin}</div>}
             </div>

    return <div>
                  Input
                  {origin && <div className='origin'>{origin}</div>}
            </div>
  }

  pinchtozoom = (e) =>
  {
      if (e.ctrlKey)
      {
        e.preventDefault()
        //scale -= e.deltaY * 0.01;
      } else {
        // posX -= e.deltaX * 2;
        // posY -= e.deltaY * 2;
      }
  }

  render()
  {
    if (!this.state.expression)
      return <div></div>   // Still fetching
    const {year, varname} = this.props
    const {expression}    = this.state

  	const tree = expression.dependencies
  	const {max_depth,max_width} = this.layout_nodes( tree )
  	const root_node = tree[varname]

  	this.width 		= max_depth * NODE_SPACE_X
  	this.height 	= max_width


  	const style = {
  					transformOrigin  : "0px 0px",
  					transition: '300ms',
	  	  			transform: `scale(${this.state.scale/10})`
	  	  		}

  	return  <div className='graph-container'>
      				<div className='depgraph-menu'>
      					<span onClick={this.zoomOut}  className='menu-icon fa fa-search-minus'/>
      					<span onClick={this.zoomIn}   className='menu-icon fa fa-search-plus' />
      					<span onClick={this.zoomFitH} className='menu-icon fa fa-arrows-alt-h' />
      					<span onClick={this.zoomFitV} className='menu-icon fa fa-arrows-alt-v' />
                <span onClick={this.props.close_graph} className='menu-icon fa fa-times right'/>
      				</div>

              <div className='expression-display term'>
                {
                  this.props.selected_node && this.make_expression()
                }
                {
                  !this.props.selected_node && <i>Click on any node to display the formula or data source</i>
                }
              </div>


      				<div ref={this.containerRef} className='graph'>
                    <div className='rescale-container'  onWheel={this.pinchtozoom} style={style}>
      	  	  			 <svg  ref={this.chartRef}
                          version={1}
            			  			xmlns='http://www.w3.org/2000/svg'
            			  			viewBox={`0 0 ${this.width} ${this.height}`}>
            			  			{
            			  				this.mk_svg_tree( tree )
            			  			}
            				  </svg>
                    </div>
      			  </div>
      			</div>
  }
}