import React 				from "react";
import {GridManager} 		from '../datamodel/grid_manager.js'
import {PEMTViewModel}	from '../viewmodel/pemt_view_model.js'
import {Axis} 				from '../datamodel/axis.js'
import {normalizeSchema}	from "../tree/tree.js"


export class TechDataModel
{
	walk_tree( node, headers, data )
	{
		if (node.name !== '_ROOT_')
		{
			for( let i in node.data )
			{
				const param 	= `${node.data[i]}`.toUpperCase()
				const entry 	= data[param]
				if (entry)
				{
					const value = entry.value
					const rounded 	= Math.round( value * 100.0 ) / 100.0
					this.data_store.set( {tech: node.key, kind: headers[i], parameter: 'x'}, rounded )
				}
			}
		}

		if (node.nodes)
			node.nodes.map( (x) => this.walk_tree( x, headers, data ) )
	}


	setup_data_store( schema, headers, data )
	{
		this.walk_tree( schema, headers, data )
	}




	get_tech_schema( i18n, schema )
	{
		const root = {
						name: "_ROOT_",
						nodes: schema.map( (x) => {
													const dev = x[1] ? i18n(`TECH:SOURCE:${x[1]}`) : ""
													return {
														name: 		i18n( `TECH:TITLE:${x[0]}` ),
														definition: dev,
														data: 	 	x.slice(2)
												  	}
												  })
				   	 }

		return normalizeSchema(root)
	}

	axis_render		= (i18n, unit_schema, headers) => {
		return (x,i) =>
		{
			const unit = unit_schema[i]
						 .replace( "MWh", 			this.units.energy   )
						 .replace( "(1000 EUR)", 	this.units.currency )

			const sp = unit.split("/")
			const i18_unit = sp.map((x,i) => {				
				if (i > 0)
				{
					const sp2 =x.replace("(", "").replace( ")", "").split(" ")
					if (sp2.length == 2)
					{
						if (x.startsWith( "("))
							return `(${i18n(sp2[0])} ${i18n(sp2[1])})`
						else
							return `${i18n(sp2[0])} ${i18n(sp2[1])}`
					}
					else 
						return i18n(x)
				}
				else
					return i18n(x)
			}).join("/")

			return <center>
					<div>
						<b>{i18n(`TECH:HEADLINE_${headers[i]}`)}</b>
						<br/>
						<i>{i18_unit}</i>
					</div>
				</center>
		}
	}

	setUnits( units )
	{
		if (!units)		return
		this.units  	= {
			energy: 	units.energy 	|| this.units.energy,
			currency: 	units.currency 	|| this.units.currency
		}
	}


	constructor( i18n, tech_schema, data, energy, currency )
	{
		this.units = {
			energy: 	energy,
			currency: 	currency
		}

		const {headers, schema, units}	= tech_schema

		const tree 			 	= this.get_tech_schema( i18n, schema )

		const axis_parameter 	= new Axis( "parameter", 	"Parameter", "alpha",  	{list: ['x']} )
		const axis_tech 	 	= new Axis( "tech",  	 	"Tech", 	 "alpha", 	{tree: tree} )

		const axis_kind 	 	= new Axis( "kind", 	 	"Kind", 	 "alpha",  	{list: headers}, this.axis_render(i18n, units, headers) )

		this.data_store 		= new GridManager( [axis_kind, axis_tech, axis_parameter] )
		this.view_model 		= new PEMTViewModel( this.data_store )
		this.view_model.extra_columns = ['definition']

		// Select the 0 element of the axes
		this.view_model.axisKeyValueListX()

		this.view_model.setSelectedValue( 'tech', 		this.view_model.axisKeyValueListX()[0].node )
		this.view_model.setSelectedValue( 'kind', 		this.view_model.axisKeyValueListY()[0].node )
		this.view_model.setSelectedValue( 'parameter', 	this.view_model.axisKeyValueListZ()[0].node )

		this.setup_data_store( tree, headers, data )
	}
}

