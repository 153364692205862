import React, { Component } from "react";
import classnames       from 'classnames';
import SDataExplorer	from './SDataExplorer'
import MasterFrame		from '../app/MasterFrame'
import queryString 		from 'query-string';
import { Navigation } from '../navigation/navigation'


class SDataFrame extends Component
{
	constructor( props )
	{
		super( props )
	}


	render()
	{
		const { match, history, location } = this.props
		const params = queryString.parse(location.search)

		console.log( this)
		return <MasterFrame parent={this}  province={this.props.match.params.province} history={history} match={match}>
					<SDataExplorer match={match} history={history} province={this.props.match.params.province}
						year={params.year} varname={params.varname} selected_node={params.selected_node}
						keys={params.keys}
						location={location}
						scenario={params.scenario}
										qd={this.props.match.params.qd}  />
				</MasterFrame>
	}
}
Object.assign(SDataFrame.prototype, Navigation)

export default SDataFrame
