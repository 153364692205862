import React, { Component, Fragment } 	from "react";
import classnames       				from 'classnames';
import JSONPretty 						from 'react-json-pretty'
import JSONTree 						from 'react-json-tree'
import {CopyToClipboard} 				from 'react-copy-to-clipboard'
import backend_config					from "../config/runconfig"
import {visitNodes}         			from '../tree/tree'
import axios 							from 'axios'
import {PEMTDataModel}   				from "../datamodel/pemt_data_model"
import {TreeEditor}						from "../TreeEditor"
import {QDataModel}   					from "./qdata-viewmodel"
import {DependencyGraph}				from "./DependencyGraph"
import UnitSelector						from '../units/UnitSelector'
import {AppController} 					from '../app/AppController'

import { Navigation } 					from '../navigation/navigation'

import {Help}							from '../help/Help'

const API_KEY 		= 'ExgWFH9XHHyXIHyKEHIH'


export class QDataExplorer extends Component
{
	static contextType = AppController;
	constructor( props )
	{
		super( props )
		this.state 	= {
			years				: null
		}

        this.all_years 		 = []
        for( let year = 2551; year < 2561; year++ )
        	this.all_years.push( `${year}` )
	}



	fetch_expression()
	{
		if (this.inflight)
			return

		const component 				= this
		const province  				= this.props.province

		this.qdata_model	= null
		const keys		= "QD_SANKEY*,QD_BAR*,QD_KPI*,QD_PROJGGPP,QD_PROJGPOP,QD_PROJFGPP,QD_PROJFPOP"
		const input_keys = this.props.keys ? this.props.keys.split(",")
															.map( x => `${x}`.toUpperCase().trim())
															.filter( x => x.startsWith("QD_")).join(",") : null
		const used_keys 	= input_keys || keys
		const year_key 	= this.context ? this.context.year_key : 'NONE'

		const url_params = this.get_backend_query()
		const url 		= `${backend_config.server}/qdata/query?${url_params}&key=${used_keys}&province=${province}&missing=backwards&api_key=${API_KEY}&year_key=${year_key}`

		if (url === this.url_displayed)
			return
		this.inflight = true
		console.log(url)
		axios.get(url)
		 .then( (incoming) =>
		{
			this.url_displayed 		= url
			this.inflight 			= false

			const {data}		= incoming
			if (data.status === 'error' )
			{
				console.log( "Error Loading expression")
				console.log( data.error )
				component.setState({ error: true, expressions: null} )
			}
			else
			{
				console.log(data.result)
				const new_state = {}
				new_state.expressions 	=  	data.result.value
				new_state.units 		= 	data.result.units
				new_state.dependencies 	=   data.result.dependencies
				new_state.error 		= false
				component.setState( new_state )
			}
		})
	}

	setUnits = (units) => this.setState( units )

	handle_key 		= (selected)  =>    {this.setState( {keys  		: selected.map( s => `${s.value}` )}) }

	close_graph 	= () 		  => 	{this.focus_variable( null, null )}




	componentDidMount()
	{
		this.fetch_expression()
	}

	componentDidUpdate()
	{
		this.fetch_expression()
	}


	focus_variable( year, varname )
	{
		const currentpath = this.props.history.location.pathname
		const params = []
		if (year == null || varname == null)
			this.props.history.push( currentpath )
		else
			this.props.history.push( `${currentpath}?year=${year}&varname=${varname}`)
	}

	setup_qdata_model()
	{

		if (!this.context || !this.context.year_refs)
			return null;

		const ref_year = `${this.context.year_refs.reference_year}`

		if (!this.state.expressions)
			return null

		const parameters = {	tree: 		this.state.expressions,
								years: 		[ref_year],
								province: 	this.props.province
							}
		const qdata_model 	= new QDataModel(parameters)
		qdata_model.view_model.setSelectedNodeCallback((node, _, year) => this.focus_variable(year.key, node.key))
		return qdata_model
	}


	render()
	{
		if (!this.context || !this.context.ctx.i18nstr)
			return <div />
		const i18 = this.context.ctx.i18nstrp("SANKEY:VALUES:QD")

		if (!this.qdata_model)
			this.qdata_model = this.setup_qdata_model()

		if (this.qdata_model)
			this.qdata_model.focusYear( this.props.year )



		const focused_expression = this.props.varname && this.props.year &&  this.state.expressions
		const chart_on = focused_expression ? 'chart-on' : 'chart-off'

		return 	<Fragment>
					<div className='sankey-main'>
						<div className='sankey-menu'>
							{
								this.qdata_model &&
								this.unit_selector()
							}
							{
								this.state.error &&
								<div className='warning section'>Data for province not available</div>
							}
							<Help id="qdata" />
						</div>

						<div className='sankey-container'>
							<div className={classnames("bottom", "grid-on",
														"sidebar-on", chart_on)}>
								{
									<div className='fill-with-title-spreadsheet'>
										<div className='helpblock'>
											<div className='title'>
												<span>{i18("HEADLINE")}</span>
												&nbsp;
												<span>{i18("PROVINCE", this.get_province())}</span>
											</div>
											<div className='text'>{i18("HELPTEXT:LINE1")}</div>
											<div className='text'>{i18("HELPTEXT:LINE2")}</div>
											<div className='text'>{i18("HELPTEXT:LINE3")}</div>
											<div className='text'>{i18("TXT:INPUTDATA")}</div>
										</div>
										<div className='sheetblock'>
										{
											this.qdata_model &&
											<TreeEditor narrow_header={true}
														view_model={this.qdata_model.view_model}
														commit={this.commit} />

										}
										</div>
									</div>
								}
							</div>
						</div>
					</div>

					{
						focused_expression &&
						<div className='depgraph'>
							<DependencyGraph 	expressions		= {this.state.expressions}
												kind 			= 'qd'
											 	history   		= {this.props.history}
											 	year      		= {this.props.year}
												varname 		= {this.props.varname}
												selected_node	= {this.props.selected_node}
												province 		= {this.props.province}
												keys 			= {this.props.keys}
											 	close_graph		= {this.close_graph} />
						</div>
					}

				</Fragment>

	}


}
Object.assign(QDataExplorer.prototype, Navigation)


export default QDataExplorer;