import React, { Component, Fragment } from "react";


function numFormat( x, scale, maximum_frac = 1000, minimum_frac = 0 )
{
	const {exp} 	= scale
	let   maxfd 	= exp < 0 ? -exp + 3 : 11 - exp

	//console.log( exp, maxfd, maxfd + exp)

	if (exp >= 6)
		maxfd = 0
	if (maxfd > 8)
		maxfd = 10
	if (maxfd < 0)
		maxfd = 0

	if (maxfd > maximum_frac)
		maxfd = maximum_frac

	try
	{
		if (maxfd == 0)
			return Math.floor(x).toLocaleString('en-US', {minimumFractionDigits: 0, maximumFractionDigits: maxfd})
		else
			return x.toLocaleString('en-US', {minimumFractionDigits: minimum_frac, maximumFractionDigits: maxfd})
	}
	catch(e)
	{
		console.log( 'NFORMAT ', x, typeof(x), isNaN(x), maximum_frac, minimum_frac, exp, maxfd)
		return '---'
	}

}

class AxisPainter
{
	draw_y_axis(svg, scaling, props )
	{

		const tick_width = 8
		const xc = scaling.axis_x_0

		let y = 0
		while (y < scaling.max) {
			const yc = scaling.axis_y_0 - y * scaling.chart_height / scaling.range
			svg.push(<line key={`y-axis-tick-mark-pos-${y}`}
				x1={xc} y1={yc} x2={xc - tick_width} y2={yc}
				stroke='black' strokeWidth={1} />)
			const y_str = numFormat( y, scaling )
			svg.push(<text key={`y-axis-tick-number-pos-${y}`} fontSize={12} textAnchor="end" x={xc - tick_width * 2} y={yc}>{y_str}</text>)

			if (!scaling.valid)
				break;
			y = y + scaling.tick
		}

		y = 0
		while (scaling.valid && y > scaling.min) {
			const yc = scaling.axis_y_0 - y * scaling.chart_height / scaling.range
			svg.push(<line key={`y-axis-tick-mark-neg-${y}`}
				x1={xc} y1={yc} x2={xc - tick_width} y2={yc}
				stroke='black' strokeWidth={1} />)
			const y_str = numFormat( y, scaling )
			svg.push(<text key={`y-axis-tick-number-neg-${y}`} fontSize={12} textAnchor="end" x={xc - tick_width * 2} y={yc}>{y_str}</text>)

			y = y - scaling.tick
		}

		const tyc = scaling.axis_y_0 - 10
		const txc = xc - scaling.tick_width + scaling.margin + 10
		svg.push(<text key='y-axis-label' dominantBaseline="central" fontSize={10} textAnchor="center" transform={`rotate(-90, ${txc}, ${tyc})`}
			x={txc} y={tyc}>{props.y_axis_label}</text>)
		if (props.y_axis_sublabel)
		{
			svg.push(<text 	key='y-axis-label-2' dominantBaseline="central" fontSize={10}
							textAnchor="center" transform={`rotate(-90, ${txc+15}, ${tyc})`}
							x={txc+15} y={tyc}>{props.y_axis_sublabel}</text>)
		}
		svg.push(<text key='y-axis-label-unit' dominantBaseline="central" fontSize={9} textAnchor="center" transform={`rotate(-90, ${txc}, ${tyc})`}
			x={txc} y={tyc+15}>{props.y_axis_unit}</text>)

		if (props.y_axis_top)
		{
			const 	l = props.y_axis_top.split("\n")
			l.forEach( (line,i) =>
			{
				const yc = scaling.chart_top - l.length * 15 + i*15 - 5
				svg.push(<text key={`y-axis-label-top-${i}`} dominantBaseline="central" fontSize={12} textAnchor="center"
				x={scaling.axis_x_0  } textAnchor='end' y={yc}>{line}</text>)
			})
		}
	}


	draw_x_axis(svg, scaling, props) {
		const x0 = scaling.axis_x_0
		const x1 = scaling.axis_x_0 + scaling.chart_width

		const ym = scaling.axis_y_0 + 10

		if (props.x_axis_label_0)
			svg.push( <text key={`x-axis-label-l`} dominantBaseline="central" fontSize={10}
				x={x0} textAnchor='start' y={ym}>{props.x_axis_label_0}</text>)
		if (props.x_axis_label_1)
			svg.push( <text key={`x-axis-label-r`} dominantBaseline="central" fontSize={10}
				x={x1} textAnchor='end' y={ym}>{props.x_axis_label_1}</text>)
	}

	draw_axis(svg, scaling, props ) 
	{

		const x0 = scaling.axis_x_0
		const x1 = scaling.axis_x_0 + scaling.chart_width

		const y0 = scaling.chart_bottom
		const ym = scaling.axis_y_0
		const y1 = scaling.chart_top

		svg.push(<line key='x-axis' x1={x0} y1={y0} x2={x0} y2={y1} stroke='black' strokeWidth={1} />)
		svg.push(<line key='y=axis' x1={x0} y1={ym} x2={x1} y2={ym} stroke='black' strokeWidth={1} />)

		this.draw_y_axis(svg, scaling, props )
		this.draw_x_axis(svg, scaling, props )
	}
}


export default AxisPainter