import React, { Component } from "react";
import './MainEditor.scss';
import {TreeEditor}		from "./TreeEditor.js"
import {Chart}			from "./chart/Chart.js"
import classnames       from 'classnames';

import {PEMTDataModel}                	from "./datamodel/pemt_data_model.js"

class MainEditor extends Component
{
	constructor( props )
	{
		super( props )
		const pemt_model 	= new PEMTDataModel({dummydata: true})
		const {view_model}  = pemt_model
		view_model.addLayoutCallback( (x) => this.setState( {view_model: this.state.view_model} ))
		this.state = {view_model: view_model}
	}

	commit( json )
	{
		console.log( "Commit Changes to Tree:")
		console.log( json )
	}


	toggle( name )
	{
	 	return (event) =>
		{
			const {view_model}		= this.state
			if      (name === 'sidebar')
				view_model.sidebar_open = !view_model.sidebar_open
			else if (name === 'grid')
				view_model.grid_open 	= !view_model.grid_open
			else if (name === 'chart')
				view_model.chart_open 	= !view_model.chart_open
			else if (name === 'filter')
				view_model.filter_open 	= !view_model.filter_open

			view_model.triggerLayoutCallbacks()
		}
	}

	render()
	{
		const {view_model}		= this.state;
		const  filter_open 		= view_model.filter_open
		const  sidebar_open 	= view_model.sidebar_open
		const  grid_open 		= view_model.grid_open
		const  chart_open 		= view_model.chart_open
		const  has_chart 		= this.props.has_chart || true
		return (
			<div className="app-frame">

				<div className="menu">
					<div className='button active'><span className='button-icon fa fa-upload'/>Upload...</div>
					<div className='button active'><span className='button-icon fa fa-download'/>Template...</div>

					<div onClick={this.toggle('filter')}
						className={classnames('button', filter_open  ? 'active' : null)}>
						<span className='button-icon fa fa-filter'/>
						{view_model.getAxisDisplayName('z')}
					</div>
					<div onClick={this.toggle('sidebar')}
						className={classnames('button', sidebar_open ? 'active' : null)}>
						<span className='button-icon fa fa-filter'/>
						{view_model.getAxisDisplayName('y')}
					</div>
					<div onClick={this.toggle('grid')}
						className={classnames('button', grid_open    ? 'active' : null)}>
						<span className='button-icon fa fa-th'/>
						Details
					</div>
					{
						has_chart &&
						<div onClick={this.toggle('chart')}
							className={classnames('button', chart_open   ? 'active' : null)}>
							<span className='button-icon fa fa-chart-line'/>
							Chart
						</div>
					}
				</div>
				<div className={classnames(
											"bottom",
											filter_open  ? "filter-on"	: "filter-off",
											chart_open 	 ? "chart-on"	: "chart-off",
											sidebar_open ? "sidebar-on"	: "sidebar-off",
											grid_open 	 ? "grid-on"	: "grid-off"  ) }>
					<TreeEditor view_model={view_model} commit={this.commit} />
					{
						chart_open && view_model.chart_node &&
							<div className='chart'>
								<Chart view_model={view_model} chart_type={view_model.chart_type} selected_nodes={view_model.chart_node}/>
							</div>
					}
				</div>
			</div>
		);
	}
}

export default MainEditor
