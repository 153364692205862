import React, { Component, Fragment }                                  from 'react';
import {DataNode}                                                      from './DataNode';
import {findNode, addNewNode, normalizeSchema, mapTree,
        visitNodesWithParent, cloneNode}                               from './tree/tree.js';
import classnames                                                      from 'classnames';

import * as utils                                                      from './utils/utils';



export class FilterNode extends Component
{

  state = {
  }

  selectFilter = (event) =>
  {
      const {view_model}  = this.props
      view_model.setSelectedValueOnAxis( "z", this.props.node )
      view_model.triggerLayoutCallbacks()
  }


  indent( level )
  {
    let rv = []
    for( let i = 0; i < level; i++ )
      rv.push( <div  onClick={this.openClose} key={`indent${this.props.node.id}-${i}`} className='indent'></div> )
    return rv
  }


  render()
  {
    const {node, children}  = this.props
    const {view_model}      = this.props

    const opened            = view_model.isOpen( node )
    const {name}            = node
    const selected          = view_model.getSelectedValueOnAxis( "z" )
    const nameClasses   = classnames( "value", "name", (selected && selected.key == node.key) ? "focused" : null )
    const indicator     = (node.level > 0 && node.nodes && node.nodes.length > 0) ? (opened ? '▾': '▸') : ''

    return  <Fragment>
              <div className='row'>
                  <div    className     = {classnames( "cell", "row-head")}
                          onDoubleClick = {this.openClose}>
                    <div className='cell-contents'>
                      {this.indent( node.level )}
                      <div  className   = 'indicator'
                            onClick     = {this.openClose}>
                          {indicator}
                      </div>
                      <div  className     = {nameClasses}
                          onClick       = {this.selectFilter}>
                          {node.level == 0 ? <b>-</b> : `${name}`}
                      </div>
                    </div>
                  </div>
              </div>
              { opened &&
                <Fragment>
                  {children}
                </Fragment>
              }
            </Fragment>
  }
}