
import React, { Component,Fragment }	from "react";
import {Link} 							from 'react-router-dom'
import ReactTooltip 					from 'react-tooltip'
import backend_config					from "../config/runconfig.js"
import {AppController} 					from '../app/AppController'
import axios 							from 'axios'





class NationalFrame  extends Component
{
	static contextType = AppController;

	constructor( props )
	{
		super( props )
		this.state 	= {
			displayed_province	: null,
			count 				: 0
		}
	}



	render()
	{
		const {match, children, history, province} = this.props

		let province_data = null
		if (this.context.provinces && province)
			province_data = this.context.provinces[province.toUpperCase()]

		const has_scenarios = province_data && province_data.scenarios && province_data.scenarios.length > 0

		return (
			<div className="app-frame sidemenu-off">
				<ReactTooltip />
				<div className="menu">

				</div>
				<div className='province-master'>
					<div className='side-menu'>
						<section>
							<section>
								<h1><Link to={`/province`}>Province Selection</Link></h1>
								<ul>
									<li><Link to={`/upload`}>Upload</Link></li>
								</ul>

							</section>
						</section>
					</div>
					<div className='province-data'>
						{this.context && this.context.connected != true && <span>Connection failure</span>}
						{this.context && this.context.connected == true && children }
					</div>
				</div>
			</div>)
	}
}


export default NationalFrame