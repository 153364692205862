import React, { Component, Fragment }   from "react";
import { EESelector }                   from "../scenario/EESelector";
import { ScenarioSelector }             from '../scenario/ScenarioSelector'
import UnitSelector                     from '../units/UnitSelector'

import queryString                      from 'query-string';
import { Constraint } from "kiwi.js";



// This is a mixin that run on all the GUi classes that supports navigation between different scenaria, CHP and ee_set values
export const Navigation = 
{
    goto(target = {})
    {
        const { ee_set_id, chp, scenario, energy, currency, area, ...rest } = target
        const q = {...this.query(), ...rest}

        q.ee_set_id         = ee_set_id !== undefined ? ee_set_id   : this.get_ee_set_id(q)
        q.chp               = chp       !== undefined ? chp         : this.get_chp(q)
        q.scenario          = scenario  !== undefined ? scenario    : this.get_scenario(q)
        q.energy            = energy    !== undefined ? energy      : this.get_energy(q)
        q.currency          = currency  !== undefined ? currency    : this.get_currency(q)
        q.area              = area      !== undefined ? area        : this.get_area(q)


        const qs = this.make_query_string(q)


        if (qs == this.props.history.location.search)
            return false

        const currentpath = this.props.history.location.pathname

        this.props.history.replace( `${currentpath}${qs}` )
        return true
    },


    // This is like goto, except the from part of the URL is exchanged
    // with the same parameters
    new_page(target, rest)
    {
        const q = { ...this.query(), ...rest }
        const qs = this.make_query_string(q)
        this.props.history.replace(`${target}${qs}`)
        return true
    },


    link_target( target, rest )
    {
        const q = { ...this.query(), ...rest }
        const qs = this.make_query_string(q)
        return `${target}${qs}`
    },

    make_query_string(q)
    {
        let str = ``
        Object.keys( q ).forEach( p =>
        {
            const v = q[p]
            if (v == 'undefined' || !v)
            {}
            else if (str.length == 0)
                str = `?${p}=${encodeURIComponent(v)}`
            else
                str = `${str}&${p}=${encodeURIComponent(v)}`
        })

        return str
    },

    query() {
        const qs = this.props.history.location.search
        if (qs)
            return queryString.parse(qs)
        else
            return {}
    },

    get_lang()
    {
        if (!this.context || !this.context.lang)
            return 'en'
        return this.context.lang
    },

    get_province ()  { 
        return this.props.match.params.province
    },

    
    get_province_data() {
        const province = this.get_province()
        if (!province)
            return null
        if (this.context.provinces)
            return this.context.provinces[province.toUpperCase()]
        else
            return null
    },

    get_ee_set_id(q)         
    { 
        const ee_set_id = q ? q.ee_set_id : this.query().ee_set_id
        return ee_set_id
    },

    get_scenario(q) {
        const scenario   = q ? q.scenario : this.query().scenario
        return scenario || 'P001'
    },

//    get_group_name(q) {
//        return q ? q.group_name : this.query().group_name
//    },

    get_chp(q)  {
        const chp = q ? q.chp : this.query().chp
        if (chp == undefined)
            return "off"
        else    
            return chp == "on" ? "on" : "off"
    },


    get_energy(q) {
        const energy = q ? q.energy : this.query().energy
        return energy || 'ktoe'
    },

    get_area(q) {
        const area = q ? q.area : this.query().area
        return area || 'km²'
    },

    get_currency( q )
    {
        const currency = q ? q.currency : this.query().currency
        return currency || 'Mio. THB'
    },

    get_units( q )
    {
        return {
            energy:         this.get_energy( q ),
            currency:       this.get_currency( q ),
            area:           this.get_area( q )
        }
    },

    get_backend_query( add_units = true)
    {
        const q = this.query()

        let   ee_set_id = ""
        if (this.get_ee_set_id(q))
            ee_set_id   =   `&ee_set_id=${encodeURIComponent(this.get_ee_set_id(q) || 'E0000')}`
        const chp       =   `&chp=${ encodeURIComponent( this.get_chp(q)  )}`
        const scenario  =   `&scenario=${encodeURIComponent(this.get_scenario(q) || 'P001')}`

        if (!add_units)
            return `${ee_set_id}${chp}${scenario}`

        const { energy, area, currency } = this.get_units( q )

        const en_str = `&energy=${encodeURIComponent(energy)}`     
        const cu_str = `&currency=${encodeURIComponent(currency)}` 
        const ar_str = `&area=${encodeURIComponent(area)}`         

        const units_alone = `${en_str}${cu_str}${ar_str}`

        const unit_flag   = units_alone.length > 0 ? '&convert_units=true' : ''
        const units       = `${units_alone}${unit_flag}`

        return `${ee_set_id}${chp}${scenario}${units}`
    },

    set_variant(v) 
    {
        this.goto(v)
    },

    variant_selector(pd = null) 
    {
        const province_data = pd || this.get_province_data()
        if (!province_data)
            return <div/>
        return <EESelector province_data={province_data} selected_ee={this.get_ee_set_id()} selected_chp={this.get_chp()} 
                    callback={(v) => {this.set_variant(v)}} />
    },


    scenario_selector(pd = null, group_only = false) 
    {
        const province_data =  pd || this.get_province_data()
        if (!province_data)
            return <div/>
        if (group_only)
            return <ScenarioSelector parent={this} display_scenario={false}
                        province_data={province_data} group_name={this.get_group_name()} callback={this.set_group_name} />
        else
            return <ScenarioSelector parent={this} 
                        province_data={province_data} scenario={this.get_scenario()} 
                        callback={(v) => { this.set_variant(v) }} />
    },

    unit_selector( params = {} )
    {
        const area      = params.area      != false
        const currency  = params.currency  != false
        const energy    = params.energy    != false
        return <UnitSelector parent={this} units={this.get_units()} callback={(v) => { this.set_variant(v) }} 
                    display_area={area} display_currency={currency} display_energy={energy} />
    }
}

