import React, { Component } from "react";
import classnames       from 'classnames';
import CDataExplorer	from './CDataExplorer'
import MasterFrame		from '../app/MasterFrame'
import queryString 		from 'query-string';


class CDataFrame extends Component
{
	constructor( props )
	{
		super( props )
	}


	render()
	{
		const { match, history, location } = this.props
		const params = queryString.parse(location.search)

		return  <MasterFrame province={this.props.match.params.province} history={history} match={match}>
			<CDataExplorer match={match} history={history} province={this.props.match.params.province}
				year={params.year} varname={params.varname} selected_node={params.selected_node}
				keys={params.keys}
				location={location}
				scenario={params.scenario}
								qd={this.props.match.params.qd}  />
				</MasterFrame>
	}
}

export default CDataFrame
