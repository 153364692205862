import React, { Component, Fragment } from "react";
import AxisPainter 					  from "./AxisPainter"
import BarChart 					  from "./BarChart"


export class BarChartSet extends Component
{
	split_into_neg_pos( data )
	{
		let   psum 			= 0
		let   nsum 			= 0

		const neg = []
		const pos = []
		const zer = []
		for( let entry of data )
		{
			const v = entry.value
			if (v < 0)
			{
				neg.push( entry )
				nsum 	+= v
			}
			else if (v > 0)
			{
				pos.push( entry )
				psum 	+= v
			}
			else
			{
				zer.push( entry )
			}
		}

		return {psum,nsum,pos,neg,zer}
	}


	get_group_indices( set )
	{
		if (this.props.groups)
			return this.props.groups
		if (Array.isArray(set.group_keys))
			return set.group_keys
		return []
	}

	get_set_indices( set )
	{
		if (this.props.sets)
			return this.props.sets
		if (Array.isArray(set.set_keys))
			return set.set_keys
		return []
	}


	// Input is a data set which looks like this:
	// { 	groups_indices	: [0,1],
	// 		set_keys		: ['A', 'B'],
	// 		data: 		{
	// 			'A' 		: [ [{id:'a', value:1}, {id:'b', value:2}],
	// 							[{id:'a', value:3},{id:'b', value:4},{id:'d', value:5}] ],
	// 			'B' 		: [ [{id:'c', value:2},{id:'d', value:9}],
	// 							[{id:'a', value:9},{id:'b', value:2},{id:'c', value:-5},{id:'e', value:10}] ]
	//					}
	// 		}
	// }
	setup_data( set )
	{
		const data_sets 	= {}
		const set_indices 	= this.get_set_indices(set)
		const group_indices	= this.get_group_indices(set)

		const chart_keys 	= []
		const chart_split 	= {}
		const charts		= {}

		const presence 		= {}

		set_indices.forEach( (key,idx) =>
		{
			const incoming 		= 	set.data[key]
			const chart_id 		= 	incoming.chart ? incoming.chart : 'MASTER'
			if (!chart_split[chart_id])
			{
				chart_keys.push( chart_id )
				chart_split[chart_id] = []
				presence[chart_id] 	  = {}
			}
			chart_split[chart_id].push( key )
		})

		chart_keys.forEach( (chart_key,idx) =>
		{
			let   gmax 			= 0
			let   gmin 			= 0

			chart_split[chart_key].forEach( (key,idx) =>
			{
				const incoming 		= 	set.data[key]
				const group_data	= 	{
											index: 		idx,
											data: 		[],
											label: 		incoming.label,
											sub_label: 	incoming.sub_label,
											top_label: 	incoming.top_label,
											target: 	incoming.target
										}

				group_indices.forEach( (group_id) =>
				{
					const subset  	= incoming.data[group_id]
					if (subset)
					{
						subset.data.forEach( e => {
							e.sub_id = subset.label || incoming.label
							if (this.props.detailClick)
								e.focus_id = `${e.id}-${e.sub_id}`
							else
								e.focus_id  = e.id

							if (!e.id.startsWith("REST") && !(this.props.legend === false))
								presence[chart_key][e.id] = true
							if (e.id.startsWith("REST"))
								e.focus_id 	= null
						})
						const {psum,nsum,pos,neg,zer} 	=  this.split_into_neg_pos( subset.data )

						group_data.data.push( {label: subset.label, pos,neg,zer})
						gmax = Math.max( psum, gmax )
						gmin = Math.min( nsum, gmin )
					}
				})

				data_sets[key] = group_data
			})

			charts[chart_key] =	{
				chart_key: 		chart_key,
				presence: 		presence[chart_key],
				group_indices: 	group_indices,
				groups: 	 	set.groups,
				set_keys: 	 	chart_split[chart_key],
				data_sets: 	 	data_sets,
				max: 	 		gmax,
				min: 	 		gmin,
				header: 		set.header 	? set.header :
								set.headers ? set.headers[chart_key] :
								null || '',
				footer: 		set.footer ? set.footer :
								set.footers ? set.footers[chart_key] :
								null || ''
			}
		})

		return {
			chart_keys: 	chart_keys,
			charts: 		charts
		}
	}


	render()
	{
		const {data, ...chartprops}		= this.props



		const chart_data 				= this.setup_data( data )

		if (chart_data.chart_keys == null || chart_data.chart_keys.length == 0)
			return <div/>

		const chart_width 				= this.props.chart_width || Math.floor( 100.0 * (1.0 / chart_data.chart_keys.length) )

		let   valids = 0
		const chart_divs = chart_data.chart_keys.map( (chart_key,idx) =>
							{
								const download 	= this.props.download ? this.props.download.replace( "_IDX_", '' +idx ) : null
								const data_for_chart = chart_data.charts[chart_key]

								const chart = <BarChart width={chart_width}
									min_legend_height={this.props.min_legend_height}
									download={download}
									key={chart_key}
									chart_key={chart_key}
									index={idx}
									data={data_for_chart} {...chartprops} />
						
								if (chart)
								{
									valids ++
									return chart
								}
							})

		if (valids > 0)
			return  <Fragment>{chart_divs}</Fragment>
		else
			return null
	}


}

export default BarChartSet