import React, { Component, Fragment } 	from "react";
import classnames 						from 'classnames';
import backend_config 					from "../config/runconfig"
import axios 							from 'axios'

import { Navigation } from '../navigation/navigation'

import { AppController } 				from '../app/AppController'
import MasterFrame 						from '../app/MasterFrame'
import BarChartSet 						from './BarChartSet'
import {Help}							from '../help/Help'

const API_KEY = 'ExgWFH9XHHyXIHyKEHIH'



export class CapacityChart extends Component {
	static contextType = AppController;
	constructor(props)
	{
		super(props)
		this.state = {}
	}


	fetch_expression() {
		if (this.inflight)
			return

		const component 	= this
		const province 		= this.get_province()

		this.data_model = null

		const url_params = this.get_backend_query()

		const root = `${backend_config.server}/sdata/capacitychartdata`
		const year_key          = this.context ? this.context.year_key : 'NONE'
		const url = `${root}?${url_params}&lang=${this.get_lang()}&province=${province}&api_key=${API_KEY}&year-key=${year_key}`

		if (url === this.displayed_url)
			return
		console.log( url )

		this.inflight = true
		axios.get(url)
			.then((incoming) => 
			{
				this.inflight = false
				this.displayed_url = url

				const { data } = incoming

				if (data.status === 'error')
				{
					component.setState({ error: true, errorMsg: data.error, chart_data: null })
				}
				else if (!data.result.chart_data || Object.keys( data.result.chart_data ).length == 0)
				{
					component.setState({ error: true, errorMsg: "No Data", chart_data: null })
				}
				else
				{
					const new_state = {}
					new_state.error = false
					new_state.errorMsg = null
					new_state.chart_data = data.result.chart_data
					new_state.chart_info = data.result.info
					new_state.units = data.result.units
					new_state.is_head_data = data.result.is_head_data
					component.setState(new_state)
				}
			})
	}



	add_value_to_chart(set, id, value)
	{
		if (!value || !value.kind || value.kind != 'scalar')
		{
			set.push({id: id, value: 0})
		}
		else
		{
			const v 	= value.value
			set.push({ id: id, value: v })
		}
	}


	setup_chart_data_for_keyset(i18, kind, data, key_set, info)
	{
		if (!data)
			return null

		data.ref_year 	= this.context && this.context.year_refs ? `${this.context.year_refs.reference_year }` 	: ''
		data.tgt_year 	= this.context && this.context.year_refs ? `${this.context.year_refs.target_year}` 		: ''


		const labels 	= {}

		const result 	= {}
		const keys 		= []
		for( let entry_i in key_set )
		{
			const entry = key_set[entry_i]
			const key 	= `P${entry_i}`

			const index 	= entry.index
			const type 		= entry.type

			const data_set_cur 	= []
			const data_set_fut 	= []
			const data_set 	 	= [
									{label: data.ref_year, data: data_set_cur},
									{label: data.tgt_year, data: data_set_fut}
								  ]
			const data_key 		= `BARCAP${index}`

			let key_added = false
			if (data['CURRENT'])
				key_added = key_added || this.add_value_to_chart( data_set_cur, index, data['CURRENT'][`QD_${data_key}`] )
			if (data['FUTURE'])
				key_added = key_added || this.add_value_to_chart( data_set_fut, index, data['FUTURE'][ `SD_${data_key}`] )

			if (keys.indexOf( key ) < 0)
				keys.push( key )

			let top_label 		= ''
			if (data['FUTURE'])
			{
				const top_key 		= `SD_BARCAPDIFF${index}`
				const value 		= data['FUTURE'][ `${top_key}`]
				if (value && value.kind == 'scalar')
					top_label 		= value.value.toPrecision( 5 )
			}

			labels[index] =  {
								description: i18(`${kind}:LABEL:${entry.index}`),
								label: i18(`${kind}:LEGEND1:${entry.index}`) + "\n" + i18(false, `${kind}:LEGEND2:${entry.index}`),
								color: 			entry.color
							}

			result[key] = {
				label: 			entry.label,
				top_label: 		top_label,
				sub_label: 		null,
				target: 		null,
				data: 			data_set,
				labels: 		labels
			}
		}

		const rv = {
			data: 			result,
			info: 			info,
			set_keys: 		keys,
			draw_legend: 	false,
			labels: 		labels
		}

 		return rv
	}


	setup_chart_data(i18, data, info)
	{
		if (!data)
			return null
		const elec_map = [
			{
				index: 'E1',
				color: '#3a393c'
			},
			{
				index: 'E10',
				color: '#615c6a'
			},
			{
				index: 'E14',
				color: '#EB8900'
			},
			{
				index: 'E2',
				color: '#CCC9CF'
			},
			{
				index: 'E12',
				color: '#6EBD2E'
			},
			{
				index: 'E13',
				color: '#C5A0A0'
			},
			{
				index: 'E7',
				color: '#6D2D08'
			},
			{
				index: 'E11',
				color: '#bb3107 '
			},
			{
				index: 'E3',
				color: '#F3DF2B'
			},
			{
				index: 'E4',
				color: '#111BDF'
			},
			{
				index: 'E5',
				color: '#0B118E'
			}

		]

		const heat_map = [
			{
				index: 'H5',
				color: '#3a393c'
			},
			{
				index: 'H6',
				color: '#CCC9CF'
			},
			{
				index: 'H9',
				color: ' #6EBD2E'
			},
			{
				index: 'H10',
				color: '#C5A0A0'
			},
			{
				index: 'H8',
				color: '#6D2D08'
			},
			{
				index: 'H1',
				color: '#DB4500'
			},
			{
				index: 'H11',
				color: '#6EBD2EB0'
			},
			{
				index: 'H12',
				color: '#C5A0A0B0'
			},

			{
				index: 'H3',
				color: '#C51627'
			},
		]

		return {
			elec: 		this.setup_chart_data_for_keyset( i18, 'ELEC', data, elec_map, info ),
			heat: 		this.setup_chart_data_for_keyset( i18, 'HEAT', data, heat_map, info )
		}
	}



	gotoTarget = (target) =>
	{
		return (event) =>
		{
			const province = this.props.match.params.province
			this.props.history.push(`/supply-demand-chart/${province}/${target}`)
		}
	}


	render_chart(i18, data_model)
	{

		const province 	= this.get_province()
		const scenario 	= this.get_scenario()

		const avgtitle = `${i18("CHART:CAPACITY:NOTE:LINE1")}\n${i18("CHART:CAPACITY:NOTE:LINE2")}\n${i18("CHART:CAPACITY:NOTE:LINE3")}`

		return  	<div className='chart-container-vertical'>
							<div className='title'>{i18("CHART:CAPACITY:HEADER:E")}</div>
							<div className='mainchart'>
								<div className='chart-set'>
									<BarChartSet 	data={data_model.elec}
											y_axis_label={i18("YLABEL")}
											y_axis_unit={`[${i18('MW')}]`}

											y_axis_top={avgtitle}

											top_labels={true}
											labels={data_model.elec.labels}
											groups={[0,1]}
											download={`${backend_config.server}/chart/capacity1?lang=${this.get_lang()}&province=${province}&scenario=${scenario}`}

											detail_target={this.gotoTarget} />
								</div>
							</div>

							<div className='title'>{i18("CHART:CAPACITY:HEADER:H")}</div>
							<div className='mainchart'>
								<div className='chart-set'>
									<BarChartSet 	data={data_model.heat}
											y_axis_label={i18("YLABEL")}
											y_axis_unit={`[${i18('MW')}]`}

											y_axis_top={avgtitle}

											top_labels={true}
											labels={data_model.heat.labels}
											groups={[0,1]}

											download={`${backend_config.server}/chart/capacity2?lang=${this.get_lang()}&province=${province}&scenario=${scenario}`}
											/>
								</div>
							</div>
					</div>
	}


	render_error()
	{
		return 	<div className='sankey-menu'>
					<div className='warning section'>Data for scenario is not available</div>
				</div>
	}


	componentDidUpdate()
	{
		this.fetch_expression()
	}


	componentDidMount()
	{
		this.fetch_expression()
	}


	render() 
	{
		if (!this.context || !this.context.ctx.i18nstrp)
			return <div />

		const i18 = this.context.ctx.i18nstrp("CHART:CAPACITY")


		const province_data = this.get_province_data()
		if (!province_data)
			return <div/>

		if (!this.state.chart_data || !this.state.chart_info)
			return <div/>

		if (!this.data_model)
			this.data_model = this.setup_chart_data(i18, this.state.chart_data, this.state.chart_info )

		const { match, history } = this.props
		const is_error 			 = this.state.error || (this.data_model && !this.state.chart_data['FUTURE'])

		const tgt_year = this.context && this.context.year_refs ? `${this.context.year_refs.target_year}` : ''

		return <MasterFrame parent={this}  province={this.props.match.params.province} history={history} match={match}>
			<div className='sankey-main'>
				<div className='sankey-menu'>
					{
						this.variant_selector()
					}
					{
						this.scenario_selector()
					}
					{
						this.data_model && this.state && this.state.is_head_data == false &&
						<div className='warning section'>
							<i className='fa fa-exclamation-circle' />
							Diagram is not based on latest data
							</div>
					}
					{
						is_error &&
						<div className='section warning'>Data for scenario not available</div>
					}

					<Help id='capacitychart'/>
				</div>
				<div className='sankey-container with-expression'>
					<div className={classnames("bottom", "grid-on",
						"sidebar-on")}>

						<div className='fill-with-title'>
							<div className='helpblock'>
								<div className='title'>
									<span>{i18("HEADLINE:LINE1")}</span>
									&nbsp;
									<span>{i18("PROVINCE", this.get_province())}</span>
									&nbsp;
									<span>{i18("HEADLINE:LINE2")}</span>
									&nbsp;
									<span>{tgt_year}</span>
									&nbsp;
									<span>{i18("HEADLINE:LINE3")}</span>
								</div>
								<div className='text'>{i18("HELPTEXT:LINE1")}</div>
								<div className='text'>{i18("HELPTEXT:LINE2")}</div>
								<div className='text'>{i18("TXT:INPUTDATA")}</div>
							</div>
							<div className='chartblock'>
								{!is_error && this.data_model && this.render_chart( i18, this.data_model )}
							</div>
						</div>
					</div>
				</div>
			</div>
		</MasterFrame>
	}
}

Object.assign(CapacityChart.prototype, Navigation)




export default CapacityChart;