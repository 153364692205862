import React, { Component, Fragment } 	from "react"
import { useContext } from "react"
import { AppController } from '../app/AppController'


export const EESelector  = ({province_data, selected_ee, selected_chp, callback}) =>
{
	const context = useContext(AppController)
	if (!context || !context.ctx.i18nstr)
		return <div />

	const i18n = context.ctx.i18nstr

	const handle_chp 				= (event) => callback({chp: 		event.target.value })
	const handle_selected_ee_set 	= (event) => callback( {ee_set_id: 	event.target.value} )


	const 	sets 			=	province_data.ee_sets

	const 	def_selected 	=	province_data.selected_ee_set
	let 	selected_set 	= 	selected_ee

	if (!selected_set)
		selected_set  		=  	def_selected || 'E0000'

	if (sets && !sets.find( x => x.id == selected_set))
		selected_set = def_selected
	

	if (sets && !sets.find( x => x.id == selected_set))
		selected_set = 'E0000'


	if (selected_ee != selected_set)
		setTimeout( () => callback( {ee_set_id: selected_set}), 1 )

	if (sets && !sets.find( x => x.id == selected_set))
		return <div/>

	return 	<div className='section'>
				<div className='unit-selector'>
					<div className='title'>{i18n('MENU:EE')}</div>
					<select className='unit-select' value={selected_set} onChange={handle_selected_ee_set}>
						{sets.map( e => <option key={e.id} value={e.id}>{e.name}</option>)}
					</select>
				</div>
				<div className='unit-selector'>
			<div className='title'>CHP</div>
						<select className='unit-select' value={selected_chp} value={selected_chp} onChange={handle_chp}>
							<option key='chpon'  value='off'>Off</option>)
							<option key='chpoff' value='on'>On</option>)
						</select>
				</div>
			</div>
}


export default EESelector