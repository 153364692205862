import React, { Component, Fragment } 		from "react";
import classnames 							from 'classnames';
import backend_config 						from "../config/runconfig"
import axios 								from 'axios'
import { AppController } 					from '../app/AppController'
import MasterFrame 							from '../app/MasterFrame'
import BarChartSet 							from './BarChartSet'
import { Navigation } from '../navigation/navigation'

import {Help}								from '../help/Help'
const API_KEY = 'ExgWFH9XHHyXIHyKEHIH'






export class OverviewChart extends Component {
	static contextType = AppController;
	constructor(props)
	{
		super(props)
		this.state = {}
	}



	fetch_expression() {
		if (this.inflight)
			return

		const component = this
		const province = this.props.match.params.province
		const url_params = this.get_backend_query()

		this.data_model = null
		const root = `${backend_config.server}/sdata/overviewchartdata`

		const year_key          = this.context ? this.context.year_key : 'NONE'
		const url = `${root}?${url_params}&province=${province}&api_key=${API_KEY}&year_key=${year_key}`

		if (url === this.displayed_url)
			return
		console.log( url )

		this.inflight = true
		axios.get(url)
			.then((incoming) => {

				this.inflight = false
				this.displayed_url = url

				const { data } = incoming

				if (data.status === 'error')
				{
					component.setState({ error: true, errorMsg: data.error, chart_data: null })
				}
				else if (!data.result.chart_data || Object.keys( data.result.chart_data ).length == 0)
				{
					component.setState({ error: true, errorMsg: "No Data", chart_data: null })
				}
				else
				{
					const new_state = {}
					new_state.error = false
					new_state.errorMsg = null
					new_state.chart_data = data.result.chart_data
					new_state.chart_info = data.result.info
					new_state.units = data.result.units
					new_state.is_head_data = data.result.is_head_data
					component.setState(new_state)
				}
			})
	}




	chart_labels = ( i18 ) => { 
		return {
			S01: { 	label: i18("SUPPLY_RE"),
					description: "A",
					color: '#8f7' },
			S02: { 	label: i18("SUPPLY_FOS"),
					description: "B",
					color: '#d4d4d4' },
			S03: { 	label: i18("DEMAND_TOT"),
					description: i18("DEMAND_TOT"),
					color: 'rgba(128,128,128,1)' },
			S04: { 	label: i18("COST"),
					description: "Cost",
					color: 'rgba(68,114,196,0.8)'
				 },
			S05: { 	label: i18("EMISSIONS"),
					description: "Emissions",
					color: 'rgba(255,115,0,0.8)' }
		} 
	}


	setup_dataset_names( data, info )
	{
		const predefined_keys 	= []
		const custom_keys 		= []
		for( let key in data )
		{
			const set_info = info[key]
			if (set_info && set_info.custom)
				custom_keys.push( key )
			if (set_info && !set_info.custom)
				predefined_keys.push( key )
		}

		return predefined_keys.sort().concat( custom_keys.sort() )
	}




	add_value_to_chart(set, id, value)
	{
		if (!value || !value.kind || value.kind != 'scalar')
			return
		let v = value.value
		set.push({ id: id, value: v })
	}


	setup_subresult( i18, subset, metadata )
	{
		const supply = []
		const demand = []
		const set4 	 = []
		const set5 	 = []


		// The keys are SD_BARS1, SD_BAR2 || QD_BAR1, QD_BAR2... and should be put into in that order
		const key_list = Object.keys(subset).map((k) =>
		{
			const s = k.replace(/S\d$/, (match, aa, bb) => `${match.substring(0, 1)}0${match.substring(1)}`)
			const sort_key 	= s.replace('SD_BAR2', '')
			const parsed	= parseInt( sort_key.replace( "S", '' ), 10 )

			return {
				n: 	parsed,
				key: k,
				sort_key: sort_key
			}
		})

		key_list.sort((x, y) => { x.sort_key > y.sort_key ? 1 : -1 })

		for (let key_entry of key_list)
		{
			const {key,n} = key_entry

			const sort_key = key_entry.sort_key
			if (n < 3)
				this.add_value_to_chart(supply, sort_key, subset[key])
			else if (n == 3)
				this.add_value_to_chart(demand, sort_key, subset[key])
			else if (n == 4)
				this.add_value_to_chart(set4, sort_key, subset[key])
			else if (n == 5)
				this.add_value_to_chart(set5, sort_key, subset[key])
		}

		const target = metadata.group_id

		return {
					label:     	metadata.group_name,
					sub_label: 	metadata.eff_fmt,
					target: 	target,
					data: 	[{label: i18("DEMAND"), 	data: demand},
					   		 {label: i18("SUPPLY"), 	data: supply},
					   		 {label: '', 		data: set4},
					   		 {label: '', 		data: set5},]
				}
	}



	setup_chart_data(i18, data, info)
	{
		if (!data)
			return null

		const result 		= {}

		for (let set in data)
			result[set] = this.setup_subresult( i18, data[set], info[set] )

		const key_list = this.setup_dataset_names( data, info )


		console.log( key_list )
		const rv = {
			data: 			result,
			info: 			info,
			set_keys: 		key_list,
			draw_legend: 	false
		}

		return rv
	}




	gotoTarget = (target) =>
	{
		return (event) =>
		{
			const province = this.get_province()
			this.new_page(`/supply-demand-chart/${province}`, {group_name: target})
		}
	}


	render_chart(i18, data_model)
	{
		const province = this.get_province()
		return  	<div className='chart-container'>
						<div className='chart-3'>
							<div className='subchart'>
								<div className='chart-set'>
									<BarChartSet 	data={data_model}
											y_axis_label={i18('ENERGY')}
											y_axis_unit={i18(`${this.get_energy()}`)}

											min_groups={4}

											download={`${backend_config.server}/chart/overview1?lang=${this.get_lang()}&province=${province}&energy=${this.get_energy()}&currency=${this.get_currency()}`}

											labels={this.chart_labels(i18)}
											groups={[0,1]}
											detail_target={this.gotoTarget}
											detail_label={i18("DETAIL")} />
								</div>
							</div>

							<div className='subchart'>
								<div className='chart-set'>
									<BarChartSet 	data={data_model}
											y_axis_label={i18('ECOST')}
											y_axis_unit={`${i18(this.get_currency())}/${i18(this.get_energy())}`}

											min_groups={4}

											download={`${backend_config.server}/chart/overview2?lang=${this.get_lang()}&province=${province}&energy=${this.get_energy()}&currency=${this.get_currency()}`}

											labels={this.chart_labels(i18)}
											groups={[2]}
											detail_target={this.gotoTarget}
											detail_label={i18("DETAIL")} />

								</div>
							</div>

							<div className='subchart'>
								<div className='chart-set'>
									<BarChartSet 	data={data_model}
											y_axis_label={i18("GHG")}
											y_axis_unit={i18("t CO2 equiv.")}

											min_groups={4}

											download={`${backend_config.server}/chart/overview3?lang=${this.get_lang()}&province=${province}&energy=${this.get_energy()}&currency=${this.get_currency()}`}

											labels={this.chart_labels(i18)}
											groups={[3]}
											detail_target={this.gotoTarget}
											detail_label={i18("DETAIL")} />

								</div>
							</div>
						</div>
					</div>
	}


	render_error()
	{
		return 	<div className='sankey-menu'>
					<div className='warning section'>Data for scenario is not available</div>
					<Help id='overviewchart'/>
				</div>
	}


	componentDidUpdate()
	{
		this.fetch_expression()
	}


	componentDidMount()
	{
		this.fetch_expression()
	}



	render() 
	{

		if (!this.context || !this.context.ctx.i18nstr)
			return <div />

		const i18 = this.context.ctx.i18nstrp("CHART:OVERVIEW")

		if (!this.data_model)
			this.data_model = this.setup_chart_data(i18, this.state.chart_data, this.state.chart_info )

		console.log( this.state.chart_data)
		console.log( this.data_model )


		const {province} 	= this.props.match.params


		let province_data = null
		if (this.context.provinces && province)
			province_data = this.context.provinces[province.toUpperCase()]
		if (!province_data || !province_data.templates)
			return <div/>	/// This will last a about half a second so no need to flash the poor user with an error

		const { match, history } = this.props
		const tgt_year = this.context && this.context.year_refs ? `${this.context.year_refs.target_year}` : ''
		return <MasterFrame parent={this} province={this.props.match.params.province} history={history} match={match}>
			<div className='sankey-main'>
				<div className='sankey-menu'>
					{
						this.variant_selector()
					}
					{
						this.unit_selector({ area: false})
					}
					{
						this.data_model && this.state && this.state.is_head_data == false &&
						<div className='warning section'>
							<i className='fa fa-exclamation-circle' />
							Diagram is not based on latest data
							</div>
					}
					{
						this.state.error &&
						<div className='section warning'>Data for scenario not available</div>
					}

					<Help id='overviewchart'/>
				</div>
				<div className='sankey-container with-expression'>
					<div className={classnames("bottom", "grid-on",
						"sidebar-on")}>
						<div className='fill-with-title'>
							<div className='helpblock'>
								<div className='title'>
									<span>{i18("HEADLINE:LINE1")}</span>
									&nbsp;
									<span>{i18("PROVINCE", this.get_province())}</span>
									&nbsp;
									<span>{i18("HEADLINE:LINE2")}</span>
									&nbsp;
									<span>{tgt_year}</span>
								</div>
								<div className='text'>{i18("HELPTEXT:LINE1")}</div>
								<div className='text'>{i18("HELPTEXT:LINE2")}</div>
								<div className='text'>{i18("HELPTEXT:LINE3")}</div>
								<div className='text'>{i18("HELPTEXT:LINE4")}</div>
								<div className='text'>{i18("HELPTEXT:LINE5")}</div>
								<div className='text'>{i18("TXT:INPUTDATA")}</div>
							</div>
							<div className='chartblock'>
								{!this.state.error && this.data_model && this.render_chart( i18, this.data_model )}
							</div>
						</div>
					</div>
				</div>
			</div>
		</MasterFrame>
	}
}

Object.assign(OverviewChart.prototype, Navigation)

// TotalDemand	S3
// Fossil Fuel  S2
// RE Supply	S1



export default OverviewChart;