import React, { Component, Fragment } 	from "react";
import classnames       				from 'classnames';

import backend_config					from "../config/runconfig"
import axios 							from 'axios'
import {TreeEditor}						from "../TreeEditor"
import {TechDataModel}   				from "./tech-viewmodel"
import {AppController} 					from '../app/AppController'
import {tech_schema}					from './techparam-data'
import MasterFrame 						from '../app/MasterFrame'

import { Navigation } 					from '../navigation/navigation'

import {Help}							from '../help/Help'

const API_KEY 		= 'ExgWFH9XHHyXIHyKEHIH'


export class TechParams extends Component
{
	static contextType = AppController;
	constructor( props )
	{
		super( props )
		this.state 	= {}
	}


	fetch_expression()
	{
		const component 				= this
		const { province } 				= this.props.match.params

		const url_params = this.get_backend_query()
		const year_key          = this.context ? this.context.year_key : 'NONE'
		const url 		= `${backend_config.server}/sdata/pdata?province=${province}&${url_params}&api_key=${API_KEY}&scenario=P001&year_key=${year_key}`
		if (this.displayed_url === url)
			return
		console.log( url )

		axios.get(url)
		 .then( (incoming) =>
		{
			const {data}			= incoming

			this.displayed_url 		= url

			if (data.status === 'error' )
			{
				console.log( "Error Loading expression")
				console.log( data.error )
				component.setState( {error:true, message: data.error} )
			}
			else
			{
				this.tdata_model = null
				component.setState( {
										error: 	false,
										all_units: 	data.result.units,
										pdata: 	data.result.value,
										timestamp: data.timestamp
									})
			}
		})
	}

	setUnits = (units) => {this.setState( units ); this.tdata_model.setUnits( units )}



	componentDidMount()
	{
		this.fetch_expression()
	}

	componentDidUpdate()
	{
		this.fetch_expression()
	}


	handle_year   	= (e)  => 	{this.setState( {year : e.target.value} )}
	render_years()
	{
		const years = []
		for( let year = 2544; year < 2593; year++ )
			years.push( year )

		return <select value={this.state.year} onChange={this.handle_year} >
			   {
			   		years.map( y => <option key={`year-${y}`} value={y}>{y} ({y - 543})</option>)
			   }
			   </select>
	}


	render()
	{
		const {history,match}	= this.props
		const {province} 		= this.props.match.params


		if (!this.context || !this.context.ctx.i18nstr)
			return <div />

		const i18n = this.context.ctx.i18nstr

		if (this.state.pdata)
			this.tdata_model = new TechDataModel( i18n, tech_schema, this.state.pdata, this.get_energy(), this.get_currency() )


		const tgt_year = this.context && this.context.year_refs ? `(${this.context.year_refs.target_year})` : ''

		return 	<MasterFrame history={history} match={match} province={province}>
					<div className='sankey-main'>
						<div className='sankey-menu'>
							{
								this.unit_selector()
							}
						<Help id='techparams'/>
						</div>
						<div className='sankey-container'>
							<div className={classnames("bottom")}>
								<div className='fill-with-title-spreadsheet'>

									<div className='helpblock'>
										<div className='title'>
											<span>{i18n("TECH:HEADLINE")}</span>
											&nbsp;
											<span>{tgt_year}</span>
										</div>
										<div className='text'>{i18n("TECH:HELPTEXT")}</div>
										<div className='text'>{i18n("TXT:INPUTDATA")}</div>
									</div>
									<div className='sheetblock'>
										{
											this.tdata_model &&
											<TreeEditor high_header={true}
														corner_header={`${i18n("TECH:HEADLINE_TEXT")} ${tgt_year}`}
														narrow_header={true}
														warnings_empty={false}
														definition={true}
														timestamp={new Date()}
														repaintSelector={`${this.state.energy} ${this.state.currency}`}
														view_model={this.tdata_model.view_model}
														commit={this.commit} />
										}
									</div>
								</div>
							</div>
						</div>
					</div>
				</MasterFrame>
	}


}

Object.assign(TechParams.prototype, Navigation)

export default TechParams