const size = 10
const s2 = size / 2
const sep = 0.5
const gap_sep = 1.8

// line intercept math by Paul Bourke http://paulbourke.net/geometry/pointlineplane/
// Determine the intersection point of two line segments
// Return FALSE if the lines don't intersect
function intersect(x1, y1, x2, y2, x3, y3, x4, y4) {

    // Check if none of the lines are of length 0
    if ((x1 === x2 && y1 === y2) || (x3 === x4 && y3 === y4)) {
        return false
    }

    denominator = ((y4 - y3) * (x2 - x1) - (x4 - x3) * (y2 - y1))

    // Lines are parallel
    if (denominator === 0) {
        return false
    }

    let ua = ((x4 - x3) * (y1 - y3) - (y4 - y3) * (x1 - x3)) / denominator
    let ub = ((x2 - x1) * (y1 - y3) - (y2 - y1) * (x1 - x3)) / denominator

    // is the intersection along the segments
    if (ua < 0 || ua > 1 || ub < 0 || ub > 1) {
        return false
    }

    // Return a object with the x and y coordinates of the intersection
    let x = x1 + ua * (x2 - x1)
    let y = y1 + ua * (y2 - y1)

    return { x, y }
}


function link_intersect(l1, l2) {
    return
}

export class SankeyLayout {

    constructor(node_list, links) {
        this.node_list = node_list
        this.links = links


        this.setupnodes(node_list)
        this.setuplinks(links)

        this.setuplayerlinks()

        this.loopnodes(n => this.setuppaths(n))


        this.partition_nodes()
        this.clusternodes()

        this.partitions.forEach((partition) => {
            partition.layers.forEach(l => this.resort_nodes(l))
        })

    }

    setupnodes(node_list) {
        this.node_list = node_list
        this.nodes = {}

        for (let n of node_list) {
            n.targetlist = []
            n.out = []
            n.outlinks = []
            n.in = []
            n.inlinks = []

            n.allinlinks = []

            n.clusterchecked = false
            n.incluster = false
            n.clustermaster = false
            n.clusterdeps = []
            n.dummy = false

            this.nodes[n.name] = n

            if (n.size < 2)
                n.size = 2
        }
    }



    setuplayers(nodelist) 
    {
        const layers = []
        nodelist.forEach(n => 
        {
            const l = n.l
            n.single_in_layer = false
            while (layers.length < l + 1)
                layers.push([])
            layers[l].push(n)
        })

        layers.forEach((l, idx) => 
        {
            if (l.length == 1)
                l[0].single_in_layer = true
            l.sort((a, b) => a.y - b.y)
            let yc = 0
            l.forEach(node => {
                node.y = yc
                node.x = idx * 50 + 20
                yc += node.size + sep
            })
        })

        return layers
    }


    setuplayerlinks() {
        this.layerlinks = {}
        for (let l of this.links) {
            const to = this.nodes[l.to]
            const fr = this.nodes[l.from]

            for (let i = fr.l; i < to.l; i++) {
                if (!this.layerlinks[i])
                    this.layerlinks[i] = []
                this.layerlinks[i].push(l)
            }
        }
    }



    downstream_bounds(node) {
        const dsb = (node, min, max) => {
            if (node.out.length == 0)
                return { min: node.y, max: node.y + node.size }

            let ymin = min
            let ymax = max

            for (let n of node.out) {
                const bounds_down = dsb(n, min, max)
                ymin = Math.min(ymin, bounds_down.min)
                ymax = Math.max(ymax, bounds_down.max)
            }

            return { min: ymin, max: ymax }
        }

        return dsb(node, node.y, node.y + node.size)
    }

    set_dsb(node) {
        node.ds_bounds = this.downstream_bounds(node)
    }


    swapnodes(node_list, a, b, reason) {

        //        console.log(a.name, b.name, reason)
        const ida = this.node_list.indexOf(a)
        const idb = this.node_list.indexOf(b)


        if (ida > -1 && idb > -1) {

            const yrng = b.y + b.size - a.y
            b.y = a.y
            a.y = b.y + yrng - a.size

            this.sort_by_bounds(node_list)
            this.set_dsb(a)
            this.set_dsb(b)
            return true
        }
        return false
    }


    sort_by_bounds(node_list) {
        if (node_list.length == 0)
            return
        for (let node of node_list) {
            if (node.out.length == 0)
                node.avg_out = node.y
            else {
                const bounds = this.y_bounds(node.out)
                node.avg_out = bounds.centery
            }

            if (node.in.length == 0)
                node.avg_in = node.y
            else {
                const bounds = this.y_bounds(node.in)
                node.avg_in = bounds.centery
            }
            node.avg = (node.avg_in + node.avg_out)
        }

        node_list.sort((a, b) => {
            if (b.y > a.y + a.bound_range)
                return -1
            if (a.y > b.y + b.bound_range)
                return 1

            return a.y - b.y
        })


        //        node_list.forEach( n => {
        //            if (n.name == 'B1' || n.name == 'B3' || n.name == "A1-E3-1")
        //                console.log( n.name, n.y, n.avg, n.avg_in, n.avg_out )
        //        })
        //        if (node_list[0].name.startsWith("B2") || node_list[0].name == "A1-E3-1")
        //            console.log( node_list.map( n => n.name ).join( " - "))
    }

    resort_nodes(nodes) 
    {
        if (nodes.length == 0)
            return
        let change = true

        for (let node of nodes)
            this.set_dsb(node)

        for (let ii = 0; change && ii < nodes.length / 2 + 1; ii++) {
            change = false
            let before = nodes.map(n => `${n.name} : ${n.y.toFixed(1)}`).join(" \t")
            this.sort_by_bounds(nodes)
            let after = nodes.map(n => `${n.name} : ${n.y.toFixed(1)}`).join(" \t")


            if (nodes && nodes.length > 1) {
                let i = 0
                let top = nodes[0]
                while (top && top.dummy && i < nodes.length) {
                    i++
                    if (i < nodes.length)
                        top = nodes[i]
                    else
                        top = null
                }

                if (!top)
                    break

                while (i < nodes.length) {
                    let bot = nodes[i]
                    i++
                    if (bot.dummy)
                        continue

                    const bol = bot.out.length
                    const bil = bot.in.length

                    const tol = top.out.length
                    const til = top.in.length

                    if (bot.y + bot.size / 2 < top.y + top.size / 2)
                        change ||= this.swapnodes(nodes, top, bot, "CENTER")

                    if (!bot.incluster && !bot.dummy) 
                    {
                        if (bot.ds_bounds.maxy <= top.ds_bounds.maxy)
                            change ||= this.swapnodes(nodes, top, bot, "DSB")

                        else if (bol > 0 && tol > 0 &&
                            top.out[0].y > bot.out[0].y)
                            change ||= this.swapnodes(nodes, top, bot, "BBB")


                        //                        else if (bil == 1 && top.in.length == 1 && top.in[0].out.length > 1 && top.out[0] == bot.out[0])
                        //                            change ||= this.swapnodes(nodes, top, bot, "EEE")

                        else if (tol == 1 && bol > 0 &&
                            top.out[0] != bot.out[0] &&
                            bot.out[0].y < top.out[0].y)
                            change ||= this.swapnodes(nodes, top, bot, `F`)


                        else if (bol == 1) {
                            const out1 = bot.out[0]
                            if (top.paths_out[out1.name]) {
                                if (top.paths_out[out1.name][0].length > bot.paths_out[out1.name][0].length)
                                    this.swapnodes(nodes, top, bot, "PathLen")
                            }
                        }

                        if (bil > 0 && top.in.length > 0 &&
                            top.in[0].y > bot.in[0].y)
                            change ||= this.swapnodes(nodes, top, bot, "BBB")

                        if (bil == 1 && top.in.length > 1 && top.in[0] == bot.in[0])
                            change ||= this.swapnodes(nodes, top, bot, "DDD")

                        if (top.in.length == 1 && bil > 0 &&
                            top.in[0] != bot.in[0] &&
                            bot.in[0].y < top.in[0].y)
                            change ||= this.swapnodes(nodes, top, bot, `EEE ${top.name} ${top.y.toFixed(0)} -> ${top.in[0].name} ${top.in[0].y.toFixed(0)} ::: ${bot.name}  ${bot.y.toFixed(0)} -> ${bot.out[0].name} ${bot.out[0].y.toFixed(0)}`)

                        top = bot

                    }
                }
            }
        }

        nodes.sort((a, b) => a.y - b.y)

        // nodes.sort((a, b) => a.y - b.y)
        nodes.forEach((n, i) => n.layer_index = i)

        //        if (nodes[0].name.startsWith("B2") || nodes[0].name == "A1-E3-1")
        //        {
        //            console.log("FINIT === ")
        //            console.log(nodes.map(n => n.name).join(" - "))
        //        }

        //    console.log( "--- OVER ", nodes.filter( n => !n.dummy).map( n=> `${n.name}:${n.y.toFixed(0)}`).join( " "))/

    }



    setcluster(n) {
        if (n.clusterchecked)
            return
        n.clusterchecked = true


        let allsingle = n.out.length > 0
        n.out.forEach(out => {
            if (out.in.length > 1 || out.out.length > 0)
                allsingle = false
        })

        if (allsingle && n.in.length > 0) {
            n.out.forEach(out => {

                n.clusterdeps.push(out)
                n.cluster_downstream = true

                out.clusterchecked = true
                out.incluster = true
                out.clustermaster_downstream = false
                out.clusterparent = n
            })
        }

        if (n.out.length == 1 && n.in.length == 0 && !n.out[0].incluster && n.out[0].in.length == 1) {
            const m = n.out[0]
            if (m.name != n.name) {
                m.clusterdeps.push(n)
                m.cluster_downstream = false
            }

            n.clustermaster_downstream = true
            n.incluster = true
            n.clusterparent = n.out[0]
        }

        if (!n.incluster && n.clusterdeps && n.clusterdeps.length > 0) {
            n.clustermaster = true
            if (n.clusterdeps.length == 1)
                n.clusterdeps[0].single_in_cluster = true

        }
    }
    
    mergecluster(root, n) {
        if (root != n && root.name != n.clusterparent.name) {
            n.clustermaster = false
            n.clusterparent = root
            root.clusterdeps.push(n)
        }

        for (let c of n.clusterdeps) {
            this.mergecluster(root, c)
        }
        if (root.name != n.name)
            n.clusterdeps = []
    }

    clusternodes() {
        this.loopnodes((n) => this.setcluster(n))

        this.loopnodes(n => {
            if (n.clustermaster && !n.incluster)
                this.mergecluster(n, n)
        })

        //        this.loopnodes(n => {
        //            if (n.clustermaster)
        //                console.log("MASTER ", n.name, ":", n.clusterdeps.map(m => `${m.name} `).join(" "))
        //        })

    }




    scan_partition_from(node, partition) 
    {
        if (node.partition_idx >= 0)
            return false
        node.partition_idx = partition
        if (!this.partitions[partition])
            this.partitions[partition] = { index: partition, maxy: 0, miny: 0, nodes: [] }
        this.partitions[partition].nodes.push(node)
        node.partition = this.partitions[partition]

        for (let lo of node.out)
            this.scan_partition_from(lo, partition)

        for (let li of node.in)
            this.scan_partition_from(li, partition)

        if (node.dummy_linked) {
            for (let li of node.dummy_linked)
                this.scan_partition_from(li, partition)

        }

        return true
    }


    partition_nodes() {
        this.partitions = []
        let found = false

        this.loopnodes(node => this.scan_partition_from(node, this.partitions.length))

        for (let p of this.partitions)
            p.layers = this.setuplayers(p.nodes)
    }


    get_partition_bounds() {
        for (let partition of this.partitions) {
            const { miny, maxy } = this.y_bounds(partition.nodes)
            partition.miny = miny
            partition.maxy = maxy
        }
    }




    create_dummynodes(link) {
        return
        const nf = this.nodes[link.from]
        const nt = this.nodes[link.to]

        for (let i = nf.l + 1; i < nt.l; i++) {
            const newnode = {}
            newnode.name = `${nf.name}-${nt.name}-${i}`

            newnode.targetlist = []
            newnode.out = []
            newnode.outlinks = []

            newnode.in = []
            newnode.inlinks = []

            newnode.x = i
            newnode.y = nf.y
            newnode.l = i
            newnode.size = Math.max(nf.size, nt.size) * 1.2

            newnode.path_start = nf
            newnode.path_end = nt
            newnode.path_index = i
            newnode.fraction = (i - nf.l) / (nt.l - nf.l)

            newnode.clusterchecked = false
            newnode.incluster = false
            newnode.clustermaster = false
            newnode.clusterdeps = null
            newnode.dummy = true

            this.node_list.push(newnode)
            this.nodes[newnode.name] = newnode

            if (!nf.dummy_linked)
                nf.dummy_linked = []
            nf.dummy_linked.push(newnode)
        }

    }

    setuplinks(links) {
        this.links = links
        for (let l of links) {
            l.outnode = this.nodes[l.from]
            l.innode = this.nodes[l.to]

            l.realto = l.innode

            l.outnode.outlinks.push(l)
            l.outnode.out.push(l.innode)

            l.innode.inlinks.push(l)
            l.innode.in.push(l.outnode)

            if (l.innode.l - l.outnode.l > 1)
                this.create_dummynodes(l)
        }
    }



    setuppaths(node) {

        const dfs = (nodepath, linkpath, link, path_hash, link_hash) => {
            linkpath.push(link)
            const out = link.innode
            nodepath.push(out)

            if (!path_hash[out.name])
                path_hash[out.name] = []
            path_hash[out.name].push([...nodepath])

            if (!link_hash[out.name])
                link_hash[out.name] = []
            link_hash[out.name].push([...linkpath])

            for (let l of out.outlinks)
                dfs(nodepath, linkpath, l, path_hash, link_hash)

            nodepath.pop()
            linkpath.pop()
        }

        const path_hash = {}
        const link_hash = {}


        node.outlinks.forEach(l => dfs([], [], l, path_hash, link_hash))



        node.paths_out = path_hash
        node.links_out = link_hash
    }


    loopnodes(fn) {
        this.node_list.forEach((n, k) => fn(n, k))
    }

    y_bounds(...nodelists) {
        let miny = 10000000
        let maxy = -10000000

        nodelists.forEach(list => {
            list.forEach(n => {
                miny = Math.min(n.y, miny)
                maxy = Math.max(n.y + n.size, maxy)
            })
        })
        return { miny, maxy, centery: (maxy + miny) / 2, range: maxy - miny }

    }

    get_node_bounds() {
        this.loopnodes(n => {
            if (n.clustermaster) {
                const { miny, maxy } = this.y_bounds(n.clusterdeps, [n])
                n.bound_min = miny
                n.bound_max = maxy
                n.bound_range = maxy - miny
            }
            else {
                n.bound_min = n.y
                n.bound_max = n.y + n.size
                n.bound_range = n.size
            }
        })
    }

    normalize() {
        let upper_maxy = 0
        this.partitions.forEach((partition, pidx) => {
            this.get_partition_bounds()
            partition.nodes.forEach(n => {
                n.y = n.y - partition.miny
                n.size2 = n.size / 2
                n.yc = n.y + n.size2
                n.xc = n.x + size / 2

                n.sx = n.x * 2
                n.sy = (n.y + upper_maxy + sep * pidx) * 10

                n.bsminy = n.bound_min * 2
                n.bsmaxy = n.bound_max * 2

            })
            this.get_partition_bounds()
            upper_maxy += (partition.maxy - partition.miny)
        })

        this.get_node_bounds()
    }


    spread(src, tlist, out) {
        if (src.incluster || tlist.length == 0)
            return

            // if (src.out.length == 1 && src.in.length == 0)
//            return

        const nodeidx = out ? 'outnode' : 'innode'
        tlist.sort((a, b) => (a[nodeidx].layer_index - b[nodeidx].layer_index))

        const ss2 = src.size / 2

        let yc = []
        let y = 0
        let n = 0
        for (; n < tlist.length; n++) {
            const t = tlist[n][nodeidx]
            t.cluster_spacer = t == 0
            yc.push(y)
            y += t.bound_max - t.bound_min + sep
        }
        y -= sep
        const tlist_c = y / 2

        if (src.name == 'G1')
        {
            console.log( out, tlist )
        }


        yc.forEach((yy, idx) => 
        {
            const n = tlist[idx][nodeidx]

            const nyc = src.y + ss2 + (yy - tlist_c)

            if (src.clustermaster && n.incluster) {
                n.fixed_target = src
                n.target_offset = nyc - src.y
            }
            else {
                const weight = tlist.length * 300
                if (src.name == 'G1')
                    console.log( "TARGET ", n.name, nyc )
                n.targetlist.push({ y: nyc, w: weight, reason: `Spread ${out ? 'Out' : 'In'} : ${src.name} -> ${n.name}` })
            }
        })
    }


    consolidate_space_targets(node) {
        const tgt = node.targetlist.filter(t => t.spacing)
        if (tgt.length < 2)
            return
        let min = node.y
        let sum = 0
        tgt.forEach(t => { min = Math.max(min, t.y); sum += t.w })
        node.targetlist = node.targetlist.filter(t => !t.spacing) // 
        node.targetlist.push({ y: min, w: sum / tgt.length, reason: "Reduced space" })
    }


    forcespacing() {

        const connected_minmax = (n, list) => {
            let min = n.bound_min
            let max = n.bound_max
            list.forEach(nn => {
                min = Math.min(min, nn.bound_min)
                max = Math.min(max, nn.bound_max)
            })

            return { min, max }
        }

        this.partitions.forEach((partition, pidx) => {
            this.get_partition_bounds()
            partition.layers.forEach((layer, lidx) => {
                let lastnode = null

                const sorted = layer.sort((a, b) => {
                    let inbound_a = connected_minmax(a, a.in).min
                    let inbound_b = connected_minmax(b, b.in).min

                    let outbound_a = connected_minmax(a, a.out).min
                    let outbound_b = connected_minmax(b, b.out).min


                    if (a.out.length == 1 && b.out.length == 1)
                        return a.out[0].bound_min - b.out[0].bound_min

                    if (a.in.length == 1 && b.in.length == 1)
                        return a.in[0].bound_min - b.in[0].bound_min

                    return inbound_a - inbound_b

                    return a.bound_min - b.bound_min
                })

                sorted.forEach((node, idx) => {
                    if (node.incluster && !node.cluster_spacer) {
                        //                        console.log( "IN cluster ", node.name )
                        //                       console.log( node )
                        return
                    }

                    if (lastnode) {
                        const min = (lastnode.incluster ? lastnode.clusterparent.bound_max : lastnode.bound_max) + sep

                        const tgt = node.incluster ? node.clusterparent : node


                        if (node.mark)
                            console.log(node.name, ":", node.incluster, tgt.name, tgt.bound_min.toFixed(2), "->", lastnode.name, min.toFixed(2))


                        let weight = Math.abs(min - tgt.bound_min)

                        node.collision = tgt.bound_min - min < 0 && !node.dummy && !tgt.dummy

                        if (node.collision) {
                            node.collision = true
                            node.min_y = min

                            if (node.mark)
                                console.log("COLLIDE", tgt.name, "(", tgt.bound_min.toFixed(0), ") < ", lastnode.name, "(", min.toFixed(0), ")")

                            weight = weight * 1000
                        }

                        const offset = node.y - node.bound_min

                        if (node.incluster && node.cluster_spacer || !node.incluster) {
                            tgt.targetlist.push({ y: min + offset, w: weight, reason: `Space ${weight.toFixed(0)}`, spacing: true })
                            //                            if (node != tgt)
                            //                                node.targetlist.push({ y: min, w: weight, reason: `Space ${weight.toFixed(0)}`, spacing: true })
                        }
                        lastnode = tgt
                    }
                    else
                        lastnode = node
                })
            })
        })

        this.loopnodes(n => this.consolidate_space_targets(n))
    }






    gettargetoffset(node) {
        let sum = 0
        let n = 0
        for (let e of node.targetlist) {
            sum += (node.y - e.y) * e.w
            n += e.w
        }

        const ny = (n > 0) ? sum / n : undefined

        if (node.name == 'E1' || node.name == 'H1' || node.name == 'J2')
        {
            node.targetlist.forEach((t, ii) => 
            {
                if (ii == 0)
                    console.log("T: ", node.name.padEnd(5, " "), `${t.y.toFixed(1)} : ${t.w.toFixed(0)} ${t.reason}`)
                else
                    console.log("T: ", " ".padEnd(5, " "), `${t.y.toFixed(1)} : ${t.w.toFixed(0)} ${t.reason}`)
            })
        }
        return { sum, n, ny }
    }



    approachtarget() {
        this.loopnodes(node => 
        {
            if (node.fixed_target)
                return
            const { ny } = this.gettargetoffset(node)

            if (node.name == 'E1' || node.name == 'H1' || node.name == 'J2')
                console.log(node.name.padEnd(5, " "), ": ", ny)

            node.tgty = node.y - ny
            node.y -= ny / 10

            if (node.collision && !node.dummy && node.min_y !== undefined) {
                node.y = node.min_y + sep * 2
            }

        })

        this.loopnodes(node => {
            if (node.fixed_target) {
                node.y = node.fixed_target.y + node.target_offset
            }
        })
    }

    position_dummynodes() {
        const p3 = (x) => (x * x * x)
        const p2 = (x) => (x * x)
        const p1 = (x) => (x)

        const bezier_point = (s, ys, ye) => 1 * ys * p3(1 - s) +
            3 * ys * p2(1 - s) * p1(s) +
            3 * ye * p1(1 - s) * p2(s) +
            1 * ye * p3(s)


        this.loopnodes(n => {
            if (n.dummy) {
                const s = n.fraction;
                const ys = n.path_start.y;
                const ye = n.path_end.y;
                n.y = bezier_point(s, ys, ye) - 0.2
            }
        })
    }

    resetstep() {
        this.loopnodes(n => {
            n.targetlist = []
            n.fixed_target = false
            n.min_y = undefined
            n.problem = false
        })
    }


    clear_gaps() {
        this.partitions.forEach((p, nn) => {
            const clusterlist = p.nodes.filter(n => !(n.dummy))
            if (clusterlist.length < 2)
                return

            const sorted = [...clusterlist].sort((a, b) => a.y - b.y)

            let skip_offset = 0
            for (let i = 1; i < sorted.length; i++) 
            {
                let gap = sorted[i].bound_min - (sorted[i - 1].bound_max)
                if (gap < 0)
                    gap = 0
                let leave_gap = false
                if (sorted[i].single_in_layer)
                    leave_gap = true
                if (sorted[i].bound_min - sorted[i - 1].bound_max < 0)
                    leave_gap = true

                if (gap > gap_sep && !leave_gap)
                    skip_offset += (gap)
                sorted[i].skip_offset = skip_offset
                if (false && (sorted[i].name == "B1" || sorted[i-1].name == "B1"))
                    console.log("GAP",  sorted[i - 1].name.padEnd(2, " "), "->", sorted[i].name.padEnd(5, " "), 
                                        sorted[i].y.toFixed(0), " : ", 
                                        sorted[i  ].bound_min.toFixed(0), " ",
                                        sorted[i-1].bound_max.toFixed(0),
                                        "  G", gap.toFixed(0) )
            }


            //            console.log( sorted.map(n => `${n.name} ${n.y.toFixed(0)}-${n.skip_offset}`).join(", ") )

            for (let i = 1; i < sorted.length; i++)
                sorted[i].y -= sorted[i].skip_offset
        })
    }



    step() {
        this.resetstep()

        this.loopnodes(n => {
            // This is weird
            n.targetlist.push({ y: 1, w: 10, reason: "Up" })
            this.spread(n, n.outlinks, false)
            this.spread(n, n.inlinks, true)
        })
    }

    // TODO FOR NOW: forcespacing does not affect the target list!



    layout_step() {
        this.partitions.forEach((partition) => {
            partition.layers.forEach(l => this.resort_nodes(l))
        })

        this.normalize()


        this.step()
        this.forcespacing()

        this.approachtarget()

        this.clear_gaps()
        this.position_dummynodes()
        this.normalize()



    }
}