import React, { Component, useContext } from "react";
import classnames       	from 'classnames';
import MasterFrame			from './app/MasterFrame'
import { AppController } from './app/AppController'

import {Help}				from './help/Help'

const OverviewImage = (props) =>
{
	const context = useContext( AppController)
	if (!context || !context.ctx || !context.ctx.i18nstrp)
		return <div/>

	const {lang} 	= context
	const i18 = context.ctx.i18nstrp("PES")

	const {history,match}		= props
	const province  			= match.params.province
	const n 					= props.match.params.number
	const src = `/overview/Overview-${n}-${lang}.jpg`

	return <MasterFrame history={history} match={match} province={province}>
			<div className={classnames("bottom", "grid-on", "sidebar-off", "chart-on")}>
				<div className='sankey-main'>
					<div className='sankey-menu'>
						&nbsp;
						<br/>
						&nbsp;
						<Help id={`overview-${n}`} />
					</div>
					<div className='sankey-container'>
							<div className='fill-with-title'>
									<div className='helpblock'>
										<div className='title'>
											<span>{i18("HEADLINE")}</span>
										</div>
										<div className='text'>{i18("LINE1")}</div>
										<div className='text'>{i18("LINE2")}</div>
										<div className='text'>{i18("LINE3")}</div>
										<div className='text'>{i18("LINE4")}</div>
										<div className='text'>{i18("LINE5")}</div>
										<div className='text'>{i18("LINE6")}</div>
									</div>
								<div className='chartblock'>
								{
									props.match.params.number &&
										<img style={{width:'100%'}} className='overview' src={src}/>
								}
								</div>
							</div>
					</div>
				</div>
			</div>
		</MasterFrame>
}

export default OverviewImage