import React, { Component,Fragment }
							from "react";
import classnames       	from 'classnames';
import axios 				from 'axios';

import {PEMTDataModel}   	from "../datamodel/pemt_data_model.js"
import {TreeEditor}			from "../TreeEditor.js"

import backend_config		from "../config/runconfig.js"
import {AppController} 		from '../app/AppController'


const Province = ({province, onClick, selected, i18n}) =>
{
			return (<div key	   ={province.key}
						 className ={classnames('province', selected === province.key ? 'selected' : null)}>
						<div 	className='head'
								onClick={(event) => {onClick( event, province)}}>
							<div className='name'>
								{i18n('PROVINCE', province.key.replaceAll( "-", "" ))}
							</div>
						</div>
					</div>)
}



export class ProvinceTreeViewer extends Component
{
	static contextType = AppController;
	constructor( props )
	{
		super( props )
		this.state 	= {}
	}


	get_selected()
	{
		return `${this.props.selected}`.trim().toUpperCase()
	}


	setSelected 	= (event, province)  => this.props.parent.setSelectedProvince( province )

	renderProvinces()
	{
		const children 			= []
		const {provinces}		= this.context
		const selected 			= this

		const ctx = this.context.ctx
		if (!ctx || !ctx.i18nstr)
			return <div />
		const { i18nstr } = ctx
		

		if (provinces)
		{
			const names = Object.keys( provinces ).sort()
			for( let pi of names )
			{
				const province 	= provinces[pi]
				children.push( <Province 	key={pi}
											i18n={i18nstr}
											province={province}
											selected={this.get_selected()}
											onClick={this.setSelected}/> )
			}
		}
		return children
	}

	render()
	{
		return <div className='province-tree'>
					{	this.renderProvinces() }
				</div>
	}
}



export default ProvinceTreeViewer


