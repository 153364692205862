import React, { Component } from "react";
import classnames       from 'classnames';
import SetupFrame from "../setup/SetupFrame";

import { AppController } from "../app/AppController";

import {Help} 			 from "../help/Help"
class TaskViewerFrame extends Component {
	static contextType = AppController

	constructor(props) {
		super(props)
	}

	render_rows( pdata_schema, table, is_error )
	{
		const now = Date.now()

		return table.map((task, idx) => 
		{
			const taskdate 			= new Date(parseInt(task.serial, 10))
			if (is_error && now - taskdate > (60 * 60 * 24 * 1000 * 7))
				return null


			const scenario_meta 	= pdata_schema.scenarios[task.id]
			const name 				= scenario_meta ? scenario_meta.name.replace( 'EE', '').trim() : 'Custom'

			const province = this.context.provinces[task.province.toUpperCase()]
			if (!province)
			{
				console.log( "PROVINCE UNKNOWN " + task.province)
				console.log( task )
			}
	
			return (
				<div className={`task status-${task.status}`} key={`task-${task.file_id}-${idx}-${task.province.toUpperCase()}`}>
					<div className="task-field province">{province ? province.name : `-- ??? --`}</div>
					<div className="task-field scenario">{name}</div>
					<div className="task-field short ee">{task.ee_set_id}</div>
					<div className="task-field short CHP">{task.chp ? "ON" : "OFF"}</div>
					<div className="task-field status">{task.status == 'queued' ? 'calculating' : task.status}</div>
					<div className="task-field date">
					{taskdate.toDateString()}
					&nbsp;
					{taskdate.toLocaleTimeString()}
					{task.message && task.message.length > 0 && <div classname="task-field message">{task.message}</div>}
					</div>
				</div>
			)
		})
	}



	render_tables(i18,tasks)
	{
		console.log( tasks )
		if (!tasks || !tasks.tasks || (tasks.tasks.length == 0)) 
			return <div>No Calculations Scheduled</div>
		const {pdata_schema} = this.context

		return	<div>
			<div className="task-list">
				<div className='task'>
					<div className="task-head">{i18("PROVINCE")}</div>
					<div className="task-head">{i18("SCN")}</div>
					<div className="task-head">{i18("ELEXCH")}</div>
					<div className="task-head">{i18("CHP")}</div>
					<div className="task-head">{i18("STATUS")}</div>
					<div className="task-head">{i18("CREATED")}</div>
				</div>
				{this.render_rows( pdata_schema, tasks.tasks, true )}
			</div>

			<div className="task-list">
				{this.render_rows( pdata_schema, tasks.error, false )}
			</div>
		</div>
	}


//	SETUP: KOMMOD: HEADER: PROVINCE
//	SETUP: KOMMOD: HEADER: SCN
//	SETUP: KOMMOD: HEADER: ELEXCH
//	SETUP: KOMMOD: HEADER: CHP
//	SETUP: KOMMOD: HEADER: STATUS
//	SETUP: KOMMOD: HEADER: CREATED
//	SETUP: KOMMOD: CHP: ON
//	SETUP: KOMMOD: CHP: OFF


	render() {
		const { history, match } = this.props

		if (!this.context || !this.context.tasks)
			return <div/>

		if (!this.context || !this.context.ctx.i18nstr)
			return <div />

		const i18 = this.context.ctx.i18nstrp("SETUP:KOMMOD:HEADER" )
		const tasks = this.context.tasks

		return (
			<SetupFrame history={history} match={match} lang={this.context.lang}>
				<div className="sankey-main">
					<div className="sankey-menu">
						<div className='section'>&nbsp;</div>
						<Help id='taskviewer'/>
					</div>
					<div className="sankey-container">
						<div className={classnames("bottom")} style={{ backgroundColor: "white" }}>
							{this.render_tables(i18, tasks)}
						</div>
					</div>
				</div>
			</SetupFrame>
		)
	}
}

export default TaskViewerFrame
