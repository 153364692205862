import React, { Component, Fragment } from "react";



export function get_origin(node)
{
	const selected =
		node.value && node.value.selected_year
			? `From ${node.value.selected_year}`
			: '';

	const line = node.value && node.value.line 	? `[${node.value.line}]` 	: node.line ? `[${node.line}]` : ''
	const col  = node.value && node.value.column ? `[${node.value.column}]` : node.column ? `[${node.column}]` : ''

	if (node.value &&  node.value.origin)
		return `${node.value.origin}  ${line}${col} ${selected}`.trim()
	else if (node.origin)
		return `${node.origin}  ${line}${col} ${selected}`.trim()
	else
		return `${line}${col} ${selected}`.trim()
}


export function node_color(id, node, value, def) 
{
	if (value == '--' || value == null) {
		console.log( "Error node")
		console.log( node)
		console.log( value )
		return 'red'
	}
	else if (id.startsWith("QD_")) {
		if (node && node.depth === 0)
			return '#3ac'
		else
			return '#2d4c5c'
	}
	else if (id.startsWith("SD_")) {
		if (node && node.depth === 0)
			return '#4a8'
		else
			return '#6d2c6c'
	}
	else if (id.startsWith("RDS_"))
		return '#48a'

	return def
}



export class Expression extends Component
{
	get_value(node) {
		if (!node)
			return null
		const node_value = node.value
		if (!node_value)
			return null
		else if (node_value.kind == 'scalar')
			return node_value.value
		else if (node_value.kind == 'string')
			return `'${node_value.value}'`
		else if (node_value.kind == 'yearly')
			return node_value.data[this.props.year]
		else
			return null
	}

	clickTerm = (id) => {
		return (e) => {
			this.setState({ [id]: !this.state[id] })
		}
	}
	operator(op, left, right) {
		return <Fragment>
			<div className='sub-op'>{this.expression(left)}</div>
			<div className='op'>{op}</div>
			<div className='sub-op'>{this.expression(right)}</div>
		</Fragment>
	}

	conditional(predicate, left, right) {
		return <Fragment>
			<div className='term'>
				<div className='bracket'>IF (</div>
				{this.expression(predicate)}
				<div className='op'> ? </div>
				{this.expression(left)}
				<div className='op'> : </div>
				{this.expression(right)}
				<div className='bracket'>)</div>
			</div>
		</Fragment>
	}

	minmax(op, left, right) {
		return <Fragment>
			<div className='bracket'>{op}(</div>
			<div className='term'>{this.expression(left)}</div>
			<div className='bracket'>,</div>
			<div className='term'>{this.expression(right)}</div>
			<div className='bracket'>)</div>
		</Fragment>
	}

	identifier(id) {
		const e = this.state;
		const expanded = this.props.expressions[id]

		const selected =
			expanded.value && expanded.value.selected_year
				? `From ${expanded.value.selected_year}`
				: null;

		const has_sub_source = expanded && expanded.source
		const value = this.get_value(expanded)

		const color = node_color(id, expanded, value, null)
		const style = color ? { background: color } : {}
		if (selected)
			style.opacity = 0.8
		if (e[id] && has_sub_source) {
			return <div className='expanded term'>
				<div className='header' onClick={this.clickTerm(id)}>{id}
					<i className='fa fa-caret-left expand' />
				</div>
				<div className='expansion'>
					{this.expression(expanded.source)}
					<div className='value'>{value}</div>
				</div>
			</div>
		}
		else
			return <div style={style} className='term identifier' onClick={this.clickTerm(id)}>
				{id}
				{has_sub_source && <i className='fa fa-caret-right expand' />}
				<div className='value'>{value}</div>
				{selected && <div className='yearshift'>({selected})</div>}
			</div>
	}


	constant( tree )
	{
		const {kind, value} = tree
		if (kind === 'scalar') 		return <div className='term literal constant'>{value}</div>
		if (kind === 'string') 		return <div className='term literal constant'>'{value}'</div>
		return <div className='term literal error'>ERR {kind}:{value}</div>

	}

	expression(tree) {
		const { sort, subnodes, value, kind } = tree
		if (sort === 'max') return this.minmax("Max", subnodes[0], subnodes[1])
		if (sort === 'min') return this.minmax("Min", subnodes[0], subnodes[1])

		if (sort === 'eq') return this.operator("＝", subnodes[0], subnodes[1])
		if (sort === 'gt') return this.operator(">", subnodes[0], subnodes[1])
		if (sort === 'gte') return this.operator("≧", subnodes[0], subnodes[1])
		if (sort === 'lt') return this.operator("<", subnodes[0], subnodes[1])
		if (sort === 'lte') return this.operator("≦", subnodes[0], subnodes[1])

		if (sort === 'plus') return this.operator("+", subnodes[0], subnodes[1])
		if (sort === 'minus') return this.operator("-", subnodes[0], subnodes[1])
		if (sort === 'mult') return this.operator("*", subnodes[0], subnodes[1])
		if (sort === 'div') return this.operator("/", subnodes[0], subnodes[1])
		if (sort === 'pow') return this.operator("^", subnodes[0], subnodes[1])
		if (sort === 'constant') 	{
			return this.constant( value )
		}

		if (sort === 'if') return this.conditional(subnodes[0], subnodes[1], subnodes[2])

		if (sort === 'identifier') return this.identifier(value)
		if (sort === '()') return <Fragment>
			<div className='bracket'>(</div>
			{this.expression(subnodes[0])}
			<div className='bracket'>)</div>
		</Fragment>

		if (sort === '{}') return <Fragment>
			<div className='bracket'>{'{'}</div>
			{this.expression(subnodes[0])}
			<div className='bracket'>{'}'}</div>
		</Fragment>

		if (sort === 'tgt_year') 		return <div className='term literal special'>TargetYear</div>
		if (sort === 'ref_year') 		return <div className='term literal special'>RefYear</div>
		if (sort === 'track_year') 			return <div className='term literal special'>ArrIndexYear</div>
		if (sort === 'suspension')			return <div className='term literal special'>Suspension</div>

		if (sort === '[]') return <Fragment>
			<div className='bracket'>{subnodes[0]}[</div>
			{this.expression(subnodes[1])}
			<div className='bracket'>]</div>
		</Fragment>

		return <div className='error'>{`${sort}  ${subnodes}`}</div>
	}


	constructor(props)
	{
		super(props)

		this.state = {}
	}

	render() {
		const { node } = this.props
		const tree = node.source
		const value = this.get_value(node)

		return <Fragment>
			{this.expression(tree)}
			<div className='equals'>=</div>
			<div className='finalresult'>{value}</div>
		</Fragment>
	}
}

