
import React, { Component,Fragment }				from 'react'
import {Form, TextBox, CheckBox, SubmitButton,
		HideShow, Select, Error}					from './form'

class FormTest  extends Component
{
	constructor(props)
	{
		super( props )
		this.sub_form_data  = {
			value: 		"Hello Sub",
		}


		this.form_data 		= {
			box1: 		100,
			box8: 		"Box2",
			sel1: 		"Rayong",
			sub1: 		this.sub_form_data,
			checked: 	false,
			hidden: 	false
		}
		this.form_errors	= {} // {download: 	["Error in DownUpLoad"]}
	}

	uc = (x) => `${x}`.toUpperCase()

	handleSubmit = (event, data) =>
	{
		console.log( "Submitting data: ")
		console.log( data )
	}


	formErrors( form, data, raw_data, errors )
	{
		if (data.box1 == 100) // && data.box2 == 'BOX2' && data.checked)
		{
			form.addError( errors, "form", "Form Error")
		}
	}

	render()
	{
		return (
		<div>
			<Form 	data={this.form_data}
					errors={this.form_errors}
					verify_form={this.formErrors}
					onSubmit={this.handleSubmit}>
				<Error 		names='download, form'/>


				<TextBox 	name		= 'box1'
							className	= 'class1'
							max_value 	= {5}
							min_value 	= {10}
							type 		= 'numeric'
							required	= {true}/>
				<Error 		names		= 'box1'/>
				<br/>

				<TextBox 	name		= 'sub1.value'
							className	= 'class2'
							required	= {true} />
				<Error 		names		='sub1.value'/>
				<br/>

			</Form>
		</div>)
	}

 	render2()
	{
		return (
		<div>
			<Form 	data={this.form_data}
					errors={this.form_errors}
					verify_form={this.formErrors}
					onSubmit={this.handleSubmit}>
				<Error 		names='download, form'/>


				<TextBox 	name		= 'box1'
							className	= 'class1'
							max_value 	= {5}
							min_value 	= {10}
							type 		= 'numeric'
							required	= {true}/>
				<Error 		names		= 'box1'/>
				<br/>

				<TextBox 	name		= 'box2'
							className	= 'class2'
							transform	= {this.uc}
							required	= {true} />
				<Error 		names		='box2'/>
				<br/>


				<TextBox 	name			= 'box3'
							className		= 'class3'
							placeholder 	= 'Data'
							default_value	= 'Enabled if Checked'
							enabled 		= 'checked'
							required		= {false} />
				<Error 		names			='box3'/>
				<br/>

				<TextBox 	name			= 'box4'
							className		= 'class3'
							placeholder 	= 'Data'
							default_value	= 'Disabled if Checked'
							disabled 		= 'checked'
							required		= {false} />
				<Error 		names			='box3'/>
				<br/>

				<TextBox 	name			= 'box4'
							className		= 'class3'
							placeholder 	= 'Data'
							default_value	= 'Disabled if Hafnium or Checked'
							disabled 		= {({checked,element}) => element == 'Hafnium' || checked}
							required		= {false} />
				<Error 		names			='box3'/>
				<br/>

				<Select 	name			='element'
							disabled 		= 'checked'
							options={["Uranium", "Plutonium", "Hafnium", "Unobtainium"]} />
				<br/>

				<Select 	name			='sel1'
							options={["Rayong", "Phuket", "Chiang-Mai"]} />
				<br/>

				<CheckBox 	name			='checked' /> Checkbox
				<br/>

				<HideShow show="checked">
					Show if Checked
					<br/>
				</HideShow>

				<HideShow hide="checked">
					Show if not Checked
					<br/>
				</HideShow>


				<HideShow show="sel1=Rayong">
					Show if Rayong
					<br/>
				</HideShow>

				<HideShow hide="sel1=Rayong">
					Show if not Rayong
					<br/>
				</HideShow>



				<br/>
				<SubmitButton/>
			</Form>
		</div>)
	}
}

export default FormTest

