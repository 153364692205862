
import React, { Component,Fragment }	from "react";
import {Link} 							from 'react-router-dom'
import ReactTooltip 					from 'react-tooltip'
import backend_config					from "../config/runconfig.js"
import {AppController} 					from '../app/AppController'
import {Navigation} 					from '../navigation/navigation'
import axios 							from 'axios'

class PLink extends Component
{
	constructor( props )
	{
		super(props )
	}

	render()
	{
		const { path, province, to, children } = this.props

		let pathStart = `${path}/`.split("/")[1]
		let toStart 	= `${to  }/`.split("/")[1]

		if (pathStart == 'overviewimage')
		{
			pathStart = path
			toStart   = to
		}

		const selectedClass =  (toStart && pathStart && toStart.startsWith( pathStart )) ? 'selected-entry' : null

		if (!to)
			return <div>{children}</div>
		else if (province && province != 'undefined')
		{
			const target = this.link_target( to )
			return <Link className={selectedClass} to={target}>{children}</Link>
		}
		else
			return <div className='disabled'>{children}</div>
	}
}
Object.assign(PLink.prototype, Navigation)




const HLink = ({path, province, to, children}) =>
{
	return <Link to={to}>{children}</Link>
}



const name_province = (p) =>
{
	if (!p)
		return "Please select a province"
	else
	{
		const spaced 	= p.replace(/-/g, " " )
		const words     = spaced.split( " ")
		const capwords  = words.map( (x) => {
			if (x.length == 0)
				return ''
			else if (x.length == 1)
				return x.toUpperCase()
			else
			{
				const firstcap = x.substring(0,1).toUpperCase()
				return `${firstcap}${x.substring(1)}`
			}
		})
		return capwords.join(" ")
	}
}




class MasterFrame  extends Component
{
	static contextType = AppController;

	constructor( props )
	{
		super( props )
		this.state 	= {
			displayed_province	: null,
			count 				: 0,
			downloading 		: false,
			download_id 		: null
		}
	}


	componentDidUpdate()
	{
		if (this.props.province !== this.state.displayed_province)
			this.setState( {displayed_province: this.props.province} )
	}

	handle_language = (e) => {
		if (this.context && this.context.i18n_controller)
			this.context.i18n_controller.set_lang(e.target.value)
	}


	render_language_select() {
		const lang = this.context?.lang || 'en'
		return <select onChange={this.handle_language} value={lang}>
			<option value='en'>English</option>
			<option value='th'>ภาษาไทย</option>
		</select>
	}

	handle_province = (e) => {
		const currentpath = this.props.history.location.pathname
		const newprovince = e.target.value.toLowerCase()
		const currentprovince = `${this.props.province}`.toLowerCase()
		const newpath = currentpath.toLowerCase().replace(currentprovince, newprovince)

		this.props.history.push(newpath)
	}



	render_province_select()
	{
		// Not loaded yet
		if (!this.context.provinces)
			return null
		const {provinces} = this.context

		const keys = Object.keys(provinces).sort()
		if (!keys || keys.length == 0)
			return null

		const current_province 	= this.props.province ?  `${this.props.province}`.toUpperCase() : ''
		if (current_province === '' || !provinces[current_province])
			return null

//		console.log( "THE PROVINCES ARE")
//		console.log( provinces )

		return <div className='province-selector'>
			<i className='province-caret fa fa-caret-down'></i>
			&nbsp;
			&nbsp;
			<select onChange={this.handle_province} value={current_province}>
				{
					keys.map(key =>
					{
						const province = provinces[key]
//						console.log( "HUH ", key)
//						console.log( province )
						return <option 	key={`province-select-${key}`}
							value={key}>{this.context.ctx.i18nstr('PROVINCE', province.key.replaceAll("-", ""))}</option>
					})
				}
			</select>
		</div>
	}


	render()
	{
		const {match, children, history, province} = this.props

		let province_data = null

		if (!this.context || !this.context.ctx.i18nstr)
			return <div>PEMT Server is down</div>

		if (this.context.provinces && province)
			province_data = this.context.provinces[province.toUpperCase()]

		const itop = this.context.ctx.i18nstrp("TOPMENU")
		const iside = this.context.ctx.i18nstrp("SIDEMENU")

		let dateString = null
		if (province_data && province_data.uploads && province_data.uploads.length > 0)
			dateString = new Date(province_data.uploads[0].serial).toDateString()

		const lang = this.context?.lang || 'en'


		let has_scenarios = false
		if (province_data && province_data.all_scenarios)
		{
			for( let k of Object.keys( province_data.all_scenarios ))
			{
				if (province_data.all_scenarios[k] && province_data.all_scenarios[k].length > 0)
				{
					has_scenarios = true
					break;
				}
			}
		}
		const path 		= history.location.pathname

		const user 		= this.context.ctx.user
		const logout	= this.context.user_controller ? this.context.user_controller.logout_user : null

		const role 		= user && user.role && user.role.kind
		const is_admin	= role == 'admin'
		const is_nat	= role == 'national'
		const is_po		= role == 'provincial'


		const hideMenu  	= this.props.hideMenu || !province_data

		const find_scenario = () => 
		{
			console.log( this.props.parent )
			if (!this.props.parent)
				return {timestamp : Date.now()}
			if (!province_data)
				return null
			const scenario_id = this.props.parent.get_scenario()
			if (!scenario_id)
				return null
			for( let s of province_data.scenarios )
			{
				if (s.id === scenario_id)
					return s
			}
			return null
		}

		const scen_data 			= find_scenario()
		const scen_timestamp 		= scen_data ? scen_data.timestamp : 0
		const province_timestamp	= province_data ? province_data.timestamp : 0

		console.log( province_data )
		console.log( scen_data )
		console.log(province_timestamp, scen_timestamp, province_timestamp - scen_timestamp, scen_timestamp < province_timestamp )
		
		
		const latest_error = scen_timestamp < province_timestamp ? itop( "TOPMENU:NOTUPTODATE") : ""
		console.log( latest_error )

		const ref_year 	= this.context && this.context.year_refs ? <div className='infoblock'><span className='infotitle'>{itop('REFYEAR')}</span><span>{this.context.year_refs.reference_year}</span></div>  : ''
		const tgt_year = this.context && this.context.year_refs ? <div className='infoblock'><span className='infotitle'>{itop('TGTYEAR')}</span><span>{this.context.year_refs.target_year}</span></div> 	: ''
		const pdata 	= this.context &&
						  !hideMenu    &&
						  this.context.year_refs &&
						  this.context.year_refs.pd_file ? <div className='infoblock'><span className='infotitle'>{itop('TECHPARAMS')}</span><span>{this.context.year_refs.pd_file.slice( 0,10 )}</span></div> :
						  !hideMenu 					 ? <div className='infoblock'><span className='infotitle'>{itop('TECHPARAMS')}</span><span>Default</span></div> :
						  								   <div/>

		const start_download = (link) => (e) => 
		{
			this.setState({downloading   : true, download_id: null})

			axios.get( link )
				 .then( result => {
					 console.log({ download_id: result.data.result.download_id, downloading: false } )
					 this.setState({ download_id: result.data.result.download_id, downloading: false })
				 })

		}

		const HideSection 	= ({children}) => hideMenu ? null : <section>{children}</section>



		return (
			<div className="app-frame sidemenu-off">
				<ReactTooltip />
				<div className="menu">
						<div className='province-selector mainsection'>
							{ hideMenu && <div className='home'><a href="/"><i className='fa fa-home'/></a></div>}
							{!hideMenu && <div className='home'><HLink path={path} province={province} to={`/province/${province}`}><i className='fa fa-home'/></HLink></div>}
							{this.render_province_select()}
						</div>

						<div className='mainsection infosection'>
							<div className='toolbuttons'>
								{this.render_language_select()}
								{
									user && (is_admin || is_nat) &&
											<Link to='/setup' className='toolbutton'>
												<span className='fa fa-tools'/>
												&nbsp;&nbsp;
												{itop('SETUP')}
											</Link>
								}
								{
									user && <div  onClick={logout} className='toolbutton'>
												<span className='fa fa-sign-out-alt'/>
												&nbsp;&nbsp;
												{user.username}
											</div>
								}
								{
									!user && <div onClick={logout} className='user'>
												<span className='fa fa-person'/>
											</div>
								}
							</div>

							<div className='info'>
								{this.context && this.context.connected != true && <span>Connection failure</span>}
								<div className='date'>{dateString && <div className='infoblock'><span className='infotitle'>{itop('PDATA')}</span>{dateString}</div>}</div>
								<div className='date'>{latest_error} {ref_year} {tgt_year} {pdata}</div>
							</div>

						</div>


				</div>
				<div className='province-master'>
					<div className='side-menu'>
						<HideSection>
							<h1><PLink history={history} path={path} province={province} to={`/overviewimage/${province}/1`}>{iside('OVW')}</PLink></h1>
						</HideSection>
						<HideSection>
							<h1><PLink history={history} path={path} province={province} to={`/overviewimage/${province}/2`}>{iside('PED')}</PLink></h1>
							<ul>
								<li><PLink history={history} path={path} province={province} to={`/rawdata/${province}`}>{iside('INP')}</PLink></li>
							</ul>
						</HideSection>


						<HideSection>
							<h1><PLink history={history} path={path} province={province} to={`/overviewimage/${province}/3`}>{iside('ESO')}</PLink></h1>
							<ul>
								<li><PLink history={history} path={path} province={province} to={`/kpi-current/${province}`}>{iside('STATSNOW')}</PLink></li>
								<li><PLink history={history} path={path} province={province} to={`/sankey-current/${province}`}>{iside('SANKEYNOW')}</PLink></li>
								{
									!backend_config.live &&
									<li><PLink history={history} path={path} province={province} to={`/qdata/${province}`}>{iside('QDATA')}</PLink></li>
								}
							</ul>
						</HideSection>


						<HideSection>
							<h1><PLink history={history} path={path} province={province} to={`/overviewimage/${province}/4`}>{iside('PEC')}</PLink></h1>
							<h2><PLink history={history} path={path} province={province} to={`/overviewimage/${province}/5`}>{iside('FRD')}</PLink></h2>
							<ul>
								<li><PLink history={history} path={path} province={province} to={`/demand-development/${province}`}>{iside('DEMDEV')}</PLink></li>
								<li><PLink history={history} path={path} province={province} to={`/techparams/${province}`}>{iside('TECHPARAM')}</PLink></li>
								<li><PLink history={history} path={path} province={province} to={`/potential/${province}`}>{iside('POTENTIAL')}</PLink></li>
								<li><PLink history={history} path={path} province={province} to={`/loadprofile-future/${province}`}>{iside('EDLP')}</PLink></li>
							</ul>
						</HideSection>

						<HideSection>
							<h2><PLink history={history} path={path} province={province} to={`/overviewimage/${province}/6`}>{iside('SCENDEF')}</PLink></h2>
							<ul>
								<li><PLink history={history} path={path} province={province} to={`/scenario/${province}`}>{iside('SCENOVW')}</PLink></li>
							</ul>
						</HideSection>

						{
							has_scenarios &&
							<HideSection>
								<h2><PLink history={history} path={path} province={province} to={`/overviewimage/${province}/7`}>{iside('SCENRES')}</PLink></h2>

								<ul>
									<li><PLink history={history} path={path} province={province} to={`/kpi-future/${province}`}>{iside('STATSFUT')}</PLink></li>
									{
										has_scenarios &&
										<li><PLink history={history} path={path} province={province} to={`/overview-chart/${province}`}>{iside('OVWCHART')}</PLink></li>
									}
									{
										has_scenarios &&
										<li><PLink history={history} path={path} province={province} to={`/supply-demand-chart/${province}`}>{iside('SUPDEMCHART')}</PLink></li>
									}
									{
										has_scenarios &&
										<li><PLink history={history} path={path} province={province} to={`/capacity-chart/${province}`}>{iside('CAPCHART')}</PLink></li>
									}
									{
										has_scenarios &&
										<li><PLink history={history} path={path} province={province} to={`/sankey-future/${province}`}>{iside('SANKEYFUT')}</PLink></li>
									}
									{
										!backend_config.live &&
											<li><PLink history={history} path={path} province={province} to={`/sdata/${province}`}>{iside('SDATA')}</PLink></li>
									}
								</ul>
							</HideSection>
						}
						<div className='filler'/>
						<section>
							<h2>
								{!hideMenu && !this.state.downloading &&
									<div onClick={start_download(`${backend_config.server}/report/pdf?province=${province}&ctr=${Date.now()}`)}>
										<span>{iside('REPORT')}</span>
									</div>}
								{!hideMenu && this.state.downloading &&
									<div>
										<span>{iside('REPORT')}</span>
										{this.state.downloading && <span className="download-icon fa fa-download" />}
									</div>}
							</h2>
							<h2><a href={`/static/pemt-manual-${lang}.pdf`} target="_download">{iside('MANUAL')}</a></h2>
							<h2><PLink history={history} path={path} province='imprint' to={`/imprint`}>{iside('IMPRINT')}</PLink></h2>
						</section>
					</div>

					<div className='province-data'>
						{children}
					</div>
					{
						this.state.download_id &&
						<iframe style={{ display: "hidden" }} src={`${backend_config.server}/report/get-file?id=${this.state.download_id}`}/>
					}
				</div>
			</div>)
	}
}




export default MasterFrame