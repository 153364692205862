import React, { Component, Fragment } from "react";
import classnames from 'classnames';
import backend_config from "../config/runconfig"
import axios from 'axios'
import { AppController } from '../app/AppController'
import MasterFrame from '../app/MasterFrame'
import {BarChartSet} from './BarChartSet'
import { Navigation } from '../navigation/navigation'

import { Help } from '../help/Help'
const API_KEY = 'ExgWFH9XHHyXIHyKEHIH'






export class SummaryChart extends Component {
    static contextType = AppController;
    constructor(props) {
        super(props)
        this.state = {}
    }



    fetch_expression() {
        if (this.inflight)
            return

        

        const component = this
        const url_params = this.get_backend_query()

        this.data_model = null
        const root = `${backend_config.server}/sdata/energydemandchartdata`

        const year_key = this.context ? this.context.year_key : 'NONE'
        const url = `${root}?${url_params}&api_key=${API_KEY}&year_key=${year_key}`

        if (url === this.displayed_url)
            return
        console.log(url)

        this.inflight = true
        axios.get(url)
            .then((incoming) => {

                this.inflight = false
                this.displayed_url = url

                const { data } = incoming

                if (data.status === 'error') {
                    component.setState({ error: true, errorMsg: data.error, chart_data: null })
                }
                else if (!data.result.chart_data || Object.keys(data.result.chart_data).length == 0) {
                    component.setState({ error: true, errorMsg: "No Data", chart_data: null })
                }
                else {

                    console.log( data.result )

                    const new_state = {}
                    new_state.error = false
                    new_state.errorMsg = null
                    new_state.chart_data = data.result.chart_data
                    new_state.units = data.result.units
                    new_state.is_head_data = data.result.is_head_data
                    component.setState(new_state)
                }
            })
    }

    setUnits = (units) => this.setState(units)



    chart_labels = (i18) => {
        return {
            SD_BARD1: {
                label: i18("START:GRAPH1:LEGEND:EL"),
                color: 'rgba(36,62,110,0.8)'
            },

            SD_BARD2: {
                label: i18("START:GRAPH1:LEGEND:HEAT"),
                color: 'rgba(179,20,36,0.8)'
            },


            SD_BARS1: {
                label: i18("START:GRAPH2:LEGEND:NATGAS"),
                label_index: "NATGAS",
                color: 'rgba(204,201,207,0.8)'
            },
            SD_BARS3: {
                label: i18("START:GRAPH2:LEGEND:COAL"),
                label_index: "COAL",
                color: 'rgba(58,57,60,1)'
            },
            SD_BARSBIOM: {
                label: i18("START:GRAPH2:LEGEND:BIOMASS"),
                label_index: "BIOMASS",
                color: '#6EBD2E'
            },
            SD_BARSBIOGAS: {
                label: i18("START:GRAPH2:LEGEND:BIOGAS"),
                label_index: "BIOGAS",
                color: '#C5A0A0'
            },
            SD_SANKEYB5J2: {
                label: i18("START:GRAPH2:LEGEND:REBOILERS"),
                label_index: "REBOILERS",
                color: 'rgb(100,200,110)'
            },
            SD_BARS8: {
                label: i18("START:GRAPH2:LEGEND:PV"),
                label_index: "PV",
                color: 'rgba(243,223,43,0.8)'
            },
            SD_BARS9: {
                label: i18("START:GRAPH2:LEGEND:WIND"),
                label_index: "WIND",
                color: 'rgba(14,27,223,0.8)'
            },
            SD_BARS10: {
                label: i18("START:GRAPH2:LEGEND:HYDRO"),
                label_index: "HYDRO",
                color: 'rgba(11,17,142,0.8)'
            },
            SD_BARS13: {
                label: i18("START:GRAPH2:LEGEND:MSW"),
                label_index: "MSW",
                color: 'rgba(109,45,8,0.8)'
            },
            SD_BARS5: {
                label: i18("START:GRAPH2:LEGEND:FOSBOILERS"),
                label_index: "FOSBOILERS",
                color: 'rgba(219,69,0,0.8)'
            },
            SD_BARS14: {
                label: i18("START:GRAPH2:LEGEND:ELIMPORT"),
                label_index: "ELIMPORT",
                color: 'rgba(93,166,244)'
            }
        }
    }




    add_value_to_chart(set, id, v) {
        set.push({ id: id, value: v })
    }


    setup_subresult_top(data, prefix, postfix, data_set) 
    {
        if (!data)
            return

        const key_list = Object.keys(data)
                               .filter( k => k.startsWith( prefix ) && k.endsWith( postfix ) )

        key_list.sort()


        for (let k of key_list) 
        {
            let     sort_key = k.replace('QD_BAR', 'SD_BAR')
            const   sp       = sort_key.split("_")
            if (sp.length == 3)
                sort_key = `${sp[0]}_${sp[1]}`
            this.add_value_to_chart(data_set, sort_key, data[k])
        }
    }



    setup_chart_data_top(i18, data ) {
        if (!data)
            return null

        data.ref_year = this.context && this.context.year_refs ? `${this.context.year_refs.reference_year}` : ''
        data.tgt_year = this.context && this.context.year_refs ? `${this.context.year_refs.target_year}` : ''

        const dataset_current   = []
        const dataset_bau       = []
        const dataset_ee        = []
        const dataset_eeplus    = []


        const result = {
            set_keys: ['current', 'bau', 'ee', 'eeplus'],
            data: {
                current: {
                    label: i18('START:GRAPH1:HEADER:TODAY'),
                    sub_label: data.ref_year,
                    data: [{ data: dataset_current }]
                },
                bau: {
                    label: i18('SCN:BAU'),
                    sub_label: data.tgt_year,
                    data: [{ data: dataset_bau }]
                },
                ee: {
                    label: i18('SCN:EE'),
                    sub_label: data.tgt_year,
                    data: [{ data: dataset_ee }]
                },
                eeplus: {
                    label: i18('SCN:EEPLUS'),
                    sub_label: data.tgt_year,
                    data: [{ data: dataset_eeplus }]
                }
            }
        }


        this.setup_subresult_top(data, "QD_BARD", "",      dataset_current)
        this.setup_subresult_top(data, "SD_BARD", "1",     dataset_bau)
        this.setup_subresult_top(data, "SD_BARD", "7",     dataset_ee)
        this.setup_subresult_top(data, "SD_BARD", "13",    dataset_eeplus)

        return result
    }




    setup_chart_data_for_bot(i18,  data, key_set ) {
        if (!data)
            return null

        const labels = {}
        const result = {}
        const keys = []

        const clabels = this.chart_labels(i18)
    

        for (let entry_i in key_set) 
        {
            const entry = key_set[entry_i]
            if (entry.index.startsWith( "SD_BARD"))
                continue

            const index = entry.index

            const data_set_bau      = []
            const data_set_ee       = []
            const data_set_eeplus   = []

            const data_set = [
                { label: "BAU", data: data_set_bau },
                { label: "EE",  data: data_set_ee },
                { label: "EE+", data: data_set_eeplus }
            ]

            this.add_value_to_chart(data_set_bau,       index,  data[`${index}_1`])
            this.add_value_to_chart(data_set_ee,        index,  data[`${index}_7`])
            this.add_value_to_chart(data_set_eeplus,    index,  data[`${index}_13`])

            const key = `P${entry_i}`
            if (keys.indexOf(key) < 0)
                keys.push(key)

            console.log(entry.index)

            result[key] = {
                label: i18(`START:GRAPH2:XLABEL2:${clabels[entry.index].label_index}`),
                top_label: "TOP LABEL",
                sub_label: null,
                target: null,
                data: data_set,
                labels: labels
            }
        }

        const rv = {
            data: result,
            set_keys: keys,
            draw_legend: false,
            labels: labels
        }

        return rv
    }





    setup_chart_data_bot(i18, data ) {
        if (!data)
            return null


        const key_map = [
            { index: "SD_BARD1"      },
            { index: "SD_BARD2"      },
            { index: "SD_BARS1"      },
            { index: "SD_BARS3"      },
            { index: "SD_BARSBIOM"   },
            { index: "SD_BARSBIOGAS" },
            { index: "SD_SANKEYB5J2" },
            { index: "SD_BARS8"      },
            { index: "SD_BARS9"      },
            { index: "SD_BARS10"     },
            { index: "SD_BARS13"     },
            { index: "SD_BARS5"      },
            { index: "SD_BARS14"     },
        ]

        return this.setup_chart_data_for_bot(i18,  data, key_map )
    }



    setup_chart_data(i18, data) 
    {
        if (!data)
            return null

        const result = {}


        return this.setup_chart_data_top( i18, data )
    }


    render_chart(i18, data_model) 
    {

        if (!data_model || !data_model.top)
            return <div/>
        return <div className='chart-container'>
            <div className='chart-3'>
                {
                    data_model.top &&
                        <div className='subchart'>
                            <div className='chart-set'>
                                <BarChartSet data={data_model.top}
                                    y_axis_label={i18('START:GRAPH1:YLABEL')}
                                    y_axis_unit={`${this.get_energy()}`}
                                    groups={[0]}
                                    labels={this.chart_labels(i18)} />
                            </div>
                        </div>
                }
                {
                    data_model.bot &&
                    <div className='subchart'>
                        <div className='chart-set'>
                            <BarChartSet data={data_model.bot}
                                y_axis_label={i18('START:GRAPH2:YLABEL')}
                                y_axis_unit={`${this.get_energy()}`}
                                groups={[0,1,2]}
                                labels={this.chart_labels(i18)} />
                        </div>
                    </div>
                }
            </div>
        </div>
    }



    render_error() {
        return <div className='sankey-menu'>
            <div className='warning section'>Data for scenario is not available</div>
            <Help id='summarychart' />
        </div>
    }



    componentDidUpdate() {
        this.fetch_expression()
    }



    componentDidMount() {
        this.fetch_expression()
    }



    render() {

        if (!this.context || !this.context.ctx.i18nstr)
            return <div />

        const i18 = this.context.ctx.i18nstrp("CHART:SUMMARY")

        if (!this.data_model)
            this.data_model =   {
                                    top: this.setup_chart_data_top(i18, this.state.chart_data),
                                    bot: this.setup_chart_data_bot(i18, this.state.chart_data),
                                }


        const { province } = this.props.match.params



        const { match, history } = this.props

        return <MasterFrame  history={history} match={match}>
            <div className='sankey-main'>
                <div className='sankey-menu'>
                    {
                        this.variant_selector()
                    }
                    {
                        this.unit_selector({ area: false, currency: false })
                    }
                    {
                        this.data_model && this.state && this.state.is_head_data == false &&
                        <div className='warning section'>
                            <i className='fa fa-exclamation-circle' />
                            Diagram is not based on latest data
                        </div>
                    }
                    {
                        this.state.error &&
                        <div className='section warning'>Connection Error</div>
                    }

                    <Help id='summarychart' />
                </div>
                <div className='sankey-container with-expression'>
                    <div className={classnames("bottom", "grid-on",
                        "sidebar-on")}>
                        {!this.state.error && this.data_model && this.render_chart(i18, this.data_model)}
                    </div>
                </div>
            </div>
        </MasterFrame>
    }
}

Object.assign(SummaryChart.prototype, Navigation)

// TotalDemand	S3
// Fossil Fuel  S2
// RE Supply	S1



export default SummaryChart;