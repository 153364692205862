
import React, { Component,Fragment }	from "react";
import {Link} 							from 'react-router-dom'
import ReactTooltip 					from 'react-tooltip'
import backend_config					from "../config/runconfig.js"
import {AppController} 					from '../app/AppController'
import axios 							from 'axios'


const SLink = ({to, path, children}) =>
{
	const selectedClass =  (to && path && to.startsWith( path )) ? 'selected-entry' : null

	if (!to)
		return <div>{children}</div>
	else
		return <Link className={selectedClass} to={to}>{children}</Link>
}





class SetupFrame  extends Component
{
	static contextType = AppController;

	constructor( props )
	{
		super( props )
		this.state 	= {
			count 				: 0
		}
	}


	componentDidUpdate()
	{
	}


	handle_language = (e) => {
		if (this.context && this.context.i18n_controller)
			this.context.i18n_controller.set_lang(e.target.value)
	}


	render_language_select() {
		const lang = this.context?.lang || 'en'
		return <select onChange={this.handle_language} value={lang}>
			<option value='en'>English</option>
			<option value='th'>ภาษาไทย</option>
		</select>
	}


	render()
	{
		const {match, children, history} = this.props

		const path = history.location.pathname

		if (!this.context || !this.context.ctx.i18nstr)
			return <div />

		const itop = this.context.ctx.i18nstrp("TOPMENU")
		const iside = this.context.ctx.i18nstrp()

		const user 		= this.context.ctx.user
		const logout	= this.context.user_controller ? this.context.user_controller.logout_user : null

		const role 		= user && user.role && user.role.kind
		const is_admin	= role == 'admin'
		const is_nat	= role == 'national'
		const hideMenu 	= false

		const ref_year = this.context && this.context.year_refs ? <div className='infoblock'><span className='infotitle'>{itop('REFYEAR')}</span><span>{this.context.year_refs.reference_year}</span></div> : ''
		const tgt_year = this.context && this.context.year_refs ? <div className='infoblock'><span className='infotitle'>{itop('TGTYEAR')}</span><span>{this.context.year_refs.target_year}</span></div> : ''
		const pdata = this.context &&
			!hideMenu &&
			this.context.year_refs &&
			this.context.year_refs.pd_file ? <div className='infoblock'><span className='infotitle'>{itop('TECHPARAMS')}</span><span>{this.context.year_refs.pd_file.slice(0, 10)}</span></div> :
			!hideMenu ? <div className='infoblock'><span className='infotitle'>{itop('TECHPARAMS')}</span><span>Default</span></div> :
				<div />

		return (
			<div className="app-frame sidemenu-off">
				<ReactTooltip />
				<div className="menu">
					<div className='province-selector mainsection'>
						<div className='home'><Link to={`/`}><i className='fa fa-home'/></Link></div>
					</div>

					<div className='mainsection infosection'>
						<div className='toolbuttons'>
							{this.render_language_select()}
							{this.context && this.context.connected != true && <span>Connection failure</span>}
							{
								user && (is_admin || is_nat) &&
										<Link to='/setup' className='toolbutton'>
											<span className='fa fa-tools'/>
											&nbsp;&nbsp;
											{itop('SETUP')}
										</Link>
							}
							{
								user && <div  onClick={logout} className='toolbutton'>
											<span className='fa fa-sign-out-alt'/>
											&nbsp;&nbsp;
											{user.username}
										</div>
							}
							{
								!user && <div onClick={logout} className='user'>
											<span className='fa fa-person'/>
										</div>
							}
						</div>

						<div className='info'>
							<div className='date'>{ref_year} {tgt_year} {pdata}</div>
						</div>
					</div>
				</div>
				<div className='province-master'>
					<div className='side-menu'>
						<section>
							{
								(is_admin || is_nat) &&
								<h1><SLink path={path} to={`/sync`}>{iside('SETUPMENU:UPDATE')}</SLink></h1>
							}
						</section>
						<section>
							{
								(is_admin || is_nat) &&
								<h1><SLink path={path} to={`/targetyear`}>{iside('SETUPMENU:CONFIG')}</SLink></h1>
							}
						</section>
						<section>
							{
								is_admin &&
								<h1><SLink path={path} to={`/upload-params`}>{iside('SETUPMENU:UPLOAD')}</SLink></h1>
							}
						</section>
						<section>
							{
								is_admin &&
								<h1><SLink path={path} to={`/users`}>{iside('SETUPMENU:USER')}</SLink></h1>
							}
						</section>
						<section>
							{
								<h1><SLink path={path} to={`/tasks`}>{iside('SETUPMENU:KOMMOD')}</SLink></h1>
							}
						</section>
						<div className='filler'/>
						<section>
							<h2><a href="/static/pemt-manual.pdf" target="_download">{iside('SIDEMENU:MANUAL')}</a></h2>
							<h2><SLink path={path} province='imprint' to={`/imprint`}>{iside('SIDEMENU:IMPRINT')}</SLink></h2>
						</section>					</div>
					<div className='province-data'>
						{children}
					</div>
				</div>
			</div>)
	}
}




export default SetupFrame